import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: any; output: any };
  Upload: { input: any; output: any };
};

export type Account = {
  __typename?: 'Account';
  accentColor?: Maybe<Scalars['String']['output']>;
  apiKey?: Maybe<Scalars['String']['output']>;
  contractTemplateManagement: Scalars['Boolean']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customSettings: AccountCustomSettings;
  datevExports: AccountExportSetting;
  defaultCompetitionLeagues: Array<CompetitionLeague>;
  defaultSeason?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  documentOutputFormat: DocumentOutputFormat;
  exports: AccountExportSetting;
  id: Scalars['String']['output'];
  inventoryBlockProbability: Scalars['Float']['output'];
  inventoryCategories: Array<InventoryCategory>;
  invoiceCategories: Array<InvoiceCategory>;
  invoicing: AccountInvoiceSettings;
  name: Scalars['String']['output'];
  partnerCategories: Array<PartnerCategory>;
  topCategories: Array<TopCategory>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userAccounts?: Maybe<Array<UserAccount>>;
  users: Array<User>;
  workflows: AccountWorkflowSetting;
};

export type AccountCustomSettings = {
  __typename?: 'AccountCustomSettings';
  disablePartnerManagement: Scalars['Boolean']['output'];
  showExternalInventoryId: Scalars['Boolean']['output'];
  showExternalPartnerContactId: Scalars['Boolean']['output'];
};

export type AccountCustomSettingsInput = {
  disablePartnerManagement: Scalars['Boolean']['input'];
  showExternalInventoryId: Scalars['Boolean']['input'];
  showExternalPartnerContactId: Scalars['Boolean']['input'];
};

export type AccountDatevExportSettingInput = {
  enabled: Scalars['Boolean']['input'];
};

export type AccountEdge = {
  __typename?: 'AccountEdge';
  node: Account;
};

export type AccountExportSetting = {
  __typename?: 'AccountExportSetting';
  enabled: Scalars['Boolean']['output'];
};

export type AccountExportSettingInput = {
  enabled: Scalars['Boolean']['input'];
};

export type AccountInvoiceSettingInput = {
  apiOnly?: InputMaybe<Scalars['Boolean']['input']>;
  byAccountingOnly?: InputMaybe<Scalars['Boolean']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  exportTemplate?: InputMaybe<Scalars['String']['input']>;
  showInvoiceStatsInContractsTable?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  valueAddTax?: InputMaybe<Scalars['Float']['input']>;
};

export type AccountInvoiceSettings = {
  __typename?: 'AccountInvoiceSettings';
  apiOnly: Scalars['Boolean']['output'];
  byAccountingOnly: Scalars['Boolean']['output'];
  enabled: Scalars['Boolean']['output'];
  exportTemplate: Scalars['String']['output'];
  showInvoiceStatsInContractsTable: Scalars['Boolean']['output'];
  startDate?: Maybe<Scalars['DateTime']['output']>;
  valueAddTax: Scalars['Float']['output'];
};

export type AccountWorkflowSetting = {
  __typename?: 'AccountWorkflowSetting';
  enabled: Scalars['Boolean']['output'];
  startDate?: Maybe<Scalars['DateTime']['output']>;
};

export type AccountWorkflowSettingInput = {
  enabled: Scalars['Boolean']['input'];
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AdminCreateCompetitionInput = {
  accountId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type AdminCreateLeagueInput = {
  accountId: Scalars['String']['input'];
  competitionId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type Author = {
  __typename?: 'Author';
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export enum AutoRenewMode {
  Once = 'ONCE',
  Yearly = 'YEARLY',
}

export type Bonus = {
  __typename?: 'Bonus';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  items: Array<BonusItem>;
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type BonusEdge = {
  __typename?: 'BonusEdge';
  node: Bonus;
};

export type BonusItem = {
  __typename?: 'BonusItem';
  achieved: Scalars['Boolean']['output'];
  contracts: Array<BonusItemContract>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  offered: Scalars['Float']['output'];
  offers: Array<BonusItemContract>;
  price: Scalars['Float']['output'];
  season: Season;
  sold: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type BonusItemContract = {
  __typename?: 'BonusItemContract';
  achieved: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  partner: Partner;
  price: Scalars['Float']['output'];
};

export type BonusStats = {
  __typename?: 'BonusStats';
  achievedCount: Scalars['Float']['output'];
  achievedRevenue: Scalars['Float']['output'];
};

export type Bundle = {
  __typename?: 'Bundle';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  items: Array<BundleItem>;
  league: League;
  name: Scalars['String']['output'];
  season: Season;
  sold: Scalars['Float']['output'];
  totalAvailable: Scalars['Float']['output'];
  totalPrice: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type BundleEdge = {
  __typename?: 'BundleEdge';
  node: Bundle;
};

export type BundleItem = {
  __typename?: 'BundleItem';
  amount: Scalars['Float']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  inventory: Inventory;
  listUnitPrice: Scalars['Float']['output'];
  price: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ChangeUserEmailInput = {
  email: Scalars['String']['input'];
};

export type ChartSeries = {
  __typename?: 'ChartSeries';
  data: Array<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
};

export type Claim = {
  __typename?: 'Claim';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type Competition = {
  __typename?: 'Competition';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  leagues: Array<League>;
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CompetitionEdge = {
  __typename?: 'CompetitionEdge';
  node: Competition;
};

export type CompetitionLeague = {
  __typename?: 'CompetitionLeague';
  competitionId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  leagueId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CompetitionLeagueInput = {
  competitionId?: InputMaybe<Scalars['String']['input']>;
  leagueId?: InputMaybe<Scalars['String']['input']>;
};

export enum ContactRole {
  InvoiceReceiver = 'INVOICE_RECEIVER',
  Main = 'MAIN',
  Signatory = 'SIGNATORY',
  Ticketing = 'TICKETING',
}

export type Contract = {
  __typename?: 'Contract';
  autoRenew?: Maybe<ContractAutoRenew>;
  bonuses: Array<ContractBonus>;
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  clubLead?: Maybe<User>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  draft: Scalars['Boolean']['output'];
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  invoices: Array<Invoice>;
  invoicesDisabled: Scalars['Boolean']['output'];
  items: Array<ContractItem>;
  name?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Array<ContractNote>>;
  partner: Partner;
  probability: Scalars['Float']['output'];
  salesLead?: Maybe<User>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  tasks?: Maybe<Array<Task>>;
  template?: Maybe<ContractTemplate>;
  total: ContractTotals;
  type: ContractType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ContractAutoRenew = {
  __typename?: 'ContractAutoRenew';
  cancellationPeriodEnd?: Maybe<Scalars['DateTime']['output']>;
  enabled: Scalars['Boolean']['output'];
  markedForCancellationAt?: Maybe<Scalars['DateTime']['output']>;
  mode: AutoRenewMode;
  renewYears: Scalars['Float']['output'];
};

export type ContractBonus = {
  __typename?: 'ContractBonus';
  achieved: Scalars['Boolean']['output'];
  bonusDescription?: Maybe<Scalars['String']['output']>;
  bonusId: Scalars['String']['output'];
  bonusName: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  season: Season;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum ContractCancelStatus {
  CanceledByClub = 'CANCELED_BY_CLUB',
  CanceledByCustomer = 'CANCELED_BY_CUSTOMER',
  Expired = 'EXPIRED',
}

export type ContractEdge = {
  __typename?: 'ContractEdge';
  node: Contract;
};

export type ContractInvoiceInput = {
  date: Scalars['DateTime']['input'];
  paymentDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  percentage: Scalars['Float']['input'];
  seasonId: Scalars['String']['input'];
};

export type ContractItem = {
  __typename?: 'ContractItem';
  amount: Scalars['Float']['output'];
  barter: Scalars['Float']['output'];
  bundle?: Maybe<ContractItemBundle>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  inventory: Inventory;
  league: League;
  listUnitPrice: Scalars['Float']['output'];
  locked: Scalars['Boolean']['output'];
  match?: Maybe<Match>;
  price: Scalars['Float']['output'];
  season: Season;
  task?: Maybe<Task>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ContractItemBundle = {
  __typename?: 'ContractItemBundle';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ContractNote = {
  __typename?: 'ContractNote';
  author: Author;
  content: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum ContractRelevanceFilter {
  Current = 'CURRENT',
  Future = 'FUTURE',
  Old = 'OLD',
}

export type ContractRenewInput = {
  cancellationPeriodEnd?: InputMaybe<Scalars['DateTime']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  markedForCancellationAt?: InputMaybe<Scalars['DateTime']['input']>;
  mode?: InputMaybe<AutoRenewMode>;
  renewYears?: InputMaybe<Scalars['Float']['input']>;
};

export type ContractStats = {
  __typename?: 'ContractStats';
  bonuses: Scalars['Float']['output'];
  bonusesAchieved: Scalars['Float']['output'];
  bonusesAchievedValue: Scalars['Float']['output'];
  bonusesValue: Scalars['Float']['output'];
  discount: Scalars['Float']['output'];
  futureInvoiceTotal?: Maybe<Scalars['Float']['output']>;
  openInvoiceTotal?: Maybe<Scalars['Float']['output']>;
  overdueInvoiceTotal?: Maybe<Scalars['Float']['output']>;
  payedInvoiceTotal?: Maybe<Scalars['Float']['output']>;
  pendingInvoiceTotal?: Maybe<Scalars['Float']['output']>;
  totalBarter: Scalars['Float']['output'];
  totalContracts: Scalars['Float']['output'];
  totalDone?: Maybe<Scalars['Float']['output']>;
  totalInProgress?: Maybe<Scalars['Float']['output']>;
  totalOpen?: Maybe<Scalars['Float']['output']>;
  totalPrice: Scalars['Float']['output'];
  totalSubTasks?: Maybe<Scalars['Float']['output']>;
  totalTasks?: Maybe<Scalars['Float']['output']>;
};

export enum ContractStatusFilter {
  Canceled = 'CANCELED',
  Draft = 'DRAFT',
  Existing = 'EXISTING',
  Future = 'FUTURE',
  New = 'NEW',
  Open = 'OPEN',
}

export type ContractTemplate = {
  __typename?: 'ContractTemplate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  template: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ContractTemplateEdge = {
  __typename?: 'ContractTemplateEdge';
  node: ContractTemplate;
};

export type ContractTotals = {
  __typename?: 'ContractTotals';
  barter: Scalars['Float']['output'];
  discount: Scalars['Float']['output'];
  listPrice: Scalars['Float']['output'];
  price: Scalars['Float']['output'];
};

export enum ContractType {
  Contract = 'CONTRACT',
  Offer = 'OFFER',
}

export type CreateAccountInput = {
  name: Scalars['String']['input'];
  startMonth: Scalars['Float']['input'];
  startYear: Scalars['Float']['input'];
};

export type CreateBonusInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  seasonId: Scalars['String']['input'];
};

export type CreateBundleInput = {
  leagueId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  seasonId: Scalars['String']['input'];
};

export type CreateBundleItemInput = {
  amount: Scalars['Float']['input'];
  inventoryId: Scalars['String']['input'];
  listPriceId: Scalars['String']['input'];
  price: Scalars['Float']['input'];
};

export type CreateCompetitionInput = {
  name: Scalars['String']['input'];
};

export type CreateContractBonusInput = {
  bonusId: Scalars['String']['input'];
};

export type CreateContractInput = {
  autoRenew?: InputMaybe<ContractRenewInput>;
  clubLeadId?: InputMaybe<Scalars['String']['input']>;
  contractDescription?: InputMaybe<Scalars['String']['input']>;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  invoicesDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  partnerId: Scalars['String']['input'];
  probability?: InputMaybe<Scalars['Float']['input']>;
  salesLeadId?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  templateId?: InputMaybe<Scalars['String']['input']>;
  type: ContractType;
};

export type CreateContractItemBulkInput = {
  amount: Scalars['Float']['input'];
  inventoryId: Scalars['String']['input'];
  price: Scalars['Float']['input'];
};

export type CreateContractItemInput = {
  amount: Scalars['Float']['input'];
  inventoryId: Scalars['String']['input'];
  leagueId: Scalars['String']['input'];
  listPriceId: Scalars['String']['input'];
  matchId?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['Float']['input'];
  seasonId: Scalars['String']['input'];
};

export type CreateContractNoteInput = {
  content: Scalars['String']['input'];
};

export type CreateContractTemplateInput = {
  name: Scalars['String']['input'];
  template: Scalars['String']['input'];
};

export type CreateInventoryCategoryInput = {
  name: Scalars['String']['input'];
  order?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateInventoryInput = {
  categoryId: Scalars['String']['input'];
  contractDescription?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  keyRightOrder?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Float']['input']>;
  soldByMatch?: InputMaybe<Scalars['Boolean']['input']>;
  soldBySeason?: InputMaybe<Scalars['Boolean']['input']>;
  topCategoryId?: InputMaybe<Scalars['String']['input']>;
  unit: UnitType;
};

export type CreateInvoiceCategoryInput = {
  accountingNumber?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  order?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateLeagueInput = {
  competitionId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateListPriceItemInput = {
  leagueId: Scalars['String']['input'];
  maxDiscount?: InputMaybe<Scalars['Float']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['Float']['input'];
  quantity: Scalars['Float']['input'];
  seasonId: Scalars['String']['input'];
  type: InventoryType;
};

export type CreatePartnerCategoryInput = {
  name: Scalars['String']['input'];
  order?: InputMaybe<Scalars['Float']['input']>;
};

export type CreatePartnerContactInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumber2?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<ContactRole>>;
};

export type CreatePartnerInput = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  clubLeadId?: InputMaybe<Scalars['String']['input']>;
  companyName: Scalars['String']['input'];
  contacts?: InputMaybe<Array<CreatePartnerContactInput>>;
  country?: InputMaybe<Scalars['String']['input']>;
  customerNumber?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  organizationType?: InputMaybe<OrganizationType>;
  partnerSince?: InputMaybe<Scalars['DateTime']['input']>;
  salesLeadId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePartnerNoteInput = {
  content: Scalars['String']['input'];
};

export type CreateSeasonInput = {
  competitionLeagues?: InputMaybe<Array<CompetitionLeagueInput>>;
  endDate: Scalars['DateTime']['input'];
  invoicing?: InputMaybe<UpdateSeasonInvoicing>;
  name: Scalars['String']['input'];
  startDate: Scalars['DateTime']['input'];
  targetRevenue?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateSeasonMatchInput = {
  date: Scalars['DateTime']['input'];
  leagueId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateSubTaskInput = {
  count?: InputMaybe<Scalars['Float']['input']>;
  deadline?: InputMaybe<Scalars['DateTime']['input']>;
  lead?: InputMaybe<TaskLeadInput>;
  matchId?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<TaskStatus>;
};

export type CreateTaskInput = {
  contractId: Scalars['String']['input'];
  customAmount?: InputMaybe<Scalars['Float']['input']>;
  matchIds?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  seasonId: Scalars['String']['input'];
  totalTasks?: InputMaybe<Scalars['Float']['input']>;
  type: TaskType;
};

export type CreateTopCategoryInput = {
  invoiceCategoryId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  order?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateUserInput = {
  accounts: Array<UserAccountInput>;
  admin?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
};

export type DashboardContractStats = {
  __typename?: 'DashboardContractStats';
  autoRenewOpen: Scalars['Float']['output'];
  autoRenewTotal: Scalars['Float']['output'];
  cancellations: Scalars['Float']['output'];
  expired: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type DashboardDiscount = {
  __typename?: 'DashboardDiscount';
  average: Scalars['Float']['output'];
};

export type DashboardForecast = {
  __typename?: 'DashboardForecast';
  actual: Scalars['Float']['output'];
  actualToPlan: Scalars['Float']['output'];
  chartSeries: Array<ForecastChartSeries>;
  forecast: Scalars['Float']['output'];
  forecastToPlan: Scalars['Float']['output'];
  planned: Scalars['Float']['output'];
};

export type DashboardInventoryStats = {
  __typename?: 'DashboardInventoryStats';
  stats: InventoryStats;
  topCategoryRevenues: Array<TopCategoryChartData>;
};

export type DashboardOffers = {
  __typename?: 'DashboardOffers';
  amountSeries: Array<ChartSeries>;
  categories: Array<Scalars['String']['output']>;
  valueSeries: Array<ChartSeries>;
};

export type DashboardPartnerAndContracts = {
  __typename?: 'DashboardPartnerAndContracts';
  contract: DashboardContractStats;
  partner: DashboardPartnerStats;
};

export type DashboardPartnerStats = {
  __typename?: 'DashboardPartnerStats';
  existing: Scalars['Float']['output'];
  fixed: Scalars['Float']['output'];
  leads: Scalars['Float']['output'];
  new: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type DashboardRevenueDetails = {
  __typename?: 'DashboardRevenueDetails';
  bonus: Scalars['Float']['output'];
  contracts: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
  totalBarter: Scalars['Float']['output'];
  totalPrice: Scalars['Float']['output'];
};

export enum DocumentOutputFormat {
  Docx = 'DOCX',
  Pdf = 'PDF',
}

export type ExportedInvoice = {
  __typename?: 'ExportedInvoice';
  amount: Scalars['Float']['output'];
  companyName: Scalars['String']['output'];
  contractId: Scalars['String']['output'];
  docType: InvoiceDocType;
  fileExists: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  invoiceDate: Scalars['DateTime']['output'];
  invoiceId: Scalars['String']['output'];
  invoiceNumber: Scalars['Float']['output'];
  pdf: Scalars['String']['output'];
  seasonId: Scalars['String']['output'];
  type: InvoiceType;
};

export type ForecastChartSeries = {
  __typename?: 'ForecastChartSeries';
  data: Array<Array<Scalars['Float']['output']>>;
  name: Scalars['String']['output'];
};

export type Inventory = {
  __typename?: 'Inventory';
  category?: Maybe<InventoryCategory>;
  contractDescription?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  keyRightOrder?: Maybe<Scalars['Float']['output']>;
  listPrices: Array<InventoryListPrice>;
  name: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  order: Scalars['Float']['output'];
  soldByMatch: Scalars['Boolean']['output'];
  soldBySeason: Scalars['Boolean']['output'];
  stats?: Maybe<InventoryStats>;
  tasks?: Maybe<InventoryTasks>;
  topCategory?: Maybe<TopCategory>;
  unit: UnitType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum InventoryAvailabilityFilter {
  Available = 'AVAILABLE',
  Sold = 'SOLD',
}

export type InventoryCategory = {
  __typename?: 'InventoryCategory';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Float']['output'];
  stats?: Maybe<InventoryStats>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type InventoryDetailContract = {
  __typename?: 'InventoryDetailContract';
  amount: Scalars['Float']['output'];
  discount: Scalars['Float']['output'];
  endDate: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  items: Array<ContractItem>;
  partnerId: Scalars['String']['output'];
  partnerName: Scalars['String']['output'];
  probability: Scalars['Float']['output'];
  revenue: Scalars['Float']['output'];
  totalValue: Scalars['Float']['output'];
  type: ContractType;
  unit: UnitType;
};

export type InventoryDetails = {
  __typename?: 'InventoryDetails';
  contractsBySeason: Array<SeasonInventoryDetailContract>;
  revenueChart: InventoryRevenueChartData;
};

export type InventoryListPrice = {
  __typename?: 'InventoryListPrice';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  league: League;
  matchStats?: Maybe<Array<InventoryMatchStats>>;
  maxDiscount?: Maybe<Scalars['Float']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  price: Scalars['Float']['output'];
  quantity: Scalars['Float']['output'];
  season: Season;
  stats?: Maybe<InventoryStats>;
  type: InventoryType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type InventoryMatchStats = {
  __typename?: 'InventoryMatchStats';
  available: Scalars['Float']['output'];
  availableValue: Scalars['Float']['output'];
  blocked: Scalars['Float']['output'];
  blockedValue: Scalars['Float']['output'];
  discount: Scalars['Float']['output'];
  match: Match;
  offered: Scalars['Float']['output'];
  offeredValue: Scalars['Float']['output'];
  sold: Scalars['Float']['output'];
  soldBySeason: Scalars['Float']['output'];
  soldRevenue: Scalars['Float']['output'];
  soldValue: Scalars['Float']['output'];
};

export type InventoryRevenueChartData = {
  __typename?: 'InventoryRevenueChartData';
  revenueSeries: TimeSeries;
  totalValueSeries: TimeSeries;
};

export type InventoryStats = {
  __typename?: 'InventoryStats';
  available?: Maybe<Scalars['Float']['output']>;
  availableValue?: Maybe<Scalars['Float']['output']>;
  blocked?: Maybe<Scalars['Float']['output']>;
  blockedValue?: Maybe<Scalars['Float']['output']>;
  capacityUtilization?: Maybe<Scalars['Float']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  offered?: Maybe<Scalars['Float']['output']>;
  offeredValue?: Maybe<Scalars['Float']['output']>;
  sold?: Maybe<Scalars['Float']['output']>;
  soldRevenue?: Maybe<Scalars['Float']['output']>;
  soldValue?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  totalValue?: Maybe<Scalars['Float']['output']>;
};

export type InventoryTasks = {
  __typename?: 'InventoryTasks';
  contracts: Array<MatchTaskContract>;
  stats: TaskStats;
};

export enum InventoryType {
  Match = 'MATCH',
  Season = 'SEASON',
}

export type InventoryWithTotals = {
  __typename?: 'InventoryWithTotals';
  inventory: Array<Inventory>;
  totals: InventoryStats;
};

export type Invoice = {
  __typename?: 'Invoice';
  bonusId?: Maybe<Scalars['String']['output']>;
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  cancellationNumber?: Maybe<Scalars['Float']['output']>;
  date: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  invoiceNumber?: Maybe<Scalars['Float']['output']>;
  invoicedAt?: Maybe<Scalars['DateTime']['output']>;
  payedAt?: Maybe<Scalars['DateTime']['output']>;
  paymentDueAt?: Maybe<Scalars['DateTime']['output']>;
  percentage?: Maybe<Scalars['Float']['output']>;
  season: Season;
  serviceEndedAt?: Maybe<Scalars['DateTime']['output']>;
  serviceStartedAt?: Maybe<Scalars['DateTime']['output']>;
  type: InvoiceType;
};

export type InvoiceCategory = {
  __typename?: 'InvoiceCategory';
  accountingNumber?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum InvoiceDocType {
  Cancellation = 'CANCELLATION',
  Invoice = 'INVOICE',
}

export type InvoiceExport = {
  __typename?: 'InvoiceExport';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  exportedBy: User;
  id: Scalars['String']['output'];
  invoices: Array<ExportedInvoice>;
  zipFile: Scalars['String']['output'];
};

export type InvoiceExportEdge = {
  __typename?: 'InvoiceExportEdge';
  node: InvoiceExport;
};

export enum InvoiceType {
  Barter = 'BARTER',
  Bonus = 'BONUS',
  Percentage = 'PERCENTAGE',
}

export enum InvoiceTypeArg {
  Barter = 'BARTER',
  Percentage = 'PERCENTAGE',
}

export type LastEdit = {
  __typename?: 'LastEdit';
  timestamp: Scalars['DateTime']['output'];
  user: LastUser;
};

export type LastUser = {
  __typename?: 'LastUser';
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type League = {
  __typename?: 'League';
  competitionId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type LeagueEdge = {
  __typename?: 'LeagueEdge';
  node: League;
};

export type Match = {
  __typename?: 'Match';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  date: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  league: League;
  name: Scalars['String']['output'];
  taskStats?: Maybe<TaskStats>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type MatchTask = {
  __typename?: 'MatchTask';
  date: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  inventory: Array<MatchTaskInventory>;
  league: League;
  name: Scalars['String']['output'];
  stats: TaskStats;
};

export type MatchTaskContract = {
  __typename?: 'MatchTaskContract';
  id: Scalars['String']['output'];
  partner: Partner;
  stats: TaskStats;
  tasks: Array<Task>;
};

export type MatchTaskInventory = {
  __typename?: 'MatchTaskInventory';
  contracts: Array<MatchTaskContract>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  stats: TaskStats;
  unit: UnitType;
};

export type MatchTasksWithTotals = {
  __typename?: 'MatchTasksWithTotals';
  match: MatchTask;
};

export type Mutation = {
  __typename?: 'Mutation';
  acquireLoginToken: Scalars['String']['output'];
  adminCreateCompetition: Competition;
  adminCreateLeague: League;
  adminRemoveCompetition: Scalars['Boolean']['output'];
  adminRemoveLeague: Scalars['Boolean']['output'];
  adminUpdateCompetition: Competition;
  adminUpdateLeague: League;
  applyContractPercentage: Contract;
  cancelContract: Contract;
  changeUserEmail: User;
  cloneContract: Contract;
  configureSeasonWorkflow: Scalars['Boolean']['output'];
  contractAddBundle: Contract;
  contractRemoveBundle: Contract;
  convertToContract: Contract;
  convertToPdf: Scalars['String']['output'];
  createAccount: Account;
  createBonus: Bonus;
  createBundle: Bundle;
  createBundleItem: BundleItem;
  createCompetition: Competition;
  createContract: Contract;
  createContractBonus: Contract;
  createContractItem: ContractItem;
  createContractItemBulk: Contract;
  createContractNote: ContractNote;
  createContractTemplate: ContractTemplate;
  createInventory: Inventory;
  createInventoryCategory: InventoryCategory;
  createInventoryListPrice: InventoryListPrice;
  createInvoiceCategory: InvoiceCategory;
  createInvoiceExport?: Maybe<InvoiceExport>;
  createLeague: League;
  createNextSeason: Season;
  createOrUpdateContractItemBulk: Contract;
  createPartner: Partner;
  createPartnerCategory: PartnerCategory;
  createPartnerContact: PartnerContact;
  createPartnerNote: PartnerNote;
  createSeason: Season;
  createSeasonMatch: Match;
  createSubTask: SubTask;
  createTask: Task;
  createTasksForContract: Scalars['Boolean']['output'];
  createTopCategory: TopCategory;
  createUser: User;
  issueContractInvoice: Invoice;
  issueContractInvoiceCancellationNumber: Invoice;
  releaseLoginToken: Scalars['Boolean']['output'];
  removeAccount: Scalars['Boolean']['output'];
  removeBonus: Scalars['Boolean']['output'];
  removeBundle: Scalars['Boolean']['output'];
  removeBundleItem: Scalars['Boolean']['output'];
  removeCompetition: Scalars['Boolean']['output'];
  removeContract: Scalars['Boolean']['output'];
  removeContractBonus: Scalars['Boolean']['output'];
  removeContractItem: Scalars['Boolean']['output'];
  removeContractItemBulk: Scalars['Boolean']['output'];
  removeContractNote: Scalars['Boolean']['output'];
  removeContractTemplate: Scalars['Boolean']['output'];
  removeInventory: Scalars['Boolean']['output'];
  removeInventoryCategory: Scalars['Boolean']['output'];
  removeInventoryListPrice: Scalars['Boolean']['output'];
  removeInvoiceCategory: Scalars['Boolean']['output'];
  removeLeague: Scalars['Boolean']['output'];
  removePartner: Scalars['Boolean']['output'];
  removePartnerCategory: Scalars['Boolean']['output'];
  removePartnerContact: Scalars['Boolean']['output'];
  removePartnerNote: Scalars['Boolean']['output'];
  removeSeasonMatch: Scalars['Boolean']['output'];
  removeSubTask: Scalars['Boolean']['output'];
  removeTask: Scalars['Boolean']['output'];
  removeTopCategory: Scalars['Boolean']['output'];
  removeUser: Scalars['Boolean']['output'];
  renewContracts: Array<Contract>;
  resetMyPassword: Scalars['Boolean']['output'];
  resetPassword: Scalars['Boolean']['output'];
  setApiKey: Scalars['String']['output'];
  unsetApiKey: Scalars['Boolean']['output'];
  updateAccount: Account;
  updateAccountUser: User;
  updateBonus: Bonus;
  updateBonusItem: Bonus;
  updateBundle: Bundle;
  updateBundleItem: BundleItem;
  updateCompetition: Competition;
  updateContract: Contract;
  updateContractBonus: Contract;
  updateContractInvoice: Invoice;
  updateContractInvoices: Array<Invoice>;
  updateContractItem: ContractItem;
  updateContractNote: ContractNote;
  updateContractTemplate: ContractTemplate;
  updateInventory: Inventory;
  updateInventoryCategory: InventoryCategory;
  updateInventoryListPrice: InventoryListPrice;
  updateInvoiceCategory: InvoiceCategory;
  updateLeague: League;
  updateMe: User;
  updateMyAccount: Account;
  updatePartner: Partner;
  updatePartnerCategory: PartnerCategory;
  updatePartnerContact: PartnerContact;
  updatePartnerNote: PartnerNote;
  updateSeason: Season;
  updateSeasonMatch: Match;
  updateSubTask: SubTask;
  updateTask: Task;
  updateTopCategory: TopCategory;
  updateUser: User;
};

export type MutationAcquireLoginTokenArgs = {
  stealLogin?: InputMaybe<Scalars['Boolean']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type MutationAdminCreateCompetitionArgs = {
  input: AdminCreateCompetitionInput;
};

export type MutationAdminCreateLeagueArgs = {
  input: AdminCreateLeagueInput;
};

export type MutationAdminRemoveCompetitionArgs = {
  accountId: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type MutationAdminRemoveLeagueArgs = {
  accountId: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type MutationAdminUpdateCompetitionArgs = {
  accountId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  input: UpdateCompetitionInput;
};

export type MutationAdminUpdateLeagueArgs = {
  accountId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  input: UpdateLeagueInput;
};

export type MutationApplyContractPercentageArgs = {
  barterFixed?: InputMaybe<Scalars['Float']['input']>;
  barterPercentage?: InputMaybe<Scalars['Float']['input']>;
  contractId: Scalars['String']['input'];
  discountFixed?: InputMaybe<Scalars['Float']['input']>;
  discountPercentage?: InputMaybe<Scalars['Float']['input']>;
  leagueId?: InputMaybe<Scalars['String']['input']>;
  seasonId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCancelContractArgs = {
  id: Scalars['String']['input'];
  status: ContractCancelStatus;
};

export type MutationChangeUserEmailArgs = {
  id: Scalars['String']['input'];
  input: ChangeUserEmailInput;
};

export type MutationCloneContractArgs = {
  id: Scalars['String']['input'];
  status?: InputMaybe<ContractType>;
};

export type MutationConfigureSeasonWorkflowArgs = {
  competitionLeagues: Array<CompetitionLeagueInput>;
  copySettings: Scalars['Boolean']['input'];
  seasonId: Scalars['String']['input'];
};

export type MutationContractAddBundleArgs = {
  bundleId: Scalars['String']['input'];
  contractId: Scalars['String']['input'];
};

export type MutationContractRemoveBundleArgs = {
  bundleId: Scalars['String']['input'];
  contractId: Scalars['String']['input'];
};

export type MutationConvertToContractArgs = {
  id: Scalars['String']['input'];
};

export type MutationConvertToPdfArgs = {
  contractId: Scalars['String']['input'];
  datevCancellationLines?: InputMaybe<Scalars['String']['input']>;
  datevInvoiceLines?: InputMaybe<Scalars['String']['input']>;
  docType?: InputMaybe<InvoiceDocType>;
  file: Scalars['Upload']['input'];
  invoiceId: Scalars['String']['input'];
};

export type MutationCreateAccountArgs = {
  input: CreateAccountInput;
};

export type MutationCreateBonusArgs = {
  input: CreateBonusInput;
};

export type MutationCreateBundleArgs = {
  input: CreateBundleInput;
};

export type MutationCreateBundleItemArgs = {
  bundleId: Scalars['String']['input'];
  input: CreateBundleItemInput;
};

export type MutationCreateCompetitionArgs = {
  input: CreateCompetitionInput;
};

export type MutationCreateContractArgs = {
  input: CreateContractInput;
};

export type MutationCreateContractBonusArgs = {
  contractId: Scalars['String']['input'];
  input: CreateContractBonusInput;
};

export type MutationCreateContractItemArgs = {
  contractId: Scalars['String']['input'];
  input: CreateContractItemInput;
};

export type MutationCreateContractItemBulkArgs = {
  contractId: Scalars['String']['input'];
  input: CreateContractItemBulkInput;
  leagueIds: Array<Scalars['String']['input']>;
  seasonIds: Array<Scalars['String']['input']>;
};

export type MutationCreateContractNoteArgs = {
  contractId: Scalars['String']['input'];
  input: CreateContractNoteInput;
};

export type MutationCreateContractTemplateArgs = {
  input: CreateContractTemplateInput;
};

export type MutationCreateInventoryArgs = {
  input: CreateInventoryInput;
};

export type MutationCreateInventoryCategoryArgs = {
  input: CreateInventoryCategoryInput;
};

export type MutationCreateInventoryListPriceArgs = {
  input: CreateListPriceItemInput;
  inventoryId: Scalars['String']['input'];
};

export type MutationCreateInvoiceCategoryArgs = {
  input: CreateInvoiceCategoryInput;
};

export type MutationCreateLeagueArgs = {
  input: CreateLeagueInput;
};

export type MutationCreateOrUpdateContractItemBulkArgs = {
  contractId: Scalars['String']['input'];
  input: UpdateContractItemBulkInput;
  inventoryId: Scalars['String']['input'];
  leagueIds: Array<Scalars['String']['input']>;
  seasonIds: Array<Scalars['String']['input']>;
};

export type MutationCreatePartnerArgs = {
  input: CreatePartnerInput;
};

export type MutationCreatePartnerCategoryArgs = {
  input: CreatePartnerCategoryInput;
};

export type MutationCreatePartnerContactArgs = {
  input: CreatePartnerContactInput;
  partnerId: Scalars['String']['input'];
};

export type MutationCreatePartnerNoteArgs = {
  input: CreatePartnerNoteInput;
  partnerId: Scalars['String']['input'];
};

export type MutationCreateSeasonArgs = {
  input: CreateSeasonInput;
};

export type MutationCreateSeasonMatchArgs = {
  input: CreateSeasonMatchInput;
  seasonId: Scalars['String']['input'];
};

export type MutationCreateSubTaskArgs = {
  input: CreateSubTaskInput;
  taskId: Scalars['String']['input'];
};

export type MutationCreateTaskArgs = {
  input: CreateTaskInput;
};

export type MutationCreateTasksForContractArgs = {
  contractId: Scalars['String']['input'];
  seasonId: Scalars['String']['input'];
};

export type MutationCreateTopCategoryArgs = {
  input: CreateTopCategoryInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationIssueContractInvoiceArgs = {
  contractId: Scalars['String']['input'];
  datevInvoiceLines?: InputMaybe<Scalars['String']['input']>;
  invoiceId: Scalars['String']['input'];
};

export type MutationIssueContractInvoiceCancellationNumberArgs = {
  contractId: Scalars['String']['input'];
  datevCancellationLines?: InputMaybe<Scalars['String']['input']>;
  invoiceId: Scalars['String']['input'];
};

export type MutationRemoveAccountArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveBonusArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveBundleArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveBundleItemArgs = {
  bundleId: Scalars['String']['input'];
  itemId: Scalars['String']['input'];
};

export type MutationRemoveCompetitionArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveContractArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveContractBonusArgs = {
  bonusId: Scalars['String']['input'];
  contractId: Scalars['String']['input'];
};

export type MutationRemoveContractItemArgs = {
  contractId: Scalars['String']['input'];
  itemId: Scalars['String']['input'];
};

export type MutationRemoveContractItemBulkArgs = {
  contractId: Scalars['String']['input'];
  inventoryId?: InputMaybe<Scalars['String']['input']>;
  leagueIds: Array<Scalars['String']['input']>;
  seasonIds: Array<Scalars['String']['input']>;
};

export type MutationRemoveContractNoteArgs = {
  contractId: Scalars['String']['input'];
  noteId: Scalars['String']['input'];
};

export type MutationRemoveContractTemplateArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveInventoryArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveInventoryCategoryArgs = {
  categoryId: Scalars['String']['input'];
};

export type MutationRemoveInventoryListPriceArgs = {
  inventoryId: Scalars['String']['input'];
  listPriceId: Scalars['String']['input'];
};

export type MutationRemoveInvoiceCategoryArgs = {
  categoryId: Scalars['String']['input'];
};

export type MutationRemoveLeagueArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemovePartnerArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemovePartnerCategoryArgs = {
  categoryId: Scalars['String']['input'];
};

export type MutationRemovePartnerContactArgs = {
  contactId: Scalars['String']['input'];
  partnerId: Scalars['String']['input'];
};

export type MutationRemovePartnerNoteArgs = {
  noteId: Scalars['String']['input'];
  partnerId: Scalars['String']['input'];
};

export type MutationRemoveSeasonMatchArgs = {
  matchId: Scalars['String']['input'];
  seasonId: Scalars['String']['input'];
};

export type MutationRemoveSubTaskArgs = {
  subTaskId: Scalars['String']['input'];
  taskId: Scalars['String']['input'];
};

export type MutationRemoveTaskArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveTopCategoryArgs = {
  categoryId: Scalars['String']['input'];
};

export type MutationRemoveUserArgs = {
  id: Scalars['String']['input'];
};

export type MutationResetMyPasswordArgs = {
  email: Scalars['String']['input'];
};

export type MutationResetPasswordArgs = {
  id: Scalars['String']['input'];
};

export type MutationUpdateAccountArgs = {
  id: Scalars['String']['input'];
  input: UpdateAccountInput;
};

export type MutationUpdateAccountUserArgs = {
  id: Scalars['String']['input'];
  input: UpdateAccountUserInput;
};

export type MutationUpdateBonusArgs = {
  id: Scalars['String']['input'];
  input: UpdateBonusInput;
};

export type MutationUpdateBonusItemArgs = {
  id: Scalars['String']['input'];
  input: UpdateBonusItemInput;
  seasonId: Scalars['String']['input'];
};

export type MutationUpdateBundleArgs = {
  id: Scalars['String']['input'];
  input: UpdateBundleInput;
};

export type MutationUpdateBundleItemArgs = {
  bundleId: Scalars['String']['input'];
  input: UpdateBundleItemInput;
  itemId: Scalars['String']['input'];
};

export type MutationUpdateCompetitionArgs = {
  id: Scalars['String']['input'];
  input: UpdateCompetitionInput;
};

export type MutationUpdateContractArgs = {
  id: Scalars['String']['input'];
  input: UpdateContractInput;
};

export type MutationUpdateContractBonusArgs = {
  bonusId: Scalars['String']['input'];
  contractId: Scalars['String']['input'];
  input: UpdateContractBonusInput;
  seasonIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type MutationUpdateContractInvoiceArgs = {
  contractId: Scalars['String']['input'];
  input: UpdateContractInvoiceInput;
  invoiceId: Scalars['String']['input'];
};

export type MutationUpdateContractInvoicesArgs = {
  contractId: Scalars['String']['input'];
  input: Array<ContractInvoiceInput>;
  seasonId?: InputMaybe<Scalars['String']['input']>;
  type: InvoiceTypeArg;
};

export type MutationUpdateContractItemArgs = {
  contractId: Scalars['String']['input'];
  input: UpdateContractItemInput;
  itemId: Scalars['String']['input'];
};

export type MutationUpdateContractNoteArgs = {
  contractId: Scalars['String']['input'];
  input: UpdateContractNoteInput;
  noteId: Scalars['String']['input'];
};

export type MutationUpdateContractTemplateArgs = {
  id: Scalars['String']['input'];
  input: UpdateContractTemplateInput;
};

export type MutationUpdateInventoryArgs = {
  id: Scalars['String']['input'];
  input: UpdateInventoryInput;
};

export type MutationUpdateInventoryCategoryArgs = {
  categoryId: Scalars['String']['input'];
  input: UpdateInventoryCategoryInput;
};

export type MutationUpdateInventoryListPriceArgs = {
  input: UpdateListPriceItemInput;
  inventoryId: Scalars['String']['input'];
  listPriceId: Scalars['String']['input'];
};

export type MutationUpdateInvoiceCategoryArgs = {
  categoryId: Scalars['String']['input'];
  input: UpdateInvoiceCategoryInput;
};

export type MutationUpdateLeagueArgs = {
  id: Scalars['String']['input'];
  input: UpdateLeagueInput;
};

export type MutationUpdateMeArgs = {
  input: UpdateMeInput;
};

export type MutationUpdateMyAccountArgs = {
  input: UpdateMyAccountInput;
};

export type MutationUpdatePartnerArgs = {
  id: Scalars['String']['input'];
  input: UpdatePartnerInput;
};

export type MutationUpdatePartnerCategoryArgs = {
  categoryId: Scalars['String']['input'];
  input: UpdatePartnerCategoryInput;
};

export type MutationUpdatePartnerContactArgs = {
  contactId: Scalars['String']['input'];
  input: UpdatePartnerContactInput;
  partnerId: Scalars['String']['input'];
};

export type MutationUpdatePartnerNoteArgs = {
  input: UpdatePartnerNoteInput;
  noteId: Scalars['String']['input'];
  partnerId: Scalars['String']['input'];
};

export type MutationUpdateSeasonArgs = {
  id: Scalars['String']['input'];
  input: UpdateSeasonInput;
};

export type MutationUpdateSeasonMatchArgs = {
  input: UpdateSeasonMatchInput;
  matchId: Scalars['String']['input'];
  seasonId: Scalars['String']['input'];
};

export type MutationUpdateSubTaskArgs = {
  input: UpdateSubTaskInput;
  subTaskId: Scalars['String']['input'];
  taskId: Scalars['String']['input'];
};

export type MutationUpdateTaskArgs = {
  id: Scalars['String']['input'];
  input: UpdateTaskInput;
};

export type MutationUpdateTopCategoryArgs = {
  categoryId: Scalars['String']['input'];
  input: UpdateTopCategoryInput;
};

export type MutationUpdateUserArgs = {
  id: Scalars['String']['input'];
  input: UpdateUserInput;
};

export enum OrganizationType {
  Organization = 'ORGANIZATION',
  Person = 'PERSON',
}

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNext: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
  totalCount: Scalars['Float']['output'];
};

export type PaginatedAccount = {
  __typename?: 'PaginatedAccount';
  edges: Array<AccountEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type PaginatedBonus = {
  __typename?: 'PaginatedBonus';
  edges: Array<BonusEdge>;
  pageInfo?: Maybe<PageInfo>;
  totals: BonusStats;
};

export type PaginatedBundle = {
  __typename?: 'PaginatedBundle';
  edges: Array<BundleEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type PaginatedCompetition = {
  __typename?: 'PaginatedCompetition';
  edges: Array<CompetitionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type PaginatedContract = {
  __typename?: 'PaginatedContract';
  edges: Array<ContractEdge>;
  pageInfo?: Maybe<PageInfo>;
  totals: ContractStats;
};

export type PaginatedContractTemplate = {
  __typename?: 'PaginatedContractTemplate';
  edges: Array<ContractTemplateEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type PaginatedInvoiceExport = {
  __typename?: 'PaginatedInvoiceExport';
  edges: Array<InvoiceExportEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type PaginatedLeague = {
  __typename?: 'PaginatedLeague';
  edges: Array<LeagueEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type PaginatedPartner = {
  __typename?: 'PaginatedPartner';
  edges: Array<PartnerEdge>;
  pageInfo?: Maybe<PageInfo>;
  totals: PartnerStats;
};

export type PaginatedSeason = {
  __typename?: 'PaginatedSeason';
  edges: Array<SeasonEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type Partner = {
  __typename?: 'Partner';
  category?: Maybe<PartnerCategory>;
  city?: Maybe<Scalars['String']['output']>;
  clubLead?: Maybe<User>;
  companyName?: Maybe<Scalars['String']['output']>;
  contacts?: Maybe<Array<PartnerContact>>;
  contractClubLeads?: Maybe<Array<User>>;
  contractSalesLeads?: Maybe<Array<User>>;
  contractStatus?: Maybe<PartnerContractStatus>;
  contracts?: Maybe<Array<Contract>>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerNumber?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Array<PartnerNote>>;
  organizationType?: Maybe<OrganizationType>;
  partnerSince?: Maybe<Scalars['DateTime']['output']>;
  salesLead?: Maybe<User>;
  salesLeads?: Maybe<Array<User>>;
  stats?: Maybe<ContractStats>;
  status?: Maybe<PartnerStatus>;
  street?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  website?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type PartnerCategory = {
  __typename?: 'PartnerCategory';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PartnerContact = {
  __typename?: 'PartnerContact';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  phoneNumber2?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  roles: Array<ContactRole>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum PartnerContractStatus {
  Active = 'ACTIVE',
  ActiveExpiring = 'ACTIVE_EXPIRING',
  Inactive = 'INACTIVE',
}

export type PartnerEdge = {
  __typename?: 'PartnerEdge';
  node: Partner;
};

export type PartnerNote = {
  __typename?: 'PartnerNote';
  author: Author;
  content: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum PartnerRelevanceFilter {
  Current = 'CURRENT',
  Future = 'FUTURE',
  Old = 'OLD',
}

export type PartnerStats = {
  __typename?: 'PartnerStats';
  bonuses: Scalars['Float']['output'];
  bonusesAchieved: Scalars['Float']['output'];
  bonusesAchievedValue: Scalars['Float']['output'];
  bonusesValue: Scalars['Float']['output'];
  discount: Scalars['Float']['output'];
  futureInvoiceTotal?: Maybe<Scalars['Float']['output']>;
  openInvoiceTotal?: Maybe<Scalars['Float']['output']>;
  overdueInvoiceTotal?: Maybe<Scalars['Float']['output']>;
  payedInvoiceTotal?: Maybe<Scalars['Float']['output']>;
  pendingInvoiceTotal?: Maybe<Scalars['Float']['output']>;
  totalBarter: Scalars['Float']['output'];
  totalContracts: Scalars['Float']['output'];
  totalDone?: Maybe<Scalars['Float']['output']>;
  totalInProgress?: Maybe<Scalars['Float']['output']>;
  totalLeads: Scalars['Float']['output'];
  totalOpen?: Maybe<Scalars['Float']['output']>;
  totalPartners: Scalars['Float']['output'];
  totalPrice: Scalars['Float']['output'];
  totalSubTasks?: Maybe<Scalars['Float']['output']>;
  totalTasks?: Maybe<Scalars['Float']['output']>;
};

export enum PartnerStatus {
  Existing = 'EXISTING',
  Expired = 'EXPIRED',
  Future = 'FUTURE',
  Lead = 'LEAD',
  New = 'NEW',
}

export type Query = {
  __typename?: 'Query';
  account: Account;
  accounts: PaginatedAccount;
  adminCompetitions: PaginatedCompetition;
  adminLeagues: PaginatedLeague;
  allInventory: InventoryWithTotals;
  bonus: Bonus;
  bonuses: PaginatedBonus;
  bundle: Bundle;
  bundles: PaginatedBundle;
  competitions: PaginatedCompetition;
  contract: Contract;
  contractTemplate: ContractTemplate;
  contractTemplates: PaginatedContractTemplate;
  contracts: PaginatedContract;
  dashboardDiscount: DashboardDiscount;
  dashboardForecast: DashboardForecast;
  dashboardInventory: DashboardInventoryStats;
  dashboardOffers: DashboardOffers;
  dashboardPartnerAndContracts: DashboardPartnerAndContracts;
  dashboardRevenueDetails: DashboardRevenueDetails;
  inventory: Inventory;
  inventoryDetails: InventoryDetails;
  invoiceExports: PaginatedInvoiceExport;
  league: League;
  leagues: PaginatedLeague;
  matchTasks: MatchTasksWithTotals;
  myAccount: Account;
  partner: Partner;
  partnerExport: Partner;
  partners: PaginatedPartner;
  season: Season;
  seasons: PaginatedSeason;
  testPartnerName: Array<Partner>;
  user: User;
  users: Array<User>;
};

export type QueryAccountArgs = {
  id: Scalars['String']['input'];
};

export type QueryAccountsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Float']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type QueryAdminCompetitionsArgs = {
  accountId: Scalars['String']['input'];
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Float']['input']>;
};

export type QueryAdminLeaguesArgs = {
  accountId: Scalars['String']['input'];
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Float']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type QueryAllInventoryArgs = {
  availabilityFilter?: InputMaybe<InventoryAvailabilityFilter>;
  filterType?: InputMaybe<InventoryType>;
  keyRightOnly?: InputMaybe<Scalars['Boolean']['input']>;
  leagueIds: Array<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  seasonId: Scalars['String']['input'];
  topCategoryId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryBonusArgs = {
  id: Scalars['String']['input'];
  seasonId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryBonusesArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Float']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  seasonId: Scalars['String']['input'];
};

export type QueryBundleArgs = {
  id: Scalars['String']['input'];
};

export type QueryBundlesArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  leagueId: Scalars['String']['input'];
  pageSize?: InputMaybe<Scalars['Float']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  seasonId: Scalars['String']['input'];
};

export type QueryCompetitionsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Float']['input']>;
};

export type QueryContractArgs = {
  id: Scalars['String']['input'];
  seasonId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryContractTemplateArgs = {
  id: Scalars['String']['input'];
};

export type QueryContractTemplatesArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Float']['input']>;
};

export type QueryContractsArgs = {
  autoRenewOnly?: InputMaybe<Scalars['Boolean']['input']>;
  clubLeadId?: InputMaybe<Scalars['String']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  expiringOnly?: InputMaybe<Scalars['Boolean']['input']>;
  leagueIds: Array<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Float']['input']>;
  partnerCategoryId?: InputMaybe<Scalars['String']['input']>;
  probability?: InputMaybe<Scalars['Float']['input']>;
  relevance?: InputMaybe<ContractRelevanceFilter>;
  salesLeadId?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  seasonId: Scalars['String']['input'];
  status?: InputMaybe<ContractStatusFilter>;
  type: ContractType;
};

export type QueryDashboardDiscountArgs = {
  leagueIds: Array<Scalars['String']['input']>;
  seasonId: Scalars['String']['input'];
};

export type QueryDashboardForecastArgs = {
  leagueIds: Array<Scalars['String']['input']>;
  seasonId: Scalars['String']['input'];
};

export type QueryDashboardInventoryArgs = {
  leagueIds: Array<Scalars['String']['input']>;
  seasonId: Scalars['String']['input'];
};

export type QueryDashboardOffersArgs = {
  leagueIds: Array<Scalars['String']['input']>;
  seasonId: Scalars['String']['input'];
};

export type QueryDashboardPartnerAndContractsArgs = {
  leagueIds: Array<Scalars['String']['input']>;
  seasonId: Scalars['String']['input'];
};

export type QueryDashboardRevenueDetailsArgs = {
  leagueIds: Array<Scalars['String']['input']>;
  seasonId: Scalars['String']['input'];
};

export type QueryInventoryArgs = {
  id: Scalars['String']['input'];
  leagueIds?: InputMaybe<Array<Scalars['String']['input']>>;
  seasonId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryInventoryDetailsArgs = {
  id: Scalars['String']['input'];
  leagueIds?: InputMaybe<Array<Scalars['String']['input']>>;
  seasonId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryInvoiceExportsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Float']['input']>;
};

export type QueryLeagueArgs = {
  id: Scalars['String']['input'];
};

export type QueryLeaguesArgs = {
  competitionId?: InputMaybe<Scalars['String']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Float']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type QueryMatchTasksArgs = {
  matchId: Scalars['String']['input'];
  seasonId: Scalars['String']['input'];
};

export type QueryPartnerArgs = {
  id: Scalars['String']['input'];
};

export type QueryPartnerExportArgs = {
  id: Scalars['String']['input'];
};

export type QueryPartnersArgs = {
  clubLeadIds?: InputMaybe<Array<Scalars['String']['input']>>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  expiringOnly?: InputMaybe<Scalars['Boolean']['input']>;
  leagueIds: Array<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Float']['input']>;
  partnerCategoryId?: InputMaybe<Scalars['String']['input']>;
  relevance?: InputMaybe<PartnerRelevanceFilter>;
  salesLeadIds?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  seasonId: Scalars['String']['input'];
  status?: InputMaybe<PartnerStatus>;
};

export type QuerySeasonArgs = {
  id: Scalars['String']['input'];
};

export type QuerySeasonsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Float']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type QueryTestPartnerNameArgs = {
  companyName: Scalars['String']['input'];
};

export type QueryUserArgs = {
  id: Scalars['String']['input'];
};

export type QueryUsersArgs = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type Season = {
  __typename?: 'Season';
  competitionLeagues: Array<CompetitionLeague>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  endDate: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  invoicing: SeasonInvoicing;
  matches: Array<Match>;
  name: Scalars['String']['output'];
  startDate: Scalars['DateTime']['output'];
  targetRevenue: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  workflows?: Maybe<SeasonWorkflow>;
};

export type SeasonEdge = {
  __typename?: 'SeasonEdge';
  node: Season;
};

export type SeasonInventoryDetailContract = {
  __typename?: 'SeasonInventoryDetailContract';
  contracts: Array<InventoryDetailContract>;
  seasonId: Scalars['String']['output'];
};

export type SeasonInvoicing = {
  __typename?: 'SeasonInvoicing';
  barter: SeasonInvoicingPrefix;
  bonus: SeasonInvoicingPrefix;
  cash: SeasonInvoicingPrefix;
  useSeparateCounters: Scalars['Boolean']['output'];
};

export type SeasonInvoicingPrefix = {
  __typename?: 'SeasonInvoicingPrefix';
  prefix: Scalars['String']['output'];
};

export type SeasonWorkflow = {
  __typename?: 'SeasonWorkflow';
  competitionLeagues: Array<CompetitionLeague>;
  copySettings: Scalars['Boolean']['output'];
  setupAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SubTask = {
  __typename?: 'SubTask';
  count: Scalars['Float']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deadline?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  lastEdit: LastEdit;
  lead?: Maybe<TaskLead>;
  match?: Maybe<Match>;
  note?: Maybe<Scalars['String']['output']>;
  status: TaskStatus;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type Task = {
  __typename?: 'Task';
  contractItemAmount?: Maybe<Scalars['Float']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customAmount?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  lastEdit: LastEdit;
  match?: Maybe<Match>;
  name?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  season: Season;
  stats: TaskStats;
  subTasks: Array<SubTask>;
  totalTasks: Scalars['Float']['output'];
  type: TaskType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TaskEdge = {
  __typename?: 'TaskEdge';
  node: Task;
};

export type TaskLead = {
  __typename?: 'TaskLead';
  id: Scalars['String']['output'];
  type: TaskLeadType;
};

export type TaskLeadInput = {
  id: Scalars['String']['input'];
  type: TaskLeadType;
};

export enum TaskLeadType {
  Contact = 'contact',
  User = 'user',
}

export type TaskStats = {
  __typename?: 'TaskStats';
  done: Scalars['Float']['output'];
  inProgress: Scalars['Float']['output'];
  open: Scalars['Float']['output'];
};

export enum TaskStatus {
  Done = 'DONE',
  InProgress = 'IN_PROGRESS',
  Open = 'OPEN',
}

export enum TaskType {
  Match = 'MATCH',
  Matches = 'MATCHES',
  Season = 'SEASON',
  Single = 'SINGLE',
  SpecificMatches = 'SPECIFIC_MATCHES',
}

export type TimeSeries = {
  __typename?: 'TimeSeries';
  data: Array<TimeSeriesChartItem>;
};

export type TimeSeriesChartItem = {
  __typename?: 'TimeSeriesChartItem';
  x: Scalars['DateTime']['output'];
  y: Scalars['Float']['output'];
};

export type TopCategory = {
  __typename?: 'TopCategory';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  invoiceCategory?: Maybe<InvoiceCategory>;
  name: Scalars['String']['output'];
  order: Scalars['Float']['output'];
  stats?: Maybe<InventoryStats>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TopCategoryChartData = {
  __typename?: 'TopCategoryChartData';
  label: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export enum UnitType {
  PricePerMeter = 'PRICE_PER_METER',
  PricePerMinute = 'PRICE_PER_MINUTE',
  PricePerSeat = 'PRICE_PER_SEAT',
  PricePerUnit = 'PRICE_PER_UNIT',
  PricePerVolume = 'PRICE_PER_VOLUME',
}

export type UpdateAccountInput = {
  name: Scalars['String']['input'];
};

export type UpdateAccountUserInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  role: UserRole;
};

export type UpdateBonusInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type UpdateBonusItemInput = {
  achieved?: InputMaybe<Scalars['Boolean']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateBundleInput = {
  name: Scalars['String']['input'];
};

export type UpdateBundleItemInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateCompetitionInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateContractBonusInput = {
  price: Scalars['Float']['input'];
};

export type UpdateContractInput = {
  autoRenew?: InputMaybe<ContractRenewInput>;
  clubLeadId?: InputMaybe<Scalars['String']['input']>;
  contractDescription?: InputMaybe<Scalars['String']['input']>;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  invoicesDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  partnerId?: InputMaybe<Scalars['String']['input']>;
  probability?: InputMaybe<Scalars['Float']['input']>;
  salesLeadId?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  templateId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateContractInvoiceInput = {
  invoicedAt?: InputMaybe<Scalars['DateTime']['input']>;
  payedAt?: InputMaybe<Scalars['DateTime']['input']>;
  paymentDueAt?: InputMaybe<Scalars['DateTime']['input']>;
  serviceEndedAt?: InputMaybe<Scalars['DateTime']['input']>;
  serviceStartedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateContractItemBulkInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateContractItemInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  barter?: InputMaybe<Scalars['Float']['input']>;
  locked?: InputMaybe<Scalars['Boolean']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateContractNoteInput = {
  content: Scalars['String']['input'];
};

export type UpdateContractTemplateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  template?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateInventoryCategoryInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateInventoryInput = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  contractDescription?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  keyRightOrder?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Float']['input']>;
  soldByMatch?: InputMaybe<Scalars['Boolean']['input']>;
  soldBySeason?: InputMaybe<Scalars['Boolean']['input']>;
  topCategoryId?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<UnitType>;
};

export type UpdateInvoiceCategoryInput = {
  accountingNumber?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateLeagueInput = {
  competitionId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateListPriceItemInput = {
  maxDiscount?: InputMaybe<Scalars['Float']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  quantity?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<InventoryType>;
};

export type UpdateMeInput = {
  name: Scalars['String']['input'];
};

export type UpdateMyAccountInput = {
  contractTemplateManagement?: InputMaybe<Scalars['Boolean']['input']>;
  customSettings?: InputMaybe<AccountCustomSettingsInput>;
  datevExports?: InputMaybe<AccountDatevExportSettingInput>;
  defaultCompetitionLeagues?: InputMaybe<Array<CompetitionLeagueInput>>;
  defaultSeason?: InputMaybe<Scalars['String']['input']>;
  documentOutputFormat?: InputMaybe<DocumentOutputFormat>;
  exports?: InputMaybe<AccountExportSettingInput>;
  inventoryBlockProbability?: InputMaybe<Scalars['Float']['input']>;
  invoicing?: InputMaybe<AccountInvoiceSettingInput>;
  workflows?: InputMaybe<AccountWorkflowSettingInput>;
};

export type UpdatePartnerCategoryInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdatePartnerContactInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumber2?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<ContactRole>>;
};

export type UpdatePartnerInput = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  clubLeadId?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  customerNumber?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  organizationType?: InputMaybe<OrganizationType>;
  partnerSince?: InputMaybe<Scalars['DateTime']['input']>;
  salesLeadId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePartnerNoteInput = {
  content: Scalars['String']['input'];
};

export type UpdateSeasonInput = {
  competitionLeagues?: InputMaybe<Array<CompetitionLeagueInput>>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  invoicing?: InputMaybe<UpdateSeasonInvoicing>;
  name?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  targetRevenue?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateSeasonInvoicing = {
  barterPrefix?: InputMaybe<Scalars['String']['input']>;
  bonusPrefix?: InputMaybe<Scalars['String']['input']>;
  cashPrefix?: InputMaybe<Scalars['String']['input']>;
  useSeparateCounters?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateSeasonMatchInput = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSubTaskInput = {
  count?: InputMaybe<Scalars['Float']['input']>;
  deadline?: InputMaybe<Scalars['DateTime']['input']>;
  lead?: InputMaybe<TaskLeadInput>;
  matchId?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<TaskStatus>;
};

export type UpdateTaskInput = {
  customAmount?: InputMaybe<Scalars['Float']['input']>;
  matchIds?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  totalTasks?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<TaskType>;
};

export type UpdateTopCategoryInput = {
  invoiceCategoryId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateUserInput = {
  accounts?: InputMaybe<Array<UserAccountInput>>;
  admin?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  accounts: Array<UserAccount>;
  admin?: Maybe<Scalars['Boolean']['output']>;
  claims?: Maybe<Array<Claim>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastSignIn?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  uid: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UserAccount = {
  __typename?: 'UserAccount';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  role?: Maybe<UserRole>;
};

export type UserAccountInput = {
  accountId: Scalars['String']['input'];
  role: UserRole;
};

export enum UserRole {
  Accounting = 'ACCOUNTING',
  AccountAdmin = 'ACCOUNT_ADMIN',
  AccountAdminRestricted = 'ACCOUNT_ADMIN_RESTRICTED',
  Readonly = 'READONLY',
  Restricted = 'RESTRICTED',
  User = 'USER',
  Workflow = 'WORKFLOW',
}

export type AccountFragmentFragment = {
  __typename?: 'Account';
  id: string;
  name: string;
  createdAt?: any | undefined;
  updatedAt?: any | undefined;
  deletedAt?: any | undefined;
  users: Array<{
    __typename?: 'User';
    id: string;
    uid: string;
    name: string;
    email: string;
    admin?: boolean | undefined;
    lastSignIn?: string | undefined;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    deletedAt?: any | undefined;
    claims?: Array<{ __typename?: 'Claim'; key: string; value: string }> | undefined;
    accounts: Array<{ __typename?: 'UserAccount'; id: string; name: string; role?: UserRole | undefined }>;
  }>;
};

export type AccountsQueryVariables = Exact<{
  pageSize?: InputMaybe<Scalars['Float']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
}>;

export type AccountsQuery = {
  __typename?: 'Query';
  accounts: {
    __typename?: 'PaginatedAccount';
    pageInfo?:
      | {
          __typename?: 'PageInfo';
          endCursor?: string | undefined;
          hasNext: boolean;
          startCursor?: string | undefined;
          totalCount: number;
        }
      | undefined;
    edges: Array<{
      __typename?: 'AccountEdge';
      node: {
        __typename?: 'Account';
        id: string;
        name: string;
        createdAt?: any | undefined;
        updatedAt?: any | undefined;
        deletedAt?: any | undefined;
        users: Array<{
          __typename?: 'User';
          id: string;
          uid: string;
          name: string;
          email: string;
          admin?: boolean | undefined;
          lastSignIn?: string | undefined;
          createdAt?: any | undefined;
          updatedAt?: any | undefined;
          deletedAt?: any | undefined;
          claims?: Array<{ __typename?: 'Claim'; key: string; value: string }> | undefined;
          accounts: Array<{
            __typename?: 'UserAccount';
            id: string;
            name: string;
            role?: UserRole | undefined;
          }>;
        }>;
      };
    }>;
  };
};

export type CreateAccountMutationVariables = Exact<{
  input: CreateAccountInput;
}>;

export type CreateAccountMutation = {
  __typename?: 'Mutation';
  createAccount: {
    __typename?: 'Account';
    id: string;
    name: string;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    deletedAt?: any | undefined;
    users: Array<{
      __typename?: 'User';
      id: string;
      uid: string;
      name: string;
      email: string;
      admin?: boolean | undefined;
      lastSignIn?: string | undefined;
      createdAt?: any | undefined;
      updatedAt?: any | undefined;
      deletedAt?: any | undefined;
      claims?: Array<{ __typename?: 'Claim'; key: string; value: string }> | undefined;
      accounts: Array<{ __typename?: 'UserAccount'; id: string; name: string; role?: UserRole | undefined }>;
    }>;
  };
};

export type AccountQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type AccountQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    id: string;
    name: string;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    deletedAt?: any | undefined;
    users: Array<{
      __typename?: 'User';
      id: string;
      uid: string;
      name: string;
      email: string;
      admin?: boolean | undefined;
      lastSignIn?: string | undefined;
      createdAt?: any | undefined;
      updatedAt?: any | undefined;
      deletedAt?: any | undefined;
      claims?: Array<{ __typename?: 'Claim'; key: string; value: string }> | undefined;
      accounts: Array<{ __typename?: 'UserAccount'; id: string; name: string; role?: UserRole | undefined }>;
    }>;
  };
};

export type CreateInventoryCategoryMutationVariables = Exact<{
  input: CreateInventoryCategoryInput;
}>;

export type CreateInventoryCategoryMutation = {
  __typename?: 'Mutation';
  createInventoryCategory: {
    __typename?: 'InventoryCategory';
    id: string;
    name: string;
    order: number;
    stats?:
      | {
          __typename?: 'InventoryStats';
          available?: number | undefined;
          availableValue?: number | undefined;
          blocked?: number | undefined;
          blockedValue?: number | undefined;
          offered?: number | undefined;
          offeredValue?: number | undefined;
          sold?: number | undefined;
          soldRevenue?: number | undefined;
          soldValue?: number | undefined;
          total?: number | undefined;
          totalValue?: number | undefined;
          capacityUtilization?: number | undefined;
          discount?: number | undefined;
        }
      | undefined;
  };
};

export type InventoryCategoryFragmentFragment = {
  __typename?: 'InventoryCategory';
  id: string;
  name: string;
  order: number;
  stats?:
    | {
        __typename?: 'InventoryStats';
        available?: number | undefined;
        availableValue?: number | undefined;
        blocked?: number | undefined;
        blockedValue?: number | undefined;
        offered?: number | undefined;
        offeredValue?: number | undefined;
        sold?: number | undefined;
        soldRevenue?: number | undefined;
        soldValue?: number | undefined;
        total?: number | undefined;
        totalValue?: number | undefined;
        capacityUtilization?: number | undefined;
        discount?: number | undefined;
      }
    | undefined;
};

export type RemoveInventoryCategoryMutationVariables = Exact<{
  categoryId: Scalars['String']['input'];
}>;

export type RemoveInventoryCategoryMutation = { __typename?: 'Mutation'; removeInventoryCategory: boolean };

export type UpdateInventoryCategoryMutationVariables = Exact<{
  categoryId: Scalars['String']['input'];
  input: UpdateInventoryCategoryInput;
}>;

export type UpdateInventoryCategoryMutation = {
  __typename?: 'Mutation';
  updateInventoryCategory: {
    __typename?: 'InventoryCategory';
    id: string;
    name: string;
    order: number;
    stats?:
      | {
          __typename?: 'InventoryStats';
          available?: number | undefined;
          availableValue?: number | undefined;
          blocked?: number | undefined;
          blockedValue?: number | undefined;
          offered?: number | undefined;
          offeredValue?: number | undefined;
          sold?: number | undefined;
          soldRevenue?: number | undefined;
          soldValue?: number | undefined;
          total?: number | undefined;
          totalValue?: number | undefined;
          capacityUtilization?: number | undefined;
          discount?: number | undefined;
        }
      | undefined;
  };
};

export type CreateInvoiceCategoryMutationVariables = Exact<{
  input: CreateInvoiceCategoryInput;
}>;

export type CreateInvoiceCategoryMutation = {
  __typename?: 'Mutation';
  createInvoiceCategory: {
    __typename?: 'InvoiceCategory';
    id: string;
    name: string;
    order: number;
    accountingNumber?: string | undefined;
  };
};

export type InvoiceCategoryFragmentFragment = {
  __typename?: 'InvoiceCategory';
  id: string;
  name: string;
  order: number;
  accountingNumber?: string | undefined;
};

export type RemoveInvoiceCategoryMutationVariables = Exact<{
  categoryId: Scalars['String']['input'];
}>;

export type RemoveInvoiceCategoryMutation = { __typename?: 'Mutation'; removeInvoiceCategory: boolean };

export type UpdateInvoiceCategoryMutationVariables = Exact<{
  categoryId: Scalars['String']['input'];
  input: UpdateInvoiceCategoryInput;
}>;

export type UpdateInvoiceCategoryMutation = {
  __typename?: 'Mutation';
  updateInvoiceCategory: {
    __typename?: 'InvoiceCategory';
    id: string;
    name: string;
    order: number;
    accountingNumber?: string | undefined;
  };
};

export type MyAccountQueryVariables = Exact<{ [key: string]: never }>;

export type MyAccountQuery = {
  __typename?: 'Query';
  myAccount: {
    __typename?: 'Account';
    accentColor?: string | undefined;
    defaultSeason?: string | undefined;
    inventoryBlockProbability: number;
    contractTemplateManagement: boolean;
    apiKey?: string | undefined;
    documentOutputFormat: DocumentOutputFormat;
    id: string;
    name: string;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    deletedAt?: any | undefined;
    defaultCompetitionLeagues: Array<{
      __typename?: 'CompetitionLeague';
      competitionId: string;
      leagueId: string;
      createdAt?: any | undefined;
      updatedAt?: any | undefined;
    }>;
    userAccounts?:
      | Array<{ __typename?: 'UserAccount'; id: string; name: string; role?: UserRole | undefined }>
      | undefined;
    invoicing: {
      __typename?: 'AccountInvoiceSettings';
      enabled: boolean;
      startDate?: any | undefined;
      apiOnly: boolean;
      byAccountingOnly: boolean;
      showInvoiceStatsInContractsTable: boolean;
      valueAddTax: number;
      exportTemplate: string;
    };
    workflows: { __typename?: 'AccountWorkflowSetting'; enabled: boolean; startDate?: any | undefined };
    exports: { __typename?: 'AccountExportSetting'; enabled: boolean };
    datevExports: { __typename?: 'AccountExportSetting'; enabled: boolean };
    customSettings: {
      __typename?: 'AccountCustomSettings';
      disablePartnerManagement: boolean;
      showExternalInventoryId: boolean;
      showExternalPartnerContactId: boolean;
    };
    inventoryCategories: Array<{
      __typename?: 'InventoryCategory';
      id: string;
      name: string;
      order: number;
      stats?:
        | {
            __typename?: 'InventoryStats';
            available?: number | undefined;
            availableValue?: number | undefined;
            blocked?: number | undefined;
            blockedValue?: number | undefined;
            offered?: number | undefined;
            offeredValue?: number | undefined;
            sold?: number | undefined;
            soldRevenue?: number | undefined;
            soldValue?: number | undefined;
            total?: number | undefined;
            totalValue?: number | undefined;
            capacityUtilization?: number | undefined;
            discount?: number | undefined;
          }
        | undefined;
    }>;
    partnerCategories: Array<{ __typename?: 'PartnerCategory'; id: string; name: string; order: number }>;
    topCategories: Array<{
      __typename?: 'TopCategory';
      id: string;
      name: string;
      order: number;
      invoiceCategory?:
        | {
            __typename?: 'InvoiceCategory';
            id: string;
            name: string;
            order: number;
            accountingNumber?: string | undefined;
          }
        | undefined;
      stats?:
        | {
            __typename?: 'InventoryStats';
            available?: number | undefined;
            availableValue?: number | undefined;
            blocked?: number | undefined;
            blockedValue?: number | undefined;
            offered?: number | undefined;
            offeredValue?: number | undefined;
            sold?: number | undefined;
            soldRevenue?: number | undefined;
            soldValue?: number | undefined;
            total?: number | undefined;
            totalValue?: number | undefined;
            capacityUtilization?: number | undefined;
            discount?: number | undefined;
          }
        | undefined;
    }>;
    invoiceCategories: Array<{
      __typename?: 'InvoiceCategory';
      id: string;
      name: string;
      order: number;
      accountingNumber?: string | undefined;
    }>;
    users: Array<{
      __typename?: 'User';
      id: string;
      uid: string;
      name: string;
      email: string;
      admin?: boolean | undefined;
      lastSignIn?: string | undefined;
      createdAt?: any | undefined;
      updatedAt?: any | undefined;
      deletedAt?: any | undefined;
      claims?: Array<{ __typename?: 'Claim'; key: string; value: string }> | undefined;
      accounts: Array<{ __typename?: 'UserAccount'; id: string; name: string; role?: UserRole | undefined }>;
    }>;
  };
};

export type CreatePartnerCategoryMutationVariables = Exact<{
  input: CreatePartnerCategoryInput;
}>;

export type CreatePartnerCategoryMutation = {
  __typename?: 'Mutation';
  createPartnerCategory: { __typename?: 'PartnerCategory'; id: string; name: string; order: number };
};

export type PartnerCategoryFragmentFragment = {
  __typename?: 'PartnerCategory';
  id: string;
  name: string;
  order: number;
};

export type RemovePartnerCategoryMutationVariables = Exact<{
  categoryId: Scalars['String']['input'];
}>;

export type RemovePartnerCategoryMutation = { __typename?: 'Mutation'; removePartnerCategory: boolean };

export type UpdatePartnerCategoryMutationVariables = Exact<{
  categoryId: Scalars['String']['input'];
  input: UpdatePartnerCategoryInput;
}>;

export type UpdatePartnerCategoryMutation = {
  __typename?: 'Mutation';
  updatePartnerCategory: { __typename?: 'PartnerCategory'; id: string; name: string; order: number };
};

export type SetApiKeyMutationVariables = Exact<{ [key: string]: never }>;

export type SetApiKeyMutation = { __typename?: 'Mutation'; setApiKey: string };

export type CreateTopCategoryMutationVariables = Exact<{
  input: CreateTopCategoryInput;
}>;

export type CreateTopCategoryMutation = {
  __typename?: 'Mutation';
  createTopCategory: {
    __typename?: 'TopCategory';
    id: string;
    name: string;
    order: number;
    invoiceCategory?:
      | {
          __typename?: 'InvoiceCategory';
          id: string;
          name: string;
          order: number;
          accountingNumber?: string | undefined;
        }
      | undefined;
    stats?:
      | {
          __typename?: 'InventoryStats';
          available?: number | undefined;
          availableValue?: number | undefined;
          blocked?: number | undefined;
          blockedValue?: number | undefined;
          offered?: number | undefined;
          offeredValue?: number | undefined;
          sold?: number | undefined;
          soldRevenue?: number | undefined;
          soldValue?: number | undefined;
          total?: number | undefined;
          totalValue?: number | undefined;
          capacityUtilization?: number | undefined;
          discount?: number | undefined;
        }
      | undefined;
  };
};

export type RemoveTopCategoryMutationVariables = Exact<{
  categoryId: Scalars['String']['input'];
}>;

export type RemoveTopCategoryMutation = { __typename?: 'Mutation'; removeTopCategory: boolean };

export type TopCategoryFragmentFragment = {
  __typename?: 'TopCategory';
  id: string;
  name: string;
  order: number;
  invoiceCategory?:
    | {
        __typename?: 'InvoiceCategory';
        id: string;
        name: string;
        order: number;
        accountingNumber?: string | undefined;
      }
    | undefined;
  stats?:
    | {
        __typename?: 'InventoryStats';
        available?: number | undefined;
        availableValue?: number | undefined;
        blocked?: number | undefined;
        blockedValue?: number | undefined;
        offered?: number | undefined;
        offeredValue?: number | undefined;
        sold?: number | undefined;
        soldRevenue?: number | undefined;
        soldValue?: number | undefined;
        total?: number | undefined;
        totalValue?: number | undefined;
        capacityUtilization?: number | undefined;
        discount?: number | undefined;
      }
    | undefined;
};

export type UpdateTopCategoryMutationVariables = Exact<{
  categoryId: Scalars['String']['input'];
  input: UpdateTopCategoryInput;
}>;

export type UpdateTopCategoryMutation = {
  __typename?: 'Mutation';
  updateTopCategory: {
    __typename?: 'TopCategory';
    id: string;
    name: string;
    order: number;
    invoiceCategory?:
      | {
          __typename?: 'InvoiceCategory';
          id: string;
          name: string;
          order: number;
          accountingNumber?: string | undefined;
        }
      | undefined;
    stats?:
      | {
          __typename?: 'InventoryStats';
          available?: number | undefined;
          availableValue?: number | undefined;
          blocked?: number | undefined;
          blockedValue?: number | undefined;
          offered?: number | undefined;
          offeredValue?: number | undefined;
          sold?: number | undefined;
          soldRevenue?: number | undefined;
          soldValue?: number | undefined;
          total?: number | undefined;
          totalValue?: number | undefined;
          capacityUtilization?: number | undefined;
          discount?: number | undefined;
        }
      | undefined;
  };
};

export type UnsetApiKeyMutationVariables = Exact<{ [key: string]: never }>;

export type UnsetApiKeyMutation = { __typename?: 'Mutation'; unsetApiKey: boolean };

export type UpdateAccountMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: UpdateAccountInput;
}>;

export type UpdateAccountMutation = {
  __typename?: 'Mutation';
  updateAccount: {
    __typename?: 'Account';
    id: string;
    name: string;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    deletedAt?: any | undefined;
    users: Array<{
      __typename?: 'User';
      id: string;
      uid: string;
      name: string;
      email: string;
      admin?: boolean | undefined;
      lastSignIn?: string | undefined;
      createdAt?: any | undefined;
      updatedAt?: any | undefined;
      deletedAt?: any | undefined;
      claims?: Array<{ __typename?: 'Claim'; key: string; value: string }> | undefined;
      accounts: Array<{ __typename?: 'UserAccount'; id: string; name: string; role?: UserRole | undefined }>;
    }>;
  };
};

export type UpdateMyAccountMutationVariables = Exact<{
  input: UpdateMyAccountInput;
}>;

export type UpdateMyAccountMutation = {
  __typename?: 'Mutation';
  updateMyAccount: {
    __typename?: 'Account';
    id: string;
    name: string;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    deletedAt?: any | undefined;
    users: Array<{
      __typename?: 'User';
      id: string;
      uid: string;
      name: string;
      email: string;
      admin?: boolean | undefined;
      lastSignIn?: string | undefined;
      createdAt?: any | undefined;
      updatedAt?: any | undefined;
      deletedAt?: any | undefined;
      claims?: Array<{ __typename?: 'Claim'; key: string; value: string }> | undefined;
      accounts: Array<{ __typename?: 'UserAccount'; id: string; name: string; role?: UserRole | undefined }>;
    }>;
  };
};

export type BonusesQueryVariables = Exact<{
  seasonId: Scalars['String']['input'];
}>;

export type BonusesQuery = {
  __typename?: 'Query';
  bonuses: {
    __typename?: 'PaginatedBonus';
    totals: { __typename?: 'BonusStats'; achievedCount: number; achievedRevenue: number };
    edges: Array<{
      __typename?: 'BonusEdge';
      node: {
        __typename?: 'Bonus';
        id: string;
        name: string;
        description?: string | undefined;
        createdAt?: any | undefined;
        items: Array<{
          __typename?: 'BonusItem';
          id: string;
          price: number;
          sold: number;
          offered: number;
          achieved: boolean;
          season: { __typename?: 'Season'; id: string; name: string };
        }>;
      };
    }>;
  };
};

export type BonusFragmentFragment = {
  __typename?: 'Bonus';
  id: string;
  name: string;
  description?: string | undefined;
  items: Array<{
    __typename?: 'BonusItem';
    id: string;
    price: number;
    achieved: boolean;
    offered: number;
    sold: number;
    season: { __typename?: 'Season'; id: string; name: string };
    contracts: Array<{
      __typename?: 'BonusItemContract';
      id: string;
      price: number;
      achieved: boolean;
      partner: { __typename?: 'Partner'; id: string; companyName?: string | undefined };
    }>;
    offers: Array<{
      __typename?: 'BonusItemContract';
      id: string;
      price: number;
      achieved: boolean;
      partner: { __typename?: 'Partner'; id: string; companyName?: string | undefined };
    }>;
  }>;
};

export type CreateBonusMutationVariables = Exact<{
  input: CreateBonusInput;
}>;

export type CreateBonusMutation = {
  __typename?: 'Mutation';
  createBonus: {
    __typename?: 'Bonus';
    id: string;
    name: string;
    description?: string | undefined;
    items: Array<{
      __typename?: 'BonusItem';
      id: string;
      price: number;
      achieved: boolean;
      offered: number;
      sold: number;
      season: { __typename?: 'Season'; id: string; name: string };
      contracts: Array<{
        __typename?: 'BonusItemContract';
        id: string;
        price: number;
        achieved: boolean;
        partner: { __typename?: 'Partner'; id: string; companyName?: string | undefined };
      }>;
      offers: Array<{
        __typename?: 'BonusItemContract';
        id: string;
        price: number;
        achieved: boolean;
        partner: { __typename?: 'Partner'; id: string; companyName?: string | undefined };
      }>;
    }>;
  };
};

export type BonusQueryVariables = Exact<{
  id: Scalars['String']['input'];
  seasonId?: InputMaybe<Scalars['String']['input']>;
}>;

export type BonusQuery = {
  __typename?: 'Query';
  bonus: {
    __typename?: 'Bonus';
    id: string;
    name: string;
    description?: string | undefined;
    items: Array<{
      __typename?: 'BonusItem';
      id: string;
      price: number;
      achieved: boolean;
      offered: number;
      sold: number;
      season: { __typename?: 'Season'; id: string; name: string };
      contracts: Array<{
        __typename?: 'BonusItemContract';
        id: string;
        price: number;
        achieved: boolean;
        partner: { __typename?: 'Partner'; id: string; companyName?: string | undefined };
      }>;
      offers: Array<{
        __typename?: 'BonusItemContract';
        id: string;
        price: number;
        achieved: boolean;
        partner: { __typename?: 'Partner'; id: string; companyName?: string | undefined };
      }>;
    }>;
  };
};

export type RemoveBonusMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RemoveBonusMutation = { __typename?: 'Mutation'; removeBonus: boolean };

export type UpdateBonusItemMutationVariables = Exact<{
  bonusId: Scalars['String']['input'];
  seasonId: Scalars['String']['input'];
  input: UpdateBonusItemInput;
}>;

export type UpdateBonusItemMutation = {
  __typename?: 'Mutation';
  updateBonusItem: {
    __typename?: 'Bonus';
    id: string;
    name: string;
    description?: string | undefined;
    items: Array<{
      __typename?: 'BonusItem';
      id: string;
      price: number;
      achieved: boolean;
      offered: number;
      sold: number;
      season: { __typename?: 'Season'; id: string; name: string };
      contracts: Array<{
        __typename?: 'BonusItemContract';
        id: string;
        price: number;
        achieved: boolean;
        partner: { __typename?: 'Partner'; id: string; companyName?: string | undefined };
      }>;
      offers: Array<{
        __typename?: 'BonusItemContract';
        id: string;
        price: number;
        achieved: boolean;
        partner: { __typename?: 'Partner'; id: string; companyName?: string | undefined };
      }>;
    }>;
  };
};

export type UpdateBonusMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: UpdateBonusInput;
}>;

export type UpdateBonusMutation = {
  __typename?: 'Mutation';
  updateBonus: {
    __typename?: 'Bonus';
    id: string;
    name: string;
    description?: string | undefined;
    items: Array<{
      __typename?: 'BonusItem';
      id: string;
      price: number;
      achieved: boolean;
      offered: number;
      sold: number;
      season: { __typename?: 'Season'; id: string; name: string };
      contracts: Array<{
        __typename?: 'BonusItemContract';
        id: string;
        price: number;
        achieved: boolean;
        partner: { __typename?: 'Partner'; id: string; companyName?: string | undefined };
      }>;
      offers: Array<{
        __typename?: 'BonusItemContract';
        id: string;
        price: number;
        achieved: boolean;
        partner: { __typename?: 'Partner'; id: string; companyName?: string | undefined };
      }>;
    }>;
  };
};

export type BundlesQueryVariables = Exact<{
  leagueId: Scalars['String']['input'];
  seasonId: Scalars['String']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
}>;

export type BundlesQuery = {
  __typename?: 'Query';
  bundles: {
    __typename?: 'PaginatedBundle';
    edges: Array<{
      __typename?: 'BundleEdge';
      node: {
        __typename?: 'Bundle';
        id: string;
        name: string;
        sold: number;
        totalAvailable: number;
        totalPrice: number;
        createdAt?: any | undefined;
        updatedAt?: any | undefined;
        items: Array<{
          __typename?: 'BundleItem';
          id: string;
          amount: number;
          listUnitPrice: number;
          price: number;
          inventory: { __typename?: 'Inventory'; id: string; name: string };
        }>;
        league: { __typename?: 'League'; id: string; name: string };
        season: { __typename?: 'Season'; id: string; name: string };
      };
    }>;
  };
};

export type BundleItemFragmentFragment = {
  __typename?: 'BundleItem';
  id: string;
  amount: number;
  listUnitPrice: number;
  price: number;
  inventory: { __typename?: 'Inventory'; id: string; name: string };
};

export type CreateBundleItemMutationVariables = Exact<{
  bundleId: Scalars['String']['input'];
  input: CreateBundleItemInput;
}>;

export type CreateBundleItemMutation = {
  __typename?: 'Mutation';
  createBundleItem: {
    __typename?: 'BundleItem';
    id: string;
    amount: number;
    listUnitPrice: number;
    price: number;
    inventory: { __typename?: 'Inventory'; id: string; name: string };
  };
};

export type RemoveBundleItemMutationVariables = Exact<{
  bundleId: Scalars['String']['input'];
  itemId: Scalars['String']['input'];
}>;

export type RemoveBundleItemMutation = { __typename?: 'Mutation'; removeBundleItem: boolean };

export type UpdateBundleItemMutationVariables = Exact<{
  bundleId: Scalars['String']['input'];
  input: UpdateBundleItemInput;
  itemId: Scalars['String']['input'];
}>;

export type UpdateBundleItemMutation = {
  __typename?: 'Mutation';
  updateBundleItem: {
    __typename?: 'BundleItem';
    id: string;
    amount: number;
    listUnitPrice: number;
    price: number;
    inventory: { __typename?: 'Inventory'; id: string; name: string };
  };
};

export type BundleFragmentFragment = {
  __typename?: 'Bundle';
  id: string;
  name: string;
  sold: number;
  totalAvailable: number;
  totalPrice: number;
  createdAt?: any | undefined;
  updatedAt?: any | undefined;
  items: Array<{
    __typename?: 'BundleItem';
    id: string;
    amount: number;
    listUnitPrice: number;
    price: number;
    inventory: { __typename?: 'Inventory'; id: string; name: string };
  }>;
  league: { __typename?: 'League'; id: string; name: string };
  season: { __typename?: 'Season'; id: string; name: string };
};

export type CreateBundleMutationVariables = Exact<{
  input: CreateBundleInput;
}>;

export type CreateBundleMutation = {
  __typename?: 'Mutation';
  createBundle: {
    __typename?: 'Bundle';
    id: string;
    name: string;
    sold: number;
    totalAvailable: number;
    totalPrice: number;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    items: Array<{
      __typename?: 'BundleItem';
      id: string;
      amount: number;
      listUnitPrice: number;
      price: number;
      inventory: { __typename?: 'Inventory'; id: string; name: string };
    }>;
    league: { __typename?: 'League'; id: string; name: string };
    season: { __typename?: 'Season'; id: string; name: string };
  };
};

export type BundleQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type BundleQuery = {
  __typename?: 'Query';
  bundle: {
    __typename?: 'Bundle';
    id: string;
    name: string;
    sold: number;
    totalAvailable: number;
    totalPrice: number;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    items: Array<{
      __typename?: 'BundleItem';
      id: string;
      amount: number;
      listUnitPrice: number;
      price: number;
      inventory: { __typename?: 'Inventory'; id: string; name: string };
    }>;
    league: { __typename?: 'League'; id: string; name: string };
    season: { __typename?: 'Season'; id: string; name: string };
  };
};

export type RemoveBundleMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RemoveBundleMutation = { __typename?: 'Mutation'; removeBundle: boolean };

export type UpdateBundleMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: UpdateBundleInput;
}>;

export type UpdateBundleMutation = {
  __typename?: 'Mutation';
  updateBundle: {
    __typename?: 'Bundle';
    id: string;
    name: string;
    sold: number;
    totalAvailable: number;
    totalPrice: number;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    items: Array<{
      __typename?: 'BundleItem';
      id: string;
      amount: number;
      listUnitPrice: number;
      price: number;
      inventory: { __typename?: 'Inventory'; id: string; name: string };
    }>;
    league: { __typename?: 'League'; id: string; name: string };
    season: { __typename?: 'Season'; id: string; name: string };
  };
};

export type AdminCompetitionsQueryVariables = Exact<{
  accountId: Scalars['String']['input'];
}>;

export type AdminCompetitionsQuery = {
  __typename?: 'Query';
  adminCompetitions: {
    __typename?: 'PaginatedCompetition';
    edges: Array<{
      __typename?: 'CompetitionEdge';
      node: {
        __typename?: 'Competition';
        id: string;
        name: string;
        createdAt?: any | undefined;
        updatedAt?: any | undefined;
        leagues: Array<{
          __typename?: 'League';
          id: string;
          name: string;
          competitionId?: string | undefined;
          createdAt?: any | undefined;
          updatedAt?: any | undefined;
          deletedAt?: any | undefined;
        }>;
      };
    }>;
  };
};

export type AdminCreateCompetitionMutationVariables = Exact<{
  input: AdminCreateCompetitionInput;
}>;

export type AdminCreateCompetitionMutation = {
  __typename?: 'Mutation';
  adminCreateCompetition: {
    __typename?: 'Competition';
    id: string;
    name: string;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    leagues: Array<{
      __typename?: 'League';
      id: string;
      name: string;
      competitionId?: string | undefined;
      createdAt?: any | undefined;
      updatedAt?: any | undefined;
      deletedAt?: any | undefined;
    }>;
  };
};

export type AdminRemoveCompetitionMutationVariables = Exact<{
  accountId: Scalars['String']['input'];
  id: Scalars['String']['input'];
}>;

export type AdminRemoveCompetitionMutation = { __typename?: 'Mutation'; adminRemoveCompetition: boolean };

export type AdminUpdateCompetitionMutationVariables = Exact<{
  accountId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  input: UpdateCompetitionInput;
}>;

export type AdminUpdateCompetitionMutation = {
  __typename?: 'Mutation';
  adminUpdateCompetition: {
    __typename?: 'Competition';
    id: string;
    name: string;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    leagues: Array<{
      __typename?: 'League';
      id: string;
      name: string;
      competitionId?: string | undefined;
      createdAt?: any | undefined;
      updatedAt?: any | undefined;
      deletedAt?: any | undefined;
    }>;
  };
};

export type CompetitionsQueryVariables = Exact<{ [key: string]: never }>;

export type CompetitionsQuery = {
  __typename?: 'Query';
  competitions: {
    __typename?: 'PaginatedCompetition';
    edges: Array<{
      __typename?: 'CompetitionEdge';
      node: {
        __typename?: 'Competition';
        id: string;
        name: string;
        createdAt?: any | undefined;
        updatedAt?: any | undefined;
        leagues: Array<{
          __typename?: 'League';
          id: string;
          name: string;
          competitionId?: string | undefined;
          createdAt?: any | undefined;
          updatedAt?: any | undefined;
          deletedAt?: any | undefined;
        }>;
      };
    }>;
  };
};

export type CompetitionFragmentFragment = {
  __typename?: 'Competition';
  id: string;
  name: string;
  createdAt?: any | undefined;
  updatedAt?: any | undefined;
  leagues: Array<{
    __typename?: 'League';
    id: string;
    name: string;
    competitionId?: string | undefined;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    deletedAt?: any | undefined;
  }>;
};

export type UpdateCompetitionMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: UpdateCompetitionInput;
}>;

export type UpdateCompetitionMutation = {
  __typename?: 'Mutation';
  updateCompetition: {
    __typename?: 'Competition';
    id: string;
    name: string;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    leagues: Array<{
      __typename?: 'League';
      id: string;
      name: string;
      competitionId?: string | undefined;
      createdAt?: any | undefined;
      updatedAt?: any | undefined;
      deletedAt?: any | undefined;
    }>;
  };
};

export type ContractTemplatesQueryVariables = Exact<{ [key: string]: never }>;

export type ContractTemplatesQuery = {
  __typename?: 'Query';
  contractTemplates: {
    __typename?: 'PaginatedContractTemplate';
    edges: Array<{
      __typename?: 'ContractTemplateEdge';
      node: {
        __typename?: 'ContractTemplate';
        id: string;
        name: string;
        template: string;
        createdAt?: any | undefined;
        updatedAt?: any | undefined;
      };
    }>;
  };
};

export type ContractTemplateQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type ContractTemplateQuery = {
  __typename?: 'Query';
  contractTemplate: {
    __typename?: 'ContractTemplate';
    id: string;
    name: string;
    template: string;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
  };
};

export type ContractTemplateFragmentFragment = {
  __typename?: 'ContractTemplate';
  id: string;
  name: string;
  template: string;
  createdAt?: any | undefined;
  updatedAt?: any | undefined;
};

export type CreateContractTemplateMutationVariables = Exact<{
  input: CreateContractTemplateInput;
}>;

export type CreateContractTemplateMutation = {
  __typename?: 'Mutation';
  createContractTemplate: {
    __typename?: 'ContractTemplate';
    id: string;
    name: string;
    template: string;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
  };
};

export type RemoveContractTemplateMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RemoveContractTemplateMutation = { __typename?: 'Mutation'; removeContractTemplate: boolean };

export type UpdateContractTemplateMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: UpdateContractTemplateInput;
}>;

export type UpdateContractTemplateMutation = {
  __typename?: 'Mutation';
  updateContractTemplate: {
    __typename?: 'ContractTemplate';
    id: string;
    name: string;
    template: string;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
  };
};

export type ContractsWithTasksQueryVariables = Exact<{
  leagueIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  seasonId: Scalars['String']['input'];
  type: ContractType;
  relevance?: InputMaybe<ContractRelevanceFilter>;
  search?: InputMaybe<Scalars['String']['input']>;
  clubLeadId?: InputMaybe<Scalars['String']['input']>;
  salesLeadId?: InputMaybe<Scalars['String']['input']>;
  partnerCategoryId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ContractStatusFilter>;
}>;

export type ContractsWithTasksQuery = {
  __typename?: 'Query';
  contracts: {
    __typename?: 'PaginatedContract';
    edges: Array<{
      __typename?: 'ContractEdge';
      node: {
        __typename?: 'Contract';
        id: string;
        name?: string | undefined;
        type: ContractType;
        draft: boolean;
        startDate?: any | undefined;
        endDate?: any | undefined;
        note?: string | undefined;
        salesLead?: { __typename?: 'User'; id: string } | undefined;
        clubLead?: { __typename?: 'User'; id: string } | undefined;
        notes?:
          | Array<{
              __typename?: 'ContractNote';
              id: string;
              content: string;
              createdAt?: any | undefined;
              updatedAt?: any | undefined;
              author: { __typename?: 'Author'; id: string; email: string };
            }>
          | undefined;
        partner: {
          __typename?: 'Partner';
          id: string;
          companyName?: string | undefined;
          clubLead?: { __typename?: 'User'; id: string } | undefined;
          contractClubLeads?: Array<{ __typename?: 'User'; id: string }> | undefined;
          category?: { __typename?: 'PartnerCategory'; id: string; name: string } | undefined;
        };
        items: Array<{
          __typename?: 'ContractItem';
          id: string;
          amount: number;
          league: { __typename?: 'League'; id: string; name: string };
          season: { __typename?: 'Season'; id: string; name: string };
          match?: { __typename?: 'Match'; id: string; name: string } | undefined;
          inventory: { __typename?: 'Inventory'; id: string; name: string; unit: UnitType };
          task?:
            | {
                __typename?: 'Task';
                id: string;
                name?: string | undefined;
                totalTasks: number;
                customAmount?: number | undefined;
                updatedAt?: any | undefined;
                lastEdit: { __typename?: 'LastEdit'; timestamp: any };
                stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
              }
            | undefined;
        }>;
        tasks?:
          | Array<{
              __typename?: 'Task';
              id: string;
              name?: string | undefined;
              totalTasks: number;
              customAmount?: number | undefined;
              updatedAt?: any | undefined;
              lastEdit: { __typename?: 'LastEdit'; timestamp: any };
              stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
            }>
          | undefined;
        total: { __typename?: 'ContractTotals'; price: number };
      };
    }>;
    totals: {
      __typename?: 'ContractStats';
      totalTasks?: number | undefined;
      totalSubTasks?: number | undefined;
      totalOpen?: number | undefined;
      totalInProgress?: number | undefined;
      totalDone?: number | undefined;
    };
  };
};

export type ContractsQueryVariables = Exact<{
  leagueIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  seasonId: Scalars['String']['input'];
  type: ContractType;
  relevance?: InputMaybe<ContractRelevanceFilter>;
  search?: InputMaybe<Scalars['String']['input']>;
  clubLeadId?: InputMaybe<Scalars['String']['input']>;
  salesLeadId?: InputMaybe<Scalars['String']['input']>;
  partnerCategoryId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ContractStatusFilter>;
  autoRenewOnly?: InputMaybe<Scalars['Boolean']['input']>;
  expiringOnly?: InputMaybe<Scalars['Boolean']['input']>;
  probability?: InputMaybe<Scalars['Float']['input']>;
}>;

export type ContractsQuery = {
  __typename?: 'Query';
  contracts: {
    __typename?: 'PaginatedContract';
    edges: Array<{
      __typename?: 'ContractEdge';
      node: {
        __typename?: 'Contract';
        id: string;
        name?: string | undefined;
        draft: boolean;
        type: ContractType;
        probability: number;
        startDate?: any | undefined;
        endDate?: any | undefined;
        invoicesDisabled: boolean;
        createdAt?: any | undefined;
        updatedAt?: any | undefined;
        canceledAt?: any | undefined;
        note?: string | undefined;
        autoRenew?:
          | {
              __typename?: 'ContractAutoRenew';
              enabled: boolean;
              mode: AutoRenewMode;
              markedForCancellationAt?: any | undefined;
              cancellationPeriodEnd?: any | undefined;
              renewYears: number;
            }
          | undefined;
        notes?:
          | Array<{
              __typename?: 'ContractNote';
              id: string;
              content: string;
              createdAt?: any | undefined;
              updatedAt?: any | undefined;
              author: { __typename?: 'Author'; id: string; email: string };
            }>
          | undefined;
        partner: {
          __typename?: 'Partner';
          id: string;
          companyName?: string | undefined;
          category?: { __typename?: 'PartnerCategory'; id: string; name: string } | undefined;
        };
        salesLead?: { __typename?: 'User'; id: string } | undefined;
        clubLead?: { __typename?: 'User'; id: string } | undefined;
        items: Array<{
          __typename?: 'ContractItem';
          id: string;
          amount: number;
          locked: boolean;
          listUnitPrice: number;
          price: number;
          barter: number;
          league: { __typename?: 'League'; id: string; name: string };
          season: { __typename?: 'Season'; id: string; name: string };
          match?: { __typename?: 'Match'; id: string; name: string } | undefined;
          inventory: {
            __typename?: 'Inventory';
            id: string;
            name: string;
            unit: UnitType;
            topCategory?: { __typename?: 'TopCategory'; id: string; name: string } | undefined;
            listPrices: Array<{ __typename?: 'InventoryListPrice'; id: string; type: InventoryType }>;
          };
        }>;
        bonuses: Array<{
          __typename?: 'ContractBonus';
          id: string;
          price: number;
          achieved: boolean;
          bonusId: string;
          bonusName: string;
          bonusDescription?: string | undefined;
          season: { __typename?: 'Season'; id: string; name: string };
        }>;
        invoices: Array<{
          __typename?: 'Invoice';
          id: string;
          type: InvoiceType;
          percentage?: number | undefined;
          invoiceNumber?: number | undefined;
          cancellationNumber?: number | undefined;
          date: any;
          invoicedAt?: any | undefined;
          serviceStartedAt?: any | undefined;
          serviceEndedAt?: any | undefined;
          paymentDueAt?: any | undefined;
          payedAt?: any | undefined;
          canceledAt?: any | undefined;
          bonusId?: string | undefined;
          season: { __typename?: 'Season'; id: string; name: string };
        }>;
        total: {
          __typename?: 'ContractTotals';
          barter: number;
          discount: number;
          listPrice: number;
          price: number;
        };
      };
    }>;
    totals: {
      __typename?: 'ContractStats';
      bonuses: number;
      bonusesValue: number;
      bonusesAchieved: number;
      bonusesAchievedValue: number;
      discount: number;
      totalBarter: number;
      totalContracts: number;
      totalPrice: number;
      futureInvoiceTotal?: number | undefined;
      openInvoiceTotal?: number | undefined;
      pendingInvoiceTotal?: number | undefined;
      overdueInvoiceTotal?: number | undefined;
      payedInvoiceTotal?: number | undefined;
      totalDone?: number | undefined;
      totalInProgress?: number | undefined;
      totalOpen?: number | undefined;
      totalSubTasks?: number | undefined;
      totalTasks?: number | undefined;
    };
  };
};

export type ApplyContractPercentageMutationVariables = Exact<{
  contractId: Scalars['String']['input'];
  leagueId?: InputMaybe<Scalars['String']['input']>;
  seasonId?: InputMaybe<Scalars['String']['input']>;
  barterPercentage?: InputMaybe<Scalars['Float']['input']>;
  barterFixed?: InputMaybe<Scalars['Float']['input']>;
  discountPercentage?: InputMaybe<Scalars['Float']['input']>;
  discountFixed?: InputMaybe<Scalars['Float']['input']>;
}>;

export type ApplyContractPercentageMutation = {
  __typename?: 'Mutation';
  applyContractPercentage: {
    __typename?: 'Contract';
    id: string;
    name?: string | undefined;
    draft: boolean;
    type: ContractType;
    probability: number;
    startDate?: any | undefined;
    endDate?: any | undefined;
    invoicesDisabled: boolean;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    canceledAt?: any | undefined;
    note?: string | undefined;
    items: Array<{
      __typename?: 'ContractItem';
      id: string;
      amount: number;
      locked: boolean;
      listUnitPrice: number;
      price: number;
      barter: number;
      task?:
        | {
            __typename?: 'Task';
            id: string;
            name?: string | undefined;
            note?: string | undefined;
            type: TaskType;
            totalTasks: number;
            customAmount?: number | undefined;
            contractItemAmount?: number | undefined;
            createdAt?: any | undefined;
            updatedAt?: any | undefined;
            match?: { __typename?: 'Match'; id: string; name: string } | undefined;
            season: { __typename?: 'Season'; id: string; name: string };
            subTasks: Array<{
              __typename?: 'SubTask';
              id: string;
              status: TaskStatus;
              count: number;
              note?: string | undefined;
              deadline?: any | undefined;
              createdAt?: any | undefined;
              lead?: { __typename?: 'TaskLead'; id: string; type: TaskLeadType } | undefined;
              match?: { __typename?: 'Match'; id: string; name: string; date: any } | undefined;
              lastEdit: {
                __typename?: 'LastEdit';
                timestamp: any;
                user: { __typename?: 'LastUser'; id: string };
              };
            }>;
            lastEdit: {
              __typename?: 'LastEdit';
              timestamp: any;
              user: { __typename?: 'LastUser'; id: string };
            };
            stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
          }
        | undefined;
      league: { __typename?: 'League'; id: string; name: string };
      season: { __typename?: 'Season'; id: string; name: string };
      match?: { __typename?: 'Match'; id: string; name: string } | undefined;
      inventory: {
        __typename?: 'Inventory';
        id: string;
        name: string;
        unit: UnitType;
        topCategory?: { __typename?: 'TopCategory'; id: string; name: string } | undefined;
        listPrices: Array<{ __typename?: 'InventoryListPrice'; id: string; type: InventoryType }>;
      };
    }>;
    tasks?:
      | Array<{
          __typename?: 'Task';
          id: string;
          name?: string | undefined;
          note?: string | undefined;
          type: TaskType;
          totalTasks: number;
          customAmount?: number | undefined;
          contractItemAmount?: number | undefined;
          createdAt?: any | undefined;
          updatedAt?: any | undefined;
          match?: { __typename?: 'Match'; id: string; name: string } | undefined;
          season: { __typename?: 'Season'; id: string; name: string };
          subTasks: Array<{
            __typename?: 'SubTask';
            id: string;
            status: TaskStatus;
            count: number;
            note?: string | undefined;
            deadline?: any | undefined;
            createdAt?: any | undefined;
            lead?: { __typename?: 'TaskLead'; id: string; type: TaskLeadType } | undefined;
            match?: { __typename?: 'Match'; id: string; name: string; date: any } | undefined;
            lastEdit: {
              __typename?: 'LastEdit';
              timestamp: any;
              user: { __typename?: 'LastUser'; id: string };
            };
          }>;
          lastEdit: {
            __typename?: 'LastEdit';
            timestamp: any;
            user: { __typename?: 'LastUser'; id: string };
          };
          stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
        }>
      | undefined;
    template?: { __typename?: 'ContractTemplate'; id: string; name: string; template: string } | undefined;
    autoRenew?:
      | {
          __typename?: 'ContractAutoRenew';
          enabled: boolean;
          mode: AutoRenewMode;
          markedForCancellationAt?: any | undefined;
          cancellationPeriodEnd?: any | undefined;
          renewYears: number;
        }
      | undefined;
    notes?:
      | Array<{
          __typename?: 'ContractNote';
          id: string;
          content: string;
          createdAt?: any | undefined;
          updatedAt?: any | undefined;
          author: { __typename?: 'Author'; id: string; email: string };
        }>
      | undefined;
    partner: {
      __typename?: 'Partner';
      id: string;
      companyName?: string | undefined;
      category?: { __typename?: 'PartnerCategory'; id: string; name: string } | undefined;
    };
    salesLead?: { __typename?: 'User'; id: string } | undefined;
    clubLead?: { __typename?: 'User'; id: string } | undefined;
    bonuses: Array<{
      __typename?: 'ContractBonus';
      id: string;
      price: number;
      achieved: boolean;
      bonusId: string;
      bonusName: string;
      bonusDescription?: string | undefined;
      season: { __typename?: 'Season'; id: string; name: string };
    }>;
    invoices: Array<{
      __typename?: 'Invoice';
      id: string;
      type: InvoiceType;
      percentage?: number | undefined;
      invoiceNumber?: number | undefined;
      cancellationNumber?: number | undefined;
      date: any;
      invoicedAt?: any | undefined;
      serviceStartedAt?: any | undefined;
      serviceEndedAt?: any | undefined;
      paymentDueAt?: any | undefined;
      payedAt?: any | undefined;
      canceledAt?: any | undefined;
      bonusId?: string | undefined;
      season: { __typename?: 'Season'; id: string; name: string };
    }>;
    total: {
      __typename?: 'ContractTotals';
      barter: number;
      discount: number;
      listPrice: number;
      price: number;
    };
  };
};

export type ContractBonusFragmentFragment = {
  __typename?: 'ContractBonus';
  id: string;
  price: number;
  achieved: boolean;
  bonusId: string;
  bonusName: string;
  bonusDescription?: string | undefined;
  season: { __typename?: 'Season'; id: string; name: string };
};

export type CreateContractBonusMutationVariables = Exact<{
  contractId: Scalars['String']['input'];
  input: CreateContractBonusInput;
}>;

export type CreateContractBonusMutation = {
  __typename?: 'Mutation';
  createContractBonus: {
    __typename?: 'Contract';
    id: string;
    name?: string | undefined;
    draft: boolean;
    type: ContractType;
    probability: number;
    startDate?: any | undefined;
    endDate?: any | undefined;
    invoicesDisabled: boolean;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    canceledAt?: any | undefined;
    note?: string | undefined;
    items: Array<{
      __typename?: 'ContractItem';
      id: string;
      amount: number;
      locked: boolean;
      listUnitPrice: number;
      price: number;
      barter: number;
      task?:
        | {
            __typename?: 'Task';
            id: string;
            name?: string | undefined;
            note?: string | undefined;
            type: TaskType;
            totalTasks: number;
            customAmount?: number | undefined;
            contractItemAmount?: number | undefined;
            createdAt?: any | undefined;
            updatedAt?: any | undefined;
            match?: { __typename?: 'Match'; id: string; name: string } | undefined;
            season: { __typename?: 'Season'; id: string; name: string };
            subTasks: Array<{
              __typename?: 'SubTask';
              id: string;
              status: TaskStatus;
              count: number;
              note?: string | undefined;
              deadline?: any | undefined;
              createdAt?: any | undefined;
              lead?: { __typename?: 'TaskLead'; id: string; type: TaskLeadType } | undefined;
              match?: { __typename?: 'Match'; id: string; name: string; date: any } | undefined;
              lastEdit: {
                __typename?: 'LastEdit';
                timestamp: any;
                user: { __typename?: 'LastUser'; id: string };
              };
            }>;
            lastEdit: {
              __typename?: 'LastEdit';
              timestamp: any;
              user: { __typename?: 'LastUser'; id: string };
            };
            stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
          }
        | undefined;
      league: { __typename?: 'League'; id: string; name: string };
      season: { __typename?: 'Season'; id: string; name: string };
      match?: { __typename?: 'Match'; id: string; name: string } | undefined;
      inventory: {
        __typename?: 'Inventory';
        id: string;
        name: string;
        unit: UnitType;
        topCategory?: { __typename?: 'TopCategory'; id: string; name: string } | undefined;
        listPrices: Array<{ __typename?: 'InventoryListPrice'; id: string; type: InventoryType }>;
      };
    }>;
    tasks?:
      | Array<{
          __typename?: 'Task';
          id: string;
          name?: string | undefined;
          note?: string | undefined;
          type: TaskType;
          totalTasks: number;
          customAmount?: number | undefined;
          contractItemAmount?: number | undefined;
          createdAt?: any | undefined;
          updatedAt?: any | undefined;
          match?: { __typename?: 'Match'; id: string; name: string } | undefined;
          season: { __typename?: 'Season'; id: string; name: string };
          subTasks: Array<{
            __typename?: 'SubTask';
            id: string;
            status: TaskStatus;
            count: number;
            note?: string | undefined;
            deadline?: any | undefined;
            createdAt?: any | undefined;
            lead?: { __typename?: 'TaskLead'; id: string; type: TaskLeadType } | undefined;
            match?: { __typename?: 'Match'; id: string; name: string; date: any } | undefined;
            lastEdit: {
              __typename?: 'LastEdit';
              timestamp: any;
              user: { __typename?: 'LastUser'; id: string };
            };
          }>;
          lastEdit: {
            __typename?: 'LastEdit';
            timestamp: any;
            user: { __typename?: 'LastUser'; id: string };
          };
          stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
        }>
      | undefined;
    template?: { __typename?: 'ContractTemplate'; id: string; name: string; template: string } | undefined;
    autoRenew?:
      | {
          __typename?: 'ContractAutoRenew';
          enabled: boolean;
          mode: AutoRenewMode;
          markedForCancellationAt?: any | undefined;
          cancellationPeriodEnd?: any | undefined;
          renewYears: number;
        }
      | undefined;
    notes?:
      | Array<{
          __typename?: 'ContractNote';
          id: string;
          content: string;
          createdAt?: any | undefined;
          updatedAt?: any | undefined;
          author: { __typename?: 'Author'; id: string; email: string };
        }>
      | undefined;
    partner: {
      __typename?: 'Partner';
      id: string;
      companyName?: string | undefined;
      category?: { __typename?: 'PartnerCategory'; id: string; name: string } | undefined;
    };
    salesLead?: { __typename?: 'User'; id: string } | undefined;
    clubLead?: { __typename?: 'User'; id: string } | undefined;
    bonuses: Array<{
      __typename?: 'ContractBonus';
      id: string;
      price: number;
      achieved: boolean;
      bonusId: string;
      bonusName: string;
      bonusDescription?: string | undefined;
      season: { __typename?: 'Season'; id: string; name: string };
    }>;
    invoices: Array<{
      __typename?: 'Invoice';
      id: string;
      type: InvoiceType;
      percentage?: number | undefined;
      invoiceNumber?: number | undefined;
      cancellationNumber?: number | undefined;
      date: any;
      invoicedAt?: any | undefined;
      serviceStartedAt?: any | undefined;
      serviceEndedAt?: any | undefined;
      paymentDueAt?: any | undefined;
      payedAt?: any | undefined;
      canceledAt?: any | undefined;
      bonusId?: string | undefined;
      season: { __typename?: 'Season'; id: string; name: string };
    }>;
    total: {
      __typename?: 'ContractTotals';
      barter: number;
      discount: number;
      listPrice: number;
      price: number;
    };
  };
};

export type RemoveContractBonusMutationVariables = Exact<{
  bonusId: Scalars['String']['input'];
  contractId: Scalars['String']['input'];
}>;

export type RemoveContractBonusMutation = { __typename?: 'Mutation'; removeContractBonus: boolean };

export type UpdateContractBonusMutationVariables = Exact<{
  bonusId: Scalars['String']['input'];
  contractId: Scalars['String']['input'];
  input: UpdateContractBonusInput;
  seasonIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;

export type UpdateContractBonusMutation = {
  __typename?: 'Mutation';
  updateContractBonus: {
    __typename?: 'Contract';
    id: string;
    name?: string | undefined;
    draft: boolean;
    type: ContractType;
    probability: number;
    startDate?: any | undefined;
    endDate?: any | undefined;
    invoicesDisabled: boolean;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    canceledAt?: any | undefined;
    note?: string | undefined;
    items: Array<{
      __typename?: 'ContractItem';
      id: string;
      amount: number;
      locked: boolean;
      listUnitPrice: number;
      price: number;
      barter: number;
      task?:
        | {
            __typename?: 'Task';
            id: string;
            name?: string | undefined;
            note?: string | undefined;
            type: TaskType;
            totalTasks: number;
            customAmount?: number | undefined;
            contractItemAmount?: number | undefined;
            createdAt?: any | undefined;
            updatedAt?: any | undefined;
            match?: { __typename?: 'Match'; id: string; name: string } | undefined;
            season: { __typename?: 'Season'; id: string; name: string };
            subTasks: Array<{
              __typename?: 'SubTask';
              id: string;
              status: TaskStatus;
              count: number;
              note?: string | undefined;
              deadline?: any | undefined;
              createdAt?: any | undefined;
              lead?: { __typename?: 'TaskLead'; id: string; type: TaskLeadType } | undefined;
              match?: { __typename?: 'Match'; id: string; name: string; date: any } | undefined;
              lastEdit: {
                __typename?: 'LastEdit';
                timestamp: any;
                user: { __typename?: 'LastUser'; id: string };
              };
            }>;
            lastEdit: {
              __typename?: 'LastEdit';
              timestamp: any;
              user: { __typename?: 'LastUser'; id: string };
            };
            stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
          }
        | undefined;
      league: { __typename?: 'League'; id: string; name: string };
      season: { __typename?: 'Season'; id: string; name: string };
      match?: { __typename?: 'Match'; id: string; name: string } | undefined;
      inventory: {
        __typename?: 'Inventory';
        id: string;
        name: string;
        unit: UnitType;
        topCategory?: { __typename?: 'TopCategory'; id: string; name: string } | undefined;
        listPrices: Array<{ __typename?: 'InventoryListPrice'; id: string; type: InventoryType }>;
      };
    }>;
    tasks?:
      | Array<{
          __typename?: 'Task';
          id: string;
          name?: string | undefined;
          note?: string | undefined;
          type: TaskType;
          totalTasks: number;
          customAmount?: number | undefined;
          contractItemAmount?: number | undefined;
          createdAt?: any | undefined;
          updatedAt?: any | undefined;
          match?: { __typename?: 'Match'; id: string; name: string } | undefined;
          season: { __typename?: 'Season'; id: string; name: string };
          subTasks: Array<{
            __typename?: 'SubTask';
            id: string;
            status: TaskStatus;
            count: number;
            note?: string | undefined;
            deadline?: any | undefined;
            createdAt?: any | undefined;
            lead?: { __typename?: 'TaskLead'; id: string; type: TaskLeadType } | undefined;
            match?: { __typename?: 'Match'; id: string; name: string; date: any } | undefined;
            lastEdit: {
              __typename?: 'LastEdit';
              timestamp: any;
              user: { __typename?: 'LastUser'; id: string };
            };
          }>;
          lastEdit: {
            __typename?: 'LastEdit';
            timestamp: any;
            user: { __typename?: 'LastUser'; id: string };
          };
          stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
        }>
      | undefined;
    template?: { __typename?: 'ContractTemplate'; id: string; name: string; template: string } | undefined;
    autoRenew?:
      | {
          __typename?: 'ContractAutoRenew';
          enabled: boolean;
          mode: AutoRenewMode;
          markedForCancellationAt?: any | undefined;
          cancellationPeriodEnd?: any | undefined;
          renewYears: number;
        }
      | undefined;
    notes?:
      | Array<{
          __typename?: 'ContractNote';
          id: string;
          content: string;
          createdAt?: any | undefined;
          updatedAt?: any | undefined;
          author: { __typename?: 'Author'; id: string; email: string };
        }>
      | undefined;
    partner: {
      __typename?: 'Partner';
      id: string;
      companyName?: string | undefined;
      category?: { __typename?: 'PartnerCategory'; id: string; name: string } | undefined;
    };
    salesLead?: { __typename?: 'User'; id: string } | undefined;
    clubLead?: { __typename?: 'User'; id: string } | undefined;
    bonuses: Array<{
      __typename?: 'ContractBonus';
      id: string;
      price: number;
      achieved: boolean;
      bonusId: string;
      bonusName: string;
      bonusDescription?: string | undefined;
      season: { __typename?: 'Season'; id: string; name: string };
    }>;
    invoices: Array<{
      __typename?: 'Invoice';
      id: string;
      type: InvoiceType;
      percentage?: number | undefined;
      invoiceNumber?: number | undefined;
      cancellationNumber?: number | undefined;
      date: any;
      invoicedAt?: any | undefined;
      serviceStartedAt?: any | undefined;
      serviceEndedAt?: any | undefined;
      paymentDueAt?: any | undefined;
      payedAt?: any | undefined;
      canceledAt?: any | undefined;
      bonusId?: string | undefined;
      season: { __typename?: 'Season'; id: string; name: string };
    }>;
    total: {
      __typename?: 'ContractTotals';
      barter: number;
      discount: number;
      listPrice: number;
      price: number;
    };
  };
};

export type CancelContractMutationVariables = Exact<{
  cancelContractId: Scalars['String']['input'];
  status: ContractCancelStatus;
}>;

export type CancelContractMutation = {
  __typename?: 'Mutation';
  cancelContract: {
    __typename?: 'Contract';
    id: string;
    name?: string | undefined;
    draft: boolean;
    type: ContractType;
    probability: number;
    startDate?: any | undefined;
    endDate?: any | undefined;
    invoicesDisabled: boolean;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    canceledAt?: any | undefined;
    note?: string | undefined;
    items: Array<{
      __typename?: 'ContractItem';
      id: string;
      amount: number;
      locked: boolean;
      listUnitPrice: number;
      price: number;
      barter: number;
      task?:
        | {
            __typename?: 'Task';
            id: string;
            name?: string | undefined;
            note?: string | undefined;
            type: TaskType;
            totalTasks: number;
            customAmount?: number | undefined;
            contractItemAmount?: number | undefined;
            createdAt?: any | undefined;
            updatedAt?: any | undefined;
            match?: { __typename?: 'Match'; id: string; name: string } | undefined;
            season: { __typename?: 'Season'; id: string; name: string };
            subTasks: Array<{
              __typename?: 'SubTask';
              id: string;
              status: TaskStatus;
              count: number;
              note?: string | undefined;
              deadline?: any | undefined;
              createdAt?: any | undefined;
              lead?: { __typename?: 'TaskLead'; id: string; type: TaskLeadType } | undefined;
              match?: { __typename?: 'Match'; id: string; name: string; date: any } | undefined;
              lastEdit: {
                __typename?: 'LastEdit';
                timestamp: any;
                user: { __typename?: 'LastUser'; id: string };
              };
            }>;
            lastEdit: {
              __typename?: 'LastEdit';
              timestamp: any;
              user: { __typename?: 'LastUser'; id: string };
            };
            stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
          }
        | undefined;
      league: { __typename?: 'League'; id: string; name: string };
      season: { __typename?: 'Season'; id: string; name: string };
      match?: { __typename?: 'Match'; id: string; name: string } | undefined;
      inventory: {
        __typename?: 'Inventory';
        id: string;
        name: string;
        unit: UnitType;
        topCategory?: { __typename?: 'TopCategory'; id: string; name: string } | undefined;
        listPrices: Array<{ __typename?: 'InventoryListPrice'; id: string; type: InventoryType }>;
      };
    }>;
    tasks?:
      | Array<{
          __typename?: 'Task';
          id: string;
          name?: string | undefined;
          note?: string | undefined;
          type: TaskType;
          totalTasks: number;
          customAmount?: number | undefined;
          contractItemAmount?: number | undefined;
          createdAt?: any | undefined;
          updatedAt?: any | undefined;
          match?: { __typename?: 'Match'; id: string; name: string } | undefined;
          season: { __typename?: 'Season'; id: string; name: string };
          subTasks: Array<{
            __typename?: 'SubTask';
            id: string;
            status: TaskStatus;
            count: number;
            note?: string | undefined;
            deadline?: any | undefined;
            createdAt?: any | undefined;
            lead?: { __typename?: 'TaskLead'; id: string; type: TaskLeadType } | undefined;
            match?: { __typename?: 'Match'; id: string; name: string; date: any } | undefined;
            lastEdit: {
              __typename?: 'LastEdit';
              timestamp: any;
              user: { __typename?: 'LastUser'; id: string };
            };
          }>;
          lastEdit: {
            __typename?: 'LastEdit';
            timestamp: any;
            user: { __typename?: 'LastUser'; id: string };
          };
          stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
        }>
      | undefined;
    template?: { __typename?: 'ContractTemplate'; id: string; name: string; template: string } | undefined;
    autoRenew?:
      | {
          __typename?: 'ContractAutoRenew';
          enabled: boolean;
          mode: AutoRenewMode;
          markedForCancellationAt?: any | undefined;
          cancellationPeriodEnd?: any | undefined;
          renewYears: number;
        }
      | undefined;
    notes?:
      | Array<{
          __typename?: 'ContractNote';
          id: string;
          content: string;
          createdAt?: any | undefined;
          updatedAt?: any | undefined;
          author: { __typename?: 'Author'; id: string; email: string };
        }>
      | undefined;
    partner: {
      __typename?: 'Partner';
      id: string;
      companyName?: string | undefined;
      category?: { __typename?: 'PartnerCategory'; id: string; name: string } | undefined;
    };
    salesLead?: { __typename?: 'User'; id: string } | undefined;
    clubLead?: { __typename?: 'User'; id: string } | undefined;
    bonuses: Array<{
      __typename?: 'ContractBonus';
      id: string;
      price: number;
      achieved: boolean;
      bonusId: string;
      bonusName: string;
      bonusDescription?: string | undefined;
      season: { __typename?: 'Season'; id: string; name: string };
    }>;
    invoices: Array<{
      __typename?: 'Invoice';
      id: string;
      type: InvoiceType;
      percentage?: number | undefined;
      invoiceNumber?: number | undefined;
      cancellationNumber?: number | undefined;
      date: any;
      invoicedAt?: any | undefined;
      serviceStartedAt?: any | undefined;
      serviceEndedAt?: any | undefined;
      paymentDueAt?: any | undefined;
      payedAt?: any | undefined;
      canceledAt?: any | undefined;
      bonusId?: string | undefined;
      season: { __typename?: 'Season'; id: string; name: string };
    }>;
    total: {
      __typename?: 'ContractTotals';
      barter: number;
      discount: number;
      listPrice: number;
      price: number;
    };
  };
};

export type CloneContractMutationVariables = Exact<{
  id: Scalars['String']['input'];
  status?: InputMaybe<ContractType>;
}>;

export type CloneContractMutation = {
  __typename?: 'Mutation';
  cloneContract: {
    __typename?: 'Contract';
    id: string;
    name?: string | undefined;
    draft: boolean;
    type: ContractType;
    probability: number;
    startDate?: any | undefined;
    endDate?: any | undefined;
    invoicesDisabled: boolean;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    canceledAt?: any | undefined;
    note?: string | undefined;
    items: Array<{
      __typename?: 'ContractItem';
      id: string;
      amount: number;
      locked: boolean;
      listUnitPrice: number;
      price: number;
      barter: number;
      task?:
        | {
            __typename?: 'Task';
            id: string;
            name?: string | undefined;
            note?: string | undefined;
            type: TaskType;
            totalTasks: number;
            customAmount?: number | undefined;
            contractItemAmount?: number | undefined;
            createdAt?: any | undefined;
            updatedAt?: any | undefined;
            match?: { __typename?: 'Match'; id: string; name: string } | undefined;
            season: { __typename?: 'Season'; id: string; name: string };
            subTasks: Array<{
              __typename?: 'SubTask';
              id: string;
              status: TaskStatus;
              count: number;
              note?: string | undefined;
              deadline?: any | undefined;
              createdAt?: any | undefined;
              lead?: { __typename?: 'TaskLead'; id: string; type: TaskLeadType } | undefined;
              match?: { __typename?: 'Match'; id: string; name: string; date: any } | undefined;
              lastEdit: {
                __typename?: 'LastEdit';
                timestamp: any;
                user: { __typename?: 'LastUser'; id: string };
              };
            }>;
            lastEdit: {
              __typename?: 'LastEdit';
              timestamp: any;
              user: { __typename?: 'LastUser'; id: string };
            };
            stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
          }
        | undefined;
      league: { __typename?: 'League'; id: string; name: string };
      season: { __typename?: 'Season'; id: string; name: string };
      match?: { __typename?: 'Match'; id: string; name: string } | undefined;
      inventory: {
        __typename?: 'Inventory';
        id: string;
        name: string;
        unit: UnitType;
        topCategory?: { __typename?: 'TopCategory'; id: string; name: string } | undefined;
        listPrices: Array<{ __typename?: 'InventoryListPrice'; id: string; type: InventoryType }>;
      };
    }>;
    tasks?:
      | Array<{
          __typename?: 'Task';
          id: string;
          name?: string | undefined;
          note?: string | undefined;
          type: TaskType;
          totalTasks: number;
          customAmount?: number | undefined;
          contractItemAmount?: number | undefined;
          createdAt?: any | undefined;
          updatedAt?: any | undefined;
          match?: { __typename?: 'Match'; id: string; name: string } | undefined;
          season: { __typename?: 'Season'; id: string; name: string };
          subTasks: Array<{
            __typename?: 'SubTask';
            id: string;
            status: TaskStatus;
            count: number;
            note?: string | undefined;
            deadline?: any | undefined;
            createdAt?: any | undefined;
            lead?: { __typename?: 'TaskLead'; id: string; type: TaskLeadType } | undefined;
            match?: { __typename?: 'Match'; id: string; name: string; date: any } | undefined;
            lastEdit: {
              __typename?: 'LastEdit';
              timestamp: any;
              user: { __typename?: 'LastUser'; id: string };
            };
          }>;
          lastEdit: {
            __typename?: 'LastEdit';
            timestamp: any;
            user: { __typename?: 'LastUser'; id: string };
          };
          stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
        }>
      | undefined;
    template?: { __typename?: 'ContractTemplate'; id: string; name: string; template: string } | undefined;
    autoRenew?:
      | {
          __typename?: 'ContractAutoRenew';
          enabled: boolean;
          mode: AutoRenewMode;
          markedForCancellationAt?: any | undefined;
          cancellationPeriodEnd?: any | undefined;
          renewYears: number;
        }
      | undefined;
    notes?:
      | Array<{
          __typename?: 'ContractNote';
          id: string;
          content: string;
          createdAt?: any | undefined;
          updatedAt?: any | undefined;
          author: { __typename?: 'Author'; id: string; email: string };
        }>
      | undefined;
    partner: {
      __typename?: 'Partner';
      id: string;
      companyName?: string | undefined;
      category?: { __typename?: 'PartnerCategory'; id: string; name: string } | undefined;
    };
    salesLead?: { __typename?: 'User'; id: string } | undefined;
    clubLead?: { __typename?: 'User'; id: string } | undefined;
    bonuses: Array<{
      __typename?: 'ContractBonus';
      id: string;
      price: number;
      achieved: boolean;
      bonusId: string;
      bonusName: string;
      bonusDescription?: string | undefined;
      season: { __typename?: 'Season'; id: string; name: string };
    }>;
    invoices: Array<{
      __typename?: 'Invoice';
      id: string;
      type: InvoiceType;
      percentage?: number | undefined;
      invoiceNumber?: number | undefined;
      cancellationNumber?: number | undefined;
      date: any;
      invoicedAt?: any | undefined;
      serviceStartedAt?: any | undefined;
      serviceEndedAt?: any | undefined;
      paymentDueAt?: any | undefined;
      payedAt?: any | undefined;
      canceledAt?: any | undefined;
      bonusId?: string | undefined;
      season: { __typename?: 'Season'; id: string; name: string };
    }>;
    total: {
      __typename?: 'ContractTotals';
      barter: number;
      discount: number;
      listPrice: number;
      price: number;
    };
  };
};

export type ContractAddBundleMutationVariables = Exact<{
  bundleId: Scalars['String']['input'];
  contractId: Scalars['String']['input'];
}>;

export type ContractAddBundleMutation = {
  __typename?: 'Mutation';
  contractAddBundle: {
    __typename?: 'Contract';
    id: string;
    name?: string | undefined;
    draft: boolean;
    type: ContractType;
    probability: number;
    startDate?: any | undefined;
    endDate?: any | undefined;
    invoicesDisabled: boolean;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    canceledAt?: any | undefined;
    note?: string | undefined;
    items: Array<{
      __typename?: 'ContractItem';
      id: string;
      amount: number;
      locked: boolean;
      listUnitPrice: number;
      price: number;
      barter: number;
      task?:
        | {
            __typename?: 'Task';
            id: string;
            name?: string | undefined;
            note?: string | undefined;
            type: TaskType;
            totalTasks: number;
            customAmount?: number | undefined;
            contractItemAmount?: number | undefined;
            createdAt?: any | undefined;
            updatedAt?: any | undefined;
            match?: { __typename?: 'Match'; id: string; name: string } | undefined;
            season: { __typename?: 'Season'; id: string; name: string };
            subTasks: Array<{
              __typename?: 'SubTask';
              id: string;
              status: TaskStatus;
              count: number;
              note?: string | undefined;
              deadline?: any | undefined;
              createdAt?: any | undefined;
              lead?: { __typename?: 'TaskLead'; id: string; type: TaskLeadType } | undefined;
              match?: { __typename?: 'Match'; id: string; name: string; date: any } | undefined;
              lastEdit: {
                __typename?: 'LastEdit';
                timestamp: any;
                user: { __typename?: 'LastUser'; id: string };
              };
            }>;
            lastEdit: {
              __typename?: 'LastEdit';
              timestamp: any;
              user: { __typename?: 'LastUser'; id: string };
            };
            stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
          }
        | undefined;
      league: { __typename?: 'League'; id: string; name: string };
      season: { __typename?: 'Season'; id: string; name: string };
      match?: { __typename?: 'Match'; id: string; name: string } | undefined;
      inventory: {
        __typename?: 'Inventory';
        id: string;
        name: string;
        unit: UnitType;
        topCategory?: { __typename?: 'TopCategory'; id: string; name: string } | undefined;
        listPrices: Array<{ __typename?: 'InventoryListPrice'; id: string; type: InventoryType }>;
      };
    }>;
    tasks?:
      | Array<{
          __typename?: 'Task';
          id: string;
          name?: string | undefined;
          note?: string | undefined;
          type: TaskType;
          totalTasks: number;
          customAmount?: number | undefined;
          contractItemAmount?: number | undefined;
          createdAt?: any | undefined;
          updatedAt?: any | undefined;
          match?: { __typename?: 'Match'; id: string; name: string } | undefined;
          season: { __typename?: 'Season'; id: string; name: string };
          subTasks: Array<{
            __typename?: 'SubTask';
            id: string;
            status: TaskStatus;
            count: number;
            note?: string | undefined;
            deadline?: any | undefined;
            createdAt?: any | undefined;
            lead?: { __typename?: 'TaskLead'; id: string; type: TaskLeadType } | undefined;
            match?: { __typename?: 'Match'; id: string; name: string; date: any } | undefined;
            lastEdit: {
              __typename?: 'LastEdit';
              timestamp: any;
              user: { __typename?: 'LastUser'; id: string };
            };
          }>;
          lastEdit: {
            __typename?: 'LastEdit';
            timestamp: any;
            user: { __typename?: 'LastUser'; id: string };
          };
          stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
        }>
      | undefined;
    template?: { __typename?: 'ContractTemplate'; id: string; name: string; template: string } | undefined;
    autoRenew?:
      | {
          __typename?: 'ContractAutoRenew';
          enabled: boolean;
          mode: AutoRenewMode;
          markedForCancellationAt?: any | undefined;
          cancellationPeriodEnd?: any | undefined;
          renewYears: number;
        }
      | undefined;
    notes?:
      | Array<{
          __typename?: 'ContractNote';
          id: string;
          content: string;
          createdAt?: any | undefined;
          updatedAt?: any | undefined;
          author: { __typename?: 'Author'; id: string; email: string };
        }>
      | undefined;
    partner: {
      __typename?: 'Partner';
      id: string;
      companyName?: string | undefined;
      category?: { __typename?: 'PartnerCategory'; id: string; name: string } | undefined;
    };
    salesLead?: { __typename?: 'User'; id: string } | undefined;
    clubLead?: { __typename?: 'User'; id: string } | undefined;
    bonuses: Array<{
      __typename?: 'ContractBonus';
      id: string;
      price: number;
      achieved: boolean;
      bonusId: string;
      bonusName: string;
      bonusDescription?: string | undefined;
      season: { __typename?: 'Season'; id: string; name: string };
    }>;
    invoices: Array<{
      __typename?: 'Invoice';
      id: string;
      type: InvoiceType;
      percentage?: number | undefined;
      invoiceNumber?: number | undefined;
      cancellationNumber?: number | undefined;
      date: any;
      invoicedAt?: any | undefined;
      serviceStartedAt?: any | undefined;
      serviceEndedAt?: any | undefined;
      paymentDueAt?: any | undefined;
      payedAt?: any | undefined;
      canceledAt?: any | undefined;
      bonusId?: string | undefined;
      season: { __typename?: 'Season'; id: string; name: string };
    }>;
    total: {
      __typename?: 'ContractTotals';
      barter: number;
      discount: number;
      listPrice: number;
      price: number;
    };
  };
};

export type ContractNoteFragmentFragment = {
  __typename?: 'ContractNote';
  id: string;
  content: string;
  createdAt?: any | undefined;
  updatedAt?: any | undefined;
  author: { __typename?: 'Author'; id: string; email: string };
};

export type CreateContractNoteMutationVariables = Exact<{
  input: CreateContractNoteInput;
  contractId: Scalars['String']['input'];
}>;

export type CreateContractNoteMutation = {
  __typename?: 'Mutation';
  createContractNote: {
    __typename?: 'ContractNote';
    id: string;
    content: string;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    author: { __typename?: 'Author'; id: string; email: string };
  };
};

export type RemoveContractNoteMutationVariables = Exact<{
  noteId: Scalars['String']['input'];
  contractId: Scalars['String']['input'];
}>;

export type RemoveContractNoteMutation = { __typename?: 'Mutation'; removeContractNote: boolean };

export type UpdateContractNoteMutationVariables = Exact<{
  input: UpdateContractNoteInput;
  noteId: Scalars['String']['input'];
  contractId: Scalars['String']['input'];
}>;

export type UpdateContractNoteMutation = {
  __typename?: 'Mutation';
  updateContractNote: {
    __typename?: 'ContractNote';
    id: string;
    content: string;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    author: { __typename?: 'Author'; id: string; email: string };
  };
};

export type ContractRemoveBundleMutationVariables = Exact<{
  bundleId: Scalars['String']['input'];
  contractId: Scalars['String']['input'];
}>;

export type ContractRemoveBundleMutation = {
  __typename?: 'Mutation';
  contractAddBundle: {
    __typename?: 'Contract';
    id: string;
    name?: string | undefined;
    draft: boolean;
    type: ContractType;
    probability: number;
    startDate?: any | undefined;
    endDate?: any | undefined;
    invoicesDisabled: boolean;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    canceledAt?: any | undefined;
    note?: string | undefined;
    items: Array<{
      __typename?: 'ContractItem';
      id: string;
      amount: number;
      locked: boolean;
      listUnitPrice: number;
      price: number;
      barter: number;
      task?:
        | {
            __typename?: 'Task';
            id: string;
            name?: string | undefined;
            note?: string | undefined;
            type: TaskType;
            totalTasks: number;
            customAmount?: number | undefined;
            contractItemAmount?: number | undefined;
            createdAt?: any | undefined;
            updatedAt?: any | undefined;
            match?: { __typename?: 'Match'; id: string; name: string } | undefined;
            season: { __typename?: 'Season'; id: string; name: string };
            subTasks: Array<{
              __typename?: 'SubTask';
              id: string;
              status: TaskStatus;
              count: number;
              note?: string | undefined;
              deadline?: any | undefined;
              createdAt?: any | undefined;
              lead?: { __typename?: 'TaskLead'; id: string; type: TaskLeadType } | undefined;
              match?: { __typename?: 'Match'; id: string; name: string; date: any } | undefined;
              lastEdit: {
                __typename?: 'LastEdit';
                timestamp: any;
                user: { __typename?: 'LastUser'; id: string };
              };
            }>;
            lastEdit: {
              __typename?: 'LastEdit';
              timestamp: any;
              user: { __typename?: 'LastUser'; id: string };
            };
            stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
          }
        | undefined;
      league: { __typename?: 'League'; id: string; name: string };
      season: { __typename?: 'Season'; id: string; name: string };
      match?: { __typename?: 'Match'; id: string; name: string } | undefined;
      inventory: {
        __typename?: 'Inventory';
        id: string;
        name: string;
        unit: UnitType;
        topCategory?: { __typename?: 'TopCategory'; id: string; name: string } | undefined;
        listPrices: Array<{ __typename?: 'InventoryListPrice'; id: string; type: InventoryType }>;
      };
    }>;
    tasks?:
      | Array<{
          __typename?: 'Task';
          id: string;
          name?: string | undefined;
          note?: string | undefined;
          type: TaskType;
          totalTasks: number;
          customAmount?: number | undefined;
          contractItemAmount?: number | undefined;
          createdAt?: any | undefined;
          updatedAt?: any | undefined;
          match?: { __typename?: 'Match'; id: string; name: string } | undefined;
          season: { __typename?: 'Season'; id: string; name: string };
          subTasks: Array<{
            __typename?: 'SubTask';
            id: string;
            status: TaskStatus;
            count: number;
            note?: string | undefined;
            deadline?: any | undefined;
            createdAt?: any | undefined;
            lead?: { __typename?: 'TaskLead'; id: string; type: TaskLeadType } | undefined;
            match?: { __typename?: 'Match'; id: string; name: string; date: any } | undefined;
            lastEdit: {
              __typename?: 'LastEdit';
              timestamp: any;
              user: { __typename?: 'LastUser'; id: string };
            };
          }>;
          lastEdit: {
            __typename?: 'LastEdit';
            timestamp: any;
            user: { __typename?: 'LastUser'; id: string };
          };
          stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
        }>
      | undefined;
    template?: { __typename?: 'ContractTemplate'; id: string; name: string; template: string } | undefined;
    autoRenew?:
      | {
          __typename?: 'ContractAutoRenew';
          enabled: boolean;
          mode: AutoRenewMode;
          markedForCancellationAt?: any | undefined;
          cancellationPeriodEnd?: any | undefined;
          renewYears: number;
        }
      | undefined;
    notes?:
      | Array<{
          __typename?: 'ContractNote';
          id: string;
          content: string;
          createdAt?: any | undefined;
          updatedAt?: any | undefined;
          author: { __typename?: 'Author'; id: string; email: string };
        }>
      | undefined;
    partner: {
      __typename?: 'Partner';
      id: string;
      companyName?: string | undefined;
      category?: { __typename?: 'PartnerCategory'; id: string; name: string } | undefined;
    };
    salesLead?: { __typename?: 'User'; id: string } | undefined;
    clubLead?: { __typename?: 'User'; id: string } | undefined;
    bonuses: Array<{
      __typename?: 'ContractBonus';
      id: string;
      price: number;
      achieved: boolean;
      bonusId: string;
      bonusName: string;
      bonusDescription?: string | undefined;
      season: { __typename?: 'Season'; id: string; name: string };
    }>;
    invoices: Array<{
      __typename?: 'Invoice';
      id: string;
      type: InvoiceType;
      percentage?: number | undefined;
      invoiceNumber?: number | undefined;
      cancellationNumber?: number | undefined;
      date: any;
      invoicedAt?: any | undefined;
      serviceStartedAt?: any | undefined;
      serviceEndedAt?: any | undefined;
      paymentDueAt?: any | undefined;
      payedAt?: any | undefined;
      canceledAt?: any | undefined;
      bonusId?: string | undefined;
      season: { __typename?: 'Season'; id: string; name: string };
    }>;
    total: {
      __typename?: 'ContractTotals';
      barter: number;
      discount: number;
      listPrice: number;
      price: number;
    };
  };
};

export type ContractFragmentFragment = {
  __typename?: 'Contract';
  id: string;
  name?: string | undefined;
  draft: boolean;
  type: ContractType;
  probability: number;
  startDate?: any | undefined;
  endDate?: any | undefined;
  invoicesDisabled: boolean;
  createdAt?: any | undefined;
  updatedAt?: any | undefined;
  canceledAt?: any | undefined;
  note?: string | undefined;
  autoRenew?:
    | {
        __typename?: 'ContractAutoRenew';
        enabled: boolean;
        mode: AutoRenewMode;
        markedForCancellationAt?: any | undefined;
        cancellationPeriodEnd?: any | undefined;
        renewYears: number;
      }
    | undefined;
  notes?:
    | Array<{
        __typename?: 'ContractNote';
        id: string;
        content: string;
        createdAt?: any | undefined;
        updatedAt?: any | undefined;
        author: { __typename?: 'Author'; id: string; email: string };
      }>
    | undefined;
  partner: {
    __typename?: 'Partner';
    id: string;
    companyName?: string | undefined;
    category?: { __typename?: 'PartnerCategory'; id: string; name: string } | undefined;
  };
  salesLead?: { __typename?: 'User'; id: string } | undefined;
  clubLead?: { __typename?: 'User'; id: string } | undefined;
  items: Array<{
    __typename?: 'ContractItem';
    id: string;
    amount: number;
    locked: boolean;
    listUnitPrice: number;
    price: number;
    barter: number;
    league: { __typename?: 'League'; id: string; name: string };
    season: { __typename?: 'Season'; id: string; name: string };
    match?: { __typename?: 'Match'; id: string; name: string } | undefined;
    inventory: {
      __typename?: 'Inventory';
      id: string;
      name: string;
      unit: UnitType;
      topCategory?: { __typename?: 'TopCategory'; id: string; name: string } | undefined;
      listPrices: Array<{ __typename?: 'InventoryListPrice'; id: string; type: InventoryType }>;
    };
  }>;
  bonuses: Array<{
    __typename?: 'ContractBonus';
    id: string;
    price: number;
    achieved: boolean;
    bonusId: string;
    bonusName: string;
    bonusDescription?: string | undefined;
    season: { __typename?: 'Season'; id: string; name: string };
  }>;
  invoices: Array<{
    __typename?: 'Invoice';
    id: string;
    type: InvoiceType;
    percentage?: number | undefined;
    invoiceNumber?: number | undefined;
    cancellationNumber?: number | undefined;
    date: any;
    invoicedAt?: any | undefined;
    serviceStartedAt?: any | undefined;
    serviceEndedAt?: any | undefined;
    paymentDueAt?: any | undefined;
    payedAt?: any | undefined;
    canceledAt?: any | undefined;
    bonusId?: string | undefined;
    season: { __typename?: 'Season'; id: string; name: string };
  }>;
  total: {
    __typename?: 'ContractTotals';
    barter: number;
    discount: number;
    listPrice: number;
    price: number;
  };
};

export type ConvertToContractMutationVariables = Exact<{
  contractId: Scalars['String']['input'];
}>;

export type ConvertToContractMutation = {
  __typename?: 'Mutation';
  convertToContract: {
    __typename?: 'Contract';
    id: string;
    name?: string | undefined;
    draft: boolean;
    type: ContractType;
    probability: number;
    startDate?: any | undefined;
    endDate?: any | undefined;
    invoicesDisabled: boolean;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    canceledAt?: any | undefined;
    note?: string | undefined;
    items: Array<{
      __typename?: 'ContractItem';
      id: string;
      amount: number;
      locked: boolean;
      listUnitPrice: number;
      price: number;
      barter: number;
      task?:
        | {
            __typename?: 'Task';
            id: string;
            name?: string | undefined;
            note?: string | undefined;
            type: TaskType;
            totalTasks: number;
            customAmount?: number | undefined;
            contractItemAmount?: number | undefined;
            createdAt?: any | undefined;
            updatedAt?: any | undefined;
            match?: { __typename?: 'Match'; id: string; name: string } | undefined;
            season: { __typename?: 'Season'; id: string; name: string };
            subTasks: Array<{
              __typename?: 'SubTask';
              id: string;
              status: TaskStatus;
              count: number;
              note?: string | undefined;
              deadline?: any | undefined;
              createdAt?: any | undefined;
              lead?: { __typename?: 'TaskLead'; id: string; type: TaskLeadType } | undefined;
              match?: { __typename?: 'Match'; id: string; name: string; date: any } | undefined;
              lastEdit: {
                __typename?: 'LastEdit';
                timestamp: any;
                user: { __typename?: 'LastUser'; id: string };
              };
            }>;
            lastEdit: {
              __typename?: 'LastEdit';
              timestamp: any;
              user: { __typename?: 'LastUser'; id: string };
            };
            stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
          }
        | undefined;
      league: { __typename?: 'League'; id: string; name: string };
      season: { __typename?: 'Season'; id: string; name: string };
      match?: { __typename?: 'Match'; id: string; name: string } | undefined;
      inventory: {
        __typename?: 'Inventory';
        id: string;
        name: string;
        unit: UnitType;
        topCategory?: { __typename?: 'TopCategory'; id: string; name: string } | undefined;
        listPrices: Array<{ __typename?: 'InventoryListPrice'; id: string; type: InventoryType }>;
      };
    }>;
    tasks?:
      | Array<{
          __typename?: 'Task';
          id: string;
          name?: string | undefined;
          note?: string | undefined;
          type: TaskType;
          totalTasks: number;
          customAmount?: number | undefined;
          contractItemAmount?: number | undefined;
          createdAt?: any | undefined;
          updatedAt?: any | undefined;
          match?: { __typename?: 'Match'; id: string; name: string } | undefined;
          season: { __typename?: 'Season'; id: string; name: string };
          subTasks: Array<{
            __typename?: 'SubTask';
            id: string;
            status: TaskStatus;
            count: number;
            note?: string | undefined;
            deadline?: any | undefined;
            createdAt?: any | undefined;
            lead?: { __typename?: 'TaskLead'; id: string; type: TaskLeadType } | undefined;
            match?: { __typename?: 'Match'; id: string; name: string; date: any } | undefined;
            lastEdit: {
              __typename?: 'LastEdit';
              timestamp: any;
              user: { __typename?: 'LastUser'; id: string };
            };
          }>;
          lastEdit: {
            __typename?: 'LastEdit';
            timestamp: any;
            user: { __typename?: 'LastUser'; id: string };
          };
          stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
        }>
      | undefined;
    template?: { __typename?: 'ContractTemplate'; id: string; name: string; template: string } | undefined;
    autoRenew?:
      | {
          __typename?: 'ContractAutoRenew';
          enabled: boolean;
          mode: AutoRenewMode;
          markedForCancellationAt?: any | undefined;
          cancellationPeriodEnd?: any | undefined;
          renewYears: number;
        }
      | undefined;
    notes?:
      | Array<{
          __typename?: 'ContractNote';
          id: string;
          content: string;
          createdAt?: any | undefined;
          updatedAt?: any | undefined;
          author: { __typename?: 'Author'; id: string; email: string };
        }>
      | undefined;
    partner: {
      __typename?: 'Partner';
      id: string;
      companyName?: string | undefined;
      category?: { __typename?: 'PartnerCategory'; id: string; name: string } | undefined;
    };
    salesLead?: { __typename?: 'User'; id: string } | undefined;
    clubLead?: { __typename?: 'User'; id: string } | undefined;
    bonuses: Array<{
      __typename?: 'ContractBonus';
      id: string;
      price: number;
      achieved: boolean;
      bonusId: string;
      bonusName: string;
      bonusDescription?: string | undefined;
      season: { __typename?: 'Season'; id: string; name: string };
    }>;
    invoices: Array<{
      __typename?: 'Invoice';
      id: string;
      type: InvoiceType;
      percentage?: number | undefined;
      invoiceNumber?: number | undefined;
      cancellationNumber?: number | undefined;
      date: any;
      invoicedAt?: any | undefined;
      serviceStartedAt?: any | undefined;
      serviceEndedAt?: any | undefined;
      paymentDueAt?: any | undefined;
      payedAt?: any | undefined;
      canceledAt?: any | undefined;
      bonusId?: string | undefined;
      season: { __typename?: 'Season'; id: string; name: string };
    }>;
    total: {
      __typename?: 'ContractTotals';
      barter: number;
      discount: number;
      listPrice: number;
      price: number;
    };
  };
};

export type CreateContractMutationVariables = Exact<{
  input: CreateContractInput;
}>;

export type CreateContractMutation = {
  __typename?: 'Mutation';
  createContract: {
    __typename?: 'Contract';
    id: string;
    name?: string | undefined;
    draft: boolean;
    type: ContractType;
    probability: number;
    startDate?: any | undefined;
    endDate?: any | undefined;
    invoicesDisabled: boolean;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    canceledAt?: any | undefined;
    note?: string | undefined;
    items: Array<{
      __typename?: 'ContractItem';
      id: string;
      amount: number;
      locked: boolean;
      listUnitPrice: number;
      price: number;
      barter: number;
      task?:
        | {
            __typename?: 'Task';
            id: string;
            name?: string | undefined;
            note?: string | undefined;
            type: TaskType;
            totalTasks: number;
            customAmount?: number | undefined;
            contractItemAmount?: number | undefined;
            createdAt?: any | undefined;
            updatedAt?: any | undefined;
            match?: { __typename?: 'Match'; id: string; name: string } | undefined;
            season: { __typename?: 'Season'; id: string; name: string };
            subTasks: Array<{
              __typename?: 'SubTask';
              id: string;
              status: TaskStatus;
              count: number;
              note?: string | undefined;
              deadline?: any | undefined;
              createdAt?: any | undefined;
              lead?: { __typename?: 'TaskLead'; id: string; type: TaskLeadType } | undefined;
              match?: { __typename?: 'Match'; id: string; name: string; date: any } | undefined;
              lastEdit: {
                __typename?: 'LastEdit';
                timestamp: any;
                user: { __typename?: 'LastUser'; id: string };
              };
            }>;
            lastEdit: {
              __typename?: 'LastEdit';
              timestamp: any;
              user: { __typename?: 'LastUser'; id: string };
            };
            stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
          }
        | undefined;
      league: { __typename?: 'League'; id: string; name: string };
      season: { __typename?: 'Season'; id: string; name: string };
      match?: { __typename?: 'Match'; id: string; name: string } | undefined;
      inventory: {
        __typename?: 'Inventory';
        id: string;
        name: string;
        unit: UnitType;
        topCategory?: { __typename?: 'TopCategory'; id: string; name: string } | undefined;
        listPrices: Array<{ __typename?: 'InventoryListPrice'; id: string; type: InventoryType }>;
      };
    }>;
    tasks?:
      | Array<{
          __typename?: 'Task';
          id: string;
          name?: string | undefined;
          note?: string | undefined;
          type: TaskType;
          totalTasks: number;
          customAmount?: number | undefined;
          contractItemAmount?: number | undefined;
          createdAt?: any | undefined;
          updatedAt?: any | undefined;
          match?: { __typename?: 'Match'; id: string; name: string } | undefined;
          season: { __typename?: 'Season'; id: string; name: string };
          subTasks: Array<{
            __typename?: 'SubTask';
            id: string;
            status: TaskStatus;
            count: number;
            note?: string | undefined;
            deadline?: any | undefined;
            createdAt?: any | undefined;
            lead?: { __typename?: 'TaskLead'; id: string; type: TaskLeadType } | undefined;
            match?: { __typename?: 'Match'; id: string; name: string; date: any } | undefined;
            lastEdit: {
              __typename?: 'LastEdit';
              timestamp: any;
              user: { __typename?: 'LastUser'; id: string };
            };
          }>;
          lastEdit: {
            __typename?: 'LastEdit';
            timestamp: any;
            user: { __typename?: 'LastUser'; id: string };
          };
          stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
        }>
      | undefined;
    template?: { __typename?: 'ContractTemplate'; id: string; name: string; template: string } | undefined;
    autoRenew?:
      | {
          __typename?: 'ContractAutoRenew';
          enabled: boolean;
          mode: AutoRenewMode;
          markedForCancellationAt?: any | undefined;
          cancellationPeriodEnd?: any | undefined;
          renewYears: number;
        }
      | undefined;
    notes?:
      | Array<{
          __typename?: 'ContractNote';
          id: string;
          content: string;
          createdAt?: any | undefined;
          updatedAt?: any | undefined;
          author: { __typename?: 'Author'; id: string; email: string };
        }>
      | undefined;
    partner: {
      __typename?: 'Partner';
      id: string;
      companyName?: string | undefined;
      category?: { __typename?: 'PartnerCategory'; id: string; name: string } | undefined;
    };
    salesLead?: { __typename?: 'User'; id: string } | undefined;
    clubLead?: { __typename?: 'User'; id: string } | undefined;
    bonuses: Array<{
      __typename?: 'ContractBonus';
      id: string;
      price: number;
      achieved: boolean;
      bonusId: string;
      bonusName: string;
      bonusDescription?: string | undefined;
      season: { __typename?: 'Season'; id: string; name: string };
    }>;
    invoices: Array<{
      __typename?: 'Invoice';
      id: string;
      type: InvoiceType;
      percentage?: number | undefined;
      invoiceNumber?: number | undefined;
      cancellationNumber?: number | undefined;
      date: any;
      invoicedAt?: any | undefined;
      serviceStartedAt?: any | undefined;
      serviceEndedAt?: any | undefined;
      paymentDueAt?: any | undefined;
      payedAt?: any | undefined;
      canceledAt?: any | undefined;
      bonusId?: string | undefined;
      season: { __typename?: 'Season'; id: string; name: string };
    }>;
    total: {
      __typename?: 'ContractTotals';
      barter: number;
      discount: number;
      listPrice: number;
      price: number;
    };
  };
};

export type ContractQueryVariables = Exact<{
  contractId: Scalars['String']['input'];
  seasonId?: InputMaybe<Scalars['String']['input']>;
}>;

export type ContractQuery = {
  __typename?: 'Query';
  contract: {
    __typename?: 'Contract';
    id: string;
    name?: string | undefined;
    draft: boolean;
    type: ContractType;
    probability: number;
    startDate?: any | undefined;
    endDate?: any | undefined;
    invoicesDisabled: boolean;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    canceledAt?: any | undefined;
    note?: string | undefined;
    items: Array<{
      __typename?: 'ContractItem';
      id: string;
      amount: number;
      locked: boolean;
      listUnitPrice: number;
      price: number;
      barter: number;
      task?:
        | {
            __typename?: 'Task';
            id: string;
            name?: string | undefined;
            note?: string | undefined;
            type: TaskType;
            totalTasks: number;
            customAmount?: number | undefined;
            contractItemAmount?: number | undefined;
            createdAt?: any | undefined;
            updatedAt?: any | undefined;
            match?: { __typename?: 'Match'; id: string; name: string } | undefined;
            season: { __typename?: 'Season'; id: string; name: string };
            subTasks: Array<{
              __typename?: 'SubTask';
              id: string;
              status: TaskStatus;
              count: number;
              note?: string | undefined;
              deadline?: any | undefined;
              createdAt?: any | undefined;
              lead?: { __typename?: 'TaskLead'; id: string; type: TaskLeadType } | undefined;
              match?: { __typename?: 'Match'; id: string; name: string; date: any } | undefined;
              lastEdit: {
                __typename?: 'LastEdit';
                timestamp: any;
                user: { __typename?: 'LastUser'; id: string };
              };
            }>;
            lastEdit: {
              __typename?: 'LastEdit';
              timestamp: any;
              user: { __typename?: 'LastUser'; id: string };
            };
            stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
          }
        | undefined;
      league: { __typename?: 'League'; id: string; name: string };
      season: { __typename?: 'Season'; id: string; name: string };
      match?: { __typename?: 'Match'; id: string; name: string } | undefined;
      inventory: {
        __typename?: 'Inventory';
        id: string;
        name: string;
        unit: UnitType;
        topCategory?: { __typename?: 'TopCategory'; id: string; name: string } | undefined;
        listPrices: Array<{ __typename?: 'InventoryListPrice'; id: string; type: InventoryType }>;
      };
    }>;
    tasks?:
      | Array<{
          __typename?: 'Task';
          id: string;
          name?: string | undefined;
          note?: string | undefined;
          type: TaskType;
          totalTasks: number;
          customAmount?: number | undefined;
          contractItemAmount?: number | undefined;
          createdAt?: any | undefined;
          updatedAt?: any | undefined;
          match?: { __typename?: 'Match'; id: string; name: string } | undefined;
          season: { __typename?: 'Season'; id: string; name: string };
          subTasks: Array<{
            __typename?: 'SubTask';
            id: string;
            status: TaskStatus;
            count: number;
            note?: string | undefined;
            deadline?: any | undefined;
            createdAt?: any | undefined;
            lead?: { __typename?: 'TaskLead'; id: string; type: TaskLeadType } | undefined;
            match?: { __typename?: 'Match'; id: string; name: string; date: any } | undefined;
            lastEdit: {
              __typename?: 'LastEdit';
              timestamp: any;
              user: { __typename?: 'LastUser'; id: string };
            };
          }>;
          lastEdit: {
            __typename?: 'LastEdit';
            timestamp: any;
            user: { __typename?: 'LastUser'; id: string };
          };
          stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
        }>
      | undefined;
    template?: { __typename?: 'ContractTemplate'; id: string; name: string; template: string } | undefined;
    autoRenew?:
      | {
          __typename?: 'ContractAutoRenew';
          enabled: boolean;
          mode: AutoRenewMode;
          markedForCancellationAt?: any | undefined;
          cancellationPeriodEnd?: any | undefined;
          renewYears: number;
        }
      | undefined;
    notes?:
      | Array<{
          __typename?: 'ContractNote';
          id: string;
          content: string;
          createdAt?: any | undefined;
          updatedAt?: any | undefined;
          author: { __typename?: 'Author'; id: string; email: string };
        }>
      | undefined;
    partner: {
      __typename?: 'Partner';
      id: string;
      companyName?: string | undefined;
      category?: { __typename?: 'PartnerCategory'; id: string; name: string } | undefined;
    };
    salesLead?: { __typename?: 'User'; id: string } | undefined;
    clubLead?: { __typename?: 'User'; id: string } | undefined;
    bonuses: Array<{
      __typename?: 'ContractBonus';
      id: string;
      price: number;
      achieved: boolean;
      bonusId: string;
      bonusName: string;
      bonusDescription?: string | undefined;
      season: { __typename?: 'Season'; id: string; name: string };
    }>;
    invoices: Array<{
      __typename?: 'Invoice';
      id: string;
      type: InvoiceType;
      percentage?: number | undefined;
      invoiceNumber?: number | undefined;
      cancellationNumber?: number | undefined;
      date: any;
      invoicedAt?: any | undefined;
      serviceStartedAt?: any | undefined;
      serviceEndedAt?: any | undefined;
      paymentDueAt?: any | undefined;
      payedAt?: any | undefined;
      canceledAt?: any | undefined;
      bonusId?: string | undefined;
      season: { __typename?: 'Season'; id: string; name: string };
    }>;
    total: {
      __typename?: 'ContractTotals';
      barter: number;
      discount: number;
      listPrice: number;
      price: number;
    };
  };
};

export type InvoiceFragmentFragment = {
  __typename?: 'Invoice';
  id: string;
  type: InvoiceType;
  percentage?: number | undefined;
  invoiceNumber?: number | undefined;
  cancellationNumber?: number | undefined;
  date: any;
  invoicedAt?: any | undefined;
  serviceStartedAt?: any | undefined;
  serviceEndedAt?: any | undefined;
  paymentDueAt?: any | undefined;
  payedAt?: any | undefined;
  canceledAt?: any | undefined;
  bonusId?: string | undefined;
  season: { __typename?: 'Season'; id: string; name: string };
};

export type IssueContractInvoiceCancellationNumberMutationVariables = Exact<{
  contractId: Scalars['String']['input'];
  invoiceId: Scalars['String']['input'];
}>;

export type IssueContractInvoiceCancellationNumberMutation = {
  __typename?: 'Mutation';
  issueContractInvoiceCancellationNumber: {
    __typename?: 'Invoice';
    id: string;
    type: InvoiceType;
    percentage?: number | undefined;
    invoiceNumber?: number | undefined;
    cancellationNumber?: number | undefined;
    date: any;
    invoicedAt?: any | undefined;
    serviceStartedAt?: any | undefined;
    serviceEndedAt?: any | undefined;
    paymentDueAt?: any | undefined;
    payedAt?: any | undefined;
    canceledAt?: any | undefined;
    bonusId?: string | undefined;
    season: { __typename?: 'Season'; id: string; name: string };
  };
};

export type IssueContractInvoiceMutationVariables = Exact<{
  contractId: Scalars['String']['input'];
  invoiceId: Scalars['String']['input'];
}>;

export type IssueContractInvoiceMutation = {
  __typename?: 'Mutation';
  issueContractInvoice: {
    __typename?: 'Invoice';
    id: string;
    type: InvoiceType;
    percentage?: number | undefined;
    invoiceNumber?: number | undefined;
    cancellationNumber?: number | undefined;
    date: any;
    invoicedAt?: any | undefined;
    serviceStartedAt?: any | undefined;
    serviceEndedAt?: any | undefined;
    paymentDueAt?: any | undefined;
    payedAt?: any | undefined;
    canceledAt?: any | undefined;
    bonusId?: string | undefined;
    season: { __typename?: 'Season'; id: string; name: string };
  };
};

export type UpdateContractInvoiceMutationVariables = Exact<{
  invoiceId: Scalars['String']['input'];
  contractId: Scalars['String']['input'];
  input: UpdateContractInvoiceInput;
}>;

export type UpdateContractInvoiceMutation = {
  __typename?: 'Mutation';
  updateContractInvoice: {
    __typename?: 'Invoice';
    id: string;
    type: InvoiceType;
    percentage?: number | undefined;
    invoiceNumber?: number | undefined;
    cancellationNumber?: number | undefined;
    date: any;
    invoicedAt?: any | undefined;
    serviceStartedAt?: any | undefined;
    serviceEndedAt?: any | undefined;
    paymentDueAt?: any | undefined;
    payedAt?: any | undefined;
    canceledAt?: any | undefined;
    bonusId?: string | undefined;
    season: { __typename?: 'Season'; id: string; name: string };
  };
};

export type UpdateContractInvoicesMutationVariables = Exact<{
  contractId: Scalars['String']['input'];
  seasonId?: InputMaybe<Scalars['String']['input']>;
  input: Array<ContractInvoiceInput> | ContractInvoiceInput;
  type: InvoiceTypeArg;
}>;

export type UpdateContractInvoicesMutation = {
  __typename?: 'Mutation';
  updateContractInvoices: Array<{
    __typename?: 'Invoice';
    id: string;
    type: InvoiceType;
    percentage?: number | undefined;
    invoiceNumber?: number | undefined;
    cancellationNumber?: number | undefined;
    date: any;
    invoicedAt?: any | undefined;
    serviceStartedAt?: any | undefined;
    serviceEndedAt?: any | undefined;
    paymentDueAt?: any | undefined;
    payedAt?: any | undefined;
    canceledAt?: any | undefined;
    bonusId?: string | undefined;
    season: { __typename?: 'Season'; id: string; name: string };
  }>;
};

export type ContractItemFragmentFragment = {
  __typename?: 'ContractItem';
  id: string;
  amount: number;
  locked: boolean;
  listUnitPrice: number;
  price: number;
  barter: number;
  league: { __typename?: 'League'; id: string; name: string };
  season: { __typename?: 'Season'; id: string; name: string };
  match?: { __typename?: 'Match'; id: string; name: string } | undefined;
  inventory: {
    __typename?: 'Inventory';
    id: string;
    name: string;
    unit: UnitType;
    topCategory?: { __typename?: 'TopCategory'; id: string; name: string } | undefined;
    listPrices: Array<{ __typename?: 'InventoryListPrice'; id: string; type: InventoryType }>;
  };
};

export type CreateContractItemMutationVariables = Exact<{
  contractId: Scalars['String']['input'];
  input: CreateContractItemInput;
}>;

export type CreateContractItemMutation = {
  __typename?: 'Mutation';
  createContractItem: {
    __typename?: 'ContractItem';
    id: string;
    amount: number;
    locked: boolean;
    listUnitPrice: number;
    price: number;
    barter: number;
    league: { __typename?: 'League'; id: string; name: string };
    season: { __typename?: 'Season'; id: string; name: string };
    match?: { __typename?: 'Match'; id: string; name: string } | undefined;
    inventory: {
      __typename?: 'Inventory';
      id: string;
      name: string;
      unit: UnitType;
      topCategory?: { __typename?: 'TopCategory'; id: string; name: string } | undefined;
      listPrices: Array<{ __typename?: 'InventoryListPrice'; id: string; type: InventoryType }>;
    };
  };
};

export type CreateOrUpdateContractItemBulkMutationVariables = Exact<{
  contractId: Scalars['String']['input'];
  inventoryId: Scalars['String']['input'];
  leagueIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  seasonIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  input: UpdateContractItemBulkInput;
}>;

export type CreateOrUpdateContractItemBulkMutation = {
  __typename?: 'Mutation';
  createOrUpdateContractItemBulk: {
    __typename?: 'Contract';
    id: string;
    name?: string | undefined;
    draft: boolean;
    type: ContractType;
    probability: number;
    startDate?: any | undefined;
    endDate?: any | undefined;
    invoicesDisabled: boolean;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    canceledAt?: any | undefined;
    note?: string | undefined;
    autoRenew?:
      | {
          __typename?: 'ContractAutoRenew';
          enabled: boolean;
          mode: AutoRenewMode;
          markedForCancellationAt?: any | undefined;
          cancellationPeriodEnd?: any | undefined;
          renewYears: number;
        }
      | undefined;
    notes?:
      | Array<{
          __typename?: 'ContractNote';
          id: string;
          content: string;
          createdAt?: any | undefined;
          updatedAt?: any | undefined;
          author: { __typename?: 'Author'; id: string; email: string };
        }>
      | undefined;
    partner: {
      __typename?: 'Partner';
      id: string;
      companyName?: string | undefined;
      category?: { __typename?: 'PartnerCategory'; id: string; name: string } | undefined;
    };
    salesLead?: { __typename?: 'User'; id: string } | undefined;
    clubLead?: { __typename?: 'User'; id: string } | undefined;
    items: Array<{
      __typename?: 'ContractItem';
      id: string;
      amount: number;
      locked: boolean;
      listUnitPrice: number;
      price: number;
      barter: number;
      league: { __typename?: 'League'; id: string; name: string };
      season: { __typename?: 'Season'; id: string; name: string };
      match?: { __typename?: 'Match'; id: string; name: string } | undefined;
      inventory: {
        __typename?: 'Inventory';
        id: string;
        name: string;
        unit: UnitType;
        topCategory?: { __typename?: 'TopCategory'; id: string; name: string } | undefined;
        listPrices: Array<{ __typename?: 'InventoryListPrice'; id: string; type: InventoryType }>;
      };
    }>;
    bonuses: Array<{
      __typename?: 'ContractBonus';
      id: string;
      price: number;
      achieved: boolean;
      bonusId: string;
      bonusName: string;
      bonusDescription?: string | undefined;
      season: { __typename?: 'Season'; id: string; name: string };
    }>;
    invoices: Array<{
      __typename?: 'Invoice';
      id: string;
      type: InvoiceType;
      percentage?: number | undefined;
      invoiceNumber?: number | undefined;
      cancellationNumber?: number | undefined;
      date: any;
      invoicedAt?: any | undefined;
      serviceStartedAt?: any | undefined;
      serviceEndedAt?: any | undefined;
      paymentDueAt?: any | undefined;
      payedAt?: any | undefined;
      canceledAt?: any | undefined;
      bonusId?: string | undefined;
      season: { __typename?: 'Season'; id: string; name: string };
    }>;
    total: {
      __typename?: 'ContractTotals';
      barter: number;
      discount: number;
      listPrice: number;
      price: number;
    };
  };
};

export type RemoveContractItemBulkMutationVariables = Exact<{
  contractId: Scalars['String']['input'];
  inventoryId?: InputMaybe<Scalars['String']['input']>;
  leagueIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  seasonIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type RemoveContractItemBulkMutation = { __typename?: 'Mutation'; removeContractItemBulk: boolean };

export type RemoveContractItemMutationVariables = Exact<{
  contractId: Scalars['String']['input'];
  itemId: Scalars['String']['input'];
}>;

export type RemoveContractItemMutation = { __typename?: 'Mutation'; removeContractItem: boolean };

export type UpdateContractItemMutationVariables = Exact<{
  contractId: Scalars['String']['input'];
  input: UpdateContractItemInput;
  itemId: Scalars['String']['input'];
}>;

export type UpdateContractItemMutation = {
  __typename?: 'Mutation';
  updateContractItem: {
    __typename?: 'ContractItem';
    id: string;
    amount: number;
    locked: boolean;
    listUnitPrice: number;
    price: number;
    barter: number;
    league: { __typename?: 'League'; id: string; name: string };
    season: { __typename?: 'Season'; id: string; name: string };
    match?: { __typename?: 'Match'; id: string; name: string } | undefined;
    inventory: {
      __typename?: 'Inventory';
      id: string;
      name: string;
      unit: UnitType;
      topCategory?: { __typename?: 'TopCategory'; id: string; name: string } | undefined;
      listPrices: Array<{ __typename?: 'InventoryListPrice'; id: string; type: InventoryType }>;
    };
  };
};

export type RemoveContractMutationVariables = Exact<{
  contractId: Scalars['String']['input'];
}>;

export type RemoveContractMutation = { __typename?: 'Mutation'; removeContract: boolean };

export type RenewContractsMutationVariables = Exact<{ [key: string]: never }>;

export type RenewContractsMutation = {
  __typename?: 'Mutation';
  renewContracts: Array<{
    __typename?: 'Contract';
    id: string;
    name?: string | undefined;
    draft: boolean;
    type: ContractType;
    probability: number;
    startDate?: any | undefined;
    endDate?: any | undefined;
    invoicesDisabled: boolean;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    canceledAt?: any | undefined;
    note?: string | undefined;
    items: Array<{
      __typename?: 'ContractItem';
      id: string;
      amount: number;
      locked: boolean;
      listUnitPrice: number;
      price: number;
      barter: number;
      task?:
        | {
            __typename?: 'Task';
            id: string;
            name?: string | undefined;
            note?: string | undefined;
            type: TaskType;
            totalTasks: number;
            customAmount?: number | undefined;
            contractItemAmount?: number | undefined;
            createdAt?: any | undefined;
            updatedAt?: any | undefined;
            match?: { __typename?: 'Match'; id: string; name: string } | undefined;
            season: { __typename?: 'Season'; id: string; name: string };
            subTasks: Array<{
              __typename?: 'SubTask';
              id: string;
              status: TaskStatus;
              count: number;
              note?: string | undefined;
              deadline?: any | undefined;
              createdAt?: any | undefined;
              lead?: { __typename?: 'TaskLead'; id: string; type: TaskLeadType } | undefined;
              match?: { __typename?: 'Match'; id: string; name: string; date: any } | undefined;
              lastEdit: {
                __typename?: 'LastEdit';
                timestamp: any;
                user: { __typename?: 'LastUser'; id: string };
              };
            }>;
            lastEdit: {
              __typename?: 'LastEdit';
              timestamp: any;
              user: { __typename?: 'LastUser'; id: string };
            };
            stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
          }
        | undefined;
      league: { __typename?: 'League'; id: string; name: string };
      season: { __typename?: 'Season'; id: string; name: string };
      match?: { __typename?: 'Match'; id: string; name: string } | undefined;
      inventory: {
        __typename?: 'Inventory';
        id: string;
        name: string;
        unit: UnitType;
        topCategory?: { __typename?: 'TopCategory'; id: string; name: string } | undefined;
        listPrices: Array<{ __typename?: 'InventoryListPrice'; id: string; type: InventoryType }>;
      };
    }>;
    tasks?:
      | Array<{
          __typename?: 'Task';
          id: string;
          name?: string | undefined;
          note?: string | undefined;
          type: TaskType;
          totalTasks: number;
          customAmount?: number | undefined;
          contractItemAmount?: number | undefined;
          createdAt?: any | undefined;
          updatedAt?: any | undefined;
          match?: { __typename?: 'Match'; id: string; name: string } | undefined;
          season: { __typename?: 'Season'; id: string; name: string };
          subTasks: Array<{
            __typename?: 'SubTask';
            id: string;
            status: TaskStatus;
            count: number;
            note?: string | undefined;
            deadline?: any | undefined;
            createdAt?: any | undefined;
            lead?: { __typename?: 'TaskLead'; id: string; type: TaskLeadType } | undefined;
            match?: { __typename?: 'Match'; id: string; name: string; date: any } | undefined;
            lastEdit: {
              __typename?: 'LastEdit';
              timestamp: any;
              user: { __typename?: 'LastUser'; id: string };
            };
          }>;
          lastEdit: {
            __typename?: 'LastEdit';
            timestamp: any;
            user: { __typename?: 'LastUser'; id: string };
          };
          stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
        }>
      | undefined;
    template?: { __typename?: 'ContractTemplate'; id: string; name: string; template: string } | undefined;
    autoRenew?:
      | {
          __typename?: 'ContractAutoRenew';
          enabled: boolean;
          mode: AutoRenewMode;
          markedForCancellationAt?: any | undefined;
          cancellationPeriodEnd?: any | undefined;
          renewYears: number;
        }
      | undefined;
    notes?:
      | Array<{
          __typename?: 'ContractNote';
          id: string;
          content: string;
          createdAt?: any | undefined;
          updatedAt?: any | undefined;
          author: { __typename?: 'Author'; id: string; email: string };
        }>
      | undefined;
    partner: {
      __typename?: 'Partner';
      id: string;
      companyName?: string | undefined;
      category?: { __typename?: 'PartnerCategory'; id: string; name: string } | undefined;
    };
    salesLead?: { __typename?: 'User'; id: string } | undefined;
    clubLead?: { __typename?: 'User'; id: string } | undefined;
    bonuses: Array<{
      __typename?: 'ContractBonus';
      id: string;
      price: number;
      achieved: boolean;
      bonusId: string;
      bonusName: string;
      bonusDescription?: string | undefined;
      season: { __typename?: 'Season'; id: string; name: string };
    }>;
    invoices: Array<{
      __typename?: 'Invoice';
      id: string;
      type: InvoiceType;
      percentage?: number | undefined;
      invoiceNumber?: number | undefined;
      cancellationNumber?: number | undefined;
      date: any;
      invoicedAt?: any | undefined;
      serviceStartedAt?: any | undefined;
      serviceEndedAt?: any | undefined;
      paymentDueAt?: any | undefined;
      payedAt?: any | undefined;
      canceledAt?: any | undefined;
      bonusId?: string | undefined;
      season: { __typename?: 'Season'; id: string; name: string };
    }>;
    total: {
      __typename?: 'ContractTotals';
      barter: number;
      discount: number;
      listPrice: number;
      price: number;
    };
  }>;
};

export type SingleContractFragmentFragment = {
  __typename?: 'Contract';
  id: string;
  name?: string | undefined;
  draft: boolean;
  type: ContractType;
  probability: number;
  startDate?: any | undefined;
  endDate?: any | undefined;
  invoicesDisabled: boolean;
  createdAt?: any | undefined;
  updatedAt?: any | undefined;
  canceledAt?: any | undefined;
  note?: string | undefined;
  items: Array<{
    __typename?: 'ContractItem';
    id: string;
    amount: number;
    locked: boolean;
    listUnitPrice: number;
    price: number;
    barter: number;
    task?:
      | {
          __typename?: 'Task';
          id: string;
          name?: string | undefined;
          note?: string | undefined;
          type: TaskType;
          totalTasks: number;
          customAmount?: number | undefined;
          contractItemAmount?: number | undefined;
          createdAt?: any | undefined;
          updatedAt?: any | undefined;
          match?: { __typename?: 'Match'; id: string; name: string } | undefined;
          season: { __typename?: 'Season'; id: string; name: string };
          subTasks: Array<{
            __typename?: 'SubTask';
            id: string;
            status: TaskStatus;
            count: number;
            note?: string | undefined;
            deadline?: any | undefined;
            createdAt?: any | undefined;
            lead?: { __typename?: 'TaskLead'; id: string; type: TaskLeadType } | undefined;
            match?: { __typename?: 'Match'; id: string; name: string; date: any } | undefined;
            lastEdit: {
              __typename?: 'LastEdit';
              timestamp: any;
              user: { __typename?: 'LastUser'; id: string };
            };
          }>;
          lastEdit: {
            __typename?: 'LastEdit';
            timestamp: any;
            user: { __typename?: 'LastUser'; id: string };
          };
          stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
        }
      | undefined;
    league: { __typename?: 'League'; id: string; name: string };
    season: { __typename?: 'Season'; id: string; name: string };
    match?: { __typename?: 'Match'; id: string; name: string } | undefined;
    inventory: {
      __typename?: 'Inventory';
      id: string;
      name: string;
      unit: UnitType;
      topCategory?: { __typename?: 'TopCategory'; id: string; name: string } | undefined;
      listPrices: Array<{ __typename?: 'InventoryListPrice'; id: string; type: InventoryType }>;
    };
  }>;
  tasks?:
    | Array<{
        __typename?: 'Task';
        id: string;
        name?: string | undefined;
        note?: string | undefined;
        type: TaskType;
        totalTasks: number;
        customAmount?: number | undefined;
        contractItemAmount?: number | undefined;
        createdAt?: any | undefined;
        updatedAt?: any | undefined;
        match?: { __typename?: 'Match'; id: string; name: string } | undefined;
        season: { __typename?: 'Season'; id: string; name: string };
        subTasks: Array<{
          __typename?: 'SubTask';
          id: string;
          status: TaskStatus;
          count: number;
          note?: string | undefined;
          deadline?: any | undefined;
          createdAt?: any | undefined;
          lead?: { __typename?: 'TaskLead'; id: string; type: TaskLeadType } | undefined;
          match?: { __typename?: 'Match'; id: string; name: string; date: any } | undefined;
          lastEdit: {
            __typename?: 'LastEdit';
            timestamp: any;
            user: { __typename?: 'LastUser'; id: string };
          };
        }>;
        lastEdit: { __typename?: 'LastEdit'; timestamp: any; user: { __typename?: 'LastUser'; id: string } };
        stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
      }>
    | undefined;
  template?: { __typename?: 'ContractTemplate'; id: string; name: string; template: string } | undefined;
  autoRenew?:
    | {
        __typename?: 'ContractAutoRenew';
        enabled: boolean;
        mode: AutoRenewMode;
        markedForCancellationAt?: any | undefined;
        cancellationPeriodEnd?: any | undefined;
        renewYears: number;
      }
    | undefined;
  notes?:
    | Array<{
        __typename?: 'ContractNote';
        id: string;
        content: string;
        createdAt?: any | undefined;
        updatedAt?: any | undefined;
        author: { __typename?: 'Author'; id: string; email: string };
      }>
    | undefined;
  partner: {
    __typename?: 'Partner';
    id: string;
    companyName?: string | undefined;
    category?: { __typename?: 'PartnerCategory'; id: string; name: string } | undefined;
  };
  salesLead?: { __typename?: 'User'; id: string } | undefined;
  clubLead?: { __typename?: 'User'; id: string } | undefined;
  bonuses: Array<{
    __typename?: 'ContractBonus';
    id: string;
    price: number;
    achieved: boolean;
    bonusId: string;
    bonusName: string;
    bonusDescription?: string | undefined;
    season: { __typename?: 'Season'; id: string; name: string };
  }>;
  invoices: Array<{
    __typename?: 'Invoice';
    id: string;
    type: InvoiceType;
    percentage?: number | undefined;
    invoiceNumber?: number | undefined;
    cancellationNumber?: number | undefined;
    date: any;
    invoicedAt?: any | undefined;
    serviceStartedAt?: any | undefined;
    serviceEndedAt?: any | undefined;
    paymentDueAt?: any | undefined;
    payedAt?: any | undefined;
    canceledAt?: any | undefined;
    bonusId?: string | undefined;
    season: { __typename?: 'Season'; id: string; name: string };
  }>;
  total: {
    __typename?: 'ContractTotals';
    barter: number;
    discount: number;
    listPrice: number;
    price: number;
  };
};

export type UpdateContractMutationVariables = Exact<{
  contractId: Scalars['String']['input'];
  input: UpdateContractInput;
}>;

export type UpdateContractMutation = {
  __typename?: 'Mutation';
  updateContract: {
    __typename?: 'Contract';
    id: string;
    name?: string | undefined;
    draft: boolean;
    type: ContractType;
    probability: number;
    startDate?: any | undefined;
    endDate?: any | undefined;
    invoicesDisabled: boolean;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    canceledAt?: any | undefined;
    note?: string | undefined;
    items: Array<{
      __typename?: 'ContractItem';
      id: string;
      amount: number;
      locked: boolean;
      listUnitPrice: number;
      price: number;
      barter: number;
      task?:
        | {
            __typename?: 'Task';
            id: string;
            name?: string | undefined;
            note?: string | undefined;
            type: TaskType;
            totalTasks: number;
            customAmount?: number | undefined;
            contractItemAmount?: number | undefined;
            createdAt?: any | undefined;
            updatedAt?: any | undefined;
            match?: { __typename?: 'Match'; id: string; name: string } | undefined;
            season: { __typename?: 'Season'; id: string; name: string };
            subTasks: Array<{
              __typename?: 'SubTask';
              id: string;
              status: TaskStatus;
              count: number;
              note?: string | undefined;
              deadline?: any | undefined;
              createdAt?: any | undefined;
              lead?: { __typename?: 'TaskLead'; id: string; type: TaskLeadType } | undefined;
              match?: { __typename?: 'Match'; id: string; name: string; date: any } | undefined;
              lastEdit: {
                __typename?: 'LastEdit';
                timestamp: any;
                user: { __typename?: 'LastUser'; id: string };
              };
            }>;
            lastEdit: {
              __typename?: 'LastEdit';
              timestamp: any;
              user: { __typename?: 'LastUser'; id: string };
            };
            stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
          }
        | undefined;
      league: { __typename?: 'League'; id: string; name: string };
      season: { __typename?: 'Season'; id: string; name: string };
      match?: { __typename?: 'Match'; id: string; name: string } | undefined;
      inventory: {
        __typename?: 'Inventory';
        id: string;
        name: string;
        unit: UnitType;
        topCategory?: { __typename?: 'TopCategory'; id: string; name: string } | undefined;
        listPrices: Array<{ __typename?: 'InventoryListPrice'; id: string; type: InventoryType }>;
      };
    }>;
    tasks?:
      | Array<{
          __typename?: 'Task';
          id: string;
          name?: string | undefined;
          note?: string | undefined;
          type: TaskType;
          totalTasks: number;
          customAmount?: number | undefined;
          contractItemAmount?: number | undefined;
          createdAt?: any | undefined;
          updatedAt?: any | undefined;
          match?: { __typename?: 'Match'; id: string; name: string } | undefined;
          season: { __typename?: 'Season'; id: string; name: string };
          subTasks: Array<{
            __typename?: 'SubTask';
            id: string;
            status: TaskStatus;
            count: number;
            note?: string | undefined;
            deadline?: any | undefined;
            createdAt?: any | undefined;
            lead?: { __typename?: 'TaskLead'; id: string; type: TaskLeadType } | undefined;
            match?: { __typename?: 'Match'; id: string; name: string; date: any } | undefined;
            lastEdit: {
              __typename?: 'LastEdit';
              timestamp: any;
              user: { __typename?: 'LastUser'; id: string };
            };
          }>;
          lastEdit: {
            __typename?: 'LastEdit';
            timestamp: any;
            user: { __typename?: 'LastUser'; id: string };
          };
          stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
        }>
      | undefined;
    template?: { __typename?: 'ContractTemplate'; id: string; name: string; template: string } | undefined;
    autoRenew?:
      | {
          __typename?: 'ContractAutoRenew';
          enabled: boolean;
          mode: AutoRenewMode;
          markedForCancellationAt?: any | undefined;
          cancellationPeriodEnd?: any | undefined;
          renewYears: number;
        }
      | undefined;
    notes?:
      | Array<{
          __typename?: 'ContractNote';
          id: string;
          content: string;
          createdAt?: any | undefined;
          updatedAt?: any | undefined;
          author: { __typename?: 'Author'; id: string; email: string };
        }>
      | undefined;
    partner: {
      __typename?: 'Partner';
      id: string;
      companyName?: string | undefined;
      category?: { __typename?: 'PartnerCategory'; id: string; name: string } | undefined;
    };
    salesLead?: { __typename?: 'User'; id: string } | undefined;
    clubLead?: { __typename?: 'User'; id: string } | undefined;
    bonuses: Array<{
      __typename?: 'ContractBonus';
      id: string;
      price: number;
      achieved: boolean;
      bonusId: string;
      bonusName: string;
      bonusDescription?: string | undefined;
      season: { __typename?: 'Season'; id: string; name: string };
    }>;
    invoices: Array<{
      __typename?: 'Invoice';
      id: string;
      type: InvoiceType;
      percentage?: number | undefined;
      invoiceNumber?: number | undefined;
      cancellationNumber?: number | undefined;
      date: any;
      invoicedAt?: any | undefined;
      serviceStartedAt?: any | undefined;
      serviceEndedAt?: any | undefined;
      paymentDueAt?: any | undefined;
      payedAt?: any | undefined;
      canceledAt?: any | undefined;
      bonusId?: string | undefined;
      season: { __typename?: 'Season'; id: string; name: string };
    }>;
    total: {
      __typename?: 'ContractTotals';
      barter: number;
      discount: number;
      listPrice: number;
      price: number;
    };
  };
};

export type DashboardDiscountQueryVariables = Exact<{
  leagueIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  seasonId: Scalars['String']['input'];
}>;

export type DashboardDiscountQuery = {
  __typename?: 'Query';
  dashboardDiscount: { __typename?: 'DashboardDiscount'; average: number };
};

export type DashboardForecastQueryVariables = Exact<{
  leagueIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  seasonId: Scalars['String']['input'];
}>;

export type DashboardForecastQuery = {
  __typename?: 'Query';
  dashboardForecast: {
    __typename?: 'DashboardForecast';
    actual: number;
    forecast: number;
    actualToPlan: number;
    forecastToPlan: number;
    planned: number;
    chartSeries: Array<{ __typename?: 'ForecastChartSeries'; name: string; data: Array<Array<number>> }>;
  };
};

export type DashboardInventoryQueryVariables = Exact<{
  leagueIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  seasonId: Scalars['String']['input'];
}>;

export type DashboardInventoryQuery = {
  __typename?: 'Query';
  dashboardInventory: {
    __typename?: 'DashboardInventoryStats';
    stats: {
      __typename?: 'InventoryStats';
      available?: number | undefined;
      availableValue?: number | undefined;
      blocked?: number | undefined;
      blockedValue?: number | undefined;
      capacityUtilization?: number | undefined;
      discount?: number | undefined;
      offered?: number | undefined;
      offeredValue?: number | undefined;
      sold?: number | undefined;
      soldRevenue?: number | undefined;
      soldValue?: number | undefined;
      total?: number | undefined;
      totalValue?: number | undefined;
    };
    topCategoryRevenues: Array<{ __typename?: 'TopCategoryChartData'; label: string; value: number }>;
  };
};

export type DashboardOffersQueryVariables = Exact<{
  leagueIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  seasonId: Scalars['String']['input'];
}>;

export type DashboardOffersQuery = {
  __typename?: 'Query';
  dashboardOffers: {
    __typename?: 'DashboardOffers';
    categories: Array<string>;
    amountSeries: Array<{ __typename?: 'ChartSeries'; name: string; data: Array<number> }>;
    valueSeries: Array<{ __typename?: 'ChartSeries'; name: string; data: Array<number> }>;
  };
};

export type DashboardPartnerAndContractsQueryVariables = Exact<{
  leagueIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  seasonId: Scalars['String']['input'];
}>;

export type DashboardPartnerAndContractsQuery = {
  __typename?: 'Query';
  dashboardPartnerAndContracts: {
    __typename?: 'DashboardPartnerAndContracts';
    partner: {
      __typename?: 'DashboardPartnerStats';
      total: number;
      new: number;
      existing: number;
      leads: number;
      fixed: number;
    };
    contract: {
      __typename?: 'DashboardContractStats';
      total: number;
      expired: number;
      autoRenewTotal: number;
      autoRenewOpen: number;
      cancellations: number;
    };
  };
};

export type DashboardRevenueDetailsQueryVariables = Exact<{
  leagueIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  seasonId: Scalars['String']['input'];
}>;

export type DashboardRevenueDetailsQuery = {
  __typename?: 'Query';
  dashboardRevenueDetails: {
    __typename?: 'DashboardRevenueDetails';
    bonus: number;
    contracts: number;
    total: number;
    totalPrice: number;
    totalBarter: number;
  };
};

export type InventoryStatsFragmentFragment = {
  __typename?: 'InventoryStats';
  available?: number | undefined;
  availableValue?: number | undefined;
  blocked?: number | undefined;
  blockedValue?: number | undefined;
  offered?: number | undefined;
  offeredValue?: number | undefined;
  sold?: number | undefined;
  soldRevenue?: number | undefined;
  soldValue?: number | undefined;
  total?: number | undefined;
  totalValue?: number | undefined;
  capacityUtilization?: number | undefined;
  discount?: number | undefined;
};

export type AllInventoryWithTasksQueryVariables = Exact<{
  filterType?: InputMaybe<InventoryType>;
  availabilityFilter?: InputMaybe<InventoryAvailabilityFilter>;
  leagueIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  seasonId: Scalars['String']['input'];
  topCategoryId?: InputMaybe<Scalars['String']['input']>;
}>;

export type AllInventoryWithTasksQuery = {
  __typename?: 'Query';
  allInventory: {
    __typename?: 'InventoryWithTotals';
    inventory: Array<{
      __typename?: 'Inventory';
      id: string;
      name: string;
      order: number;
      unit: UnitType;
      keyRightOrder?: number | undefined;
      soldByMatch: boolean;
      soldBySeason: boolean;
      note?: string | undefined;
      externalId?: string | undefined;
      contractDescription?: string | undefined;
      createdAt?: any | undefined;
      updatedAt?: any | undefined;
      category?: { __typename?: 'InventoryCategory'; id: string; name: string } | undefined;
      tasks?:
        | {
            __typename?: 'InventoryTasks';
            contracts: Array<{
              __typename?: 'MatchTaskContract';
              id: string;
              partner: { __typename?: 'Partner'; id: string; companyName?: string | undefined };
              stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
              tasks: Array<{
                __typename?: 'Task';
                id: string;
                name?: string | undefined;
                note?: string | undefined;
                type: TaskType;
                totalTasks: number;
                customAmount?: number | undefined;
                contractItemAmount?: number | undefined;
                createdAt?: any | undefined;
                updatedAt?: any | undefined;
                match?: { __typename?: 'Match'; id: string; name: string } | undefined;
                season: { __typename?: 'Season'; id: string; name: string };
                subTasks: Array<{
                  __typename?: 'SubTask';
                  id: string;
                  status: TaskStatus;
                  count: number;
                  note?: string | undefined;
                  deadline?: any | undefined;
                  createdAt?: any | undefined;
                  lead?: { __typename?: 'TaskLead'; id: string; type: TaskLeadType } | undefined;
                  match?: { __typename?: 'Match'; id: string; name: string; date: any } | undefined;
                  lastEdit: {
                    __typename?: 'LastEdit';
                    timestamp: any;
                    user: { __typename?: 'LastUser'; id: string };
                  };
                }>;
                lastEdit: {
                  __typename?: 'LastEdit';
                  timestamp: any;
                  user: { __typename?: 'LastUser'; id: string };
                };
                stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
              }>;
            }>;
            stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
          }
        | undefined;
    }>;
  };
};

export type AllInventoryQueryVariables = Exact<{
  filterType?: InputMaybe<InventoryType>;
  availabilityFilter?: InputMaybe<InventoryAvailabilityFilter>;
  leagueIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  seasonId: Scalars['String']['input'];
  topCategoryId?: InputMaybe<Scalars['String']['input']>;
}>;

export type AllInventoryQuery = {
  __typename?: 'Query';
  allInventory: {
    __typename?: 'InventoryWithTotals';
    inventory: Array<{
      __typename?: 'Inventory';
      id: string;
      name: string;
      order: number;
      unit: UnitType;
      keyRightOrder?: number | undefined;
      soldByMatch: boolean;
      soldBySeason: boolean;
      note?: string | undefined;
      externalId?: string | undefined;
      contractDescription?: string | undefined;
      createdAt?: any | undefined;
      updatedAt?: any | undefined;
      category?:
        | {
            __typename?: 'InventoryCategory';
            id: string;
            name: string;
            stats?:
              | {
                  __typename?: 'InventoryStats';
                  available?: number | undefined;
                  availableValue?: number | undefined;
                  blocked?: number | undefined;
                  blockedValue?: number | undefined;
                  offered?: number | undefined;
                  offeredValue?: number | undefined;
                  sold?: number | undefined;
                  soldRevenue?: number | undefined;
                  soldValue?: number | undefined;
                  total?: number | undefined;
                  totalValue?: number | undefined;
                  capacityUtilization?: number | undefined;
                  discount?: number | undefined;
                }
              | undefined;
          }
        | undefined;
      topCategory?:
        | {
            __typename?: 'TopCategory';
            id: string;
            name: string;
            stats?:
              | {
                  __typename?: 'InventoryStats';
                  available?: number | undefined;
                  availableValue?: number | undefined;
                  blocked?: number | undefined;
                  blockedValue?: number | undefined;
                  offered?: number | undefined;
                  offeredValue?: number | undefined;
                  sold?: number | undefined;
                  soldRevenue?: number | undefined;
                  soldValue?: number | undefined;
                  total?: number | undefined;
                  totalValue?: number | undefined;
                  capacityUtilization?: number | undefined;
                  discount?: number | undefined;
                }
              | undefined;
          }
        | undefined;
      stats?:
        | {
            __typename?: 'InventoryStats';
            available?: number | undefined;
            availableValue?: number | undefined;
            blocked?: number | undefined;
            blockedValue?: number | undefined;
            offered?: number | undefined;
            offeredValue?: number | undefined;
            sold?: number | undefined;
            soldRevenue?: number | undefined;
            soldValue?: number | undefined;
            total?: number | undefined;
            totalValue?: number | undefined;
            capacityUtilization?: number | undefined;
            discount?: number | undefined;
          }
        | undefined;
      listPrices: Array<{
        __typename?: 'InventoryListPrice';
        id: string;
        type: InventoryType;
        note?: string | undefined;
        quantity: number;
        price: number;
        createdAt?: any | undefined;
        updatedAt?: any | undefined;
        season: { __typename?: 'Season'; id: string; name: string };
        league: { __typename?: 'League'; id: string; name: string };
        stats?:
          | {
              __typename?: 'InventoryStats';
              available?: number | undefined;
              availableValue?: number | undefined;
              blocked?: number | undefined;
              blockedValue?: number | undefined;
              offered?: number | undefined;
              offeredValue?: number | undefined;
              sold?: number | undefined;
              soldRevenue?: number | undefined;
              soldValue?: number | undefined;
              total?: number | undefined;
              totalValue?: number | undefined;
              capacityUtilization?: number | undefined;
              discount?: number | undefined;
            }
          | undefined;
        matchStats?:
          | Array<{
              __typename?: 'InventoryMatchStats';
              available: number;
              availableValue: number;
              blocked: number;
              blockedValue: number;
              offered: number;
              offeredValue: number;
              sold: number;
              soldRevenue: number;
              soldValue: number;
              soldBySeason: number;
              discount: number;
              match: { __typename?: 'Match'; id: string };
            }>
          | undefined;
      }>;
      tasks?:
        | {
            __typename?: 'InventoryTasks';
            contracts: Array<{
              __typename?: 'MatchTaskContract';
              id: string;
              partner: { __typename?: 'Partner'; id: string; companyName?: string | undefined };
              stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
              tasks: Array<{
                __typename?: 'Task';
                id: string;
                name?: string | undefined;
                note?: string | undefined;
                type: TaskType;
                totalTasks: number;
                customAmount?: number | undefined;
                contractItemAmount?: number | undefined;
                createdAt?: any | undefined;
                updatedAt?: any | undefined;
                match?: { __typename?: 'Match'; id: string; name: string } | undefined;
                season: { __typename?: 'Season'; id: string; name: string };
                subTasks: Array<{
                  __typename?: 'SubTask';
                  id: string;
                  status: TaskStatus;
                  count: number;
                  note?: string | undefined;
                  deadline?: any | undefined;
                  createdAt?: any | undefined;
                  lead?: { __typename?: 'TaskLead'; id: string; type: TaskLeadType } | undefined;
                  match?: { __typename?: 'Match'; id: string; name: string; date: any } | undefined;
                  lastEdit: {
                    __typename?: 'LastEdit';
                    timestamp: any;
                    user: { __typename?: 'LastUser'; id: string };
                  };
                }>;
                lastEdit: {
                  __typename?: 'LastEdit';
                  timestamp: any;
                  user: { __typename?: 'LastUser'; id: string };
                };
                stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
              }>;
            }>;
            stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
          }
        | undefined;
    }>;
    totals: {
      __typename?: 'InventoryStats';
      available?: number | undefined;
      availableValue?: number | undefined;
      blocked?: number | undefined;
      blockedValue?: number | undefined;
      capacityUtilization?: number | undefined;
      discount?: number | undefined;
      offered?: number | undefined;
      offeredValue?: number | undefined;
      sold?: number | undefined;
      soldRevenue?: number | undefined;
      soldValue?: number | undefined;
      total?: number | undefined;
      totalValue?: number | undefined;
    };
  };
};

export type CreateInventoryMutationVariables = Exact<{
  input: CreateInventoryInput;
}>;

export type CreateInventoryMutation = {
  __typename?: 'Mutation';
  createInventory: {
    __typename?: 'Inventory';
    id: string;
    name: string;
    order: number;
    unit: UnitType;
    keyRightOrder?: number | undefined;
    soldByMatch: boolean;
    soldBySeason: boolean;
    note?: string | undefined;
    externalId?: string | undefined;
    contractDescription?: string | undefined;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    category?:
      | {
          __typename?: 'InventoryCategory';
          id: string;
          name: string;
          stats?:
            | {
                __typename?: 'InventoryStats';
                available?: number | undefined;
                availableValue?: number | undefined;
                blocked?: number | undefined;
                blockedValue?: number | undefined;
                offered?: number | undefined;
                offeredValue?: number | undefined;
                sold?: number | undefined;
                soldRevenue?: number | undefined;
                soldValue?: number | undefined;
                total?: number | undefined;
                totalValue?: number | undefined;
                capacityUtilization?: number | undefined;
                discount?: number | undefined;
              }
            | undefined;
        }
      | undefined;
    topCategory?:
      | {
          __typename?: 'TopCategory';
          id: string;
          name: string;
          stats?:
            | {
                __typename?: 'InventoryStats';
                available?: number | undefined;
                availableValue?: number | undefined;
                blocked?: number | undefined;
                blockedValue?: number | undefined;
                offered?: number | undefined;
                offeredValue?: number | undefined;
                sold?: number | undefined;
                soldRevenue?: number | undefined;
                soldValue?: number | undefined;
                total?: number | undefined;
                totalValue?: number | undefined;
                capacityUtilization?: number | undefined;
                discount?: number | undefined;
              }
            | undefined;
        }
      | undefined;
    stats?:
      | {
          __typename?: 'InventoryStats';
          available?: number | undefined;
          availableValue?: number | undefined;
          blocked?: number | undefined;
          blockedValue?: number | undefined;
          offered?: number | undefined;
          offeredValue?: number | undefined;
          sold?: number | undefined;
          soldRevenue?: number | undefined;
          soldValue?: number | undefined;
          total?: number | undefined;
          totalValue?: number | undefined;
          capacityUtilization?: number | undefined;
          discount?: number | undefined;
        }
      | undefined;
    listPrices: Array<{
      __typename?: 'InventoryListPrice';
      id: string;
      type: InventoryType;
      note?: string | undefined;
      quantity: number;
      price: number;
      createdAt?: any | undefined;
      updatedAt?: any | undefined;
      season: { __typename?: 'Season'; id: string; name: string };
      league: { __typename?: 'League'; id: string; name: string };
      stats?:
        | {
            __typename?: 'InventoryStats';
            available?: number | undefined;
            availableValue?: number | undefined;
            blocked?: number | undefined;
            blockedValue?: number | undefined;
            offered?: number | undefined;
            offeredValue?: number | undefined;
            sold?: number | undefined;
            soldRevenue?: number | undefined;
            soldValue?: number | undefined;
            total?: number | undefined;
            totalValue?: number | undefined;
            capacityUtilization?: number | undefined;
            discount?: number | undefined;
          }
        | undefined;
      matchStats?:
        | Array<{
            __typename?: 'InventoryMatchStats';
            available: number;
            availableValue: number;
            blocked: number;
            blockedValue: number;
            offered: number;
            offeredValue: number;
            sold: number;
            soldRevenue: number;
            soldValue: number;
            soldBySeason: number;
            discount: number;
            match: { __typename?: 'Match'; id: string };
          }>
        | undefined;
    }>;
    tasks?:
      | {
          __typename?: 'InventoryTasks';
          contracts: Array<{
            __typename?: 'MatchTaskContract';
            id: string;
            partner: { __typename?: 'Partner'; id: string; companyName?: string | undefined };
            stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
            tasks: Array<{
              __typename?: 'Task';
              id: string;
              name?: string | undefined;
              note?: string | undefined;
              type: TaskType;
              totalTasks: number;
              customAmount?: number | undefined;
              contractItemAmount?: number | undefined;
              createdAt?: any | undefined;
              updatedAt?: any | undefined;
              match?: { __typename?: 'Match'; id: string; name: string } | undefined;
              season: { __typename?: 'Season'; id: string; name: string };
              subTasks: Array<{
                __typename?: 'SubTask';
                id: string;
                status: TaskStatus;
                count: number;
                note?: string | undefined;
                deadline?: any | undefined;
                createdAt?: any | undefined;
                lead?: { __typename?: 'TaskLead'; id: string; type: TaskLeadType } | undefined;
                match?: { __typename?: 'Match'; id: string; name: string; date: any } | undefined;
                lastEdit: {
                  __typename?: 'LastEdit';
                  timestamp: any;
                  user: { __typename?: 'LastUser'; id: string };
                };
              }>;
              lastEdit: {
                __typename?: 'LastEdit';
                timestamp: any;
                user: { __typename?: 'LastUser'; id: string };
              };
              stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
            }>;
          }>;
          stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
        }
      | undefined;
  };
};

export type InventoryQueryVariables = Exact<{
  id: Scalars['String']['input'];
  leagueIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  seasonId?: InputMaybe<Scalars['String']['input']>;
}>;

export type InventoryQuery = {
  __typename?: 'Query';
  inventory: {
    __typename?: 'Inventory';
    id: string;
    name: string;
    order: number;
    unit: UnitType;
    keyRightOrder?: number | undefined;
    soldByMatch: boolean;
    soldBySeason: boolean;
    note?: string | undefined;
    externalId?: string | undefined;
    contractDescription?: string | undefined;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    category?:
      | {
          __typename?: 'InventoryCategory';
          id: string;
          name: string;
          stats?:
            | {
                __typename?: 'InventoryStats';
                available?: number | undefined;
                availableValue?: number | undefined;
                blocked?: number | undefined;
                blockedValue?: number | undefined;
                offered?: number | undefined;
                offeredValue?: number | undefined;
                sold?: number | undefined;
                soldRevenue?: number | undefined;
                soldValue?: number | undefined;
                total?: number | undefined;
                totalValue?: number | undefined;
                capacityUtilization?: number | undefined;
                discount?: number | undefined;
              }
            | undefined;
        }
      | undefined;
    topCategory?:
      | {
          __typename?: 'TopCategory';
          id: string;
          name: string;
          stats?:
            | {
                __typename?: 'InventoryStats';
                available?: number | undefined;
                availableValue?: number | undefined;
                blocked?: number | undefined;
                blockedValue?: number | undefined;
                offered?: number | undefined;
                offeredValue?: number | undefined;
                sold?: number | undefined;
                soldRevenue?: number | undefined;
                soldValue?: number | undefined;
                total?: number | undefined;
                totalValue?: number | undefined;
                capacityUtilization?: number | undefined;
                discount?: number | undefined;
              }
            | undefined;
        }
      | undefined;
    stats?:
      | {
          __typename?: 'InventoryStats';
          available?: number | undefined;
          availableValue?: number | undefined;
          blocked?: number | undefined;
          blockedValue?: number | undefined;
          offered?: number | undefined;
          offeredValue?: number | undefined;
          sold?: number | undefined;
          soldRevenue?: number | undefined;
          soldValue?: number | undefined;
          total?: number | undefined;
          totalValue?: number | undefined;
          capacityUtilization?: number | undefined;
          discount?: number | undefined;
        }
      | undefined;
    listPrices: Array<{
      __typename?: 'InventoryListPrice';
      id: string;
      type: InventoryType;
      note?: string | undefined;
      quantity: number;
      price: number;
      createdAt?: any | undefined;
      updatedAt?: any | undefined;
      season: { __typename?: 'Season'; id: string; name: string };
      league: { __typename?: 'League'; id: string; name: string };
      stats?:
        | {
            __typename?: 'InventoryStats';
            available?: number | undefined;
            availableValue?: number | undefined;
            blocked?: number | undefined;
            blockedValue?: number | undefined;
            offered?: number | undefined;
            offeredValue?: number | undefined;
            sold?: number | undefined;
            soldRevenue?: number | undefined;
            soldValue?: number | undefined;
            total?: number | undefined;
            totalValue?: number | undefined;
            capacityUtilization?: number | undefined;
            discount?: number | undefined;
          }
        | undefined;
      matchStats?:
        | Array<{
            __typename?: 'InventoryMatchStats';
            available: number;
            availableValue: number;
            blocked: number;
            blockedValue: number;
            offered: number;
            offeredValue: number;
            sold: number;
            soldRevenue: number;
            soldValue: number;
            soldBySeason: number;
            discount: number;
            match: { __typename?: 'Match'; id: string };
          }>
        | undefined;
    }>;
    tasks?:
      | {
          __typename?: 'InventoryTasks';
          contracts: Array<{
            __typename?: 'MatchTaskContract';
            id: string;
            partner: { __typename?: 'Partner'; id: string; companyName?: string | undefined };
            stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
            tasks: Array<{
              __typename?: 'Task';
              id: string;
              name?: string | undefined;
              note?: string | undefined;
              type: TaskType;
              totalTasks: number;
              customAmount?: number | undefined;
              contractItemAmount?: number | undefined;
              createdAt?: any | undefined;
              updatedAt?: any | undefined;
              match?: { __typename?: 'Match'; id: string; name: string } | undefined;
              season: { __typename?: 'Season'; id: string; name: string };
              subTasks: Array<{
                __typename?: 'SubTask';
                id: string;
                status: TaskStatus;
                count: number;
                note?: string | undefined;
                deadline?: any | undefined;
                createdAt?: any | undefined;
                lead?: { __typename?: 'TaskLead'; id: string; type: TaskLeadType } | undefined;
                match?: { __typename?: 'Match'; id: string; name: string; date: any } | undefined;
                lastEdit: {
                  __typename?: 'LastEdit';
                  timestamp: any;
                  user: { __typename?: 'LastUser'; id: string };
                };
              }>;
              lastEdit: {
                __typename?: 'LastEdit';
                timestamp: any;
                user: { __typename?: 'LastUser'; id: string };
              };
              stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
            }>;
          }>;
          stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
        }
      | undefined;
  };
};

export type InventoryDetailsQueryVariables = Exact<{
  id: Scalars['String']['input'];
  leagueIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  seasonId?: InputMaybe<Scalars['String']['input']>;
}>;

export type InventoryDetailsQuery = {
  __typename?: 'Query';
  inventoryDetails: {
    __typename?: 'InventoryDetails';
    contractsBySeason: Array<{
      __typename?: 'SeasonInventoryDetailContract';
      seasonId: string;
      contracts: Array<{
        __typename?: 'InventoryDetailContract';
        id: string;
        partnerId: string;
        partnerName: string;
        discount: number;
        endDate: any;
        revenue: number;
        probability: number;
        type: ContractType;
        amount: number;
        unit: UnitType;
        totalValue: number;
        items: Array<{
          __typename?: 'ContractItem';
          id: string;
          amount: number;
          locked: boolean;
          listUnitPrice: number;
          price: number;
          barter: number;
          league: { __typename?: 'League'; id: string; name: string };
          season: { __typename?: 'Season'; id: string; name: string };
          match?: { __typename?: 'Match'; id: string; name: string } | undefined;
          inventory: {
            __typename?: 'Inventory';
            id: string;
            name: string;
            unit: UnitType;
            topCategory?: { __typename?: 'TopCategory'; id: string; name: string } | undefined;
            listPrices: Array<{ __typename?: 'InventoryListPrice'; id: string; type: InventoryType }>;
          };
        }>;
      }>;
    }>;
    revenueChart: {
      __typename?: 'InventoryRevenueChartData';
      revenueSeries: {
        __typename?: 'TimeSeries';
        data: Array<{ __typename?: 'TimeSeriesChartItem'; x: any; y: number }>;
      };
      totalValueSeries: {
        __typename?: 'TimeSeries';
        data: Array<{ __typename?: 'TimeSeriesChartItem'; x: any; y: number }>;
      };
    };
  };
};

export type InventoryMatchStatsFragmentFragment = {
  __typename?: 'InventoryMatchStats';
  available: number;
  availableValue: number;
  blocked: number;
  blockedValue: number;
  offered: number;
  offeredValue: number;
  sold: number;
  soldRevenue: number;
  soldValue: number;
  soldBySeason: number;
  discount: number;
  match: { __typename?: 'Match'; id: string };
};

export type InventoryFragmentFragment = {
  __typename?: 'Inventory';
  id: string;
  name: string;
  order: number;
  unit: UnitType;
  keyRightOrder?: number | undefined;
  soldByMatch: boolean;
  soldBySeason: boolean;
  note?: string | undefined;
  externalId?: string | undefined;
  contractDescription?: string | undefined;
  createdAt?: any | undefined;
  updatedAt?: any | undefined;
  category?:
    | {
        __typename?: 'InventoryCategory';
        id: string;
        name: string;
        stats?:
          | {
              __typename?: 'InventoryStats';
              available?: number | undefined;
              availableValue?: number | undefined;
              blocked?: number | undefined;
              blockedValue?: number | undefined;
              offered?: number | undefined;
              offeredValue?: number | undefined;
              sold?: number | undefined;
              soldRevenue?: number | undefined;
              soldValue?: number | undefined;
              total?: number | undefined;
              totalValue?: number | undefined;
              capacityUtilization?: number | undefined;
              discount?: number | undefined;
            }
          | undefined;
      }
    | undefined;
  topCategory?:
    | {
        __typename?: 'TopCategory';
        id: string;
        name: string;
        stats?:
          | {
              __typename?: 'InventoryStats';
              available?: number | undefined;
              availableValue?: number | undefined;
              blocked?: number | undefined;
              blockedValue?: number | undefined;
              offered?: number | undefined;
              offeredValue?: number | undefined;
              sold?: number | undefined;
              soldRevenue?: number | undefined;
              soldValue?: number | undefined;
              total?: number | undefined;
              totalValue?: number | undefined;
              capacityUtilization?: number | undefined;
              discount?: number | undefined;
            }
          | undefined;
      }
    | undefined;
  stats?:
    | {
        __typename?: 'InventoryStats';
        available?: number | undefined;
        availableValue?: number | undefined;
        blocked?: number | undefined;
        blockedValue?: number | undefined;
        offered?: number | undefined;
        offeredValue?: number | undefined;
        sold?: number | undefined;
        soldRevenue?: number | undefined;
        soldValue?: number | undefined;
        total?: number | undefined;
        totalValue?: number | undefined;
        capacityUtilization?: number | undefined;
        discount?: number | undefined;
      }
    | undefined;
  listPrices: Array<{
    __typename?: 'InventoryListPrice';
    id: string;
    type: InventoryType;
    note?: string | undefined;
    quantity: number;
    price: number;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    season: { __typename?: 'Season'; id: string; name: string };
    league: { __typename?: 'League'; id: string; name: string };
    stats?:
      | {
          __typename?: 'InventoryStats';
          available?: number | undefined;
          availableValue?: number | undefined;
          blocked?: number | undefined;
          blockedValue?: number | undefined;
          offered?: number | undefined;
          offeredValue?: number | undefined;
          sold?: number | undefined;
          soldRevenue?: number | undefined;
          soldValue?: number | undefined;
          total?: number | undefined;
          totalValue?: number | undefined;
          capacityUtilization?: number | undefined;
          discount?: number | undefined;
        }
      | undefined;
    matchStats?:
      | Array<{
          __typename?: 'InventoryMatchStats';
          available: number;
          availableValue: number;
          blocked: number;
          blockedValue: number;
          offered: number;
          offeredValue: number;
          sold: number;
          soldRevenue: number;
          soldValue: number;
          soldBySeason: number;
          discount: number;
          match: { __typename?: 'Match'; id: string };
        }>
      | undefined;
  }>;
  tasks?:
    | {
        __typename?: 'InventoryTasks';
        contracts: Array<{
          __typename?: 'MatchTaskContract';
          id: string;
          partner: { __typename?: 'Partner'; id: string; companyName?: string | undefined };
          stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
          tasks: Array<{
            __typename?: 'Task';
            id: string;
            name?: string | undefined;
            note?: string | undefined;
            type: TaskType;
            totalTasks: number;
            customAmount?: number | undefined;
            contractItemAmount?: number | undefined;
            createdAt?: any | undefined;
            updatedAt?: any | undefined;
            match?: { __typename?: 'Match'; id: string; name: string } | undefined;
            season: { __typename?: 'Season'; id: string; name: string };
            subTasks: Array<{
              __typename?: 'SubTask';
              id: string;
              status: TaskStatus;
              count: number;
              note?: string | undefined;
              deadline?: any | undefined;
              createdAt?: any | undefined;
              lead?: { __typename?: 'TaskLead'; id: string; type: TaskLeadType } | undefined;
              match?: { __typename?: 'Match'; id: string; name: string; date: any } | undefined;
              lastEdit: {
                __typename?: 'LastEdit';
                timestamp: any;
                user: { __typename?: 'LastUser'; id: string };
              };
            }>;
            lastEdit: {
              __typename?: 'LastEdit';
              timestamp: any;
              user: { __typename?: 'LastUser'; id: string };
            };
            stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
          }>;
        }>;
        stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
      }
    | undefined;
};

export type DashboardKeyInventoryQueryVariables = Exact<{
  leagueIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  seasonId: Scalars['String']['input'];
}>;

export type DashboardKeyInventoryQuery = {
  __typename?: 'Query';
  allInventory: {
    __typename?: 'InventoryWithTotals';
    inventory: Array<{
      __typename?: 'Inventory';
      id: string;
      name: string;
      unit: UnitType;
      note?: string | undefined;
      keyRightOrder?: number | undefined;
      listPrices: Array<{ __typename?: 'InventoryListPrice'; price: number }>;
      stats?:
        | {
            __typename?: 'InventoryStats';
            total?: number | undefined;
            sold?: number | undefined;
            available?: number | undefined;
            blocked?: number | undefined;
            offered?: number | undefined;
          }
        | undefined;
    }>;
  };
};

export type CreateInventoryListPriceMutationVariables = Exact<{
  input: CreateListPriceItemInput;
  inventoryId: Scalars['String']['input'];
}>;

export type CreateInventoryListPriceMutation = {
  __typename?: 'Mutation';
  createInventoryListPrice: {
    __typename?: 'InventoryListPrice';
    id: string;
    type: InventoryType;
    note?: string | undefined;
    quantity: number;
    price: number;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    season: { __typename?: 'Season'; id: string; name: string };
    league: { __typename?: 'League'; id: string; name: string };
    stats?:
      | {
          __typename?: 'InventoryStats';
          available?: number | undefined;
          availableValue?: number | undefined;
          blocked?: number | undefined;
          blockedValue?: number | undefined;
          offered?: number | undefined;
          offeredValue?: number | undefined;
          sold?: number | undefined;
          soldRevenue?: number | undefined;
          soldValue?: number | undefined;
          total?: number | undefined;
          totalValue?: number | undefined;
          capacityUtilization?: number | undefined;
          discount?: number | undefined;
        }
      | undefined;
    matchStats?:
      | Array<{
          __typename?: 'InventoryMatchStats';
          available: number;
          availableValue: number;
          blocked: number;
          blockedValue: number;
          offered: number;
          offeredValue: number;
          sold: number;
          soldRevenue: number;
          soldValue: number;
          soldBySeason: number;
          discount: number;
          match: { __typename?: 'Match'; id: string };
        }>
      | undefined;
  };
};

export type InventoryListPriceFragmentFragment = {
  __typename?: 'InventoryListPrice';
  id: string;
  type: InventoryType;
  note?: string | undefined;
  quantity: number;
  price: number;
  createdAt?: any | undefined;
  updatedAt?: any | undefined;
  season: { __typename?: 'Season'; id: string; name: string };
  league: { __typename?: 'League'; id: string; name: string };
  stats?:
    | {
        __typename?: 'InventoryStats';
        available?: number | undefined;
        availableValue?: number | undefined;
        blocked?: number | undefined;
        blockedValue?: number | undefined;
        offered?: number | undefined;
        offeredValue?: number | undefined;
        sold?: number | undefined;
        soldRevenue?: number | undefined;
        soldValue?: number | undefined;
        total?: number | undefined;
        totalValue?: number | undefined;
        capacityUtilization?: number | undefined;
        discount?: number | undefined;
      }
    | undefined;
  matchStats?:
    | Array<{
        __typename?: 'InventoryMatchStats';
        available: number;
        availableValue: number;
        blocked: number;
        blockedValue: number;
        offered: number;
        offeredValue: number;
        sold: number;
        soldRevenue: number;
        soldValue: number;
        soldBySeason: number;
        discount: number;
        match: { __typename?: 'Match'; id: string };
      }>
    | undefined;
};

export type RemoveInventoryListPriceMutationVariables = Exact<{
  inventoryId: Scalars['String']['input'];
  listPriceId: Scalars['String']['input'];
}>;

export type RemoveInventoryListPriceMutation = { __typename?: 'Mutation'; removeInventoryListPrice: boolean };

export type UpdateInventoryListPriceMutationVariables = Exact<{
  input: UpdateListPriceItemInput;
  inventoryId: Scalars['String']['input'];
  listPriceId: Scalars['String']['input'];
}>;

export type UpdateInventoryListPriceMutation = {
  __typename?: 'Mutation';
  updateInventoryListPrice: {
    __typename?: 'InventoryListPrice';
    id: string;
    type: InventoryType;
    note?: string | undefined;
    quantity: number;
    price: number;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    season: { __typename?: 'Season'; id: string; name: string };
    league: { __typename?: 'League'; id: string; name: string };
    stats?:
      | {
          __typename?: 'InventoryStats';
          available?: number | undefined;
          availableValue?: number | undefined;
          blocked?: number | undefined;
          blockedValue?: number | undefined;
          offered?: number | undefined;
          offeredValue?: number | undefined;
          sold?: number | undefined;
          soldRevenue?: number | undefined;
          soldValue?: number | undefined;
          total?: number | undefined;
          totalValue?: number | undefined;
          capacityUtilization?: number | undefined;
          discount?: number | undefined;
        }
      | undefined;
    matchStats?:
      | Array<{
          __typename?: 'InventoryMatchStats';
          available: number;
          availableValue: number;
          blocked: number;
          blockedValue: number;
          offered: number;
          offeredValue: number;
          sold: number;
          soldRevenue: number;
          soldValue: number;
          soldBySeason: number;
          discount: number;
          match: { __typename?: 'Match'; id: string };
        }>
      | undefined;
  };
};

export type RemoveInventoryMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RemoveInventoryMutation = { __typename?: 'Mutation'; removeInventory: boolean };

export type UpdateInventoryMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: UpdateInventoryInput;
}>;

export type UpdateInventoryMutation = {
  __typename?: 'Mutation';
  updateInventory: {
    __typename?: 'Inventory';
    id: string;
    name: string;
    order: number;
    unit: UnitType;
    keyRightOrder?: number | undefined;
    soldByMatch: boolean;
    soldBySeason: boolean;
    note?: string | undefined;
    externalId?: string | undefined;
    contractDescription?: string | undefined;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    category?:
      | {
          __typename?: 'InventoryCategory';
          id: string;
          name: string;
          stats?:
            | {
                __typename?: 'InventoryStats';
                available?: number | undefined;
                availableValue?: number | undefined;
                blocked?: number | undefined;
                blockedValue?: number | undefined;
                offered?: number | undefined;
                offeredValue?: number | undefined;
                sold?: number | undefined;
                soldRevenue?: number | undefined;
                soldValue?: number | undefined;
                total?: number | undefined;
                totalValue?: number | undefined;
                capacityUtilization?: number | undefined;
                discount?: number | undefined;
              }
            | undefined;
        }
      | undefined;
    topCategory?:
      | {
          __typename?: 'TopCategory';
          id: string;
          name: string;
          stats?:
            | {
                __typename?: 'InventoryStats';
                available?: number | undefined;
                availableValue?: number | undefined;
                blocked?: number | undefined;
                blockedValue?: number | undefined;
                offered?: number | undefined;
                offeredValue?: number | undefined;
                sold?: number | undefined;
                soldRevenue?: number | undefined;
                soldValue?: number | undefined;
                total?: number | undefined;
                totalValue?: number | undefined;
                capacityUtilization?: number | undefined;
                discount?: number | undefined;
              }
            | undefined;
        }
      | undefined;
    stats?:
      | {
          __typename?: 'InventoryStats';
          available?: number | undefined;
          availableValue?: number | undefined;
          blocked?: number | undefined;
          blockedValue?: number | undefined;
          offered?: number | undefined;
          offeredValue?: number | undefined;
          sold?: number | undefined;
          soldRevenue?: number | undefined;
          soldValue?: number | undefined;
          total?: number | undefined;
          totalValue?: number | undefined;
          capacityUtilization?: number | undefined;
          discount?: number | undefined;
        }
      | undefined;
    listPrices: Array<{
      __typename?: 'InventoryListPrice';
      id: string;
      type: InventoryType;
      note?: string | undefined;
      quantity: number;
      price: number;
      createdAt?: any | undefined;
      updatedAt?: any | undefined;
      season: { __typename?: 'Season'; id: string; name: string };
      league: { __typename?: 'League'; id: string; name: string };
      stats?:
        | {
            __typename?: 'InventoryStats';
            available?: number | undefined;
            availableValue?: number | undefined;
            blocked?: number | undefined;
            blockedValue?: number | undefined;
            offered?: number | undefined;
            offeredValue?: number | undefined;
            sold?: number | undefined;
            soldRevenue?: number | undefined;
            soldValue?: number | undefined;
            total?: number | undefined;
            totalValue?: number | undefined;
            capacityUtilization?: number | undefined;
            discount?: number | undefined;
          }
        | undefined;
      matchStats?:
        | Array<{
            __typename?: 'InventoryMatchStats';
            available: number;
            availableValue: number;
            blocked: number;
            blockedValue: number;
            offered: number;
            offeredValue: number;
            sold: number;
            soldRevenue: number;
            soldValue: number;
            soldBySeason: number;
            discount: number;
            match: { __typename?: 'Match'; id: string };
          }>
        | undefined;
    }>;
    tasks?:
      | {
          __typename?: 'InventoryTasks';
          contracts: Array<{
            __typename?: 'MatchTaskContract';
            id: string;
            partner: { __typename?: 'Partner'; id: string; companyName?: string | undefined };
            stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
            tasks: Array<{
              __typename?: 'Task';
              id: string;
              name?: string | undefined;
              note?: string | undefined;
              type: TaskType;
              totalTasks: number;
              customAmount?: number | undefined;
              contractItemAmount?: number | undefined;
              createdAt?: any | undefined;
              updatedAt?: any | undefined;
              match?: { __typename?: 'Match'; id: string; name: string } | undefined;
              season: { __typename?: 'Season'; id: string; name: string };
              subTasks: Array<{
                __typename?: 'SubTask';
                id: string;
                status: TaskStatus;
                count: number;
                note?: string | undefined;
                deadline?: any | undefined;
                createdAt?: any | undefined;
                lead?: { __typename?: 'TaskLead'; id: string; type: TaskLeadType } | undefined;
                match?: { __typename?: 'Match'; id: string; name: string; date: any } | undefined;
                lastEdit: {
                  __typename?: 'LastEdit';
                  timestamp: any;
                  user: { __typename?: 'LastUser'; id: string };
                };
              }>;
              lastEdit: {
                __typename?: 'LastEdit';
                timestamp: any;
                user: { __typename?: 'LastUser'; id: string };
              };
              stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
            }>;
          }>;
          stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
        }
      | undefined;
  };
};

export type InvoiceExportsQueryVariables = Exact<{ [key: string]: never }>;

export type InvoiceExportsQuery = {
  __typename?: 'Query';
  invoiceExports: {
    __typename?: 'PaginatedInvoiceExport';
    edges: Array<{
      __typename?: 'InvoiceExportEdge';
      node: {
        __typename?: 'InvoiceExport';
        id: string;
        zipFile: string;
        createdAt?: any | undefined;
        exportedBy: { __typename?: 'User'; id: string };
        invoices: Array<{
          __typename?: 'ExportedInvoice';
          id: string;
          seasonId: string;
          contractId: string;
          companyName: string;
          invoiceId: string;
          invoiceDate: any;
          invoiceNumber: number;
          docType: InvoiceDocType;
          amount: number;
          type: InvoiceType;
          pdf: string;
          fileExists: boolean;
        }>;
      };
    }>;
  };
};

export type ConvertToPdfMutationVariables = Exact<{
  contractId: Scalars['String']['input'];
  file: Scalars['Upload']['input'];
  invoiceId: Scalars['String']['input'];
  docType?: InputMaybe<InvoiceDocType>;
  datevCancellationLines?: InputMaybe<Scalars['String']['input']>;
  datevInvoiceLines?: InputMaybe<Scalars['String']['input']>;
}>;

export type ConvertToPdfMutation = { __typename?: 'Mutation'; convertToPdf: string };

export type CreateInvoiceExportMutationVariables = Exact<{ [key: string]: never }>;

export type CreateInvoiceExportMutation = {
  __typename?: 'Mutation';
  createInvoiceExport?:
    | {
        __typename?: 'InvoiceExport';
        id: string;
        zipFile: string;
        createdAt?: any | undefined;
        exportedBy: { __typename?: 'User'; id: string };
        invoices: Array<{ __typename?: 'ExportedInvoice'; id: string }>;
      }
    | undefined;
};

export type AdminLeaguesQueryVariables = Exact<{
  accountId: Scalars['String']['input'];
}>;

export type AdminLeaguesQuery = {
  __typename?: 'Query';
  adminLeagues: {
    __typename?: 'PaginatedLeague';
    edges: Array<{
      __typename?: 'LeagueEdge';
      node: {
        __typename?: 'League';
        id: string;
        name: string;
        competitionId?: string | undefined;
        createdAt?: any | undefined;
        updatedAt?: any | undefined;
        deletedAt?: any | undefined;
      };
    }>;
  };
};

export type AdminCreateLeagueMutationVariables = Exact<{
  input: AdminCreateLeagueInput;
}>;

export type AdminCreateLeagueMutation = {
  __typename?: 'Mutation';
  adminCreateLeague: {
    __typename?: 'League';
    id: string;
    name: string;
    competitionId?: string | undefined;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    deletedAt?: any | undefined;
  };
};

export type AdminRemoveLeagueMutationVariables = Exact<{
  accountId: Scalars['String']['input'];
  id: Scalars['String']['input'];
}>;

export type AdminRemoveLeagueMutation = { __typename?: 'Mutation'; adminRemoveLeague: boolean };

export type AdminUpdateLeagueMutationVariables = Exact<{
  accountId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  input: UpdateLeagueInput;
}>;

export type AdminUpdateLeagueMutation = {
  __typename?: 'Mutation';
  adminUpdateLeague: {
    __typename?: 'League';
    id: string;
    name: string;
    competitionId?: string | undefined;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    deletedAt?: any | undefined;
  };
};

export type LeaguesQueryVariables = Exact<{
  pageSize?: InputMaybe<Scalars['Float']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
}>;

export type LeaguesQuery = {
  __typename?: 'Query';
  leagues: {
    __typename?: 'PaginatedLeague';
    edges: Array<{
      __typename?: 'LeagueEdge';
      node: {
        __typename?: 'League';
        id: string;
        name: string;
        competitionId?: string | undefined;
        createdAt?: any | undefined;
        updatedAt?: any | undefined;
        deletedAt?: any | undefined;
      };
    }>;
  };
};

export type CompetitionLeagueFragmentFragment = {
  __typename?: 'CompetitionLeague';
  competitionId: string;
  leagueId: string;
  createdAt?: any | undefined;
  updatedAt?: any | undefined;
};

export type LeagueQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type LeagueQuery = {
  __typename?: 'Query';
  league: {
    __typename?: 'League';
    id: string;
    name: string;
    competitionId?: string | undefined;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    deletedAt?: any | undefined;
  };
};

export type LeagueFragmentFragment = {
  __typename?: 'League';
  id: string;
  name: string;
  competitionId?: string | undefined;
  createdAt?: any | undefined;
  updatedAt?: any | undefined;
  deletedAt?: any | undefined;
};

export type AcquireTokenMutationVariables = Exact<{
  token?: InputMaybe<Scalars['String']['input']>;
  stealLogin?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type AcquireTokenMutation = { __typename?: 'Mutation'; acquireLoginToken: string };

export type ReleaseTokenMutationVariables = Exact<{ [key: string]: never }>;

export type ReleaseTokenMutation = { __typename?: 'Mutation'; releaseLoginToken: boolean };

export type PartnersQueryVariables = Exact<{
  seasonId: Scalars['String']['input'];
  leagueIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  relevance?: InputMaybe<PartnerRelevanceFilter>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<PartnerStatus>;
  partnerCategoryId?: InputMaybe<Scalars['String']['input']>;
  clubLeadIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  salesLeadIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  expiringOnly?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type PartnersQuery = {
  __typename?: 'Query';
  partners: {
    __typename?: 'PaginatedPartner';
    pageInfo?:
      | {
          __typename?: 'PageInfo';
          endCursor?: string | undefined;
          hasNext: boolean;
          startCursor?: string | undefined;
          totalCount: number;
        }
      | undefined;
    edges: Array<{
      __typename?: 'PartnerEdge';
      node: {
        __typename?: 'Partner';
        id: string;
        status?: PartnerStatus | undefined;
        contractStatus?: PartnerContractStatus | undefined;
        customerNumber?: string | undefined;
        organizationType?: OrganizationType | undefined;
        country?: string | undefined;
        city?: string | undefined;
        zipCode?: string | undefined;
        street?: string | undefined;
        website?: string | undefined;
        companyName?: string | undefined;
        partnerSince?: any | undefined;
        createdAt?: any | undefined;
        updatedAt?: any | undefined;
        note?: string | undefined;
        notes?:
          | Array<{
              __typename?: 'PartnerNote';
              id: string;
              content: string;
              createdAt?: any | undefined;
              updatedAt?: any | undefined;
              author: { __typename?: 'Author'; id: string; email: string };
            }>
          | undefined;
        category?: { __typename?: 'PartnerCategory'; id: string; name: string } | undefined;
        clubLead?: { __typename?: 'User'; id: string } | undefined;
        contractClubLeads?: Array<{ __typename?: 'User'; id: string }> | undefined;
        salesLead?: { __typename?: 'User'; id: string } | undefined;
        contractSalesLeads?: Array<{ __typename?: 'User'; id: string }> | undefined;
        contacts?:
          | Array<{
              __typename?: 'PartnerContact';
              id: string;
              name: string;
              email?: string | undefined;
              position?: string | undefined;
              roles: Array<ContactRole>;
              phoneNumber?: string | undefined;
              phoneNumber2?: string | undefined;
              externalId?: string | undefined;
            }>
          | undefined;
        contracts?:
          | Array<{
              __typename?: 'Contract';
              id: string;
              name?: string | undefined;
              draft: boolean;
              type: ContractType;
              probability: number;
              startDate?: any | undefined;
              endDate?: any | undefined;
              invoicesDisabled: boolean;
              createdAt?: any | undefined;
              updatedAt?: any | undefined;
              canceledAt?: any | undefined;
              note?: string | undefined;
              autoRenew?:
                | {
                    __typename?: 'ContractAutoRenew';
                    enabled: boolean;
                    mode: AutoRenewMode;
                    markedForCancellationAt?: any | undefined;
                    cancellationPeriodEnd?: any | undefined;
                    renewYears: number;
                  }
                | undefined;
              notes?:
                | Array<{
                    __typename?: 'ContractNote';
                    id: string;
                    content: string;
                    createdAt?: any | undefined;
                    updatedAt?: any | undefined;
                    author: { __typename?: 'Author'; id: string; email: string };
                  }>
                | undefined;
              partner: {
                __typename?: 'Partner';
                id: string;
                companyName?: string | undefined;
                category?: { __typename?: 'PartnerCategory'; id: string; name: string } | undefined;
              };
              salesLead?: { __typename?: 'User'; id: string } | undefined;
              clubLead?: { __typename?: 'User'; id: string } | undefined;
              items: Array<{
                __typename?: 'ContractItem';
                id: string;
                amount: number;
                locked: boolean;
                listUnitPrice: number;
                price: number;
                barter: number;
                league: { __typename?: 'League'; id: string; name: string };
                season: { __typename?: 'Season'; id: string; name: string };
                match?: { __typename?: 'Match'; id: string; name: string } | undefined;
                inventory: {
                  __typename?: 'Inventory';
                  id: string;
                  name: string;
                  unit: UnitType;
                  topCategory?: { __typename?: 'TopCategory'; id: string; name: string } | undefined;
                  listPrices: Array<{ __typename?: 'InventoryListPrice'; id: string; type: InventoryType }>;
                };
              }>;
              bonuses: Array<{
                __typename?: 'ContractBonus';
                id: string;
                price: number;
                achieved: boolean;
                bonusId: string;
                bonusName: string;
                bonusDescription?: string | undefined;
                season: { __typename?: 'Season'; id: string; name: string };
              }>;
              invoices: Array<{
                __typename?: 'Invoice';
                id: string;
                type: InvoiceType;
                percentage?: number | undefined;
                invoiceNumber?: number | undefined;
                cancellationNumber?: number | undefined;
                date: any;
                invoicedAt?: any | undefined;
                serviceStartedAt?: any | undefined;
                serviceEndedAt?: any | undefined;
                paymentDueAt?: any | undefined;
                payedAt?: any | undefined;
                canceledAt?: any | undefined;
                bonusId?: string | undefined;
                season: { __typename?: 'Season'; id: string; name: string };
              }>;
              total: {
                __typename?: 'ContractTotals';
                barter: number;
                discount: number;
                listPrice: number;
                price: number;
              };
            }>
          | undefined;
        stats?:
          | {
              __typename?: 'ContractStats';
              bonuses: number;
              bonusesValue: number;
              bonusesAchieved: number;
              bonusesAchievedValue: number;
              discount: number;
              totalBarter: number;
              totalContracts: number;
              totalPrice: number;
            }
          | undefined;
      };
    }>;
    totals: {
      __typename?: 'PartnerStats';
      totalPartners: number;
      totalLeads: number;
      bonuses: number;
      bonusesValue: number;
      bonusesAchieved: number;
      bonusesAchievedValue: number;
      discount: number;
      totalBarter: number;
      totalContracts: number;
      totalPrice: number;
      totalDone?: number | undefined;
      totalInProgress?: number | undefined;
      totalOpen?: number | undefined;
      totalSubTasks?: number | undefined;
      totalTasks?: number | undefined;
    };
  };
};

export type CreatePartnerContactMutationVariables = Exact<{
  input: CreatePartnerContactInput;
  partnerId: Scalars['String']['input'];
}>;

export type CreatePartnerContactMutation = {
  __typename?: 'Mutation';
  createPartnerContact: {
    __typename?: 'PartnerContact';
    id: string;
    name: string;
    email?: string | undefined;
    position?: string | undefined;
    roles: Array<ContactRole>;
    phoneNumber?: string | undefined;
    phoneNumber2?: string | undefined;
    externalId?: string | undefined;
  };
};

export type PartnerContactFragmentFragment = {
  __typename?: 'PartnerContact';
  id: string;
  name: string;
  email?: string | undefined;
  position?: string | undefined;
  roles: Array<ContactRole>;
  phoneNumber?: string | undefined;
  phoneNumber2?: string | undefined;
  externalId?: string | undefined;
};

export type RemovePartnerContactMutationVariables = Exact<{
  contactId: Scalars['String']['input'];
  partnerId: Scalars['String']['input'];
}>;

export type RemovePartnerContactMutation = { __typename?: 'Mutation'; removePartnerContact: boolean };

export type UpdatePartnerContactMutationVariables = Exact<{
  contactId: Scalars['String']['input'];
  input: UpdatePartnerContactInput;
  partnerId: Scalars['String']['input'];
}>;

export type UpdatePartnerContactMutation = {
  __typename?: 'Mutation';
  updatePartnerContact: {
    __typename?: 'PartnerContact';
    id: string;
    name: string;
    email?: string | undefined;
    position?: string | undefined;
    roles: Array<ContactRole>;
    phoneNumber?: string | undefined;
    phoneNumber2?: string | undefined;
    externalId?: string | undefined;
  };
};

export type CreatePartnerMutationVariables = Exact<{
  input: CreatePartnerInput;
}>;

export type CreatePartnerMutation = {
  __typename?: 'Mutation';
  createPartner: {
    __typename?: 'Partner';
    id: string;
    status?: PartnerStatus | undefined;
    contractStatus?: PartnerContractStatus | undefined;
    customerNumber?: string | undefined;
    organizationType?: OrganizationType | undefined;
    country?: string | undefined;
    city?: string | undefined;
    zipCode?: string | undefined;
    street?: string | undefined;
    website?: string | undefined;
    companyName?: string | undefined;
    partnerSince?: any | undefined;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    note?: string | undefined;
    notes?:
      | Array<{
          __typename?: 'PartnerNote';
          id: string;
          content: string;
          createdAt?: any | undefined;
          updatedAt?: any | undefined;
          author: { __typename?: 'Author'; id: string; email: string };
        }>
      | undefined;
    category?: { __typename?: 'PartnerCategory'; id: string; name: string } | undefined;
    clubLead?: { __typename?: 'User'; id: string } | undefined;
    contractClubLeads?: Array<{ __typename?: 'User'; id: string }> | undefined;
    salesLead?: { __typename?: 'User'; id: string } | undefined;
    contractSalesLeads?: Array<{ __typename?: 'User'; id: string }> | undefined;
    contacts?:
      | Array<{
          __typename?: 'PartnerContact';
          id: string;
          name: string;
          email?: string | undefined;
          position?: string | undefined;
          roles: Array<ContactRole>;
          phoneNumber?: string | undefined;
          phoneNumber2?: string | undefined;
          externalId?: string | undefined;
        }>
      | undefined;
    contracts?:
      | Array<{
          __typename?: 'Contract';
          id: string;
          name?: string | undefined;
          draft: boolean;
          type: ContractType;
          probability: number;
          startDate?: any | undefined;
          endDate?: any | undefined;
          invoicesDisabled: boolean;
          createdAt?: any | undefined;
          updatedAt?: any | undefined;
          canceledAt?: any | undefined;
          note?: string | undefined;
          autoRenew?:
            | {
                __typename?: 'ContractAutoRenew';
                enabled: boolean;
                mode: AutoRenewMode;
                markedForCancellationAt?: any | undefined;
                cancellationPeriodEnd?: any | undefined;
                renewYears: number;
              }
            | undefined;
          notes?:
            | Array<{
                __typename?: 'ContractNote';
                id: string;
                content: string;
                createdAt?: any | undefined;
                updatedAt?: any | undefined;
                author: { __typename?: 'Author'; id: string; email: string };
              }>
            | undefined;
          partner: {
            __typename?: 'Partner';
            id: string;
            companyName?: string | undefined;
            category?: { __typename?: 'PartnerCategory'; id: string; name: string } | undefined;
          };
          salesLead?: { __typename?: 'User'; id: string } | undefined;
          clubLead?: { __typename?: 'User'; id: string } | undefined;
          items: Array<{
            __typename?: 'ContractItem';
            id: string;
            amount: number;
            locked: boolean;
            listUnitPrice: number;
            price: number;
            barter: number;
            league: { __typename?: 'League'; id: string; name: string };
            season: { __typename?: 'Season'; id: string; name: string };
            match?: { __typename?: 'Match'; id: string; name: string } | undefined;
            inventory: {
              __typename?: 'Inventory';
              id: string;
              name: string;
              unit: UnitType;
              topCategory?: { __typename?: 'TopCategory'; id: string; name: string } | undefined;
              listPrices: Array<{ __typename?: 'InventoryListPrice'; id: string; type: InventoryType }>;
            };
          }>;
          bonuses: Array<{
            __typename?: 'ContractBonus';
            id: string;
            price: number;
            achieved: boolean;
            bonusId: string;
            bonusName: string;
            bonusDescription?: string | undefined;
            season: { __typename?: 'Season'; id: string; name: string };
          }>;
          invoices: Array<{
            __typename?: 'Invoice';
            id: string;
            type: InvoiceType;
            percentage?: number | undefined;
            invoiceNumber?: number | undefined;
            cancellationNumber?: number | undefined;
            date: any;
            invoicedAt?: any | undefined;
            serviceStartedAt?: any | undefined;
            serviceEndedAt?: any | undefined;
            paymentDueAt?: any | undefined;
            payedAt?: any | undefined;
            canceledAt?: any | undefined;
            bonusId?: string | undefined;
            season: { __typename?: 'Season'; id: string; name: string };
          }>;
          total: {
            __typename?: 'ContractTotals';
            barter: number;
            discount: number;
            listPrice: number;
            price: number;
          };
        }>
      | undefined;
    stats?:
      | {
          __typename?: 'ContractStats';
          bonuses: number;
          bonusesValue: number;
          bonusesAchieved: number;
          bonusesAchievedValue: number;
          discount: number;
          totalBarter: number;
          totalContracts: number;
          totalPrice: number;
        }
      | undefined;
  };
};

export type PartnerExportQueryVariables = Exact<{
  partnerId: Scalars['String']['input'];
}>;

export type PartnerExportQuery = {
  __typename?: 'Query';
  partnerExport: {
    __typename?: 'Partner';
    id: string;
    status?: PartnerStatus | undefined;
    contractStatus?: PartnerContractStatus | undefined;
    customerNumber?: string | undefined;
    organizationType?: OrganizationType | undefined;
    country?: string | undefined;
    city?: string | undefined;
    zipCode?: string | undefined;
    street?: string | undefined;
    website?: string | undefined;
    companyName?: string | undefined;
    partnerSince?: any | undefined;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    note?: string | undefined;
    notes?:
      | Array<{
          __typename?: 'PartnerNote';
          id: string;
          content: string;
          createdAt?: any | undefined;
          updatedAt?: any | undefined;
          author: { __typename?: 'Author'; id: string; email: string };
        }>
      | undefined;
    category?: { __typename?: 'PartnerCategory'; id: string; name: string } | undefined;
    clubLead?: { __typename?: 'User'; id: string } | undefined;
    contractClubLeads?: Array<{ __typename?: 'User'; id: string }> | undefined;
    salesLead?: { __typename?: 'User'; id: string } | undefined;
    contractSalesLeads?: Array<{ __typename?: 'User'; id: string }> | undefined;
    contacts?:
      | Array<{
          __typename?: 'PartnerContact';
          id: string;
          name: string;
          email?: string | undefined;
          position?: string | undefined;
          roles: Array<ContactRole>;
          phoneNumber?: string | undefined;
          phoneNumber2?: string | undefined;
          externalId?: string | undefined;
        }>
      | undefined;
    contracts?:
      | Array<{
          __typename?: 'Contract';
          id: string;
          name?: string | undefined;
          draft: boolean;
          type: ContractType;
          probability: number;
          startDate?: any | undefined;
          endDate?: any | undefined;
          invoicesDisabled: boolean;
          createdAt?: any | undefined;
          updatedAt?: any | undefined;
          canceledAt?: any | undefined;
          note?: string | undefined;
          autoRenew?:
            | {
                __typename?: 'ContractAutoRenew';
                enabled: boolean;
                mode: AutoRenewMode;
                markedForCancellationAt?: any | undefined;
                cancellationPeriodEnd?: any | undefined;
                renewYears: number;
              }
            | undefined;
          notes?:
            | Array<{
                __typename?: 'ContractNote';
                id: string;
                content: string;
                createdAt?: any | undefined;
                updatedAt?: any | undefined;
                author: { __typename?: 'Author'; id: string; email: string };
              }>
            | undefined;
          partner: {
            __typename?: 'Partner';
            id: string;
            companyName?: string | undefined;
            category?: { __typename?: 'PartnerCategory'; id: string; name: string } | undefined;
          };
          salesLead?: { __typename?: 'User'; id: string } | undefined;
          clubLead?: { __typename?: 'User'; id: string } | undefined;
          items: Array<{
            __typename?: 'ContractItem';
            id: string;
            amount: number;
            locked: boolean;
            listUnitPrice: number;
            price: number;
            barter: number;
            league: { __typename?: 'League'; id: string; name: string };
            season: { __typename?: 'Season'; id: string; name: string };
            match?: { __typename?: 'Match'; id: string; name: string } | undefined;
            inventory: {
              __typename?: 'Inventory';
              id: string;
              name: string;
              unit: UnitType;
              topCategory?: { __typename?: 'TopCategory'; id: string; name: string } | undefined;
              listPrices: Array<{ __typename?: 'InventoryListPrice'; id: string; type: InventoryType }>;
            };
          }>;
          bonuses: Array<{
            __typename?: 'ContractBonus';
            id: string;
            price: number;
            achieved: boolean;
            bonusId: string;
            bonusName: string;
            bonusDescription?: string | undefined;
            season: { __typename?: 'Season'; id: string; name: string };
          }>;
          invoices: Array<{
            __typename?: 'Invoice';
            id: string;
            type: InvoiceType;
            percentage?: number | undefined;
            invoiceNumber?: number | undefined;
            cancellationNumber?: number | undefined;
            date: any;
            invoicedAt?: any | undefined;
            serviceStartedAt?: any | undefined;
            serviceEndedAt?: any | undefined;
            paymentDueAt?: any | undefined;
            payedAt?: any | undefined;
            canceledAt?: any | undefined;
            bonusId?: string | undefined;
            season: { __typename?: 'Season'; id: string; name: string };
          }>;
          total: {
            __typename?: 'ContractTotals';
            barter: number;
            discount: number;
            listPrice: number;
            price: number;
          };
        }>
      | undefined;
    stats?:
      | {
          __typename?: 'ContractStats';
          bonuses: number;
          bonusesValue: number;
          bonusesAchieved: number;
          bonusesAchievedValue: number;
          discount: number;
          totalBarter: number;
          totalContracts: number;
          totalPrice: number;
        }
      | undefined;
  };
};

export type PartnerQueryVariables = Exact<{
  partnerId: Scalars['String']['input'];
}>;

export type PartnerQuery = {
  __typename?: 'Query';
  partner: {
    __typename?: 'Partner';
    id: string;
    status?: PartnerStatus | undefined;
    contractStatus?: PartnerContractStatus | undefined;
    customerNumber?: string | undefined;
    organizationType?: OrganizationType | undefined;
    country?: string | undefined;
    city?: string | undefined;
    zipCode?: string | undefined;
    street?: string | undefined;
    website?: string | undefined;
    companyName?: string | undefined;
    partnerSince?: any | undefined;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    note?: string | undefined;
    notes?:
      | Array<{
          __typename?: 'PartnerNote';
          id: string;
          content: string;
          createdAt?: any | undefined;
          updatedAt?: any | undefined;
          author: { __typename?: 'Author'; id: string; email: string };
        }>
      | undefined;
    category?: { __typename?: 'PartnerCategory'; id: string; name: string } | undefined;
    clubLead?: { __typename?: 'User'; id: string } | undefined;
    contractClubLeads?: Array<{ __typename?: 'User'; id: string }> | undefined;
    salesLead?: { __typename?: 'User'; id: string } | undefined;
    contractSalesLeads?: Array<{ __typename?: 'User'; id: string }> | undefined;
    contacts?:
      | Array<{
          __typename?: 'PartnerContact';
          id: string;
          name: string;
          email?: string | undefined;
          position?: string | undefined;
          roles: Array<ContactRole>;
          phoneNumber?: string | undefined;
          phoneNumber2?: string | undefined;
          externalId?: string | undefined;
        }>
      | undefined;
    contracts?:
      | Array<{
          __typename?: 'Contract';
          id: string;
          name?: string | undefined;
          draft: boolean;
          type: ContractType;
          probability: number;
          startDate?: any | undefined;
          endDate?: any | undefined;
          invoicesDisabled: boolean;
          createdAt?: any | undefined;
          updatedAt?: any | undefined;
          canceledAt?: any | undefined;
          note?: string | undefined;
          autoRenew?:
            | {
                __typename?: 'ContractAutoRenew';
                enabled: boolean;
                mode: AutoRenewMode;
                markedForCancellationAt?: any | undefined;
                cancellationPeriodEnd?: any | undefined;
                renewYears: number;
              }
            | undefined;
          notes?:
            | Array<{
                __typename?: 'ContractNote';
                id: string;
                content: string;
                createdAt?: any | undefined;
                updatedAt?: any | undefined;
                author: { __typename?: 'Author'; id: string; email: string };
              }>
            | undefined;
          partner: {
            __typename?: 'Partner';
            id: string;
            companyName?: string | undefined;
            category?: { __typename?: 'PartnerCategory'; id: string; name: string } | undefined;
          };
          salesLead?: { __typename?: 'User'; id: string } | undefined;
          clubLead?: { __typename?: 'User'; id: string } | undefined;
          items: Array<{
            __typename?: 'ContractItem';
            id: string;
            amount: number;
            locked: boolean;
            listUnitPrice: number;
            price: number;
            barter: number;
            league: { __typename?: 'League'; id: string; name: string };
            season: { __typename?: 'Season'; id: string; name: string };
            match?: { __typename?: 'Match'; id: string; name: string } | undefined;
            inventory: {
              __typename?: 'Inventory';
              id: string;
              name: string;
              unit: UnitType;
              topCategory?: { __typename?: 'TopCategory'; id: string; name: string } | undefined;
              listPrices: Array<{ __typename?: 'InventoryListPrice'; id: string; type: InventoryType }>;
            };
          }>;
          bonuses: Array<{
            __typename?: 'ContractBonus';
            id: string;
            price: number;
            achieved: boolean;
            bonusId: string;
            bonusName: string;
            bonusDescription?: string | undefined;
            season: { __typename?: 'Season'; id: string; name: string };
          }>;
          invoices: Array<{
            __typename?: 'Invoice';
            id: string;
            type: InvoiceType;
            percentage?: number | undefined;
            invoiceNumber?: number | undefined;
            cancellationNumber?: number | undefined;
            date: any;
            invoicedAt?: any | undefined;
            serviceStartedAt?: any | undefined;
            serviceEndedAt?: any | undefined;
            paymentDueAt?: any | undefined;
            payedAt?: any | undefined;
            canceledAt?: any | undefined;
            bonusId?: string | undefined;
            season: { __typename?: 'Season'; id: string; name: string };
          }>;
          total: {
            __typename?: 'ContractTotals';
            barter: number;
            discount: number;
            listPrice: number;
            price: number;
          };
        }>
      | undefined;
    stats?:
      | {
          __typename?: 'ContractStats';
          bonuses: number;
          bonusesValue: number;
          bonusesAchieved: number;
          bonusesAchievedValue: number;
          discount: number;
          totalBarter: number;
          totalContracts: number;
          totalPrice: number;
        }
      | undefined;
  };
};

export type CreatePartnerNoteMutationVariables = Exact<{
  input: CreatePartnerNoteInput;
  partnerId: Scalars['String']['input'];
}>;

export type CreatePartnerNoteMutation = {
  __typename?: 'Mutation';
  createPartnerNote: {
    __typename?: 'PartnerNote';
    id: string;
    content: string;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    author: { __typename?: 'Author'; id: string; email: string };
  };
};

export type PartnerNoteFragmentFragment = {
  __typename?: 'PartnerNote';
  id: string;
  content: string;
  createdAt?: any | undefined;
  updatedAt?: any | undefined;
  author: { __typename?: 'Author'; id: string; email: string };
};

export type RemovePartnerNoteMutationVariables = Exact<{
  noteId: Scalars['String']['input'];
  partnerId: Scalars['String']['input'];
}>;

export type RemovePartnerNoteMutation = { __typename?: 'Mutation'; removePartnerNote: boolean };

export type UpdatePartnerNoteMutationVariables = Exact<{
  input: UpdatePartnerNoteInput;
  noteId: Scalars['String']['input'];
  partnerId: Scalars['String']['input'];
}>;

export type UpdatePartnerNoteMutation = {
  __typename?: 'Mutation';
  updatePartnerNote: {
    __typename?: 'PartnerNote';
    id: string;
    content: string;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    author: { __typename?: 'Author'; id: string; email: string };
  };
};

export type PartnerStatsFragmentFragment = {
  __typename?: 'Partner';
  stats?:
    | {
        __typename?: 'ContractStats';
        bonuses: number;
        bonusesValue: number;
        bonusesAchieved: number;
        bonusesAchievedValue: number;
        discount: number;
        totalBarter: number;
        totalContracts: number;
        totalPrice: number;
      }
    | undefined;
};

export type PartnerFragmentFragment = {
  __typename?: 'Partner';
  id: string;
  status?: PartnerStatus | undefined;
  contractStatus?: PartnerContractStatus | undefined;
  customerNumber?: string | undefined;
  organizationType?: OrganizationType | undefined;
  country?: string | undefined;
  city?: string | undefined;
  zipCode?: string | undefined;
  street?: string | undefined;
  website?: string | undefined;
  companyName?: string | undefined;
  partnerSince?: any | undefined;
  createdAt?: any | undefined;
  updatedAt?: any | undefined;
  note?: string | undefined;
  notes?:
    | Array<{
        __typename?: 'PartnerNote';
        id: string;
        content: string;
        createdAt?: any | undefined;
        updatedAt?: any | undefined;
        author: { __typename?: 'Author'; id: string; email: string };
      }>
    | undefined;
  category?: { __typename?: 'PartnerCategory'; id: string; name: string } | undefined;
  clubLead?: { __typename?: 'User'; id: string } | undefined;
  contractClubLeads?: Array<{ __typename?: 'User'; id: string }> | undefined;
  salesLead?: { __typename?: 'User'; id: string } | undefined;
  contractSalesLeads?: Array<{ __typename?: 'User'; id: string }> | undefined;
  contacts?:
    | Array<{
        __typename?: 'PartnerContact';
        id: string;
        name: string;
        email?: string | undefined;
        position?: string | undefined;
        roles: Array<ContactRole>;
        phoneNumber?: string | undefined;
        phoneNumber2?: string | undefined;
        externalId?: string | undefined;
      }>
    | undefined;
  contracts?:
    | Array<{
        __typename?: 'Contract';
        id: string;
        name?: string | undefined;
        draft: boolean;
        type: ContractType;
        probability: number;
        startDate?: any | undefined;
        endDate?: any | undefined;
        invoicesDisabled: boolean;
        createdAt?: any | undefined;
        updatedAt?: any | undefined;
        canceledAt?: any | undefined;
        note?: string | undefined;
        autoRenew?:
          | {
              __typename?: 'ContractAutoRenew';
              enabled: boolean;
              mode: AutoRenewMode;
              markedForCancellationAt?: any | undefined;
              cancellationPeriodEnd?: any | undefined;
              renewYears: number;
            }
          | undefined;
        notes?:
          | Array<{
              __typename?: 'ContractNote';
              id: string;
              content: string;
              createdAt?: any | undefined;
              updatedAt?: any | undefined;
              author: { __typename?: 'Author'; id: string; email: string };
            }>
          | undefined;
        partner: {
          __typename?: 'Partner';
          id: string;
          companyName?: string | undefined;
          category?: { __typename?: 'PartnerCategory'; id: string; name: string } | undefined;
        };
        salesLead?: { __typename?: 'User'; id: string } | undefined;
        clubLead?: { __typename?: 'User'; id: string } | undefined;
        items: Array<{
          __typename?: 'ContractItem';
          id: string;
          amount: number;
          locked: boolean;
          listUnitPrice: number;
          price: number;
          barter: number;
          league: { __typename?: 'League'; id: string; name: string };
          season: { __typename?: 'Season'; id: string; name: string };
          match?: { __typename?: 'Match'; id: string; name: string } | undefined;
          inventory: {
            __typename?: 'Inventory';
            id: string;
            name: string;
            unit: UnitType;
            topCategory?: { __typename?: 'TopCategory'; id: string; name: string } | undefined;
            listPrices: Array<{ __typename?: 'InventoryListPrice'; id: string; type: InventoryType }>;
          };
        }>;
        bonuses: Array<{
          __typename?: 'ContractBonus';
          id: string;
          price: number;
          achieved: boolean;
          bonusId: string;
          bonusName: string;
          bonusDescription?: string | undefined;
          season: { __typename?: 'Season'; id: string; name: string };
        }>;
        invoices: Array<{
          __typename?: 'Invoice';
          id: string;
          type: InvoiceType;
          percentage?: number | undefined;
          invoiceNumber?: number | undefined;
          cancellationNumber?: number | undefined;
          date: any;
          invoicedAt?: any | undefined;
          serviceStartedAt?: any | undefined;
          serviceEndedAt?: any | undefined;
          paymentDueAt?: any | undefined;
          payedAt?: any | undefined;
          canceledAt?: any | undefined;
          bonusId?: string | undefined;
          season: { __typename?: 'Season'; id: string; name: string };
        }>;
        total: {
          __typename?: 'ContractTotals';
          barter: number;
          discount: number;
          listPrice: number;
          price: number;
        };
      }>
    | undefined;
  stats?:
    | {
        __typename?: 'ContractStats';
        bonuses: number;
        bonusesValue: number;
        bonusesAchieved: number;
        bonusesAchievedValue: number;
        discount: number;
        totalBarter: number;
        totalContracts: number;
        totalPrice: number;
      }
    | undefined;
};

export type RemovePartnerMutationVariables = Exact<{
  partnerId: Scalars['String']['input'];
}>;

export type RemovePartnerMutation = { __typename?: 'Mutation'; removePartner: boolean };

export type TestPartnerNameQueryVariables = Exact<{
  companyName: Scalars['String']['input'];
}>;

export type TestPartnerNameQuery = {
  __typename?: 'Query';
  testPartnerName: Array<{ __typename?: 'Partner'; id: string; companyName?: string | undefined }>;
};

export type UpdatePartnerMutationVariables = Exact<{
  partnerId: Scalars['String']['input'];
  input: UpdatePartnerInput;
}>;

export type UpdatePartnerMutation = {
  __typename?: 'Mutation';
  updatePartner: {
    __typename?: 'Partner';
    id: string;
    status?: PartnerStatus | undefined;
    contractStatus?: PartnerContractStatus | undefined;
    customerNumber?: string | undefined;
    organizationType?: OrganizationType | undefined;
    country?: string | undefined;
    city?: string | undefined;
    zipCode?: string | undefined;
    street?: string | undefined;
    website?: string | undefined;
    companyName?: string | undefined;
    partnerSince?: any | undefined;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    note?: string | undefined;
    notes?:
      | Array<{
          __typename?: 'PartnerNote';
          id: string;
          content: string;
          createdAt?: any | undefined;
          updatedAt?: any | undefined;
          author: { __typename?: 'Author'; id: string; email: string };
        }>
      | undefined;
    category?: { __typename?: 'PartnerCategory'; id: string; name: string } | undefined;
    clubLead?: { __typename?: 'User'; id: string } | undefined;
    contractClubLeads?: Array<{ __typename?: 'User'; id: string }> | undefined;
    salesLead?: { __typename?: 'User'; id: string } | undefined;
    contractSalesLeads?: Array<{ __typename?: 'User'; id: string }> | undefined;
    contacts?:
      | Array<{
          __typename?: 'PartnerContact';
          id: string;
          name: string;
          email?: string | undefined;
          position?: string | undefined;
          roles: Array<ContactRole>;
          phoneNumber?: string | undefined;
          phoneNumber2?: string | undefined;
          externalId?: string | undefined;
        }>
      | undefined;
    contracts?:
      | Array<{
          __typename?: 'Contract';
          id: string;
          name?: string | undefined;
          draft: boolean;
          type: ContractType;
          probability: number;
          startDate?: any | undefined;
          endDate?: any | undefined;
          invoicesDisabled: boolean;
          createdAt?: any | undefined;
          updatedAt?: any | undefined;
          canceledAt?: any | undefined;
          note?: string | undefined;
          autoRenew?:
            | {
                __typename?: 'ContractAutoRenew';
                enabled: boolean;
                mode: AutoRenewMode;
                markedForCancellationAt?: any | undefined;
                cancellationPeriodEnd?: any | undefined;
                renewYears: number;
              }
            | undefined;
          notes?:
            | Array<{
                __typename?: 'ContractNote';
                id: string;
                content: string;
                createdAt?: any | undefined;
                updatedAt?: any | undefined;
                author: { __typename?: 'Author'; id: string; email: string };
              }>
            | undefined;
          partner: {
            __typename?: 'Partner';
            id: string;
            companyName?: string | undefined;
            category?: { __typename?: 'PartnerCategory'; id: string; name: string } | undefined;
          };
          salesLead?: { __typename?: 'User'; id: string } | undefined;
          clubLead?: { __typename?: 'User'; id: string } | undefined;
          items: Array<{
            __typename?: 'ContractItem';
            id: string;
            amount: number;
            locked: boolean;
            listUnitPrice: number;
            price: number;
            barter: number;
            league: { __typename?: 'League'; id: string; name: string };
            season: { __typename?: 'Season'; id: string; name: string };
            match?: { __typename?: 'Match'; id: string; name: string } | undefined;
            inventory: {
              __typename?: 'Inventory';
              id: string;
              name: string;
              unit: UnitType;
              topCategory?: { __typename?: 'TopCategory'; id: string; name: string } | undefined;
              listPrices: Array<{ __typename?: 'InventoryListPrice'; id: string; type: InventoryType }>;
            };
          }>;
          bonuses: Array<{
            __typename?: 'ContractBonus';
            id: string;
            price: number;
            achieved: boolean;
            bonusId: string;
            bonusName: string;
            bonusDescription?: string | undefined;
            season: { __typename?: 'Season'; id: string; name: string };
          }>;
          invoices: Array<{
            __typename?: 'Invoice';
            id: string;
            type: InvoiceType;
            percentage?: number | undefined;
            invoiceNumber?: number | undefined;
            cancellationNumber?: number | undefined;
            date: any;
            invoicedAt?: any | undefined;
            serviceStartedAt?: any | undefined;
            serviceEndedAt?: any | undefined;
            paymentDueAt?: any | undefined;
            payedAt?: any | undefined;
            canceledAt?: any | undefined;
            bonusId?: string | undefined;
            season: { __typename?: 'Season'; id: string; name: string };
          }>;
          total: {
            __typename?: 'ContractTotals';
            barter: number;
            discount: number;
            listPrice: number;
            price: number;
          };
        }>
      | undefined;
    stats?:
      | {
          __typename?: 'ContractStats';
          bonuses: number;
          bonusesValue: number;
          bonusesAchieved: number;
          bonusesAchievedValue: number;
          discount: number;
          totalBarter: number;
          totalContracts: number;
          totalPrice: number;
        }
      | undefined;
  };
};

export type SeasonsWithMatchesQueryVariables = Exact<{
  pageSize?: InputMaybe<Scalars['Float']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
}>;

export type SeasonsWithMatchesQuery = {
  __typename?: 'Query';
  seasons: {
    __typename?: 'PaginatedSeason';
    edges: Array<{
      __typename?: 'SeasonEdge';
      node: {
        __typename?: 'Season';
        id: string;
        name: string;
        endDate: any;
        startDate: any;
        targetRevenue: number;
        createdAt?: any | undefined;
        updatedAt?: any | undefined;
        deletedAt?: any | undefined;
        matches: Array<{
          __typename?: 'Match';
          id: string;
          name: string;
          date: any;
          createdAt?: any | undefined;
          updatedAt?: any | undefined;
          league: {
            __typename?: 'League';
            id: string;
            name: string;
            competitionId?: string | undefined;
            createdAt?: any | undefined;
            updatedAt?: any | undefined;
            deletedAt?: any | undefined;
          };
          taskStats?:
            | { __typename?: 'TaskStats'; open: number; inProgress: number; done: number }
            | undefined;
        }>;
        competitionLeagues: Array<{
          __typename?: 'CompetitionLeague';
          competitionId: string;
          leagueId: string;
          createdAt?: any | undefined;
          updatedAt?: any | undefined;
        }>;
        workflows?:
          | {
              __typename?: 'SeasonWorkflow';
              setupAt?: any | undefined;
              copySettings: boolean;
              competitionLeagues: Array<{
                __typename?: 'CompetitionLeague';
                competitionId: string;
                leagueId: string;
              }>;
            }
          | undefined;
        invoicing: {
          __typename?: 'SeasonInvoicing';
          useSeparateCounters: boolean;
          barter: { __typename?: 'SeasonInvoicingPrefix'; prefix: string };
          bonus: { __typename?: 'SeasonInvoicingPrefix'; prefix: string };
          cash: { __typename?: 'SeasonInvoicingPrefix'; prefix: string };
        };
      };
    }>;
  };
};

export type SeasonsQueryVariables = Exact<{
  pageSize?: InputMaybe<Scalars['Float']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
}>;

export type SeasonsQuery = {
  __typename?: 'Query';
  seasons: {
    __typename?: 'PaginatedSeason';
    edges: Array<{
      __typename?: 'SeasonEdge';
      node: {
        __typename?: 'Season';
        id: string;
        name: string;
        endDate: any;
        startDate: any;
        targetRevenue: number;
        createdAt?: any | undefined;
        updatedAt?: any | undefined;
        deletedAt?: any | undefined;
        competitionLeagues: Array<{
          __typename?: 'CompetitionLeague';
          competitionId: string;
          leagueId: string;
          createdAt?: any | undefined;
          updatedAt?: any | undefined;
        }>;
        workflows?:
          | {
              __typename?: 'SeasonWorkflow';
              setupAt?: any | undefined;
              copySettings: boolean;
              competitionLeagues: Array<{
                __typename?: 'CompetitionLeague';
                competitionId: string;
                leagueId: string;
              }>;
            }
          | undefined;
        invoicing: {
          __typename?: 'SeasonInvoicing';
          useSeparateCounters: boolean;
          barter: { __typename?: 'SeasonInvoicingPrefix'; prefix: string };
          bonus: { __typename?: 'SeasonInvoicingPrefix'; prefix: string };
          cash: { __typename?: 'SeasonInvoicingPrefix'; prefix: string };
        };
      };
    }>;
  };
};

export type CreateNextSeasonMutationVariables = Exact<{ [key: string]: never }>;

export type CreateNextSeasonMutation = {
  __typename?: 'Mutation';
  createNextSeason: {
    __typename?: 'Season';
    id: string;
    name: string;
    endDate: any;
    startDate: any;
    targetRevenue: number;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    deletedAt?: any | undefined;
    competitionLeagues: Array<{
      __typename?: 'CompetitionLeague';
      competitionId: string;
      leagueId: string;
      createdAt?: any | undefined;
      updatedAt?: any | undefined;
    }>;
    workflows?:
      | {
          __typename?: 'SeasonWorkflow';
          setupAt?: any | undefined;
          copySettings: boolean;
          competitionLeagues: Array<{
            __typename?: 'CompetitionLeague';
            competitionId: string;
            leagueId: string;
          }>;
        }
      | undefined;
    invoicing: {
      __typename?: 'SeasonInvoicing';
      useSeparateCounters: boolean;
      barter: { __typename?: 'SeasonInvoicingPrefix'; prefix: string };
      bonus: { __typename?: 'SeasonInvoicingPrefix'; prefix: string };
      cash: { __typename?: 'SeasonInvoicingPrefix'; prefix: string };
    };
  };
};

export type CreateSeasonMutationVariables = Exact<{
  input: CreateSeasonInput;
}>;

export type CreateSeasonMutation = {
  __typename?: 'Mutation';
  createSeason: {
    __typename?: 'Season';
    id: string;
    name: string;
    endDate: any;
    startDate: any;
    targetRevenue: number;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    deletedAt?: any | undefined;
    competitionLeagues: Array<{
      __typename?: 'CompetitionLeague';
      competitionId: string;
      leagueId: string;
      createdAt?: any | undefined;
      updatedAt?: any | undefined;
    }>;
    workflows?:
      | {
          __typename?: 'SeasonWorkflow';
          setupAt?: any | undefined;
          copySettings: boolean;
          competitionLeagues: Array<{
            __typename?: 'CompetitionLeague';
            competitionId: string;
            leagueId: string;
          }>;
        }
      | undefined;
    invoicing: {
      __typename?: 'SeasonInvoicing';
      useSeparateCounters: boolean;
      barter: { __typename?: 'SeasonInvoicingPrefix'; prefix: string };
      bonus: { __typename?: 'SeasonInvoicingPrefix'; prefix: string };
      cash: { __typename?: 'SeasonInvoicingPrefix'; prefix: string };
    };
  };
};

export type SeasonQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type SeasonQuery = {
  __typename?: 'Query';
  season: {
    __typename?: 'Season';
    id: string;
    name: string;
    endDate: any;
    startDate: any;
    targetRevenue: number;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    deletedAt?: any | undefined;
    matches: Array<{
      __typename?: 'Match';
      id: string;
      name: string;
      date: any;
      createdAt?: any | undefined;
      updatedAt?: any | undefined;
      league: {
        __typename?: 'League';
        id: string;
        name: string;
        competitionId?: string | undefined;
        createdAt?: any | undefined;
        updatedAt?: any | undefined;
        deletedAt?: any | undefined;
      };
      taskStats?: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number } | undefined;
    }>;
    competitionLeagues: Array<{
      __typename?: 'CompetitionLeague';
      competitionId: string;
      leagueId: string;
      createdAt?: any | undefined;
      updatedAt?: any | undefined;
    }>;
    workflows?:
      | {
          __typename?: 'SeasonWorkflow';
          setupAt?: any | undefined;
          copySettings: boolean;
          competitionLeagues: Array<{
            __typename?: 'CompetitionLeague';
            competitionId: string;
            leagueId: string;
          }>;
        }
      | undefined;
    invoicing: {
      __typename?: 'SeasonInvoicing';
      useSeparateCounters: boolean;
      barter: { __typename?: 'SeasonInvoicingPrefix'; prefix: string };
      bonus: { __typename?: 'SeasonInvoicingPrefix'; prefix: string };
      cash: { __typename?: 'SeasonInvoicingPrefix'; prefix: string };
    };
  };
};

export type CreateSeasonMatchMutationVariables = Exact<{
  input: CreateSeasonMatchInput;
  seasonId: Scalars['String']['input'];
}>;

export type CreateSeasonMatchMutation = {
  __typename?: 'Mutation';
  createSeasonMatch: {
    __typename?: 'Match';
    id: string;
    name: string;
    date: any;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    league: {
      __typename?: 'League';
      id: string;
      name: string;
      competitionId?: string | undefined;
      createdAt?: any | undefined;
      updatedAt?: any | undefined;
      deletedAt?: any | undefined;
    };
    taskStats?: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number } | undefined;
  };
};

export type MatchFragmentFragment = {
  __typename?: 'Match';
  id: string;
  name: string;
  date: any;
  createdAt?: any | undefined;
  updatedAt?: any | undefined;
  league: {
    __typename?: 'League';
    id: string;
    name: string;
    competitionId?: string | undefined;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    deletedAt?: any | undefined;
  };
  taskStats?: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number } | undefined;
};

export type RemoveMatchMutationVariables = Exact<{
  matchId: Scalars['String']['input'];
  seasonId: Scalars['String']['input'];
}>;

export type RemoveMatchMutation = { __typename?: 'Mutation'; removeSeasonMatch: boolean };

export type UpdateMatchMutationVariables = Exact<{
  input: UpdateSeasonMatchInput;
  matchId: Scalars['String']['input'];
  seasonId: Scalars['String']['input'];
}>;

export type UpdateMatchMutation = {
  __typename?: 'Mutation';
  updateSeasonMatch: {
    __typename?: 'Match';
    id: string;
    name: string;
    date: any;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    league: {
      __typename?: 'League';
      id: string;
      name: string;
      competitionId?: string | undefined;
      createdAt?: any | undefined;
      updatedAt?: any | undefined;
      deletedAt?: any | undefined;
    };
    taskStats?: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number } | undefined;
  };
};

export type SeasonFragmentFragment = {
  __typename?: 'Season';
  id: string;
  name: string;
  endDate: any;
  startDate: any;
  targetRevenue: number;
  createdAt?: any | undefined;
  updatedAt?: any | undefined;
  deletedAt?: any | undefined;
  competitionLeagues: Array<{
    __typename?: 'CompetitionLeague';
    competitionId: string;
    leagueId: string;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
  }>;
  workflows?:
    | {
        __typename?: 'SeasonWorkflow';
        setupAt?: any | undefined;
        copySettings: boolean;
        competitionLeagues: Array<{
          __typename?: 'CompetitionLeague';
          competitionId: string;
          leagueId: string;
        }>;
      }
    | undefined;
  invoicing: {
    __typename?: 'SeasonInvoicing';
    useSeparateCounters: boolean;
    barter: { __typename?: 'SeasonInvoicingPrefix'; prefix: string };
    bonus: { __typename?: 'SeasonInvoicingPrefix'; prefix: string };
    cash: { __typename?: 'SeasonInvoicingPrefix'; prefix: string };
  };
};

export type UpdateSeasonMutationVariables = Exact<{
  seasonId: Scalars['String']['input'];
  input: UpdateSeasonInput;
}>;

export type UpdateSeasonMutation = {
  __typename?: 'Mutation';
  updateSeason: {
    __typename?: 'Season';
    id: string;
    name: string;
    endDate: any;
    startDate: any;
    targetRevenue: number;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    deletedAt?: any | undefined;
    competitionLeagues: Array<{
      __typename?: 'CompetitionLeague';
      competitionId: string;
      leagueId: string;
      createdAt?: any | undefined;
      updatedAt?: any | undefined;
    }>;
    workflows?:
      | {
          __typename?: 'SeasonWorkflow';
          setupAt?: any | undefined;
          copySettings: boolean;
          competitionLeagues: Array<{
            __typename?: 'CompetitionLeague';
            competitionId: string;
            leagueId: string;
          }>;
        }
      | undefined;
    invoicing: {
      __typename?: 'SeasonInvoicing';
      useSeparateCounters: boolean;
      barter: { __typename?: 'SeasonInvoicingPrefix'; prefix: string };
      bonus: { __typename?: 'SeasonInvoicingPrefix'; prefix: string };
      cash: { __typename?: 'SeasonInvoicingPrefix'; prefix: string };
    };
  };
};

export type ConfigureSeasonWorkflowMutationVariables = Exact<{
  competitionLeagues: Array<CompetitionLeagueInput> | CompetitionLeagueInput;
  copySettings: Scalars['Boolean']['input'];
  seasonId: Scalars['String']['input'];
}>;

export type ConfigureSeasonWorkflowMutation = { __typename?: 'Mutation'; configureSeasonWorkflow: boolean };

export type CreateSubTaskMutationVariables = Exact<{
  taskId: Scalars['String']['input'];
  input: CreateSubTaskInput;
}>;

export type CreateSubTaskMutation = {
  __typename?: 'Mutation';
  createSubTask: {
    __typename?: 'SubTask';
    id: string;
    status: TaskStatus;
    count: number;
    note?: string | undefined;
    deadline?: any | undefined;
    createdAt?: any | undefined;
    lead?: { __typename?: 'TaskLead'; id: string; type: TaskLeadType } | undefined;
    match?: { __typename?: 'Match'; id: string; name: string; date: any } | undefined;
    lastEdit: { __typename?: 'LastEdit'; timestamp: any; user: { __typename?: 'LastUser'; id: string } };
  };
};

export type CreateTaskMutationVariables = Exact<{
  input: CreateTaskInput;
}>;

export type CreateTaskMutation = {
  __typename?: 'Mutation';
  createTask: {
    __typename?: 'Task';
    id: string;
    name?: string | undefined;
    note?: string | undefined;
    type: TaskType;
    totalTasks: number;
    customAmount?: number | undefined;
    contractItemAmount?: number | undefined;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    match?: { __typename?: 'Match'; id: string; name: string } | undefined;
    season: { __typename?: 'Season'; id: string; name: string };
    subTasks: Array<{
      __typename?: 'SubTask';
      id: string;
      status: TaskStatus;
      count: number;
      note?: string | undefined;
      deadline?: any | undefined;
      createdAt?: any | undefined;
      lead?: { __typename?: 'TaskLead'; id: string; type: TaskLeadType } | undefined;
      match?: { __typename?: 'Match'; id: string; name: string; date: any } | undefined;
      lastEdit: { __typename?: 'LastEdit'; timestamp: any; user: { __typename?: 'LastUser'; id: string } };
    }>;
    lastEdit: { __typename?: 'LastEdit'; timestamp: any; user: { __typename?: 'LastUser'; id: string } };
    stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
  };
};

export type CreateTasksForContractMutationVariables = Exact<{
  contractId: Scalars['String']['input'];
  seasonId: Scalars['String']['input'];
}>;

export type CreateTasksForContractMutation = { __typename?: 'Mutation'; createTasksForContract: boolean };

export type MatchTaskFragmentFragment = {
  __typename?: 'MatchTask';
  id: string;
  name: string;
  date: any;
  league: { __typename?: 'League'; id: string; name: string };
  inventory: Array<{
    __typename?: 'MatchTaskInventory';
    id: string;
    name: string;
    unit: UnitType;
    contracts: Array<{
      __typename?: 'MatchTaskContract';
      id: string;
      partner: { __typename?: 'Partner'; id: string; companyName?: string | undefined };
      stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
      tasks: Array<{
        __typename?: 'Task';
        id: string;
        name?: string | undefined;
        note?: string | undefined;
        type: TaskType;
        totalTasks: number;
        customAmount?: number | undefined;
        contractItemAmount?: number | undefined;
        createdAt?: any | undefined;
        updatedAt?: any | undefined;
        match?: { __typename?: 'Match'; id: string; name: string } | undefined;
        season: { __typename?: 'Season'; id: string; name: string };
        subTasks: Array<{
          __typename?: 'SubTask';
          id: string;
          status: TaskStatus;
          count: number;
          note?: string | undefined;
          deadline?: any | undefined;
          createdAt?: any | undefined;
          lead?: { __typename?: 'TaskLead'; id: string; type: TaskLeadType } | undefined;
          match?: { __typename?: 'Match'; id: string; name: string; date: any } | undefined;
          lastEdit: {
            __typename?: 'LastEdit';
            timestamp: any;
            user: { __typename?: 'LastUser'; id: string };
          };
        }>;
        lastEdit: { __typename?: 'LastEdit'; timestamp: any; user: { __typename?: 'LastUser'; id: string } };
        stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
      }>;
    }>;
    stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
  }>;
  stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
};

export type MatchTasksQueryVariables = Exact<{
  matchId: Scalars['String']['input'];
  seasonId: Scalars['String']['input'];
}>;

export type MatchTasksQuery = {
  __typename?: 'Query';
  matchTasks: {
    __typename?: 'MatchTasksWithTotals';
    match: {
      __typename?: 'MatchTask';
      id: string;
      name: string;
      date: any;
      league: { __typename?: 'League'; id: string; name: string };
      inventory: Array<{
        __typename?: 'MatchTaskInventory';
        id: string;
        name: string;
        unit: UnitType;
        contracts: Array<{
          __typename?: 'MatchTaskContract';
          id: string;
          partner: { __typename?: 'Partner'; id: string; companyName?: string | undefined };
          stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
          tasks: Array<{
            __typename?: 'Task';
            id: string;
            name?: string | undefined;
            note?: string | undefined;
            type: TaskType;
            totalTasks: number;
            customAmount?: number | undefined;
            contractItemAmount?: number | undefined;
            createdAt?: any | undefined;
            updatedAt?: any | undefined;
            match?: { __typename?: 'Match'; id: string; name: string } | undefined;
            season: { __typename?: 'Season'; id: string; name: string };
            subTasks: Array<{
              __typename?: 'SubTask';
              id: string;
              status: TaskStatus;
              count: number;
              note?: string | undefined;
              deadline?: any | undefined;
              createdAt?: any | undefined;
              lead?: { __typename?: 'TaskLead'; id: string; type: TaskLeadType } | undefined;
              match?: { __typename?: 'Match'; id: string; name: string; date: any } | undefined;
              lastEdit: {
                __typename?: 'LastEdit';
                timestamp: any;
                user: { __typename?: 'LastUser'; id: string };
              };
            }>;
            lastEdit: {
              __typename?: 'LastEdit';
              timestamp: any;
              user: { __typename?: 'LastUser'; id: string };
            };
            stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
          }>;
        }>;
        stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
      }>;
      stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
    };
  };
};

export type RemoveSubTaskMutationVariables = Exact<{
  subTaskId: Scalars['String']['input'];
  taskId: Scalars['String']['input'];
}>;

export type RemoveSubTaskMutation = { __typename?: 'Mutation'; removeSubTask: boolean };

export type RemoveTaskMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RemoveTaskMutation = { __typename?: 'Mutation'; removeTask: boolean };

export type SubTaskFragmentFragment = {
  __typename?: 'SubTask';
  id: string;
  status: TaskStatus;
  count: number;
  note?: string | undefined;
  deadline?: any | undefined;
  createdAt?: any | undefined;
  lead?: { __typename?: 'TaskLead'; id: string; type: TaskLeadType } | undefined;
  match?: { __typename?: 'Match'; id: string; name: string; date: any } | undefined;
  lastEdit: { __typename?: 'LastEdit'; timestamp: any; user: { __typename?: 'LastUser'; id: string } };
};

export type TaskStatsFragmentFragment = {
  __typename?: 'TaskStats';
  open: number;
  inProgress: number;
  done: number;
};

export type TaskFragmentFragment = {
  __typename?: 'Task';
  id: string;
  name?: string | undefined;
  note?: string | undefined;
  type: TaskType;
  totalTasks: number;
  customAmount?: number | undefined;
  contractItemAmount?: number | undefined;
  createdAt?: any | undefined;
  updatedAt?: any | undefined;
  match?: { __typename?: 'Match'; id: string; name: string } | undefined;
  season: { __typename?: 'Season'; id: string; name: string };
  subTasks: Array<{
    __typename?: 'SubTask';
    id: string;
    status: TaskStatus;
    count: number;
    note?: string | undefined;
    deadline?: any | undefined;
    createdAt?: any | undefined;
    lead?: { __typename?: 'TaskLead'; id: string; type: TaskLeadType } | undefined;
    match?: { __typename?: 'Match'; id: string; name: string; date: any } | undefined;
    lastEdit: { __typename?: 'LastEdit'; timestamp: any; user: { __typename?: 'LastUser'; id: string } };
  }>;
  lastEdit: { __typename?: 'LastEdit'; timestamp: any; user: { __typename?: 'LastUser'; id: string } };
  stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
};

export type UpdateSubTaskMutationVariables = Exact<{
  subTaskId: Scalars['String']['input'];
  taskId: Scalars['String']['input'];
  input: UpdateSubTaskInput;
}>;

export type UpdateSubTaskMutation = {
  __typename?: 'Mutation';
  updateSubTask: {
    __typename?: 'SubTask';
    id: string;
    status: TaskStatus;
    count: number;
    note?: string | undefined;
    deadline?: any | undefined;
    createdAt?: any | undefined;
    lead?: { __typename?: 'TaskLead'; id: string; type: TaskLeadType } | undefined;
    match?: { __typename?: 'Match'; id: string; name: string; date: any } | undefined;
    lastEdit: { __typename?: 'LastEdit'; timestamp: any; user: { __typename?: 'LastUser'; id: string } };
  };
};

export type UpdateTaskMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: UpdateTaskInput;
}>;

export type UpdateTaskMutation = {
  __typename?: 'Mutation';
  updateTask: {
    __typename?: 'Task';
    id: string;
    name?: string | undefined;
    note?: string | undefined;
    type: TaskType;
    totalTasks: number;
    customAmount?: number | undefined;
    contractItemAmount?: number | undefined;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    match?: { __typename?: 'Match'; id: string; name: string } | undefined;
    season: { __typename?: 'Season'; id: string; name: string };
    subTasks: Array<{
      __typename?: 'SubTask';
      id: string;
      status: TaskStatus;
      count: number;
      note?: string | undefined;
      deadline?: any | undefined;
      createdAt?: any | undefined;
      lead?: { __typename?: 'TaskLead'; id: string; type: TaskLeadType } | undefined;
      match?: { __typename?: 'Match'; id: string; name: string; date: any } | undefined;
      lastEdit: { __typename?: 'LastEdit'; timestamp: any; user: { __typename?: 'LastUser'; id: string } };
    }>;
    lastEdit: { __typename?: 'LastEdit'; timestamp: any; user: { __typename?: 'LastUser'; id: string } };
    stats: { __typename?: 'TaskStats'; open: number; inProgress: number; done: number };
  };
};

export type UsersQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
}>;

export type UsersQuery = {
  __typename?: 'Query';
  users: Array<{
    __typename?: 'User';
    id: string;
    uid: string;
    name: string;
    email: string;
    admin?: boolean | undefined;
    lastSignIn?: string | undefined;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    deletedAt?: any | undefined;
    claims?: Array<{ __typename?: 'Claim'; key: string; value: string }> | undefined;
    accounts: Array<{ __typename?: 'UserAccount'; id: string; name: string; role?: UserRole | undefined }>;
  }>;
};

export type ChangeUserEmailMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: ChangeUserEmailInput;
}>;

export type ChangeUserEmailMutation = {
  __typename?: 'Mutation';
  changeUserEmail: {
    __typename?: 'User';
    id: string;
    uid: string;
    name: string;
    email: string;
    admin?: boolean | undefined;
    lastSignIn?: string | undefined;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    deletedAt?: any | undefined;
    claims?: Array<{ __typename?: 'Claim'; key: string; value: string }> | undefined;
    accounts: Array<{ __typename?: 'UserAccount'; id: string; name: string; role?: UserRole | undefined }>;
  };
};

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;

export type CreateUserMutation = {
  __typename?: 'Mutation';
  createUser: {
    __typename?: 'User';
    id: string;
    uid: string;
    name: string;
    email: string;
    admin?: boolean | undefined;
    lastSignIn?: string | undefined;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    deletedAt?: any | undefined;
    claims?: Array<{ __typename?: 'Claim'; key: string; value: string }> | undefined;
    accounts: Array<{ __typename?: 'UserAccount'; id: string; name: string; role?: UserRole | undefined }>;
  };
};

export type UserQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type UserQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    uid: string;
    name: string;
    email: string;
    admin?: boolean | undefined;
    lastSignIn?: string | undefined;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    deletedAt?: any | undefined;
    claims?: Array<{ __typename?: 'Claim'; key: string; value: string }> | undefined;
    accounts: Array<{ __typename?: 'UserAccount'; id: string; name: string; role?: UserRole | undefined }>;
  };
};

export type ResetMyPasswordMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type ResetMyPasswordMutation = { __typename?: 'Mutation'; resetMyPassword: boolean };

export type PasswordResetMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type PasswordResetMutation = { __typename?: 'Mutation'; resetPassword: boolean };

export type RemoveUserMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RemoveUserMutation = { __typename?: 'Mutation'; removeUser: boolean };

export type UpdateAccountUserMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: UpdateAccountUserInput;
}>;

export type UpdateAccountUserMutation = {
  __typename?: 'Mutation';
  updateAccountUser: {
    __typename?: 'User';
    id: string;
    uid: string;
    name: string;
    email: string;
    admin?: boolean | undefined;
    lastSignIn?: string | undefined;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    deletedAt?: any | undefined;
    claims?: Array<{ __typename?: 'Claim'; key: string; value: string }> | undefined;
    accounts: Array<{ __typename?: 'UserAccount'; id: string; name: string; role?: UserRole | undefined }>;
  };
};

export type UpdateMeMutationVariables = Exact<{
  input: UpdateMeInput;
}>;

export type UpdateMeMutation = {
  __typename?: 'Mutation';
  updateMe: {
    __typename?: 'User';
    id: string;
    uid: string;
    name: string;
    email: string;
    admin?: boolean | undefined;
    lastSignIn?: string | undefined;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    deletedAt?: any | undefined;
    claims?: Array<{ __typename?: 'Claim'; key: string; value: string }> | undefined;
    accounts: Array<{ __typename?: 'UserAccount'; id: string; name: string; role?: UserRole | undefined }>;
  };
};

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser: {
    __typename?: 'User';
    id: string;
    uid: string;
    name: string;
    email: string;
    admin?: boolean | undefined;
    lastSignIn?: string | undefined;
    createdAt?: any | undefined;
    updatedAt?: any | undefined;
    deletedAt?: any | undefined;
    claims?: Array<{ __typename?: 'Claim'; key: string; value: string }> | undefined;
    accounts: Array<{ __typename?: 'UserAccount'; id: string; name: string; role?: UserRole | undefined }>;
  };
};

export type UserFragmentFragment = {
  __typename?: 'User';
  id: string;
  uid: string;
  name: string;
  email: string;
  admin?: boolean | undefined;
  lastSignIn?: string | undefined;
  createdAt?: any | undefined;
  updatedAt?: any | undefined;
  deletedAt?: any | undefined;
  claims?: Array<{ __typename?: 'Claim'; key: string; value: string }> | undefined;
  accounts: Array<{ __typename?: 'UserAccount'; id: string; name: string; role?: UserRole | undefined }>;
};

export const UserFragmentFragmentDoc = gql`
  fragment UserFragment on User {
    id
    uid
    name
    email
    admin
    lastSignIn
    createdAt
    updatedAt
    deletedAt
    claims {
      key
      value
    }
    accounts {
      id
      name
      role
    }
  }
`;
export const AccountFragmentFragmentDoc = gql`
  fragment AccountFragment on Account {
    id
    name
    createdAt
    updatedAt
    deletedAt
    users {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export const InventoryStatsFragmentFragmentDoc = gql`
  fragment InventoryStatsFragment on InventoryStats {
    available
    availableValue
    blocked
    blockedValue
    offered
    offeredValue
    sold
    soldRevenue
    soldValue
    total
    totalValue
    capacityUtilization
    discount
  }
`;
export const InventoryCategoryFragmentFragmentDoc = gql`
  fragment InventoryCategoryFragment on InventoryCategory {
    id
    name
    order
    stats {
      ...InventoryStatsFragment
    }
  }
  ${InventoryStatsFragmentFragmentDoc}
`;
export const PartnerCategoryFragmentFragmentDoc = gql`
  fragment PartnerCategoryFragment on PartnerCategory {
    id
    name
    order
  }
`;
export const InvoiceCategoryFragmentFragmentDoc = gql`
  fragment InvoiceCategoryFragment on InvoiceCategory {
    id
    name
    order
    accountingNumber
  }
`;
export const TopCategoryFragmentFragmentDoc = gql`
  fragment TopCategoryFragment on TopCategory {
    id
    name
    order
    invoiceCategory {
      ...InvoiceCategoryFragment
    }
    stats {
      ...InventoryStatsFragment
    }
  }
  ${InvoiceCategoryFragmentFragmentDoc}
  ${InventoryStatsFragmentFragmentDoc}
`;
export const BonusFragmentFragmentDoc = gql`
  fragment BonusFragment on Bonus {
    id
    name
    description
    items {
      id
      price
      achieved
      offered
      sold
      season {
        id
        name
      }
      contracts {
        id
        price
        achieved
        partner {
          id
          companyName
        }
      }
      offers {
        id
        price
        achieved
        partner {
          id
          companyName
        }
      }
    }
  }
`;
export const BundleItemFragmentFragmentDoc = gql`
  fragment BundleItemFragment on BundleItem {
    id
    amount
    inventory {
      id
      name
    }
    listUnitPrice
    price
  }
`;
export const BundleFragmentFragmentDoc = gql`
  fragment BundleFragment on Bundle {
    id
    name
    items {
      ...BundleItemFragment
    }
    league {
      id
      name
    }
    season {
      id
      name
    }
    sold
    totalAvailable
    totalPrice
    createdAt
    updatedAt
  }
  ${BundleItemFragmentFragmentDoc}
`;
export const LeagueFragmentFragmentDoc = gql`
  fragment LeagueFragment on League {
    id
    name
    competitionId
    createdAt
    updatedAt
    deletedAt
  }
`;
export const CompetitionFragmentFragmentDoc = gql`
  fragment CompetitionFragment on Competition {
    id
    name
    createdAt
    updatedAt
    leagues {
      ...LeagueFragment
    }
  }
  ${LeagueFragmentFragmentDoc}
`;
export const ContractTemplateFragmentFragmentDoc = gql`
  fragment ContractTemplateFragment on ContractTemplate {
    id
    name
    template
    createdAt
    updatedAt
  }
`;
export const ContractNoteFragmentFragmentDoc = gql`
  fragment ContractNoteFragment on ContractNote {
    id
    content
    author {
      id
      email
    }
    createdAt
    updatedAt
  }
`;
export const ContractItemFragmentFragmentDoc = gql`
  fragment ContractItemFragment on ContractItem {
    id
    amount
    locked
    listUnitPrice
    price
    barter
    league {
      id
      name
    }
    season {
      id
      name
    }
    match {
      id
      name
    }
    inventory {
      id
      name
      unit
      topCategory {
        id
        name
      }
      listPrices {
        id
        type
      }
    }
  }
`;
export const ContractBonusFragmentFragmentDoc = gql`
  fragment ContractBonusFragment on ContractBonus {
    id
    price
    achieved
    bonusId
    bonusName
    bonusDescription
    season {
      id
      name
    }
  }
`;
export const InvoiceFragmentFragmentDoc = gql`
  fragment InvoiceFragment on Invoice {
    id
    type
    percentage
    invoiceNumber
    cancellationNumber
    date
    invoicedAt
    serviceStartedAt
    serviceEndedAt
    paymentDueAt
    payedAt
    canceledAt
    bonusId
    season {
      id
      name
    }
  }
`;
export const ContractFragmentFragmentDoc = gql`
  fragment ContractFragment on Contract {
    id
    name
    draft
    type
    probability
    startDate
    endDate
    invoicesDisabled
    createdAt
    updatedAt
    canceledAt
    autoRenew {
      enabled
      mode
      markedForCancellationAt
      cancellationPeriodEnd
      renewYears
    }
    note
    notes {
      ...ContractNoteFragment
    }
    partner {
      id
      companyName
      category {
        id
        name
      }
    }
    salesLead {
      id
    }
    clubLead {
      id
    }
    items {
      ...ContractItemFragment
    }
    bonuses {
      ...ContractBonusFragment
    }
    invoices {
      ...InvoiceFragment
    }
    total {
      barter
      discount
      listPrice
      price
    }
  }
  ${ContractNoteFragmentFragmentDoc}
  ${ContractItemFragmentFragmentDoc}
  ${ContractBonusFragmentFragmentDoc}
  ${InvoiceFragmentFragmentDoc}
`;
export const SubTaskFragmentFragmentDoc = gql`
  fragment SubTaskFragment on SubTask {
    id
    status
    count
    note
    deadline
    createdAt
    lead {
      id
      type
    }
    match {
      id
      name
      date
    }
    lastEdit {
      timestamp
      user {
        id
      }
    }
  }
`;
export const TaskStatsFragmentFragmentDoc = gql`
  fragment TaskStatsFragment on TaskStats {
    open
    inProgress
    done
  }
`;
export const TaskFragmentFragmentDoc = gql`
  fragment TaskFragment on Task {
    id
    name
    note
    type
    totalTasks
    customAmount
    contractItemAmount
    createdAt
    updatedAt
    match {
      id
      name
    }
    season {
      id
      name
    }
    subTasks {
      ...SubTaskFragment
    }
    lastEdit {
      timestamp
      user {
        id
      }
    }
    stats {
      ...TaskStatsFragment
    }
  }
  ${SubTaskFragmentFragmentDoc}
  ${TaskStatsFragmentFragmentDoc}
`;
export const SingleContractFragmentFragmentDoc = gql`
  fragment SingleContractFragment on Contract {
    ...ContractFragment
    items {
      ...ContractItemFragment
      task {
        ...TaskFragment
      }
    }
    tasks {
      ...TaskFragment
    }
    template {
      id
      name
      template
    }
  }
  ${ContractFragmentFragmentDoc}
  ${ContractItemFragmentFragmentDoc}
  ${TaskFragmentFragmentDoc}
`;
export const InventoryMatchStatsFragmentFragmentDoc = gql`
  fragment InventoryMatchStatsFragment on InventoryMatchStats {
    available
    availableValue
    blocked
    blockedValue
    offered
    offeredValue
    sold
    soldRevenue
    soldValue
    match {
      id
    }
    soldBySeason
    discount
  }
`;
export const InventoryListPriceFragmentFragmentDoc = gql`
  fragment InventoryListPriceFragment on InventoryListPrice {
    id
    type
    note
    quantity
    price
    createdAt
    updatedAt
    season {
      id
      name
    }
    league {
      id
      name
    }
    stats {
      ...InventoryStatsFragment
    }
    matchStats {
      ...InventoryMatchStatsFragment
    }
  }
  ${InventoryStatsFragmentFragmentDoc}
  ${InventoryMatchStatsFragmentFragmentDoc}
`;
export const InventoryFragmentFragmentDoc = gql`
  fragment InventoryFragment on Inventory {
    id
    name
    order
    unit
    keyRightOrder
    soldByMatch
    soldBySeason
    note
    externalId
    contractDescription
    createdAt
    updatedAt
    category {
      id
      name
      stats {
        ...InventoryStatsFragment
      }
    }
    topCategory {
      id
      name
      stats {
        ...InventoryStatsFragment
      }
    }
    stats {
      ...InventoryStatsFragment
    }
    listPrices {
      ...InventoryListPriceFragment
    }
    tasks {
      contracts {
        id
        partner {
          id
          companyName
        }
        stats {
          ...TaskStatsFragment
        }
        tasks {
          ...TaskFragment
        }
      }
      stats {
        ...TaskStatsFragment
      }
    }
  }
  ${InventoryStatsFragmentFragmentDoc}
  ${InventoryListPriceFragmentFragmentDoc}
  ${TaskStatsFragmentFragmentDoc}
  ${TaskFragmentFragmentDoc}
`;
export const PartnerNoteFragmentFragmentDoc = gql`
  fragment PartnerNoteFragment on PartnerNote {
    id
    content
    author {
      id
      email
    }
    createdAt
    updatedAt
  }
`;
export const PartnerContactFragmentFragmentDoc = gql`
  fragment PartnerContactFragment on PartnerContact {
    id
    name
    email
    position
    roles
    phoneNumber
    phoneNumber2
    externalId
  }
`;
export const PartnerStatsFragmentFragmentDoc = gql`
  fragment PartnerStatsFragment on Partner {
    stats {
      bonuses
      bonusesValue
      bonusesAchieved
      bonusesAchievedValue
      discount
      totalBarter
      totalContracts
      totalPrice
    }
  }
`;
export const PartnerFragmentFragmentDoc = gql`
  fragment PartnerFragment on Partner {
    id
    status
    contractStatus
    customerNumber
    organizationType
    country
    city
    zipCode
    street
    website
    companyName
    partnerSince
    createdAt
    updatedAt
    note
    notes {
      ...PartnerNoteFragment
    }
    category {
      id
      name
    }
    clubLead {
      id
    }
    contractClubLeads {
      id
    }
    salesLead {
      id
    }
    contractSalesLeads {
      id
    }
    contacts {
      ...PartnerContactFragment
    }
    contracts {
      ...ContractFragment
    }
    ...PartnerStatsFragment
  }
  ${PartnerNoteFragmentFragmentDoc}
  ${PartnerContactFragmentFragmentDoc}
  ${ContractFragmentFragmentDoc}
  ${PartnerStatsFragmentFragmentDoc}
`;
export const MatchFragmentFragmentDoc = gql`
  fragment MatchFragment on Match {
    id
    name
    date
    createdAt
    updatedAt
    league {
      ...LeagueFragment
    }
    taskStats {
      ...TaskStatsFragment
    }
  }
  ${LeagueFragmentFragmentDoc}
  ${TaskStatsFragmentFragmentDoc}
`;
export const CompetitionLeagueFragmentFragmentDoc = gql`
  fragment CompetitionLeagueFragment on CompetitionLeague {
    competitionId
    leagueId
    createdAt
    updatedAt
  }
`;
export const SeasonFragmentFragmentDoc = gql`
  fragment SeasonFragment on Season {
    id
    name
    endDate
    startDate
    targetRevenue
    competitionLeagues {
      ...CompetitionLeagueFragment
    }
    workflows {
      setupAt
      copySettings
      competitionLeagues {
        competitionId
        leagueId
      }
    }
    invoicing {
      barter {
        prefix
      }
      bonus {
        prefix
      }
      cash {
        prefix
      }
      useSeparateCounters
    }
    createdAt
    updatedAt
    deletedAt
  }
  ${CompetitionLeagueFragmentFragmentDoc}
`;
export const MatchTaskFragmentFragmentDoc = gql`
  fragment MatchTaskFragment on MatchTask {
    id
    name
    date
    league {
      id
      name
    }
    inventory {
      id
      name
      unit
      contracts {
        id
        partner {
          id
          companyName
        }
        stats {
          ...TaskStatsFragment
        }
        tasks {
          ...TaskFragment
        }
      }
      stats {
        ...TaskStatsFragment
      }
    }
    stats {
      ...TaskStatsFragment
    }
  }
  ${TaskStatsFragmentFragmentDoc}
  ${TaskFragmentFragmentDoc}
`;
export const AccountsDocument = gql`
  query Accounts($pageSize: Float, $cursor: String, $search: String) {
    accounts(pageSize: $pageSize, cursor: $cursor, search: $search) {
      pageInfo {
        endCursor
        hasNext
        startCursor
        totalCount
      }
      edges {
        node {
          ...AccountFragment
        }
      }
    }
  }
  ${AccountFragmentFragmentDoc}
`;

/**
 * __useAccountsQuery__
 *
 * To run a query within a React component, call `useAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      cursor: // value for 'cursor'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<AccountsQuery, AccountsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountsQuery, AccountsQueryVariables>(AccountsDocument, options);
}
export function useAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccountsQuery, AccountsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountsQuery, AccountsQueryVariables>(AccountsDocument, options);
}
export function useAccountsSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AccountsQuery, AccountsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AccountsQuery, AccountsQueryVariables>(AccountsDocument, options);
}
export type AccountsQueryHookResult = ReturnType<typeof useAccountsQuery>;
export type AccountsLazyQueryHookResult = ReturnType<typeof useAccountsLazyQuery>;
export type AccountsSuspenseQueryHookResult = ReturnType<typeof useAccountsSuspenseQuery>;
export type AccountsQueryResult = Apollo.QueryResult<AccountsQuery, AccountsQueryVariables>;
export const CreateAccountDocument = gql`
  mutation CreateAccount($input: CreateAccountInput!) {
    createAccount(input: $input) {
      ...AccountFragment
    }
  }
  ${AccountFragmentFragmentDoc}
`;
export type CreateAccountMutationFn = Apollo.MutationFunction<
  CreateAccountMutation,
  CreateAccountMutationVariables
>;

/**
 * __useCreateAccountMutation__
 *
 * To run a mutation, you first call `useCreateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountMutation, { data, loading, error }] = useCreateAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAccountMutation, CreateAccountMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAccountMutation, CreateAccountMutationVariables>(
    CreateAccountDocument,
    options,
  );
}
export type CreateAccountMutationHookResult = ReturnType<typeof useCreateAccountMutation>;
export type CreateAccountMutationResult = Apollo.MutationResult<CreateAccountMutation>;
export type CreateAccountMutationOptions = Apollo.BaseMutationOptions<
  CreateAccountMutation,
  CreateAccountMutationVariables
>;
export const AccountDocument = gql`
  query Account($id: String!) {
    account(id: $id) {
      ...AccountFragment
    }
  }
  ${AccountFragmentFragmentDoc}
`;

/**
 * __useAccountQuery__
 *
 * To run a query within a React component, call `useAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAccountQuery(
  baseOptions: Apollo.QueryHookOptions<AccountQuery, AccountQueryVariables> &
    ({ variables: AccountQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountQuery, AccountQueryVariables>(AccountDocument, options);
}
export function useAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccountQuery, AccountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountQuery, AccountQueryVariables>(AccountDocument, options);
}
export function useAccountSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AccountQuery, AccountQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AccountQuery, AccountQueryVariables>(AccountDocument, options);
}
export type AccountQueryHookResult = ReturnType<typeof useAccountQuery>;
export type AccountLazyQueryHookResult = ReturnType<typeof useAccountLazyQuery>;
export type AccountSuspenseQueryHookResult = ReturnType<typeof useAccountSuspenseQuery>;
export type AccountQueryResult = Apollo.QueryResult<AccountQuery, AccountQueryVariables>;
export const CreateInventoryCategoryDocument = gql`
  mutation CreateInventoryCategory($input: CreateInventoryCategoryInput!) {
    createInventoryCategory(input: $input) {
      ...InventoryCategoryFragment
    }
  }
  ${InventoryCategoryFragmentFragmentDoc}
`;
export type CreateInventoryCategoryMutationFn = Apollo.MutationFunction<
  CreateInventoryCategoryMutation,
  CreateInventoryCategoryMutationVariables
>;

/**
 * __useCreateInventoryCategoryMutation__
 *
 * To run a mutation, you first call `useCreateInventoryCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInventoryCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInventoryCategoryMutation, { data, loading, error }] = useCreateInventoryCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInventoryCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInventoryCategoryMutation,
    CreateInventoryCategoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateInventoryCategoryMutation, CreateInventoryCategoryMutationVariables>(
    CreateInventoryCategoryDocument,
    options,
  );
}
export type CreateInventoryCategoryMutationHookResult = ReturnType<typeof useCreateInventoryCategoryMutation>;
export type CreateInventoryCategoryMutationResult = Apollo.MutationResult<CreateInventoryCategoryMutation>;
export type CreateInventoryCategoryMutationOptions = Apollo.BaseMutationOptions<
  CreateInventoryCategoryMutation,
  CreateInventoryCategoryMutationVariables
>;
export const RemoveInventoryCategoryDocument = gql`
  mutation RemoveInventoryCategory($categoryId: String!) {
    removeInventoryCategory(categoryId: $categoryId)
  }
`;
export type RemoveInventoryCategoryMutationFn = Apollo.MutationFunction<
  RemoveInventoryCategoryMutation,
  RemoveInventoryCategoryMutationVariables
>;

/**
 * __useRemoveInventoryCategoryMutation__
 *
 * To run a mutation, you first call `useRemoveInventoryCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveInventoryCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeInventoryCategoryMutation, { data, loading, error }] = useRemoveInventoryCategoryMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useRemoveInventoryCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveInventoryCategoryMutation,
    RemoveInventoryCategoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveInventoryCategoryMutation, RemoveInventoryCategoryMutationVariables>(
    RemoveInventoryCategoryDocument,
    options,
  );
}
export type RemoveInventoryCategoryMutationHookResult = ReturnType<typeof useRemoveInventoryCategoryMutation>;
export type RemoveInventoryCategoryMutationResult = Apollo.MutationResult<RemoveInventoryCategoryMutation>;
export type RemoveInventoryCategoryMutationOptions = Apollo.BaseMutationOptions<
  RemoveInventoryCategoryMutation,
  RemoveInventoryCategoryMutationVariables
>;
export const UpdateInventoryCategoryDocument = gql`
  mutation UpdateInventoryCategory($categoryId: String!, $input: UpdateInventoryCategoryInput!) {
    updateInventoryCategory(categoryId: $categoryId, input: $input) {
      ...InventoryCategoryFragment
    }
  }
  ${InventoryCategoryFragmentFragmentDoc}
`;
export type UpdateInventoryCategoryMutationFn = Apollo.MutationFunction<
  UpdateInventoryCategoryMutation,
  UpdateInventoryCategoryMutationVariables
>;

/**
 * __useUpdateInventoryCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateInventoryCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInventoryCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInventoryCategoryMutation, { data, loading, error }] = useUpdateInventoryCategoryMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInventoryCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInventoryCategoryMutation,
    UpdateInventoryCategoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateInventoryCategoryMutation, UpdateInventoryCategoryMutationVariables>(
    UpdateInventoryCategoryDocument,
    options,
  );
}
export type UpdateInventoryCategoryMutationHookResult = ReturnType<typeof useUpdateInventoryCategoryMutation>;
export type UpdateInventoryCategoryMutationResult = Apollo.MutationResult<UpdateInventoryCategoryMutation>;
export type UpdateInventoryCategoryMutationOptions = Apollo.BaseMutationOptions<
  UpdateInventoryCategoryMutation,
  UpdateInventoryCategoryMutationVariables
>;
export const CreateInvoiceCategoryDocument = gql`
  mutation CreateInvoiceCategory($input: CreateInvoiceCategoryInput!) {
    createInvoiceCategory(input: $input) {
      ...InvoiceCategoryFragment
    }
  }
  ${InvoiceCategoryFragmentFragmentDoc}
`;
export type CreateInvoiceCategoryMutationFn = Apollo.MutationFunction<
  CreateInvoiceCategoryMutation,
  CreateInvoiceCategoryMutationVariables
>;

/**
 * __useCreateInvoiceCategoryMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceCategoryMutation, { data, loading, error }] = useCreateInvoiceCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInvoiceCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInvoiceCategoryMutation,
    CreateInvoiceCategoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateInvoiceCategoryMutation, CreateInvoiceCategoryMutationVariables>(
    CreateInvoiceCategoryDocument,
    options,
  );
}
export type CreateInvoiceCategoryMutationHookResult = ReturnType<typeof useCreateInvoiceCategoryMutation>;
export type CreateInvoiceCategoryMutationResult = Apollo.MutationResult<CreateInvoiceCategoryMutation>;
export type CreateInvoiceCategoryMutationOptions = Apollo.BaseMutationOptions<
  CreateInvoiceCategoryMutation,
  CreateInvoiceCategoryMutationVariables
>;
export const RemoveInvoiceCategoryDocument = gql`
  mutation RemoveInvoiceCategory($categoryId: String!) {
    removeInvoiceCategory(categoryId: $categoryId)
  }
`;
export type RemoveInvoiceCategoryMutationFn = Apollo.MutationFunction<
  RemoveInvoiceCategoryMutation,
  RemoveInvoiceCategoryMutationVariables
>;

/**
 * __useRemoveInvoiceCategoryMutation__
 *
 * To run a mutation, you first call `useRemoveInvoiceCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveInvoiceCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeInvoiceCategoryMutation, { data, loading, error }] = useRemoveInvoiceCategoryMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useRemoveInvoiceCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveInvoiceCategoryMutation,
    RemoveInvoiceCategoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveInvoiceCategoryMutation, RemoveInvoiceCategoryMutationVariables>(
    RemoveInvoiceCategoryDocument,
    options,
  );
}
export type RemoveInvoiceCategoryMutationHookResult = ReturnType<typeof useRemoveInvoiceCategoryMutation>;
export type RemoveInvoiceCategoryMutationResult = Apollo.MutationResult<RemoveInvoiceCategoryMutation>;
export type RemoveInvoiceCategoryMutationOptions = Apollo.BaseMutationOptions<
  RemoveInvoiceCategoryMutation,
  RemoveInvoiceCategoryMutationVariables
>;
export const UpdateInvoiceCategoryDocument = gql`
  mutation UpdateInvoiceCategory($categoryId: String!, $input: UpdateInvoiceCategoryInput!) {
    updateInvoiceCategory(categoryId: $categoryId, input: $input) {
      ...InvoiceCategoryFragment
    }
  }
  ${InvoiceCategoryFragmentFragmentDoc}
`;
export type UpdateInvoiceCategoryMutationFn = Apollo.MutationFunction<
  UpdateInvoiceCategoryMutation,
  UpdateInvoiceCategoryMutationVariables
>;

/**
 * __useUpdateInvoiceCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceCategoryMutation, { data, loading, error }] = useUpdateInvoiceCategoryMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInvoiceCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInvoiceCategoryMutation,
    UpdateInvoiceCategoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateInvoiceCategoryMutation, UpdateInvoiceCategoryMutationVariables>(
    UpdateInvoiceCategoryDocument,
    options,
  );
}
export type UpdateInvoiceCategoryMutationHookResult = ReturnType<typeof useUpdateInvoiceCategoryMutation>;
export type UpdateInvoiceCategoryMutationResult = Apollo.MutationResult<UpdateInvoiceCategoryMutation>;
export type UpdateInvoiceCategoryMutationOptions = Apollo.BaseMutationOptions<
  UpdateInvoiceCategoryMutation,
  UpdateInvoiceCategoryMutationVariables
>;
export const MyAccountDocument = gql`
  query MyAccount {
    myAccount {
      ...AccountFragment
      accentColor
      defaultSeason
      inventoryBlockProbability
      contractTemplateManagement
      apiKey
      defaultCompetitionLeagues {
        ...CompetitionLeagueFragment
      }
      userAccounts {
        id
        name
        role
      }
      invoicing {
        enabled
        startDate
        apiOnly
        byAccountingOnly
        showInvoiceStatsInContractsTable
        valueAddTax
        exportTemplate
      }
      documentOutputFormat
      workflows {
        enabled
        startDate
      }
      exports {
        enabled
      }
      datevExports {
        enabled
      }
      customSettings {
        disablePartnerManagement
        showExternalInventoryId
        showExternalPartnerContactId
      }
      inventoryCategories {
        ...InventoryCategoryFragment
      }
      partnerCategories {
        ...PartnerCategoryFragment
      }
      topCategories {
        ...TopCategoryFragment
      }
      invoiceCategories {
        ...InvoiceCategoryFragment
      }
    }
  }
  ${AccountFragmentFragmentDoc}
  ${CompetitionLeagueFragmentFragmentDoc}
  ${InventoryCategoryFragmentFragmentDoc}
  ${PartnerCategoryFragmentFragmentDoc}
  ${TopCategoryFragmentFragmentDoc}
  ${InvoiceCategoryFragmentFragmentDoc}
`;

/**
 * __useMyAccountQuery__
 *
 * To run a query within a React component, call `useMyAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyAccountQuery(
  baseOptions?: Apollo.QueryHookOptions<MyAccountQuery, MyAccountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyAccountQuery, MyAccountQueryVariables>(MyAccountDocument, options);
}
export function useMyAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MyAccountQuery, MyAccountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyAccountQuery, MyAccountQueryVariables>(MyAccountDocument, options);
}
export function useMyAccountSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MyAccountQuery, MyAccountQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MyAccountQuery, MyAccountQueryVariables>(MyAccountDocument, options);
}
export type MyAccountQueryHookResult = ReturnType<typeof useMyAccountQuery>;
export type MyAccountLazyQueryHookResult = ReturnType<typeof useMyAccountLazyQuery>;
export type MyAccountSuspenseQueryHookResult = ReturnType<typeof useMyAccountSuspenseQuery>;
export type MyAccountQueryResult = Apollo.QueryResult<MyAccountQuery, MyAccountQueryVariables>;
export const CreatePartnerCategoryDocument = gql`
  mutation CreatePartnerCategory($input: CreatePartnerCategoryInput!) {
    createPartnerCategory(input: $input) {
      ...PartnerCategoryFragment
    }
  }
  ${PartnerCategoryFragmentFragmentDoc}
`;
export type CreatePartnerCategoryMutationFn = Apollo.MutationFunction<
  CreatePartnerCategoryMutation,
  CreatePartnerCategoryMutationVariables
>;

/**
 * __useCreatePartnerCategoryMutation__
 *
 * To run a mutation, you first call `useCreatePartnerCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePartnerCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPartnerCategoryMutation, { data, loading, error }] = useCreatePartnerCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePartnerCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePartnerCategoryMutation,
    CreatePartnerCategoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePartnerCategoryMutation, CreatePartnerCategoryMutationVariables>(
    CreatePartnerCategoryDocument,
    options,
  );
}
export type CreatePartnerCategoryMutationHookResult = ReturnType<typeof useCreatePartnerCategoryMutation>;
export type CreatePartnerCategoryMutationResult = Apollo.MutationResult<CreatePartnerCategoryMutation>;
export type CreatePartnerCategoryMutationOptions = Apollo.BaseMutationOptions<
  CreatePartnerCategoryMutation,
  CreatePartnerCategoryMutationVariables
>;
export const RemovePartnerCategoryDocument = gql`
  mutation RemovePartnerCategory($categoryId: String!) {
    removePartnerCategory(categoryId: $categoryId)
  }
`;
export type RemovePartnerCategoryMutationFn = Apollo.MutationFunction<
  RemovePartnerCategoryMutation,
  RemovePartnerCategoryMutationVariables
>;

/**
 * __useRemovePartnerCategoryMutation__
 *
 * To run a mutation, you first call `useRemovePartnerCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePartnerCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePartnerCategoryMutation, { data, loading, error }] = useRemovePartnerCategoryMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useRemovePartnerCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemovePartnerCategoryMutation,
    RemovePartnerCategoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemovePartnerCategoryMutation, RemovePartnerCategoryMutationVariables>(
    RemovePartnerCategoryDocument,
    options,
  );
}
export type RemovePartnerCategoryMutationHookResult = ReturnType<typeof useRemovePartnerCategoryMutation>;
export type RemovePartnerCategoryMutationResult = Apollo.MutationResult<RemovePartnerCategoryMutation>;
export type RemovePartnerCategoryMutationOptions = Apollo.BaseMutationOptions<
  RemovePartnerCategoryMutation,
  RemovePartnerCategoryMutationVariables
>;
export const UpdatePartnerCategoryDocument = gql`
  mutation UpdatePartnerCategory($categoryId: String!, $input: UpdatePartnerCategoryInput!) {
    updatePartnerCategory(categoryId: $categoryId, input: $input) {
      ...PartnerCategoryFragment
    }
  }
  ${PartnerCategoryFragmentFragmentDoc}
`;
export type UpdatePartnerCategoryMutationFn = Apollo.MutationFunction<
  UpdatePartnerCategoryMutation,
  UpdatePartnerCategoryMutationVariables
>;

/**
 * __useUpdatePartnerCategoryMutation__
 *
 * To run a mutation, you first call `useUpdatePartnerCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartnerCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartnerCategoryMutation, { data, loading, error }] = useUpdatePartnerCategoryMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePartnerCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePartnerCategoryMutation,
    UpdatePartnerCategoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePartnerCategoryMutation, UpdatePartnerCategoryMutationVariables>(
    UpdatePartnerCategoryDocument,
    options,
  );
}
export type UpdatePartnerCategoryMutationHookResult = ReturnType<typeof useUpdatePartnerCategoryMutation>;
export type UpdatePartnerCategoryMutationResult = Apollo.MutationResult<UpdatePartnerCategoryMutation>;
export type UpdatePartnerCategoryMutationOptions = Apollo.BaseMutationOptions<
  UpdatePartnerCategoryMutation,
  UpdatePartnerCategoryMutationVariables
>;
export const SetApiKeyDocument = gql`
  mutation SetApiKey {
    setApiKey
  }
`;
export type SetApiKeyMutationFn = Apollo.MutationFunction<SetApiKeyMutation, SetApiKeyMutationVariables>;

/**
 * __useSetApiKeyMutation__
 *
 * To run a mutation, you first call `useSetApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setApiKeyMutation, { data, loading, error }] = useSetApiKeyMutation({
 *   variables: {
 *   },
 * });
 */
export function useSetApiKeyMutation(
  baseOptions?: Apollo.MutationHookOptions<SetApiKeyMutation, SetApiKeyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetApiKeyMutation, SetApiKeyMutationVariables>(SetApiKeyDocument, options);
}
export type SetApiKeyMutationHookResult = ReturnType<typeof useSetApiKeyMutation>;
export type SetApiKeyMutationResult = Apollo.MutationResult<SetApiKeyMutation>;
export type SetApiKeyMutationOptions = Apollo.BaseMutationOptions<
  SetApiKeyMutation,
  SetApiKeyMutationVariables
>;
export const CreateTopCategoryDocument = gql`
  mutation CreateTopCategory($input: CreateTopCategoryInput!) {
    createTopCategory(input: $input) {
      ...TopCategoryFragment
    }
  }
  ${TopCategoryFragmentFragmentDoc}
`;
export type CreateTopCategoryMutationFn = Apollo.MutationFunction<
  CreateTopCategoryMutation,
  CreateTopCategoryMutationVariables
>;

/**
 * __useCreateTopCategoryMutation__
 *
 * To run a mutation, you first call `useCreateTopCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTopCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTopCategoryMutation, { data, loading, error }] = useCreateTopCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTopCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTopCategoryMutation, CreateTopCategoryMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTopCategoryMutation, CreateTopCategoryMutationVariables>(
    CreateTopCategoryDocument,
    options,
  );
}
export type CreateTopCategoryMutationHookResult = ReturnType<typeof useCreateTopCategoryMutation>;
export type CreateTopCategoryMutationResult = Apollo.MutationResult<CreateTopCategoryMutation>;
export type CreateTopCategoryMutationOptions = Apollo.BaseMutationOptions<
  CreateTopCategoryMutation,
  CreateTopCategoryMutationVariables
>;
export const RemoveTopCategoryDocument = gql`
  mutation RemoveTopCategory($categoryId: String!) {
    removeTopCategory(categoryId: $categoryId)
  }
`;
export type RemoveTopCategoryMutationFn = Apollo.MutationFunction<
  RemoveTopCategoryMutation,
  RemoveTopCategoryMutationVariables
>;

/**
 * __useRemoveTopCategoryMutation__
 *
 * To run a mutation, you first call `useRemoveTopCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTopCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTopCategoryMutation, { data, loading, error }] = useRemoveTopCategoryMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useRemoveTopCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveTopCategoryMutation, RemoveTopCategoryMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveTopCategoryMutation, RemoveTopCategoryMutationVariables>(
    RemoveTopCategoryDocument,
    options,
  );
}
export type RemoveTopCategoryMutationHookResult = ReturnType<typeof useRemoveTopCategoryMutation>;
export type RemoveTopCategoryMutationResult = Apollo.MutationResult<RemoveTopCategoryMutation>;
export type RemoveTopCategoryMutationOptions = Apollo.BaseMutationOptions<
  RemoveTopCategoryMutation,
  RemoveTopCategoryMutationVariables
>;
export const UpdateTopCategoryDocument = gql`
  mutation UpdateTopCategory($categoryId: String!, $input: UpdateTopCategoryInput!) {
    updateTopCategory(categoryId: $categoryId, input: $input) {
      ...TopCategoryFragment
    }
  }
  ${TopCategoryFragmentFragmentDoc}
`;
export type UpdateTopCategoryMutationFn = Apollo.MutationFunction<
  UpdateTopCategoryMutation,
  UpdateTopCategoryMutationVariables
>;

/**
 * __useUpdateTopCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateTopCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTopCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTopCategoryMutation, { data, loading, error }] = useUpdateTopCategoryMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTopCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTopCategoryMutation, UpdateTopCategoryMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTopCategoryMutation, UpdateTopCategoryMutationVariables>(
    UpdateTopCategoryDocument,
    options,
  );
}
export type UpdateTopCategoryMutationHookResult = ReturnType<typeof useUpdateTopCategoryMutation>;
export type UpdateTopCategoryMutationResult = Apollo.MutationResult<UpdateTopCategoryMutation>;
export type UpdateTopCategoryMutationOptions = Apollo.BaseMutationOptions<
  UpdateTopCategoryMutation,
  UpdateTopCategoryMutationVariables
>;
export const UnsetApiKeyDocument = gql`
  mutation UnsetApiKey {
    unsetApiKey
  }
`;
export type UnsetApiKeyMutationFn = Apollo.MutationFunction<
  UnsetApiKeyMutation,
  UnsetApiKeyMutationVariables
>;

/**
 * __useUnsetApiKeyMutation__
 *
 * To run a mutation, you first call `useUnsetApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsetApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsetApiKeyMutation, { data, loading, error }] = useUnsetApiKeyMutation({
 *   variables: {
 *   },
 * });
 */
export function useUnsetApiKeyMutation(
  baseOptions?: Apollo.MutationHookOptions<UnsetApiKeyMutation, UnsetApiKeyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnsetApiKeyMutation, UnsetApiKeyMutationVariables>(UnsetApiKeyDocument, options);
}
export type UnsetApiKeyMutationHookResult = ReturnType<typeof useUnsetApiKeyMutation>;
export type UnsetApiKeyMutationResult = Apollo.MutationResult<UnsetApiKeyMutation>;
export type UnsetApiKeyMutationOptions = Apollo.BaseMutationOptions<
  UnsetApiKeyMutation,
  UnsetApiKeyMutationVariables
>;
export const UpdateAccountDocument = gql`
  mutation UpdateAccount($id: String!, $input: UpdateAccountInput!) {
    updateAccount(id: $id, input: $input) {
      ...AccountFragment
    }
  }
  ${AccountFragmentFragmentDoc}
`;
export type UpdateAccountMutationFn = Apollo.MutationFunction<
  UpdateAccountMutation,
  UpdateAccountMutationVariables
>;

/**
 * __useUpdateAccountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutation, { data, loading, error }] = useUpdateAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAccountMutation, UpdateAccountMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAccountMutation, UpdateAccountMutationVariables>(
    UpdateAccountDocument,
    options,
  );
}
export type UpdateAccountMutationHookResult = ReturnType<typeof useUpdateAccountMutation>;
export type UpdateAccountMutationResult = Apollo.MutationResult<UpdateAccountMutation>;
export type UpdateAccountMutationOptions = Apollo.BaseMutationOptions<
  UpdateAccountMutation,
  UpdateAccountMutationVariables
>;
export const UpdateMyAccountDocument = gql`
  mutation UpdateMyAccount($input: UpdateMyAccountInput!) {
    updateMyAccount(input: $input) {
      ...AccountFragment
    }
  }
  ${AccountFragmentFragmentDoc}
`;
export type UpdateMyAccountMutationFn = Apollo.MutationFunction<
  UpdateMyAccountMutation,
  UpdateMyAccountMutationVariables
>;

/**
 * __useUpdateMyAccountMutation__
 *
 * To run a mutation, you first call `useUpdateMyAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyAccountMutation, { data, loading, error }] = useUpdateMyAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMyAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMyAccountMutation, UpdateMyAccountMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMyAccountMutation, UpdateMyAccountMutationVariables>(
    UpdateMyAccountDocument,
    options,
  );
}
export type UpdateMyAccountMutationHookResult = ReturnType<typeof useUpdateMyAccountMutation>;
export type UpdateMyAccountMutationResult = Apollo.MutationResult<UpdateMyAccountMutation>;
export type UpdateMyAccountMutationOptions = Apollo.BaseMutationOptions<
  UpdateMyAccountMutation,
  UpdateMyAccountMutationVariables
>;
export const BonusesDocument = gql`
  query Bonuses($seasonId: String!) {
    bonuses(seasonId: $seasonId) {
      totals {
        achievedCount
        achievedRevenue
      }
      edges {
        node {
          id
          name
          description
          createdAt
          items {
            id
            season {
              id
              name
            }
            price
            sold
            offered
            achieved
          }
        }
      }
    }
  }
`;

/**
 * __useBonusesQuery__
 *
 * To run a query within a React component, call `useBonusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBonusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBonusesQuery({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useBonusesQuery(
  baseOptions: Apollo.QueryHookOptions<BonusesQuery, BonusesQueryVariables> &
    ({ variables: BonusesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BonusesQuery, BonusesQueryVariables>(BonusesDocument, options);
}
export function useBonusesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BonusesQuery, BonusesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BonusesQuery, BonusesQueryVariables>(BonusesDocument, options);
}
export function useBonusesSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BonusesQuery, BonusesQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BonusesQuery, BonusesQueryVariables>(BonusesDocument, options);
}
export type BonusesQueryHookResult = ReturnType<typeof useBonusesQuery>;
export type BonusesLazyQueryHookResult = ReturnType<typeof useBonusesLazyQuery>;
export type BonusesSuspenseQueryHookResult = ReturnType<typeof useBonusesSuspenseQuery>;
export type BonusesQueryResult = Apollo.QueryResult<BonusesQuery, BonusesQueryVariables>;
export const CreateBonusDocument = gql`
  mutation CreateBonus($input: CreateBonusInput!) {
    createBonus(input: $input) {
      ...BonusFragment
    }
  }
  ${BonusFragmentFragmentDoc}
`;
export type CreateBonusMutationFn = Apollo.MutationFunction<
  CreateBonusMutation,
  CreateBonusMutationVariables
>;

/**
 * __useCreateBonusMutation__
 *
 * To run a mutation, you first call `useCreateBonusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBonusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBonusMutation, { data, loading, error }] = useCreateBonusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBonusMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateBonusMutation, CreateBonusMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBonusMutation, CreateBonusMutationVariables>(CreateBonusDocument, options);
}
export type CreateBonusMutationHookResult = ReturnType<typeof useCreateBonusMutation>;
export type CreateBonusMutationResult = Apollo.MutationResult<CreateBonusMutation>;
export type CreateBonusMutationOptions = Apollo.BaseMutationOptions<
  CreateBonusMutation,
  CreateBonusMutationVariables
>;
export const BonusDocument = gql`
  query Bonus($id: String!, $seasonId: String) {
    bonus(id: $id, seasonId: $seasonId) {
      ...BonusFragment
    }
  }
  ${BonusFragmentFragmentDoc}
`;

/**
 * __useBonusQuery__
 *
 * To run a query within a React component, call `useBonusQuery` and pass it any options that fit your needs.
 * When your component renders, `useBonusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBonusQuery({
 *   variables: {
 *      id: // value for 'id'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useBonusQuery(
  baseOptions: Apollo.QueryHookOptions<BonusQuery, BonusQueryVariables> &
    ({ variables: BonusQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BonusQuery, BonusQueryVariables>(BonusDocument, options);
}
export function useBonusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BonusQuery, BonusQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BonusQuery, BonusQueryVariables>(BonusDocument, options);
}
export function useBonusSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BonusQuery, BonusQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BonusQuery, BonusQueryVariables>(BonusDocument, options);
}
export type BonusQueryHookResult = ReturnType<typeof useBonusQuery>;
export type BonusLazyQueryHookResult = ReturnType<typeof useBonusLazyQuery>;
export type BonusSuspenseQueryHookResult = ReturnType<typeof useBonusSuspenseQuery>;
export type BonusQueryResult = Apollo.QueryResult<BonusQuery, BonusQueryVariables>;
export const RemoveBonusDocument = gql`
  mutation RemoveBonus($id: String!) {
    removeBonus(id: $id)
  }
`;
export type RemoveBonusMutationFn = Apollo.MutationFunction<
  RemoveBonusMutation,
  RemoveBonusMutationVariables
>;

/**
 * __useRemoveBonusMutation__
 *
 * To run a mutation, you first call `useRemoveBonusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBonusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBonusMutation, { data, loading, error }] = useRemoveBonusMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveBonusMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveBonusMutation, RemoveBonusMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveBonusMutation, RemoveBonusMutationVariables>(RemoveBonusDocument, options);
}
export type RemoveBonusMutationHookResult = ReturnType<typeof useRemoveBonusMutation>;
export type RemoveBonusMutationResult = Apollo.MutationResult<RemoveBonusMutation>;
export type RemoveBonusMutationOptions = Apollo.BaseMutationOptions<
  RemoveBonusMutation,
  RemoveBonusMutationVariables
>;
export const UpdateBonusItemDocument = gql`
  mutation UpdateBonusItem($bonusId: String!, $seasonId: String!, $input: UpdateBonusItemInput!) {
    updateBonusItem(id: $bonusId, seasonId: $seasonId, input: $input) {
      ...BonusFragment
    }
  }
  ${BonusFragmentFragmentDoc}
`;
export type UpdateBonusItemMutationFn = Apollo.MutationFunction<
  UpdateBonusItemMutation,
  UpdateBonusItemMutationVariables
>;

/**
 * __useUpdateBonusItemMutation__
 *
 * To run a mutation, you first call `useUpdateBonusItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBonusItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBonusItemMutation, { data, loading, error }] = useUpdateBonusItemMutation({
 *   variables: {
 *      bonusId: // value for 'bonusId'
 *      seasonId: // value for 'seasonId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBonusItemMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateBonusItemMutation, UpdateBonusItemMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBonusItemMutation, UpdateBonusItemMutationVariables>(
    UpdateBonusItemDocument,
    options,
  );
}
export type UpdateBonusItemMutationHookResult = ReturnType<typeof useUpdateBonusItemMutation>;
export type UpdateBonusItemMutationResult = Apollo.MutationResult<UpdateBonusItemMutation>;
export type UpdateBonusItemMutationOptions = Apollo.BaseMutationOptions<
  UpdateBonusItemMutation,
  UpdateBonusItemMutationVariables
>;
export const UpdateBonusDocument = gql`
  mutation UpdateBonus($id: String!, $input: UpdateBonusInput!) {
    updateBonus(id: $id, input: $input) {
      ...BonusFragment
    }
  }
  ${BonusFragmentFragmentDoc}
`;
export type UpdateBonusMutationFn = Apollo.MutationFunction<
  UpdateBonusMutation,
  UpdateBonusMutationVariables
>;

/**
 * __useUpdateBonusMutation__
 *
 * To run a mutation, you first call `useUpdateBonusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBonusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBonusMutation, { data, loading, error }] = useUpdateBonusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBonusMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateBonusMutation, UpdateBonusMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBonusMutation, UpdateBonusMutationVariables>(UpdateBonusDocument, options);
}
export type UpdateBonusMutationHookResult = ReturnType<typeof useUpdateBonusMutation>;
export type UpdateBonusMutationResult = Apollo.MutationResult<UpdateBonusMutation>;
export type UpdateBonusMutationOptions = Apollo.BaseMutationOptions<
  UpdateBonusMutation,
  UpdateBonusMutationVariables
>;
export const BundlesDocument = gql`
  query Bundles($leagueId: String!, $seasonId: String!, $search: String) {
    bundles(leagueId: $leagueId, seasonId: $seasonId, search: $search) {
      edges {
        node {
          ...BundleFragment
        }
      }
    }
  }
  ${BundleFragmentFragmentDoc}
`;

/**
 * __useBundlesQuery__
 *
 * To run a query within a React component, call `useBundlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBundlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBundlesQuery({
 *   variables: {
 *      leagueId: // value for 'leagueId'
 *      seasonId: // value for 'seasonId'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useBundlesQuery(
  baseOptions: Apollo.QueryHookOptions<BundlesQuery, BundlesQueryVariables> &
    ({ variables: BundlesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BundlesQuery, BundlesQueryVariables>(BundlesDocument, options);
}
export function useBundlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BundlesQuery, BundlesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BundlesQuery, BundlesQueryVariables>(BundlesDocument, options);
}
export function useBundlesSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BundlesQuery, BundlesQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BundlesQuery, BundlesQueryVariables>(BundlesDocument, options);
}
export type BundlesQueryHookResult = ReturnType<typeof useBundlesQuery>;
export type BundlesLazyQueryHookResult = ReturnType<typeof useBundlesLazyQuery>;
export type BundlesSuspenseQueryHookResult = ReturnType<typeof useBundlesSuspenseQuery>;
export type BundlesQueryResult = Apollo.QueryResult<BundlesQuery, BundlesQueryVariables>;
export const CreateBundleItemDocument = gql`
  mutation CreateBundleItem($bundleId: String!, $input: CreateBundleItemInput!) {
    createBundleItem(bundleId: $bundleId, input: $input) {
      ...BundleItemFragment
    }
  }
  ${BundleItemFragmentFragmentDoc}
`;
export type CreateBundleItemMutationFn = Apollo.MutationFunction<
  CreateBundleItemMutation,
  CreateBundleItemMutationVariables
>;

/**
 * __useCreateBundleItemMutation__
 *
 * To run a mutation, you first call `useCreateBundleItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBundleItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBundleItemMutation, { data, loading, error }] = useCreateBundleItemMutation({
 *   variables: {
 *      bundleId: // value for 'bundleId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBundleItemMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateBundleItemMutation, CreateBundleItemMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBundleItemMutation, CreateBundleItemMutationVariables>(
    CreateBundleItemDocument,
    options,
  );
}
export type CreateBundleItemMutationHookResult = ReturnType<typeof useCreateBundleItemMutation>;
export type CreateBundleItemMutationResult = Apollo.MutationResult<CreateBundleItemMutation>;
export type CreateBundleItemMutationOptions = Apollo.BaseMutationOptions<
  CreateBundleItemMutation,
  CreateBundleItemMutationVariables
>;
export const RemoveBundleItemDocument = gql`
  mutation RemoveBundleItem($bundleId: String!, $itemId: String!) {
    removeBundleItem(bundleId: $bundleId, itemId: $itemId)
  }
`;
export type RemoveBundleItemMutationFn = Apollo.MutationFunction<
  RemoveBundleItemMutation,
  RemoveBundleItemMutationVariables
>;

/**
 * __useRemoveBundleItemMutation__
 *
 * To run a mutation, you first call `useRemoveBundleItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBundleItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBundleItemMutation, { data, loading, error }] = useRemoveBundleItemMutation({
 *   variables: {
 *      bundleId: // value for 'bundleId'
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useRemoveBundleItemMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveBundleItemMutation, RemoveBundleItemMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveBundleItemMutation, RemoveBundleItemMutationVariables>(
    RemoveBundleItemDocument,
    options,
  );
}
export type RemoveBundleItemMutationHookResult = ReturnType<typeof useRemoveBundleItemMutation>;
export type RemoveBundleItemMutationResult = Apollo.MutationResult<RemoveBundleItemMutation>;
export type RemoveBundleItemMutationOptions = Apollo.BaseMutationOptions<
  RemoveBundleItemMutation,
  RemoveBundleItemMutationVariables
>;
export const UpdateBundleItemDocument = gql`
  mutation UpdateBundleItem($bundleId: String!, $input: UpdateBundleItemInput!, $itemId: String!) {
    updateBundleItem(bundleId: $bundleId, input: $input, itemId: $itemId) {
      ...BundleItemFragment
    }
  }
  ${BundleItemFragmentFragmentDoc}
`;
export type UpdateBundleItemMutationFn = Apollo.MutationFunction<
  UpdateBundleItemMutation,
  UpdateBundleItemMutationVariables
>;

/**
 * __useUpdateBundleItemMutation__
 *
 * To run a mutation, you first call `useUpdateBundleItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBundleItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBundleItemMutation, { data, loading, error }] = useUpdateBundleItemMutation({
 *   variables: {
 *      bundleId: // value for 'bundleId'
 *      input: // value for 'input'
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useUpdateBundleItemMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateBundleItemMutation, UpdateBundleItemMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBundleItemMutation, UpdateBundleItemMutationVariables>(
    UpdateBundleItemDocument,
    options,
  );
}
export type UpdateBundleItemMutationHookResult = ReturnType<typeof useUpdateBundleItemMutation>;
export type UpdateBundleItemMutationResult = Apollo.MutationResult<UpdateBundleItemMutation>;
export type UpdateBundleItemMutationOptions = Apollo.BaseMutationOptions<
  UpdateBundleItemMutation,
  UpdateBundleItemMutationVariables
>;
export const CreateBundleDocument = gql`
  mutation CreateBundle($input: CreateBundleInput!) {
    createBundle(input: $input) {
      ...BundleFragment
    }
  }
  ${BundleFragmentFragmentDoc}
`;
export type CreateBundleMutationFn = Apollo.MutationFunction<
  CreateBundleMutation,
  CreateBundleMutationVariables
>;

/**
 * __useCreateBundleMutation__
 *
 * To run a mutation, you first call `useCreateBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBundleMutation, { data, loading, error }] = useCreateBundleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBundleMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateBundleMutation, CreateBundleMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBundleMutation, CreateBundleMutationVariables>(
    CreateBundleDocument,
    options,
  );
}
export type CreateBundleMutationHookResult = ReturnType<typeof useCreateBundleMutation>;
export type CreateBundleMutationResult = Apollo.MutationResult<CreateBundleMutation>;
export type CreateBundleMutationOptions = Apollo.BaseMutationOptions<
  CreateBundleMutation,
  CreateBundleMutationVariables
>;
export const BundleDocument = gql`
  query Bundle($id: String!) {
    bundle(id: $id) {
      ...BundleFragment
    }
  }
  ${BundleFragmentFragmentDoc}
`;

/**
 * __useBundleQuery__
 *
 * To run a query within a React component, call `useBundleQuery` and pass it any options that fit your needs.
 * When your component renders, `useBundleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBundleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBundleQuery(
  baseOptions: Apollo.QueryHookOptions<BundleQuery, BundleQueryVariables> &
    ({ variables: BundleQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BundleQuery, BundleQueryVariables>(BundleDocument, options);
}
export function useBundleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BundleQuery, BundleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BundleQuery, BundleQueryVariables>(BundleDocument, options);
}
export function useBundleSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BundleQuery, BundleQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<BundleQuery, BundleQueryVariables>(BundleDocument, options);
}
export type BundleQueryHookResult = ReturnType<typeof useBundleQuery>;
export type BundleLazyQueryHookResult = ReturnType<typeof useBundleLazyQuery>;
export type BundleSuspenseQueryHookResult = ReturnType<typeof useBundleSuspenseQuery>;
export type BundleQueryResult = Apollo.QueryResult<BundleQuery, BundleQueryVariables>;
export const RemoveBundleDocument = gql`
  mutation RemoveBundle($id: String!) {
    removeBundle(id: $id)
  }
`;
export type RemoveBundleMutationFn = Apollo.MutationFunction<
  RemoveBundleMutation,
  RemoveBundleMutationVariables
>;

/**
 * __useRemoveBundleMutation__
 *
 * To run a mutation, you first call `useRemoveBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBundleMutation, { data, loading, error }] = useRemoveBundleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveBundleMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveBundleMutation, RemoveBundleMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveBundleMutation, RemoveBundleMutationVariables>(
    RemoveBundleDocument,
    options,
  );
}
export type RemoveBundleMutationHookResult = ReturnType<typeof useRemoveBundleMutation>;
export type RemoveBundleMutationResult = Apollo.MutationResult<RemoveBundleMutation>;
export type RemoveBundleMutationOptions = Apollo.BaseMutationOptions<
  RemoveBundleMutation,
  RemoveBundleMutationVariables
>;
export const UpdateBundleDocument = gql`
  mutation UpdateBundle($id: String!, $input: UpdateBundleInput!) {
    updateBundle(id: $id, input: $input) {
      ...BundleFragment
    }
  }
  ${BundleFragmentFragmentDoc}
`;
export type UpdateBundleMutationFn = Apollo.MutationFunction<
  UpdateBundleMutation,
  UpdateBundleMutationVariables
>;

/**
 * __useUpdateBundleMutation__
 *
 * To run a mutation, you first call `useUpdateBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBundleMutation, { data, loading, error }] = useUpdateBundleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBundleMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateBundleMutation, UpdateBundleMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBundleMutation, UpdateBundleMutationVariables>(
    UpdateBundleDocument,
    options,
  );
}
export type UpdateBundleMutationHookResult = ReturnType<typeof useUpdateBundleMutation>;
export type UpdateBundleMutationResult = Apollo.MutationResult<UpdateBundleMutation>;
export type UpdateBundleMutationOptions = Apollo.BaseMutationOptions<
  UpdateBundleMutation,
  UpdateBundleMutationVariables
>;
export const AdminCompetitionsDocument = gql`
  query AdminCompetitions($accountId: String!) {
    adminCompetitions(accountId: $accountId) {
      edges {
        node {
          ...CompetitionFragment
        }
      }
    }
  }
  ${CompetitionFragmentFragmentDoc}
`;

/**
 * __useAdminCompetitionsQuery__
 *
 * To run a query within a React component, call `useAdminCompetitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminCompetitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminCompetitionsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAdminCompetitionsQuery(
  baseOptions: Apollo.QueryHookOptions<AdminCompetitionsQuery, AdminCompetitionsQueryVariables> &
    ({ variables: AdminCompetitionsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminCompetitionsQuery, AdminCompetitionsQueryVariables>(
    AdminCompetitionsDocument,
    options,
  );
}
export function useAdminCompetitionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminCompetitionsQuery, AdminCompetitionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminCompetitionsQuery, AdminCompetitionsQueryVariables>(
    AdminCompetitionsDocument,
    options,
  );
}
export function useAdminCompetitionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AdminCompetitionsQuery, AdminCompetitionsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AdminCompetitionsQuery, AdminCompetitionsQueryVariables>(
    AdminCompetitionsDocument,
    options,
  );
}
export type AdminCompetitionsQueryHookResult = ReturnType<typeof useAdminCompetitionsQuery>;
export type AdminCompetitionsLazyQueryHookResult = ReturnType<typeof useAdminCompetitionsLazyQuery>;
export type AdminCompetitionsSuspenseQueryHookResult = ReturnType<typeof useAdminCompetitionsSuspenseQuery>;
export type AdminCompetitionsQueryResult = Apollo.QueryResult<
  AdminCompetitionsQuery,
  AdminCompetitionsQueryVariables
>;
export const AdminCreateCompetitionDocument = gql`
  mutation AdminCreateCompetition($input: AdminCreateCompetitionInput!) {
    adminCreateCompetition(input: $input) {
      ...CompetitionFragment
    }
  }
  ${CompetitionFragmentFragmentDoc}
`;
export type AdminCreateCompetitionMutationFn = Apollo.MutationFunction<
  AdminCreateCompetitionMutation,
  AdminCreateCompetitionMutationVariables
>;

/**
 * __useAdminCreateCompetitionMutation__
 *
 * To run a mutation, you first call `useAdminCreateCompetitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateCompetitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateCompetitionMutation, { data, loading, error }] = useAdminCreateCompetitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminCreateCompetitionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminCreateCompetitionMutation,
    AdminCreateCompetitionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminCreateCompetitionMutation, AdminCreateCompetitionMutationVariables>(
    AdminCreateCompetitionDocument,
    options,
  );
}
export type AdminCreateCompetitionMutationHookResult = ReturnType<typeof useAdminCreateCompetitionMutation>;
export type AdminCreateCompetitionMutationResult = Apollo.MutationResult<AdminCreateCompetitionMutation>;
export type AdminCreateCompetitionMutationOptions = Apollo.BaseMutationOptions<
  AdminCreateCompetitionMutation,
  AdminCreateCompetitionMutationVariables
>;
export const AdminRemoveCompetitionDocument = gql`
  mutation AdminRemoveCompetition($accountId: String!, $id: String!) {
    adminRemoveCompetition(accountId: $accountId, id: $id)
  }
`;
export type AdminRemoveCompetitionMutationFn = Apollo.MutationFunction<
  AdminRemoveCompetitionMutation,
  AdminRemoveCompetitionMutationVariables
>;

/**
 * __useAdminRemoveCompetitionMutation__
 *
 * To run a mutation, you first call `useAdminRemoveCompetitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminRemoveCompetitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminRemoveCompetitionMutation, { data, loading, error }] = useAdminRemoveCompetitionMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminRemoveCompetitionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminRemoveCompetitionMutation,
    AdminRemoveCompetitionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminRemoveCompetitionMutation, AdminRemoveCompetitionMutationVariables>(
    AdminRemoveCompetitionDocument,
    options,
  );
}
export type AdminRemoveCompetitionMutationHookResult = ReturnType<typeof useAdminRemoveCompetitionMutation>;
export type AdminRemoveCompetitionMutationResult = Apollo.MutationResult<AdminRemoveCompetitionMutation>;
export type AdminRemoveCompetitionMutationOptions = Apollo.BaseMutationOptions<
  AdminRemoveCompetitionMutation,
  AdminRemoveCompetitionMutationVariables
>;
export const AdminUpdateCompetitionDocument = gql`
  mutation AdminUpdateCompetition($accountId: String!, $id: String!, $input: UpdateCompetitionInput!) {
    adminUpdateCompetition(accountId: $accountId, id: $id, input: $input) {
      ...CompetitionFragment
    }
  }
  ${CompetitionFragmentFragmentDoc}
`;
export type AdminUpdateCompetitionMutationFn = Apollo.MutationFunction<
  AdminUpdateCompetitionMutation,
  AdminUpdateCompetitionMutationVariables
>;

/**
 * __useAdminUpdateCompetitionMutation__
 *
 * To run a mutation, you first call `useAdminUpdateCompetitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateCompetitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateCompetitionMutation, { data, loading, error }] = useAdminUpdateCompetitionMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminUpdateCompetitionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminUpdateCompetitionMutation,
    AdminUpdateCompetitionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminUpdateCompetitionMutation, AdminUpdateCompetitionMutationVariables>(
    AdminUpdateCompetitionDocument,
    options,
  );
}
export type AdminUpdateCompetitionMutationHookResult = ReturnType<typeof useAdminUpdateCompetitionMutation>;
export type AdminUpdateCompetitionMutationResult = Apollo.MutationResult<AdminUpdateCompetitionMutation>;
export type AdminUpdateCompetitionMutationOptions = Apollo.BaseMutationOptions<
  AdminUpdateCompetitionMutation,
  AdminUpdateCompetitionMutationVariables
>;
export const CompetitionsDocument = gql`
  query Competitions {
    competitions {
      edges {
        node {
          ...CompetitionFragment
        }
      }
    }
  }
  ${CompetitionFragmentFragmentDoc}
`;

/**
 * __useCompetitionsQuery__
 *
 * To run a query within a React component, call `useCompetitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompetitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompetitionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompetitionsQuery(
  baseOptions?: Apollo.QueryHookOptions<CompetitionsQuery, CompetitionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompetitionsQuery, CompetitionsQueryVariables>(CompetitionsDocument, options);
}
export function useCompetitionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CompetitionsQuery, CompetitionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompetitionsQuery, CompetitionsQueryVariables>(CompetitionsDocument, options);
}
export function useCompetitionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<CompetitionsQuery, CompetitionsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CompetitionsQuery, CompetitionsQueryVariables>(
    CompetitionsDocument,
    options,
  );
}
export type CompetitionsQueryHookResult = ReturnType<typeof useCompetitionsQuery>;
export type CompetitionsLazyQueryHookResult = ReturnType<typeof useCompetitionsLazyQuery>;
export type CompetitionsSuspenseQueryHookResult = ReturnType<typeof useCompetitionsSuspenseQuery>;
export type CompetitionsQueryResult = Apollo.QueryResult<CompetitionsQuery, CompetitionsQueryVariables>;
export const UpdateCompetitionDocument = gql`
  mutation UpdateCompetition($id: String!, $input: UpdateCompetitionInput!) {
    updateCompetition(id: $id, input: $input) {
      ...CompetitionFragment
    }
  }
  ${CompetitionFragmentFragmentDoc}
`;
export type UpdateCompetitionMutationFn = Apollo.MutationFunction<
  UpdateCompetitionMutation,
  UpdateCompetitionMutationVariables
>;

/**
 * __useUpdateCompetitionMutation__
 *
 * To run a mutation, you first call `useUpdateCompetitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompetitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompetitionMutation, { data, loading, error }] = useUpdateCompetitionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompetitionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCompetitionMutation, UpdateCompetitionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCompetitionMutation, UpdateCompetitionMutationVariables>(
    UpdateCompetitionDocument,
    options,
  );
}
export type UpdateCompetitionMutationHookResult = ReturnType<typeof useUpdateCompetitionMutation>;
export type UpdateCompetitionMutationResult = Apollo.MutationResult<UpdateCompetitionMutation>;
export type UpdateCompetitionMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompetitionMutation,
  UpdateCompetitionMutationVariables
>;
export const ContractTemplatesDocument = gql`
  query ContractTemplates {
    contractTemplates {
      edges {
        node {
          ...ContractTemplateFragment
        }
      }
    }
  }
  ${ContractTemplateFragmentFragmentDoc}
`;

/**
 * __useContractTemplatesQuery__
 *
 * To run a query within a React component, call `useContractTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useContractTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<ContractTemplatesQuery, ContractTemplatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ContractTemplatesQuery, ContractTemplatesQueryVariables>(
    ContractTemplatesDocument,
    options,
  );
}
export function useContractTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ContractTemplatesQuery, ContractTemplatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ContractTemplatesQuery, ContractTemplatesQueryVariables>(
    ContractTemplatesDocument,
    options,
  );
}
export function useContractTemplatesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ContractTemplatesQuery, ContractTemplatesQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ContractTemplatesQuery, ContractTemplatesQueryVariables>(
    ContractTemplatesDocument,
    options,
  );
}
export type ContractTemplatesQueryHookResult = ReturnType<typeof useContractTemplatesQuery>;
export type ContractTemplatesLazyQueryHookResult = ReturnType<typeof useContractTemplatesLazyQuery>;
export type ContractTemplatesSuspenseQueryHookResult = ReturnType<typeof useContractTemplatesSuspenseQuery>;
export type ContractTemplatesQueryResult = Apollo.QueryResult<
  ContractTemplatesQuery,
  ContractTemplatesQueryVariables
>;
export const ContractTemplateDocument = gql`
  query ContractTemplate($id: String!) {
    contractTemplate(id: $id) {
      ...ContractTemplateFragment
    }
  }
  ${ContractTemplateFragmentFragmentDoc}
`;

/**
 * __useContractTemplateQuery__
 *
 * To run a query within a React component, call `useContractTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContractTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<ContractTemplateQuery, ContractTemplateQueryVariables> &
    ({ variables: ContractTemplateQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ContractTemplateQuery, ContractTemplateQueryVariables>(
    ContractTemplateDocument,
    options,
  );
}
export function useContractTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ContractTemplateQuery, ContractTemplateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ContractTemplateQuery, ContractTemplateQueryVariables>(
    ContractTemplateDocument,
    options,
  );
}
export function useContractTemplateSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ContractTemplateQuery, ContractTemplateQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ContractTemplateQuery, ContractTemplateQueryVariables>(
    ContractTemplateDocument,
    options,
  );
}
export type ContractTemplateQueryHookResult = ReturnType<typeof useContractTemplateQuery>;
export type ContractTemplateLazyQueryHookResult = ReturnType<typeof useContractTemplateLazyQuery>;
export type ContractTemplateSuspenseQueryHookResult = ReturnType<typeof useContractTemplateSuspenseQuery>;
export type ContractTemplateQueryResult = Apollo.QueryResult<
  ContractTemplateQuery,
  ContractTemplateQueryVariables
>;
export const CreateContractTemplateDocument = gql`
  mutation CreateContractTemplate($input: CreateContractTemplateInput!) {
    createContractTemplate(input: $input) {
      ...ContractTemplateFragment
    }
  }
  ${ContractTemplateFragmentFragmentDoc}
`;
export type CreateContractTemplateMutationFn = Apollo.MutationFunction<
  CreateContractTemplateMutation,
  CreateContractTemplateMutationVariables
>;

/**
 * __useCreateContractTemplateMutation__
 *
 * To run a mutation, you first call `useCreateContractTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractTemplateMutation, { data, loading, error }] = useCreateContractTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContractTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateContractTemplateMutation,
    CreateContractTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateContractTemplateMutation, CreateContractTemplateMutationVariables>(
    CreateContractTemplateDocument,
    options,
  );
}
export type CreateContractTemplateMutationHookResult = ReturnType<typeof useCreateContractTemplateMutation>;
export type CreateContractTemplateMutationResult = Apollo.MutationResult<CreateContractTemplateMutation>;
export type CreateContractTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateContractTemplateMutation,
  CreateContractTemplateMutationVariables
>;
export const RemoveContractTemplateDocument = gql`
  mutation RemoveContractTemplate($id: String!) {
    removeContractTemplate(id: $id)
  }
`;
export type RemoveContractTemplateMutationFn = Apollo.MutationFunction<
  RemoveContractTemplateMutation,
  RemoveContractTemplateMutationVariables
>;

/**
 * __useRemoveContractTemplateMutation__
 *
 * To run a mutation, you first call `useRemoveContractTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveContractTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeContractTemplateMutation, { data, loading, error }] = useRemoveContractTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveContractTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveContractTemplateMutation,
    RemoveContractTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveContractTemplateMutation, RemoveContractTemplateMutationVariables>(
    RemoveContractTemplateDocument,
    options,
  );
}
export type RemoveContractTemplateMutationHookResult = ReturnType<typeof useRemoveContractTemplateMutation>;
export type RemoveContractTemplateMutationResult = Apollo.MutationResult<RemoveContractTemplateMutation>;
export type RemoveContractTemplateMutationOptions = Apollo.BaseMutationOptions<
  RemoveContractTemplateMutation,
  RemoveContractTemplateMutationVariables
>;
export const UpdateContractTemplateDocument = gql`
  mutation UpdateContractTemplate($id: String!, $input: UpdateContractTemplateInput!) {
    updateContractTemplate(id: $id, input: $input) {
      ...ContractTemplateFragment
    }
  }
  ${ContractTemplateFragmentFragmentDoc}
`;
export type UpdateContractTemplateMutationFn = Apollo.MutationFunction<
  UpdateContractTemplateMutation,
  UpdateContractTemplateMutationVariables
>;

/**
 * __useUpdateContractTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateContractTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractTemplateMutation, { data, loading, error }] = useUpdateContractTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContractTemplateMutation,
    UpdateContractTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateContractTemplateMutation, UpdateContractTemplateMutationVariables>(
    UpdateContractTemplateDocument,
    options,
  );
}
export type UpdateContractTemplateMutationHookResult = ReturnType<typeof useUpdateContractTemplateMutation>;
export type UpdateContractTemplateMutationResult = Apollo.MutationResult<UpdateContractTemplateMutation>;
export type UpdateContractTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpdateContractTemplateMutation,
  UpdateContractTemplateMutationVariables
>;
export const ContractsWithTasksDocument = gql`
  query ContractsWithTasks(
    $leagueIds: [String!]!
    $seasonId: String!
    $type: ContractType!
    $relevance: ContractRelevanceFilter
    $search: String
    $clubLeadId: String
    $salesLeadId: String
    $partnerCategoryId: String
    $status: ContractStatusFilter
  ) {
    contracts(
      leagueIds: $leagueIds
      seasonId: $seasonId
      type: $type
      relevance: $relevance
      clubLeadId: $clubLeadId
      salesLeadId: $salesLeadId
      partnerCategoryId: $partnerCategoryId
      search: $search
      status: $status
    ) {
      edges {
        node {
          id
          name
          type
          draft
          startDate
          endDate
          salesLead {
            id
          }
          clubLead {
            id
          }
          note
          notes {
            ...ContractNoteFragment
          }
          partner {
            id
            companyName
            clubLead {
              id
            }
            contractClubLeads {
              id
            }
            category {
              id
              name
            }
          }
          items {
            id
            amount
            league {
              id
              name
            }
            season {
              id
              name
            }
            match {
              id
              name
            }
            inventory {
              id
              name
              unit
            }
            task {
              id
              name
              totalTasks
              customAmount
              updatedAt
              lastEdit {
                timestamp
              }
              stats {
                ...TaskStatsFragment
              }
            }
          }
          tasks {
            id
            name
            totalTasks
            customAmount
            updatedAt
            lastEdit {
              timestamp
            }
            stats {
              ...TaskStatsFragment
            }
          }
          total {
            price
          }
        }
      }
      totals {
        totalTasks
        totalSubTasks
        totalOpen
        totalInProgress
        totalDone
      }
    }
  }
  ${ContractNoteFragmentFragmentDoc}
  ${TaskStatsFragmentFragmentDoc}
`;

/**
 * __useContractsWithTasksQuery__
 *
 * To run a query within a React component, call `useContractsWithTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractsWithTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractsWithTasksQuery({
 *   variables: {
 *      leagueIds: // value for 'leagueIds'
 *      seasonId: // value for 'seasonId'
 *      type: // value for 'type'
 *      relevance: // value for 'relevance'
 *      search: // value for 'search'
 *      clubLeadId: // value for 'clubLeadId'
 *      salesLeadId: // value for 'salesLeadId'
 *      partnerCategoryId: // value for 'partnerCategoryId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useContractsWithTasksQuery(
  baseOptions: Apollo.QueryHookOptions<ContractsWithTasksQuery, ContractsWithTasksQueryVariables> &
    ({ variables: ContractsWithTasksQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ContractsWithTasksQuery, ContractsWithTasksQueryVariables>(
    ContractsWithTasksDocument,
    options,
  );
}
export function useContractsWithTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ContractsWithTasksQuery, ContractsWithTasksQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ContractsWithTasksQuery, ContractsWithTasksQueryVariables>(
    ContractsWithTasksDocument,
    options,
  );
}
export function useContractsWithTasksSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<ContractsWithTasksQuery, ContractsWithTasksQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ContractsWithTasksQuery, ContractsWithTasksQueryVariables>(
    ContractsWithTasksDocument,
    options,
  );
}
export type ContractsWithTasksQueryHookResult = ReturnType<typeof useContractsWithTasksQuery>;
export type ContractsWithTasksLazyQueryHookResult = ReturnType<typeof useContractsWithTasksLazyQuery>;
export type ContractsWithTasksSuspenseQueryHookResult = ReturnType<typeof useContractsWithTasksSuspenseQuery>;
export type ContractsWithTasksQueryResult = Apollo.QueryResult<
  ContractsWithTasksQuery,
  ContractsWithTasksQueryVariables
>;
export const ContractsDocument = gql`
  query Contracts(
    $leagueIds: [String!]!
    $seasonId: String!
    $type: ContractType!
    $relevance: ContractRelevanceFilter
    $search: String
    $clubLeadId: String
    $salesLeadId: String
    $partnerCategoryId: String
    $status: ContractStatusFilter
    $autoRenewOnly: Boolean
    $expiringOnly: Boolean
    $probability: Float
  ) {
    contracts(
      leagueIds: $leagueIds
      seasonId: $seasonId
      relevance: $relevance
      type: $type
      clubLeadId: $clubLeadId
      salesLeadId: $salesLeadId
      partnerCategoryId: $partnerCategoryId
      search: $search
      status: $status
      autoRenewOnly: $autoRenewOnly
      expiringOnly: $expiringOnly
      probability: $probability
    ) {
      edges {
        node {
          ...ContractFragment
        }
      }
      totals {
        bonuses
        bonusesValue
        bonusesAchieved
        bonusesAchievedValue
        discount
        totalBarter
        totalContracts
        totalPrice
        futureInvoiceTotal
        openInvoiceTotal
        pendingInvoiceTotal
        overdueInvoiceTotal
        payedInvoiceTotal
        totalDone
        totalInProgress
        totalOpen
        totalSubTasks
        totalTasks
      }
    }
  }
  ${ContractFragmentFragmentDoc}
`;

/**
 * __useContractsQuery__
 *
 * To run a query within a React component, call `useContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractsQuery({
 *   variables: {
 *      leagueIds: // value for 'leagueIds'
 *      seasonId: // value for 'seasonId'
 *      type: // value for 'type'
 *      relevance: // value for 'relevance'
 *      search: // value for 'search'
 *      clubLeadId: // value for 'clubLeadId'
 *      salesLeadId: // value for 'salesLeadId'
 *      partnerCategoryId: // value for 'partnerCategoryId'
 *      status: // value for 'status'
 *      autoRenewOnly: // value for 'autoRenewOnly'
 *      expiringOnly: // value for 'expiringOnly'
 *      probability: // value for 'probability'
 *   },
 * });
 */
export function useContractsQuery(
  baseOptions: Apollo.QueryHookOptions<ContractsQuery, ContractsQueryVariables> &
    ({ variables: ContractsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ContractsQuery, ContractsQueryVariables>(ContractsDocument, options);
}
export function useContractsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ContractsQuery, ContractsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ContractsQuery, ContractsQueryVariables>(ContractsDocument, options);
}
export function useContractsSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ContractsQuery, ContractsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ContractsQuery, ContractsQueryVariables>(ContractsDocument, options);
}
export type ContractsQueryHookResult = ReturnType<typeof useContractsQuery>;
export type ContractsLazyQueryHookResult = ReturnType<typeof useContractsLazyQuery>;
export type ContractsSuspenseQueryHookResult = ReturnType<typeof useContractsSuspenseQuery>;
export type ContractsQueryResult = Apollo.QueryResult<ContractsQuery, ContractsQueryVariables>;
export const ApplyContractPercentageDocument = gql`
  mutation ApplyContractPercentage(
    $contractId: String!
    $leagueId: String
    $seasonId: String
    $barterPercentage: Float
    $barterFixed: Float
    $discountPercentage: Float
    $discountFixed: Float
  ) {
    applyContractPercentage(
      contractId: $contractId
      barterPercentage: $barterPercentage
      barterFixed: $barterFixed
      discountPercentage: $discountPercentage
      discountFixed: $discountFixed
      leagueId: $leagueId
      seasonId: $seasonId
    ) {
      ...SingleContractFragment
    }
  }
  ${SingleContractFragmentFragmentDoc}
`;
export type ApplyContractPercentageMutationFn = Apollo.MutationFunction<
  ApplyContractPercentageMutation,
  ApplyContractPercentageMutationVariables
>;

/**
 * __useApplyContractPercentageMutation__
 *
 * To run a mutation, you first call `useApplyContractPercentageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyContractPercentageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyContractPercentageMutation, { data, loading, error }] = useApplyContractPercentageMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      leagueId: // value for 'leagueId'
 *      seasonId: // value for 'seasonId'
 *      barterPercentage: // value for 'barterPercentage'
 *      barterFixed: // value for 'barterFixed'
 *      discountPercentage: // value for 'discountPercentage'
 *      discountFixed: // value for 'discountFixed'
 *   },
 * });
 */
export function useApplyContractPercentageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApplyContractPercentageMutation,
    ApplyContractPercentageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ApplyContractPercentageMutation, ApplyContractPercentageMutationVariables>(
    ApplyContractPercentageDocument,
    options,
  );
}
export type ApplyContractPercentageMutationHookResult = ReturnType<typeof useApplyContractPercentageMutation>;
export type ApplyContractPercentageMutationResult = Apollo.MutationResult<ApplyContractPercentageMutation>;
export type ApplyContractPercentageMutationOptions = Apollo.BaseMutationOptions<
  ApplyContractPercentageMutation,
  ApplyContractPercentageMutationVariables
>;
export const CreateContractBonusDocument = gql`
  mutation CreateContractBonus($contractId: String!, $input: CreateContractBonusInput!) {
    createContractBonus(contractId: $contractId, input: $input) {
      ...SingleContractFragment
    }
  }
  ${SingleContractFragmentFragmentDoc}
`;
export type CreateContractBonusMutationFn = Apollo.MutationFunction<
  CreateContractBonusMutation,
  CreateContractBonusMutationVariables
>;

/**
 * __useCreateContractBonusMutation__
 *
 * To run a mutation, you first call `useCreateContractBonusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractBonusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractBonusMutation, { data, loading, error }] = useCreateContractBonusMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContractBonusMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateContractBonusMutation, CreateContractBonusMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateContractBonusMutation, CreateContractBonusMutationVariables>(
    CreateContractBonusDocument,
    options,
  );
}
export type CreateContractBonusMutationHookResult = ReturnType<typeof useCreateContractBonusMutation>;
export type CreateContractBonusMutationResult = Apollo.MutationResult<CreateContractBonusMutation>;
export type CreateContractBonusMutationOptions = Apollo.BaseMutationOptions<
  CreateContractBonusMutation,
  CreateContractBonusMutationVariables
>;
export const RemoveContractBonusDocument = gql`
  mutation RemoveContractBonus($bonusId: String!, $contractId: String!) {
    removeContractBonus(bonusId: $bonusId, contractId: $contractId)
  }
`;
export type RemoveContractBonusMutationFn = Apollo.MutationFunction<
  RemoveContractBonusMutation,
  RemoveContractBonusMutationVariables
>;

/**
 * __useRemoveContractBonusMutation__
 *
 * To run a mutation, you first call `useRemoveContractBonusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveContractBonusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeContractBonusMutation, { data, loading, error }] = useRemoveContractBonusMutation({
 *   variables: {
 *      bonusId: // value for 'bonusId'
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useRemoveContractBonusMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveContractBonusMutation, RemoveContractBonusMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveContractBonusMutation, RemoveContractBonusMutationVariables>(
    RemoveContractBonusDocument,
    options,
  );
}
export type RemoveContractBonusMutationHookResult = ReturnType<typeof useRemoveContractBonusMutation>;
export type RemoveContractBonusMutationResult = Apollo.MutationResult<RemoveContractBonusMutation>;
export type RemoveContractBonusMutationOptions = Apollo.BaseMutationOptions<
  RemoveContractBonusMutation,
  RemoveContractBonusMutationVariables
>;
export const UpdateContractBonusDocument = gql`
  mutation UpdateContractBonus(
    $bonusId: String!
    $contractId: String!
    $input: UpdateContractBonusInput!
    $seasonIds: [String!]
  ) {
    updateContractBonus(bonusId: $bonusId, contractId: $contractId, input: $input, seasonIds: $seasonIds) {
      ...SingleContractFragment
    }
  }
  ${SingleContractFragmentFragmentDoc}
`;
export type UpdateContractBonusMutationFn = Apollo.MutationFunction<
  UpdateContractBonusMutation,
  UpdateContractBonusMutationVariables
>;

/**
 * __useUpdateContractBonusMutation__
 *
 * To run a mutation, you first call `useUpdateContractBonusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractBonusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractBonusMutation, { data, loading, error }] = useUpdateContractBonusMutation({
 *   variables: {
 *      bonusId: // value for 'bonusId'
 *      contractId: // value for 'contractId'
 *      input: // value for 'input'
 *      seasonIds: // value for 'seasonIds'
 *   },
 * });
 */
export function useUpdateContractBonusMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateContractBonusMutation, UpdateContractBonusMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateContractBonusMutation, UpdateContractBonusMutationVariables>(
    UpdateContractBonusDocument,
    options,
  );
}
export type UpdateContractBonusMutationHookResult = ReturnType<typeof useUpdateContractBonusMutation>;
export type UpdateContractBonusMutationResult = Apollo.MutationResult<UpdateContractBonusMutation>;
export type UpdateContractBonusMutationOptions = Apollo.BaseMutationOptions<
  UpdateContractBonusMutation,
  UpdateContractBonusMutationVariables
>;
export const CancelContractDocument = gql`
  mutation CancelContract($cancelContractId: String!, $status: ContractCancelStatus!) {
    cancelContract(id: $cancelContractId, status: $status) {
      ...SingleContractFragment
    }
  }
  ${SingleContractFragmentFragmentDoc}
`;
export type CancelContractMutationFn = Apollo.MutationFunction<
  CancelContractMutation,
  CancelContractMutationVariables
>;

/**
 * __useCancelContractMutation__
 *
 * To run a mutation, you first call `useCancelContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelContractMutation, { data, loading, error }] = useCancelContractMutation({
 *   variables: {
 *      cancelContractId: // value for 'cancelContractId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useCancelContractMutation(
  baseOptions?: Apollo.MutationHookOptions<CancelContractMutation, CancelContractMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelContractMutation, CancelContractMutationVariables>(
    CancelContractDocument,
    options,
  );
}
export type CancelContractMutationHookResult = ReturnType<typeof useCancelContractMutation>;
export type CancelContractMutationResult = Apollo.MutationResult<CancelContractMutation>;
export type CancelContractMutationOptions = Apollo.BaseMutationOptions<
  CancelContractMutation,
  CancelContractMutationVariables
>;
export const CloneContractDocument = gql`
  mutation CloneContract($id: String!, $status: ContractType) {
    cloneContract(id: $id, status: $status) {
      ...SingleContractFragment
    }
  }
  ${SingleContractFragmentFragmentDoc}
`;
export type CloneContractMutationFn = Apollo.MutationFunction<
  CloneContractMutation,
  CloneContractMutationVariables
>;

/**
 * __useCloneContractMutation__
 *
 * To run a mutation, you first call `useCloneContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneContractMutation, { data, loading, error }] = useCloneContractMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useCloneContractMutation(
  baseOptions?: Apollo.MutationHookOptions<CloneContractMutation, CloneContractMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CloneContractMutation, CloneContractMutationVariables>(
    CloneContractDocument,
    options,
  );
}
export type CloneContractMutationHookResult = ReturnType<typeof useCloneContractMutation>;
export type CloneContractMutationResult = Apollo.MutationResult<CloneContractMutation>;
export type CloneContractMutationOptions = Apollo.BaseMutationOptions<
  CloneContractMutation,
  CloneContractMutationVariables
>;
export const ContractAddBundleDocument = gql`
  mutation ContractAddBundle($bundleId: String!, $contractId: String!) {
    contractAddBundle(bundleId: $bundleId, contractId: $contractId) {
      ...SingleContractFragment
    }
  }
  ${SingleContractFragmentFragmentDoc}
`;
export type ContractAddBundleMutationFn = Apollo.MutationFunction<
  ContractAddBundleMutation,
  ContractAddBundleMutationVariables
>;

/**
 * __useContractAddBundleMutation__
 *
 * To run a mutation, you first call `useContractAddBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContractAddBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contractAddBundleMutation, { data, loading, error }] = useContractAddBundleMutation({
 *   variables: {
 *      bundleId: // value for 'bundleId'
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useContractAddBundleMutation(
  baseOptions?: Apollo.MutationHookOptions<ContractAddBundleMutation, ContractAddBundleMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ContractAddBundleMutation, ContractAddBundleMutationVariables>(
    ContractAddBundleDocument,
    options,
  );
}
export type ContractAddBundleMutationHookResult = ReturnType<typeof useContractAddBundleMutation>;
export type ContractAddBundleMutationResult = Apollo.MutationResult<ContractAddBundleMutation>;
export type ContractAddBundleMutationOptions = Apollo.BaseMutationOptions<
  ContractAddBundleMutation,
  ContractAddBundleMutationVariables
>;
export const CreateContractNoteDocument = gql`
  mutation CreateContractNote($input: CreateContractNoteInput!, $contractId: String!) {
    createContractNote(input: $input, contractId: $contractId) {
      ...ContractNoteFragment
    }
  }
  ${ContractNoteFragmentFragmentDoc}
`;
export type CreateContractNoteMutationFn = Apollo.MutationFunction<
  CreateContractNoteMutation,
  CreateContractNoteMutationVariables
>;

/**
 * __useCreateContractNoteMutation__
 *
 * To run a mutation, you first call `useCreateContractNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractNoteMutation, { data, loading, error }] = useCreateContractNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useCreateContractNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateContractNoteMutation, CreateContractNoteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateContractNoteMutation, CreateContractNoteMutationVariables>(
    CreateContractNoteDocument,
    options,
  );
}
export type CreateContractNoteMutationHookResult = ReturnType<typeof useCreateContractNoteMutation>;
export type CreateContractNoteMutationResult = Apollo.MutationResult<CreateContractNoteMutation>;
export type CreateContractNoteMutationOptions = Apollo.BaseMutationOptions<
  CreateContractNoteMutation,
  CreateContractNoteMutationVariables
>;
export const RemoveContractNoteDocument = gql`
  mutation RemoveContractNote($noteId: String!, $contractId: String!) {
    removeContractNote(noteId: $noteId, contractId: $contractId)
  }
`;
export type RemoveContractNoteMutationFn = Apollo.MutationFunction<
  RemoveContractNoteMutation,
  RemoveContractNoteMutationVariables
>;

/**
 * __useRemoveContractNoteMutation__
 *
 * To run a mutation, you first call `useRemoveContractNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveContractNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeContractNoteMutation, { data, loading, error }] = useRemoveContractNoteMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useRemoveContractNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveContractNoteMutation, RemoveContractNoteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveContractNoteMutation, RemoveContractNoteMutationVariables>(
    RemoveContractNoteDocument,
    options,
  );
}
export type RemoveContractNoteMutationHookResult = ReturnType<typeof useRemoveContractNoteMutation>;
export type RemoveContractNoteMutationResult = Apollo.MutationResult<RemoveContractNoteMutation>;
export type RemoveContractNoteMutationOptions = Apollo.BaseMutationOptions<
  RemoveContractNoteMutation,
  RemoveContractNoteMutationVariables
>;
export const UpdateContractNoteDocument = gql`
  mutation UpdateContractNote($input: UpdateContractNoteInput!, $noteId: String!, $contractId: String!) {
    updateContractNote(input: $input, noteId: $noteId, contractId: $contractId) {
      ...ContractNoteFragment
    }
  }
  ${ContractNoteFragmentFragmentDoc}
`;
export type UpdateContractNoteMutationFn = Apollo.MutationFunction<
  UpdateContractNoteMutation,
  UpdateContractNoteMutationVariables
>;

/**
 * __useUpdateContractNoteMutation__
 *
 * To run a mutation, you first call `useUpdateContractNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractNoteMutation, { data, loading, error }] = useUpdateContractNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *      noteId: // value for 'noteId'
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useUpdateContractNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateContractNoteMutation, UpdateContractNoteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateContractNoteMutation, UpdateContractNoteMutationVariables>(
    UpdateContractNoteDocument,
    options,
  );
}
export type UpdateContractNoteMutationHookResult = ReturnType<typeof useUpdateContractNoteMutation>;
export type UpdateContractNoteMutationResult = Apollo.MutationResult<UpdateContractNoteMutation>;
export type UpdateContractNoteMutationOptions = Apollo.BaseMutationOptions<
  UpdateContractNoteMutation,
  UpdateContractNoteMutationVariables
>;
export const ContractRemoveBundleDocument = gql`
  mutation ContractRemoveBundle($bundleId: String!, $contractId: String!) {
    contractAddBundle(bundleId: $bundleId, contractId: $contractId) {
      ...SingleContractFragment
    }
  }
  ${SingleContractFragmentFragmentDoc}
`;
export type ContractRemoveBundleMutationFn = Apollo.MutationFunction<
  ContractRemoveBundleMutation,
  ContractRemoveBundleMutationVariables
>;

/**
 * __useContractRemoveBundleMutation__
 *
 * To run a mutation, you first call `useContractRemoveBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContractRemoveBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contractRemoveBundleMutation, { data, loading, error }] = useContractRemoveBundleMutation({
 *   variables: {
 *      bundleId: // value for 'bundleId'
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useContractRemoveBundleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ContractRemoveBundleMutation,
    ContractRemoveBundleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ContractRemoveBundleMutation, ContractRemoveBundleMutationVariables>(
    ContractRemoveBundleDocument,
    options,
  );
}
export type ContractRemoveBundleMutationHookResult = ReturnType<typeof useContractRemoveBundleMutation>;
export type ContractRemoveBundleMutationResult = Apollo.MutationResult<ContractRemoveBundleMutation>;
export type ContractRemoveBundleMutationOptions = Apollo.BaseMutationOptions<
  ContractRemoveBundleMutation,
  ContractRemoveBundleMutationVariables
>;
export const ConvertToContractDocument = gql`
  mutation ConvertToContract($contractId: String!) {
    convertToContract(id: $contractId) {
      ...SingleContractFragment
    }
  }
  ${SingleContractFragmentFragmentDoc}
`;
export type ConvertToContractMutationFn = Apollo.MutationFunction<
  ConvertToContractMutation,
  ConvertToContractMutationVariables
>;

/**
 * __useConvertToContractMutation__
 *
 * To run a mutation, you first call `useConvertToContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConvertToContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [convertToContractMutation, { data, loading, error }] = useConvertToContractMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useConvertToContractMutation(
  baseOptions?: Apollo.MutationHookOptions<ConvertToContractMutation, ConvertToContractMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConvertToContractMutation, ConvertToContractMutationVariables>(
    ConvertToContractDocument,
    options,
  );
}
export type ConvertToContractMutationHookResult = ReturnType<typeof useConvertToContractMutation>;
export type ConvertToContractMutationResult = Apollo.MutationResult<ConvertToContractMutation>;
export type ConvertToContractMutationOptions = Apollo.BaseMutationOptions<
  ConvertToContractMutation,
  ConvertToContractMutationVariables
>;
export const CreateContractDocument = gql`
  mutation CreateContract($input: CreateContractInput!) {
    createContract(input: $input) {
      ...SingleContractFragment
    }
  }
  ${SingleContractFragmentFragmentDoc}
`;
export type CreateContractMutationFn = Apollo.MutationFunction<
  CreateContractMutation,
  CreateContractMutationVariables
>;

/**
 * __useCreateContractMutation__
 *
 * To run a mutation, you first call `useCreateContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractMutation, { data, loading, error }] = useCreateContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContractMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateContractMutation, CreateContractMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateContractMutation, CreateContractMutationVariables>(
    CreateContractDocument,
    options,
  );
}
export type CreateContractMutationHookResult = ReturnType<typeof useCreateContractMutation>;
export type CreateContractMutationResult = Apollo.MutationResult<CreateContractMutation>;
export type CreateContractMutationOptions = Apollo.BaseMutationOptions<
  CreateContractMutation,
  CreateContractMutationVariables
>;
export const ContractDocument = gql`
  query Contract($contractId: String!, $seasonId: String) {
    contract(id: $contractId, seasonId: $seasonId) {
      ...SingleContractFragment
    }
  }
  ${SingleContractFragmentFragmentDoc}
`;

/**
 * __useContractQuery__
 *
 * To run a query within a React component, call `useContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractQuery({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useContractQuery(
  baseOptions: Apollo.QueryHookOptions<ContractQuery, ContractQueryVariables> &
    ({ variables: ContractQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ContractQuery, ContractQueryVariables>(ContractDocument, options);
}
export function useContractLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ContractQuery, ContractQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ContractQuery, ContractQueryVariables>(ContractDocument, options);
}
export function useContractSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ContractQuery, ContractQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ContractQuery, ContractQueryVariables>(ContractDocument, options);
}
export type ContractQueryHookResult = ReturnType<typeof useContractQuery>;
export type ContractLazyQueryHookResult = ReturnType<typeof useContractLazyQuery>;
export type ContractSuspenseQueryHookResult = ReturnType<typeof useContractSuspenseQuery>;
export type ContractQueryResult = Apollo.QueryResult<ContractQuery, ContractQueryVariables>;
export const IssueContractInvoiceCancellationNumberDocument = gql`
  mutation IssueContractInvoiceCancellationNumber($contractId: String!, $invoiceId: String!) {
    issueContractInvoiceCancellationNumber(contractId: $contractId, invoiceId: $invoiceId) {
      ...InvoiceFragment
    }
  }
  ${InvoiceFragmentFragmentDoc}
`;
export type IssueContractInvoiceCancellationNumberMutationFn = Apollo.MutationFunction<
  IssueContractInvoiceCancellationNumberMutation,
  IssueContractInvoiceCancellationNumberMutationVariables
>;

/**
 * __useIssueContractInvoiceCancellationNumberMutation__
 *
 * To run a mutation, you first call `useIssueContractInvoiceCancellationNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssueContractInvoiceCancellationNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issueContractInvoiceCancellationNumberMutation, { data, loading, error }] = useIssueContractInvoiceCancellationNumberMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useIssueContractInvoiceCancellationNumberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IssueContractInvoiceCancellationNumberMutation,
    IssueContractInvoiceCancellationNumberMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IssueContractInvoiceCancellationNumberMutation,
    IssueContractInvoiceCancellationNumberMutationVariables
  >(IssueContractInvoiceCancellationNumberDocument, options);
}
export type IssueContractInvoiceCancellationNumberMutationHookResult = ReturnType<
  typeof useIssueContractInvoiceCancellationNumberMutation
>;
export type IssueContractInvoiceCancellationNumberMutationResult =
  Apollo.MutationResult<IssueContractInvoiceCancellationNumberMutation>;
export type IssueContractInvoiceCancellationNumberMutationOptions = Apollo.BaseMutationOptions<
  IssueContractInvoiceCancellationNumberMutation,
  IssueContractInvoiceCancellationNumberMutationVariables
>;
export const IssueContractInvoiceDocument = gql`
  mutation IssueContractInvoice($contractId: String!, $invoiceId: String!) {
    issueContractInvoice(contractId: $contractId, invoiceId: $invoiceId) {
      ...InvoiceFragment
    }
  }
  ${InvoiceFragmentFragmentDoc}
`;
export type IssueContractInvoiceMutationFn = Apollo.MutationFunction<
  IssueContractInvoiceMutation,
  IssueContractInvoiceMutationVariables
>;

/**
 * __useIssueContractInvoiceMutation__
 *
 * To run a mutation, you first call `useIssueContractInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssueContractInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issueContractInvoiceMutation, { data, loading, error }] = useIssueContractInvoiceMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useIssueContractInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IssueContractInvoiceMutation,
    IssueContractInvoiceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IssueContractInvoiceMutation, IssueContractInvoiceMutationVariables>(
    IssueContractInvoiceDocument,
    options,
  );
}
export type IssueContractInvoiceMutationHookResult = ReturnType<typeof useIssueContractInvoiceMutation>;
export type IssueContractInvoiceMutationResult = Apollo.MutationResult<IssueContractInvoiceMutation>;
export type IssueContractInvoiceMutationOptions = Apollo.BaseMutationOptions<
  IssueContractInvoiceMutation,
  IssueContractInvoiceMutationVariables
>;
export const UpdateContractInvoiceDocument = gql`
  mutation UpdateContractInvoice(
    $invoiceId: String!
    $contractId: String!
    $input: UpdateContractInvoiceInput!
  ) {
    updateContractInvoice(invoiceId: $invoiceId, contractId: $contractId, input: $input) {
      ...InvoiceFragment
    }
  }
  ${InvoiceFragmentFragmentDoc}
`;
export type UpdateContractInvoiceMutationFn = Apollo.MutationFunction<
  UpdateContractInvoiceMutation,
  UpdateContractInvoiceMutationVariables
>;

/**
 * __useUpdateContractInvoiceMutation__
 *
 * To run a mutation, you first call `useUpdateContractInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractInvoiceMutation, { data, loading, error }] = useUpdateContractInvoiceMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      contractId: // value for 'contractId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContractInvoiceMutation,
    UpdateContractInvoiceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateContractInvoiceMutation, UpdateContractInvoiceMutationVariables>(
    UpdateContractInvoiceDocument,
    options,
  );
}
export type UpdateContractInvoiceMutationHookResult = ReturnType<typeof useUpdateContractInvoiceMutation>;
export type UpdateContractInvoiceMutationResult = Apollo.MutationResult<UpdateContractInvoiceMutation>;
export type UpdateContractInvoiceMutationOptions = Apollo.BaseMutationOptions<
  UpdateContractInvoiceMutation,
  UpdateContractInvoiceMutationVariables
>;
export const UpdateContractInvoicesDocument = gql`
  mutation UpdateContractInvoices(
    $contractId: String!
    $seasonId: String
    $input: [ContractInvoiceInput!]!
    $type: InvoiceTypeArg!
  ) {
    updateContractInvoices(contractId: $contractId, seasonId: $seasonId, input: $input, type: $type) {
      ...InvoiceFragment
    }
  }
  ${InvoiceFragmentFragmentDoc}
`;
export type UpdateContractInvoicesMutationFn = Apollo.MutationFunction<
  UpdateContractInvoicesMutation,
  UpdateContractInvoicesMutationVariables
>;

/**
 * __useUpdateContractInvoicesMutation__
 *
 * To run a mutation, you first call `useUpdateContractInvoicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractInvoicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractInvoicesMutation, { data, loading, error }] = useUpdateContractInvoicesMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      seasonId: // value for 'seasonId'
 *      input: // value for 'input'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useUpdateContractInvoicesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContractInvoicesMutation,
    UpdateContractInvoicesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateContractInvoicesMutation, UpdateContractInvoicesMutationVariables>(
    UpdateContractInvoicesDocument,
    options,
  );
}
export type UpdateContractInvoicesMutationHookResult = ReturnType<typeof useUpdateContractInvoicesMutation>;
export type UpdateContractInvoicesMutationResult = Apollo.MutationResult<UpdateContractInvoicesMutation>;
export type UpdateContractInvoicesMutationOptions = Apollo.BaseMutationOptions<
  UpdateContractInvoicesMutation,
  UpdateContractInvoicesMutationVariables
>;
export const CreateContractItemDocument = gql`
  mutation CreateContractItem($contractId: String!, $input: CreateContractItemInput!) {
    createContractItem(contractId: $contractId, input: $input) {
      ...ContractItemFragment
    }
  }
  ${ContractItemFragmentFragmentDoc}
`;
export type CreateContractItemMutationFn = Apollo.MutationFunction<
  CreateContractItemMutation,
  CreateContractItemMutationVariables
>;

/**
 * __useCreateContractItemMutation__
 *
 * To run a mutation, you first call `useCreateContractItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractItemMutation, { data, loading, error }] = useCreateContractItemMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContractItemMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateContractItemMutation, CreateContractItemMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateContractItemMutation, CreateContractItemMutationVariables>(
    CreateContractItemDocument,
    options,
  );
}
export type CreateContractItemMutationHookResult = ReturnType<typeof useCreateContractItemMutation>;
export type CreateContractItemMutationResult = Apollo.MutationResult<CreateContractItemMutation>;
export type CreateContractItemMutationOptions = Apollo.BaseMutationOptions<
  CreateContractItemMutation,
  CreateContractItemMutationVariables
>;
export const CreateOrUpdateContractItemBulkDocument = gql`
  mutation CreateOrUpdateContractItemBulk(
    $contractId: String!
    $inventoryId: String!
    $leagueIds: [String!]!
    $seasonIds: [String!]!
    $input: UpdateContractItemBulkInput!
  ) {
    createOrUpdateContractItemBulk(
      contractId: $contractId
      inventoryId: $inventoryId
      leagueIds: $leagueIds
      seasonIds: $seasonIds
      input: $input
    ) {
      ...ContractFragment
    }
  }
  ${ContractFragmentFragmentDoc}
`;
export type CreateOrUpdateContractItemBulkMutationFn = Apollo.MutationFunction<
  CreateOrUpdateContractItemBulkMutation,
  CreateOrUpdateContractItemBulkMutationVariables
>;

/**
 * __useCreateOrUpdateContractItemBulkMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateContractItemBulkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateContractItemBulkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateContractItemBulkMutation, { data, loading, error }] = useCreateOrUpdateContractItemBulkMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      inventoryId: // value for 'inventoryId'
 *      leagueIds: // value for 'leagueIds'
 *      seasonIds: // value for 'seasonIds'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrUpdateContractItemBulkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrUpdateContractItemBulkMutation,
    CreateOrUpdateContractItemBulkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOrUpdateContractItemBulkMutation,
    CreateOrUpdateContractItemBulkMutationVariables
  >(CreateOrUpdateContractItemBulkDocument, options);
}
export type CreateOrUpdateContractItemBulkMutationHookResult = ReturnType<
  typeof useCreateOrUpdateContractItemBulkMutation
>;
export type CreateOrUpdateContractItemBulkMutationResult =
  Apollo.MutationResult<CreateOrUpdateContractItemBulkMutation>;
export type CreateOrUpdateContractItemBulkMutationOptions = Apollo.BaseMutationOptions<
  CreateOrUpdateContractItemBulkMutation,
  CreateOrUpdateContractItemBulkMutationVariables
>;
export const RemoveContractItemBulkDocument = gql`
  mutation RemoveContractItemBulk(
    $contractId: String!
    $inventoryId: String
    $leagueIds: [String!]!
    $seasonIds: [String!]!
  ) {
    removeContractItemBulk(
      contractId: $contractId
      inventoryId: $inventoryId
      leagueIds: $leagueIds
      seasonIds: $seasonIds
    )
  }
`;
export type RemoveContractItemBulkMutationFn = Apollo.MutationFunction<
  RemoveContractItemBulkMutation,
  RemoveContractItemBulkMutationVariables
>;

/**
 * __useRemoveContractItemBulkMutation__
 *
 * To run a mutation, you first call `useRemoveContractItemBulkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveContractItemBulkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeContractItemBulkMutation, { data, loading, error }] = useRemoveContractItemBulkMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      inventoryId: // value for 'inventoryId'
 *      leagueIds: // value for 'leagueIds'
 *      seasonIds: // value for 'seasonIds'
 *   },
 * });
 */
export function useRemoveContractItemBulkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveContractItemBulkMutation,
    RemoveContractItemBulkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveContractItemBulkMutation, RemoveContractItemBulkMutationVariables>(
    RemoveContractItemBulkDocument,
    options,
  );
}
export type RemoveContractItemBulkMutationHookResult = ReturnType<typeof useRemoveContractItemBulkMutation>;
export type RemoveContractItemBulkMutationResult = Apollo.MutationResult<RemoveContractItemBulkMutation>;
export type RemoveContractItemBulkMutationOptions = Apollo.BaseMutationOptions<
  RemoveContractItemBulkMutation,
  RemoveContractItemBulkMutationVariables
>;
export const RemoveContractItemDocument = gql`
  mutation RemoveContractItem($contractId: String!, $itemId: String!) {
    removeContractItem(contractId: $contractId, itemId: $itemId)
  }
`;
export type RemoveContractItemMutationFn = Apollo.MutationFunction<
  RemoveContractItemMutation,
  RemoveContractItemMutationVariables
>;

/**
 * __useRemoveContractItemMutation__
 *
 * To run a mutation, you first call `useRemoveContractItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveContractItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeContractItemMutation, { data, loading, error }] = useRemoveContractItemMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useRemoveContractItemMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveContractItemMutation, RemoveContractItemMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveContractItemMutation, RemoveContractItemMutationVariables>(
    RemoveContractItemDocument,
    options,
  );
}
export type RemoveContractItemMutationHookResult = ReturnType<typeof useRemoveContractItemMutation>;
export type RemoveContractItemMutationResult = Apollo.MutationResult<RemoveContractItemMutation>;
export type RemoveContractItemMutationOptions = Apollo.BaseMutationOptions<
  RemoveContractItemMutation,
  RemoveContractItemMutationVariables
>;
export const UpdateContractItemDocument = gql`
  mutation UpdateContractItem($contractId: String!, $input: UpdateContractItemInput!, $itemId: String!) {
    updateContractItem(contractId: $contractId, input: $input, itemId: $itemId) {
      ...ContractItemFragment
    }
  }
  ${ContractItemFragmentFragmentDoc}
`;
export type UpdateContractItemMutationFn = Apollo.MutationFunction<
  UpdateContractItemMutation,
  UpdateContractItemMutationVariables
>;

/**
 * __useUpdateContractItemMutation__
 *
 * To run a mutation, you first call `useUpdateContractItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractItemMutation, { data, loading, error }] = useUpdateContractItemMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      input: // value for 'input'
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useUpdateContractItemMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateContractItemMutation, UpdateContractItemMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateContractItemMutation, UpdateContractItemMutationVariables>(
    UpdateContractItemDocument,
    options,
  );
}
export type UpdateContractItemMutationHookResult = ReturnType<typeof useUpdateContractItemMutation>;
export type UpdateContractItemMutationResult = Apollo.MutationResult<UpdateContractItemMutation>;
export type UpdateContractItemMutationOptions = Apollo.BaseMutationOptions<
  UpdateContractItemMutation,
  UpdateContractItemMutationVariables
>;
export const RemoveContractDocument = gql`
  mutation RemoveContract($contractId: String!) {
    removeContract(id: $contractId)
  }
`;
export type RemoveContractMutationFn = Apollo.MutationFunction<
  RemoveContractMutation,
  RemoveContractMutationVariables
>;

/**
 * __useRemoveContractMutation__
 *
 * To run a mutation, you first call `useRemoveContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeContractMutation, { data, loading, error }] = useRemoveContractMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useRemoveContractMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveContractMutation, RemoveContractMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveContractMutation, RemoveContractMutationVariables>(
    RemoveContractDocument,
    options,
  );
}
export type RemoveContractMutationHookResult = ReturnType<typeof useRemoveContractMutation>;
export type RemoveContractMutationResult = Apollo.MutationResult<RemoveContractMutation>;
export type RemoveContractMutationOptions = Apollo.BaseMutationOptions<
  RemoveContractMutation,
  RemoveContractMutationVariables
>;
export const RenewContractsDocument = gql`
  mutation RenewContracts {
    renewContracts {
      ...SingleContractFragment
    }
  }
  ${SingleContractFragmentFragmentDoc}
`;
export type RenewContractsMutationFn = Apollo.MutationFunction<
  RenewContractsMutation,
  RenewContractsMutationVariables
>;

/**
 * __useRenewContractsMutation__
 *
 * To run a mutation, you first call `useRenewContractsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenewContractsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renewContractsMutation, { data, loading, error }] = useRenewContractsMutation({
 *   variables: {
 *   },
 * });
 */
export function useRenewContractsMutation(
  baseOptions?: Apollo.MutationHookOptions<RenewContractsMutation, RenewContractsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RenewContractsMutation, RenewContractsMutationVariables>(
    RenewContractsDocument,
    options,
  );
}
export type RenewContractsMutationHookResult = ReturnType<typeof useRenewContractsMutation>;
export type RenewContractsMutationResult = Apollo.MutationResult<RenewContractsMutation>;
export type RenewContractsMutationOptions = Apollo.BaseMutationOptions<
  RenewContractsMutation,
  RenewContractsMutationVariables
>;
export const UpdateContractDocument = gql`
  mutation UpdateContract($contractId: String!, $input: UpdateContractInput!) {
    updateContract(id: $contractId, input: $input) {
      ...SingleContractFragment
    }
  }
  ${SingleContractFragmentFragmentDoc}
`;
export type UpdateContractMutationFn = Apollo.MutationFunction<
  UpdateContractMutation,
  UpdateContractMutationVariables
>;

/**
 * __useUpdateContractMutation__
 *
 * To run a mutation, you first call `useUpdateContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractMutation, { data, loading, error }] = useUpdateContractMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateContractMutation, UpdateContractMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateContractMutation, UpdateContractMutationVariables>(
    UpdateContractDocument,
    options,
  );
}
export type UpdateContractMutationHookResult = ReturnType<typeof useUpdateContractMutation>;
export type UpdateContractMutationResult = Apollo.MutationResult<UpdateContractMutation>;
export type UpdateContractMutationOptions = Apollo.BaseMutationOptions<
  UpdateContractMutation,
  UpdateContractMutationVariables
>;
export const DashboardDiscountDocument = gql`
  query DashboardDiscount($leagueIds: [String!]!, $seasonId: String!) {
    dashboardDiscount(leagueIds: $leagueIds, seasonId: $seasonId) {
      average
    }
  }
`;

/**
 * __useDashboardDiscountQuery__
 *
 * To run a query within a React component, call `useDashboardDiscountQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardDiscountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardDiscountQuery({
 *   variables: {
 *      leagueIds: // value for 'leagueIds'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useDashboardDiscountQuery(
  baseOptions: Apollo.QueryHookOptions<DashboardDiscountQuery, DashboardDiscountQueryVariables> &
    ({ variables: DashboardDiscountQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DashboardDiscountQuery, DashboardDiscountQueryVariables>(
    DashboardDiscountDocument,
    options,
  );
}
export function useDashboardDiscountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DashboardDiscountQuery, DashboardDiscountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DashboardDiscountQuery, DashboardDiscountQueryVariables>(
    DashboardDiscountDocument,
    options,
  );
}
export function useDashboardDiscountSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<DashboardDiscountQuery, DashboardDiscountQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DashboardDiscountQuery, DashboardDiscountQueryVariables>(
    DashboardDiscountDocument,
    options,
  );
}
export type DashboardDiscountQueryHookResult = ReturnType<typeof useDashboardDiscountQuery>;
export type DashboardDiscountLazyQueryHookResult = ReturnType<typeof useDashboardDiscountLazyQuery>;
export type DashboardDiscountSuspenseQueryHookResult = ReturnType<typeof useDashboardDiscountSuspenseQuery>;
export type DashboardDiscountQueryResult = Apollo.QueryResult<
  DashboardDiscountQuery,
  DashboardDiscountQueryVariables
>;
export const DashboardForecastDocument = gql`
  query DashboardForecast($leagueIds: [String!]!, $seasonId: String!) {
    dashboardForecast(leagueIds: $leagueIds, seasonId: $seasonId) {
      actual
      forecast
      actualToPlan
      forecastToPlan
      planned
      chartSeries {
        name
        data
      }
    }
  }
`;

/**
 * __useDashboardForecastQuery__
 *
 * To run a query within a React component, call `useDashboardForecastQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardForecastQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardForecastQuery({
 *   variables: {
 *      leagueIds: // value for 'leagueIds'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useDashboardForecastQuery(
  baseOptions: Apollo.QueryHookOptions<DashboardForecastQuery, DashboardForecastQueryVariables> &
    ({ variables: DashboardForecastQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DashboardForecastQuery, DashboardForecastQueryVariables>(
    DashboardForecastDocument,
    options,
  );
}
export function useDashboardForecastLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DashboardForecastQuery, DashboardForecastQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DashboardForecastQuery, DashboardForecastQueryVariables>(
    DashboardForecastDocument,
    options,
  );
}
export function useDashboardForecastSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<DashboardForecastQuery, DashboardForecastQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DashboardForecastQuery, DashboardForecastQueryVariables>(
    DashboardForecastDocument,
    options,
  );
}
export type DashboardForecastQueryHookResult = ReturnType<typeof useDashboardForecastQuery>;
export type DashboardForecastLazyQueryHookResult = ReturnType<typeof useDashboardForecastLazyQuery>;
export type DashboardForecastSuspenseQueryHookResult = ReturnType<typeof useDashboardForecastSuspenseQuery>;
export type DashboardForecastQueryResult = Apollo.QueryResult<
  DashboardForecastQuery,
  DashboardForecastQueryVariables
>;
export const DashboardInventoryDocument = gql`
  query DashboardInventory($leagueIds: [String!]!, $seasonId: String!) {
    dashboardInventory(leagueIds: $leagueIds, seasonId: $seasonId) {
      stats {
        available
        availableValue
        blocked
        blockedValue
        capacityUtilization
        discount
        offered
        offeredValue
        sold
        soldRevenue
        soldValue
        total
        totalValue
      }
      topCategoryRevenues {
        label
        value
      }
    }
  }
`;

/**
 * __useDashboardInventoryQuery__
 *
 * To run a query within a React component, call `useDashboardInventoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardInventoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardInventoryQuery({
 *   variables: {
 *      leagueIds: // value for 'leagueIds'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useDashboardInventoryQuery(
  baseOptions: Apollo.QueryHookOptions<DashboardInventoryQuery, DashboardInventoryQueryVariables> &
    ({ variables: DashboardInventoryQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DashboardInventoryQuery, DashboardInventoryQueryVariables>(
    DashboardInventoryDocument,
    options,
  );
}
export function useDashboardInventoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DashboardInventoryQuery, DashboardInventoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DashboardInventoryQuery, DashboardInventoryQueryVariables>(
    DashboardInventoryDocument,
    options,
  );
}
export function useDashboardInventorySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<DashboardInventoryQuery, DashboardInventoryQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DashboardInventoryQuery, DashboardInventoryQueryVariables>(
    DashboardInventoryDocument,
    options,
  );
}
export type DashboardInventoryQueryHookResult = ReturnType<typeof useDashboardInventoryQuery>;
export type DashboardInventoryLazyQueryHookResult = ReturnType<typeof useDashboardInventoryLazyQuery>;
export type DashboardInventorySuspenseQueryHookResult = ReturnType<typeof useDashboardInventorySuspenseQuery>;
export type DashboardInventoryQueryResult = Apollo.QueryResult<
  DashboardInventoryQuery,
  DashboardInventoryQueryVariables
>;
export const DashboardOffersDocument = gql`
  query DashboardOffers($leagueIds: [String!]!, $seasonId: String!) {
    dashboardOffers(leagueIds: $leagueIds, seasonId: $seasonId) {
      categories
      amountSeries {
        name
        data
      }
      valueSeries {
        name
        data
      }
    }
  }
`;

/**
 * __useDashboardOffersQuery__
 *
 * To run a query within a React component, call `useDashboardOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardOffersQuery({
 *   variables: {
 *      leagueIds: // value for 'leagueIds'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useDashboardOffersQuery(
  baseOptions: Apollo.QueryHookOptions<DashboardOffersQuery, DashboardOffersQueryVariables> &
    ({ variables: DashboardOffersQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DashboardOffersQuery, DashboardOffersQueryVariables>(
    DashboardOffersDocument,
    options,
  );
}
export function useDashboardOffersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DashboardOffersQuery, DashboardOffersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DashboardOffersQuery, DashboardOffersQueryVariables>(
    DashboardOffersDocument,
    options,
  );
}
export function useDashboardOffersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<DashboardOffersQuery, DashboardOffersQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DashboardOffersQuery, DashboardOffersQueryVariables>(
    DashboardOffersDocument,
    options,
  );
}
export type DashboardOffersQueryHookResult = ReturnType<typeof useDashboardOffersQuery>;
export type DashboardOffersLazyQueryHookResult = ReturnType<typeof useDashboardOffersLazyQuery>;
export type DashboardOffersSuspenseQueryHookResult = ReturnType<typeof useDashboardOffersSuspenseQuery>;
export type DashboardOffersQueryResult = Apollo.QueryResult<
  DashboardOffersQuery,
  DashboardOffersQueryVariables
>;
export const DashboardPartnerAndContractsDocument = gql`
  query DashboardPartnerAndContracts($leagueIds: [String!]!, $seasonId: String!) {
    dashboardPartnerAndContracts(leagueIds: $leagueIds, seasonId: $seasonId) {
      partner {
        total
        new
        existing
        leads
        fixed
      }
      contract {
        total
        expired
        autoRenewTotal
        autoRenewOpen
        cancellations
      }
    }
  }
`;

/**
 * __useDashboardPartnerAndContractsQuery__
 *
 * To run a query within a React component, call `useDashboardPartnerAndContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardPartnerAndContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardPartnerAndContractsQuery({
 *   variables: {
 *      leagueIds: // value for 'leagueIds'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useDashboardPartnerAndContractsQuery(
  baseOptions: Apollo.QueryHookOptions<
    DashboardPartnerAndContractsQuery,
    DashboardPartnerAndContractsQueryVariables
  > &
    ({ variables: DashboardPartnerAndContractsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DashboardPartnerAndContractsQuery, DashboardPartnerAndContractsQueryVariables>(
    DashboardPartnerAndContractsDocument,
    options,
  );
}
export function useDashboardPartnerAndContractsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DashboardPartnerAndContractsQuery,
    DashboardPartnerAndContractsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DashboardPartnerAndContractsQuery, DashboardPartnerAndContractsQueryVariables>(
    DashboardPartnerAndContractsDocument,
    options,
  );
}
export function useDashboardPartnerAndContractsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        DashboardPartnerAndContractsQuery,
        DashboardPartnerAndContractsQueryVariables
      >,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    DashboardPartnerAndContractsQuery,
    DashboardPartnerAndContractsQueryVariables
  >(DashboardPartnerAndContractsDocument, options);
}
export type DashboardPartnerAndContractsQueryHookResult = ReturnType<
  typeof useDashboardPartnerAndContractsQuery
>;
export type DashboardPartnerAndContractsLazyQueryHookResult = ReturnType<
  typeof useDashboardPartnerAndContractsLazyQuery
>;
export type DashboardPartnerAndContractsSuspenseQueryHookResult = ReturnType<
  typeof useDashboardPartnerAndContractsSuspenseQuery
>;
export type DashboardPartnerAndContractsQueryResult = Apollo.QueryResult<
  DashboardPartnerAndContractsQuery,
  DashboardPartnerAndContractsQueryVariables
>;
export const DashboardRevenueDetailsDocument = gql`
  query DashboardRevenueDetails($leagueIds: [String!]!, $seasonId: String!) {
    dashboardRevenueDetails(leagueIds: $leagueIds, seasonId: $seasonId) {
      bonus
      contracts
      total
      totalPrice
      totalBarter
    }
  }
`;

/**
 * __useDashboardRevenueDetailsQuery__
 *
 * To run a query within a React component, call `useDashboardRevenueDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardRevenueDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardRevenueDetailsQuery({
 *   variables: {
 *      leagueIds: // value for 'leagueIds'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useDashboardRevenueDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<DashboardRevenueDetailsQuery, DashboardRevenueDetailsQueryVariables> &
    ({ variables: DashboardRevenueDetailsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DashboardRevenueDetailsQuery, DashboardRevenueDetailsQueryVariables>(
    DashboardRevenueDetailsDocument,
    options,
  );
}
export function useDashboardRevenueDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DashboardRevenueDetailsQuery,
    DashboardRevenueDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DashboardRevenueDetailsQuery, DashboardRevenueDetailsQueryVariables>(
    DashboardRevenueDetailsDocument,
    options,
  );
}
export function useDashboardRevenueDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<DashboardRevenueDetailsQuery, DashboardRevenueDetailsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DashboardRevenueDetailsQuery, DashboardRevenueDetailsQueryVariables>(
    DashboardRevenueDetailsDocument,
    options,
  );
}
export type DashboardRevenueDetailsQueryHookResult = ReturnType<typeof useDashboardRevenueDetailsQuery>;
export type DashboardRevenueDetailsLazyQueryHookResult = ReturnType<
  typeof useDashboardRevenueDetailsLazyQuery
>;
export type DashboardRevenueDetailsSuspenseQueryHookResult = ReturnType<
  typeof useDashboardRevenueDetailsSuspenseQuery
>;
export type DashboardRevenueDetailsQueryResult = Apollo.QueryResult<
  DashboardRevenueDetailsQuery,
  DashboardRevenueDetailsQueryVariables
>;
export const AllInventoryWithTasksDocument = gql`
  query AllInventoryWithTasks(
    $filterType: InventoryType
    $availabilityFilter: InventoryAvailabilityFilter
    $leagueIds: [String!]!
    $search: String
    $seasonId: String!
    $topCategoryId: String
  ) {
    allInventory(
      filterType: $filterType
      availabilityFilter: $availabilityFilter
      leagueIds: $leagueIds
      search: $search
      seasonId: $seasonId
      topCategoryId: $topCategoryId
    ) {
      inventory {
        id
        name
        order
        unit
        keyRightOrder
        soldByMatch
        soldBySeason
        note
        externalId
        contractDescription
        createdAt
        updatedAt
        category {
          id
          name
        }
        tasks {
          contracts {
            id
            partner {
              id
              companyName
            }
            stats {
              ...TaskStatsFragment
            }
            tasks {
              ...TaskFragment
            }
          }
          stats {
            ...TaskStatsFragment
          }
        }
      }
    }
  }
  ${TaskStatsFragmentFragmentDoc}
  ${TaskFragmentFragmentDoc}
`;

/**
 * __useAllInventoryWithTasksQuery__
 *
 * To run a query within a React component, call `useAllInventoryWithTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllInventoryWithTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllInventoryWithTasksQuery({
 *   variables: {
 *      filterType: // value for 'filterType'
 *      availabilityFilter: // value for 'availabilityFilter'
 *      leagueIds: // value for 'leagueIds'
 *      search: // value for 'search'
 *      seasonId: // value for 'seasonId'
 *      topCategoryId: // value for 'topCategoryId'
 *   },
 * });
 */
export function useAllInventoryWithTasksQuery(
  baseOptions: Apollo.QueryHookOptions<AllInventoryWithTasksQuery, AllInventoryWithTasksQueryVariables> &
    ({ variables: AllInventoryWithTasksQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllInventoryWithTasksQuery, AllInventoryWithTasksQueryVariables>(
    AllInventoryWithTasksDocument,
    options,
  );
}
export function useAllInventoryWithTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllInventoryWithTasksQuery, AllInventoryWithTasksQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllInventoryWithTasksQuery, AllInventoryWithTasksQueryVariables>(
    AllInventoryWithTasksDocument,
    options,
  );
}
export function useAllInventoryWithTasksSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AllInventoryWithTasksQuery, AllInventoryWithTasksQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AllInventoryWithTasksQuery, AllInventoryWithTasksQueryVariables>(
    AllInventoryWithTasksDocument,
    options,
  );
}
export type AllInventoryWithTasksQueryHookResult = ReturnType<typeof useAllInventoryWithTasksQuery>;
export type AllInventoryWithTasksLazyQueryHookResult = ReturnType<typeof useAllInventoryWithTasksLazyQuery>;
export type AllInventoryWithTasksSuspenseQueryHookResult = ReturnType<
  typeof useAllInventoryWithTasksSuspenseQuery
>;
export type AllInventoryWithTasksQueryResult = Apollo.QueryResult<
  AllInventoryWithTasksQuery,
  AllInventoryWithTasksQueryVariables
>;
export const AllInventoryDocument = gql`
  query AllInventory(
    $filterType: InventoryType
    $availabilityFilter: InventoryAvailabilityFilter
    $leagueIds: [String!]!
    $search: String
    $seasonId: String!
    $topCategoryId: String
  ) {
    allInventory(
      filterType: $filterType
      availabilityFilter: $availabilityFilter
      leagueIds: $leagueIds
      search: $search
      seasonId: $seasonId
      topCategoryId: $topCategoryId
    ) {
      inventory {
        ...InventoryFragment
      }
      totals {
        available
        availableValue
        blocked
        blockedValue
        capacityUtilization
        discount
        offered
        offeredValue
        sold
        soldRevenue
        soldValue
        total
        totalValue
      }
    }
  }
  ${InventoryFragmentFragmentDoc}
`;

/**
 * __useAllInventoryQuery__
 *
 * To run a query within a React component, call `useAllInventoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllInventoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllInventoryQuery({
 *   variables: {
 *      filterType: // value for 'filterType'
 *      availabilityFilter: // value for 'availabilityFilter'
 *      leagueIds: // value for 'leagueIds'
 *      search: // value for 'search'
 *      seasonId: // value for 'seasonId'
 *      topCategoryId: // value for 'topCategoryId'
 *   },
 * });
 */
export function useAllInventoryQuery(
  baseOptions: Apollo.QueryHookOptions<AllInventoryQuery, AllInventoryQueryVariables> &
    ({ variables: AllInventoryQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllInventoryQuery, AllInventoryQueryVariables>(AllInventoryDocument, options);
}
export function useAllInventoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllInventoryQuery, AllInventoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllInventoryQuery, AllInventoryQueryVariables>(AllInventoryDocument, options);
}
export function useAllInventorySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AllInventoryQuery, AllInventoryQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AllInventoryQuery, AllInventoryQueryVariables>(
    AllInventoryDocument,
    options,
  );
}
export type AllInventoryQueryHookResult = ReturnType<typeof useAllInventoryQuery>;
export type AllInventoryLazyQueryHookResult = ReturnType<typeof useAllInventoryLazyQuery>;
export type AllInventorySuspenseQueryHookResult = ReturnType<typeof useAllInventorySuspenseQuery>;
export type AllInventoryQueryResult = Apollo.QueryResult<AllInventoryQuery, AllInventoryQueryVariables>;
export const CreateInventoryDocument = gql`
  mutation CreateInventory($input: CreateInventoryInput!) {
    createInventory(input: $input) {
      ...InventoryFragment
    }
  }
  ${InventoryFragmentFragmentDoc}
`;
export type CreateInventoryMutationFn = Apollo.MutationFunction<
  CreateInventoryMutation,
  CreateInventoryMutationVariables
>;

/**
 * __useCreateInventoryMutation__
 *
 * To run a mutation, you first call `useCreateInventoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInventoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInventoryMutation, { data, loading, error }] = useCreateInventoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInventoryMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateInventoryMutation, CreateInventoryMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateInventoryMutation, CreateInventoryMutationVariables>(
    CreateInventoryDocument,
    options,
  );
}
export type CreateInventoryMutationHookResult = ReturnType<typeof useCreateInventoryMutation>;
export type CreateInventoryMutationResult = Apollo.MutationResult<CreateInventoryMutation>;
export type CreateInventoryMutationOptions = Apollo.BaseMutationOptions<
  CreateInventoryMutation,
  CreateInventoryMutationVariables
>;
export const InventoryDocument = gql`
  query Inventory($id: String!, $leagueIds: [String!], $seasonId: String) {
    inventory(id: $id, leagueIds: $leagueIds, seasonId: $seasonId) {
      ...InventoryFragment
    }
  }
  ${InventoryFragmentFragmentDoc}
`;

/**
 * __useInventoryQuery__
 *
 * To run a query within a React component, call `useInventoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useInventoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInventoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *      leagueIds: // value for 'leagueIds'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useInventoryQuery(
  baseOptions: Apollo.QueryHookOptions<InventoryQuery, InventoryQueryVariables> &
    ({ variables: InventoryQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InventoryQuery, InventoryQueryVariables>(InventoryDocument, options);
}
export function useInventoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InventoryQuery, InventoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InventoryQuery, InventoryQueryVariables>(InventoryDocument, options);
}
export function useInventorySuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<InventoryQuery, InventoryQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<InventoryQuery, InventoryQueryVariables>(InventoryDocument, options);
}
export type InventoryQueryHookResult = ReturnType<typeof useInventoryQuery>;
export type InventoryLazyQueryHookResult = ReturnType<typeof useInventoryLazyQuery>;
export type InventorySuspenseQueryHookResult = ReturnType<typeof useInventorySuspenseQuery>;
export type InventoryQueryResult = Apollo.QueryResult<InventoryQuery, InventoryQueryVariables>;
export const InventoryDetailsDocument = gql`
  query InventoryDetails($id: String!, $leagueIds: [String!], $seasonId: String) {
    inventoryDetails(id: $id, leagueIds: $leagueIds, seasonId: $seasonId) {
      contractsBySeason {
        seasonId
        contracts {
          id
          partnerId
          partnerName
          discount
          endDate
          revenue
          probability
          type
          items {
            ...ContractItemFragment
          }
          amount
          unit
          totalValue
        }
      }
      revenueChart {
        revenueSeries {
          data {
            x
            y
          }
        }
        totalValueSeries {
          data {
            x
            y
          }
        }
      }
    }
  }
  ${ContractItemFragmentFragmentDoc}
`;

/**
 * __useInventoryDetailsQuery__
 *
 * To run a query within a React component, call `useInventoryDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInventoryDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInventoryDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      leagueIds: // value for 'leagueIds'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useInventoryDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<InventoryDetailsQuery, InventoryDetailsQueryVariables> &
    ({ variables: InventoryDetailsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InventoryDetailsQuery, InventoryDetailsQueryVariables>(
    InventoryDetailsDocument,
    options,
  );
}
export function useInventoryDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InventoryDetailsQuery, InventoryDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InventoryDetailsQuery, InventoryDetailsQueryVariables>(
    InventoryDetailsDocument,
    options,
  );
}
export function useInventoryDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<InventoryDetailsQuery, InventoryDetailsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<InventoryDetailsQuery, InventoryDetailsQueryVariables>(
    InventoryDetailsDocument,
    options,
  );
}
export type InventoryDetailsQueryHookResult = ReturnType<typeof useInventoryDetailsQuery>;
export type InventoryDetailsLazyQueryHookResult = ReturnType<typeof useInventoryDetailsLazyQuery>;
export type InventoryDetailsSuspenseQueryHookResult = ReturnType<typeof useInventoryDetailsSuspenseQuery>;
export type InventoryDetailsQueryResult = Apollo.QueryResult<
  InventoryDetailsQuery,
  InventoryDetailsQueryVariables
>;
export const DashboardKeyInventoryDocument = gql`
  query DashboardKeyInventory($leagueIds: [String!]!, $seasonId: String!) {
    allInventory(leagueIds: $leagueIds, seasonId: $seasonId, keyRightOnly: true) {
      inventory {
        id
        name
        unit
        note
        keyRightOrder
        listPrices {
          price
        }
        stats {
          total
          sold
          available
          blocked
          offered
        }
      }
    }
  }
`;

/**
 * __useDashboardKeyInventoryQuery__
 *
 * To run a query within a React component, call `useDashboardKeyInventoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardKeyInventoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardKeyInventoryQuery({
 *   variables: {
 *      leagueIds: // value for 'leagueIds'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useDashboardKeyInventoryQuery(
  baseOptions: Apollo.QueryHookOptions<DashboardKeyInventoryQuery, DashboardKeyInventoryQueryVariables> &
    ({ variables: DashboardKeyInventoryQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DashboardKeyInventoryQuery, DashboardKeyInventoryQueryVariables>(
    DashboardKeyInventoryDocument,
    options,
  );
}
export function useDashboardKeyInventoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DashboardKeyInventoryQuery, DashboardKeyInventoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DashboardKeyInventoryQuery, DashboardKeyInventoryQueryVariables>(
    DashboardKeyInventoryDocument,
    options,
  );
}
export function useDashboardKeyInventorySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<DashboardKeyInventoryQuery, DashboardKeyInventoryQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DashboardKeyInventoryQuery, DashboardKeyInventoryQueryVariables>(
    DashboardKeyInventoryDocument,
    options,
  );
}
export type DashboardKeyInventoryQueryHookResult = ReturnType<typeof useDashboardKeyInventoryQuery>;
export type DashboardKeyInventoryLazyQueryHookResult = ReturnType<typeof useDashboardKeyInventoryLazyQuery>;
export type DashboardKeyInventorySuspenseQueryHookResult = ReturnType<
  typeof useDashboardKeyInventorySuspenseQuery
>;
export type DashboardKeyInventoryQueryResult = Apollo.QueryResult<
  DashboardKeyInventoryQuery,
  DashboardKeyInventoryQueryVariables
>;
export const CreateInventoryListPriceDocument = gql`
  mutation CreateInventoryListPrice($input: CreateListPriceItemInput!, $inventoryId: String!) {
    createInventoryListPrice(input: $input, inventoryId: $inventoryId) {
      ...InventoryListPriceFragment
    }
  }
  ${InventoryListPriceFragmentFragmentDoc}
`;
export type CreateInventoryListPriceMutationFn = Apollo.MutationFunction<
  CreateInventoryListPriceMutation,
  CreateInventoryListPriceMutationVariables
>;

/**
 * __useCreateInventoryListPriceMutation__
 *
 * To run a mutation, you first call `useCreateInventoryListPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInventoryListPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInventoryListPriceMutation, { data, loading, error }] = useCreateInventoryListPriceMutation({
 *   variables: {
 *      input: // value for 'input'
 *      inventoryId: // value for 'inventoryId'
 *   },
 * });
 */
export function useCreateInventoryListPriceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInventoryListPriceMutation,
    CreateInventoryListPriceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateInventoryListPriceMutation, CreateInventoryListPriceMutationVariables>(
    CreateInventoryListPriceDocument,
    options,
  );
}
export type CreateInventoryListPriceMutationHookResult = ReturnType<
  typeof useCreateInventoryListPriceMutation
>;
export type CreateInventoryListPriceMutationResult = Apollo.MutationResult<CreateInventoryListPriceMutation>;
export type CreateInventoryListPriceMutationOptions = Apollo.BaseMutationOptions<
  CreateInventoryListPriceMutation,
  CreateInventoryListPriceMutationVariables
>;
export const RemoveInventoryListPriceDocument = gql`
  mutation removeInventoryListPrice($inventoryId: String!, $listPriceId: String!) {
    removeInventoryListPrice(inventoryId: $inventoryId, listPriceId: $listPriceId)
  }
`;
export type RemoveInventoryListPriceMutationFn = Apollo.MutationFunction<
  RemoveInventoryListPriceMutation,
  RemoveInventoryListPriceMutationVariables
>;

/**
 * __useRemoveInventoryListPriceMutation__
 *
 * To run a mutation, you first call `useRemoveInventoryListPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveInventoryListPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeInventoryListPriceMutation, { data, loading, error }] = useRemoveInventoryListPriceMutation({
 *   variables: {
 *      inventoryId: // value for 'inventoryId'
 *      listPriceId: // value for 'listPriceId'
 *   },
 * });
 */
export function useRemoveInventoryListPriceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveInventoryListPriceMutation,
    RemoveInventoryListPriceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveInventoryListPriceMutation, RemoveInventoryListPriceMutationVariables>(
    RemoveInventoryListPriceDocument,
    options,
  );
}
export type RemoveInventoryListPriceMutationHookResult = ReturnType<
  typeof useRemoveInventoryListPriceMutation
>;
export type RemoveInventoryListPriceMutationResult = Apollo.MutationResult<RemoveInventoryListPriceMutation>;
export type RemoveInventoryListPriceMutationOptions = Apollo.BaseMutationOptions<
  RemoveInventoryListPriceMutation,
  RemoveInventoryListPriceMutationVariables
>;
export const UpdateInventoryListPriceDocument = gql`
  mutation UpdateInventoryListPrice(
    $input: UpdateListPriceItemInput!
    $inventoryId: String!
    $listPriceId: String!
  ) {
    updateInventoryListPrice(input: $input, inventoryId: $inventoryId, listPriceId: $listPriceId) {
      ...InventoryListPriceFragment
    }
  }
  ${InventoryListPriceFragmentFragmentDoc}
`;
export type UpdateInventoryListPriceMutationFn = Apollo.MutationFunction<
  UpdateInventoryListPriceMutation,
  UpdateInventoryListPriceMutationVariables
>;

/**
 * __useUpdateInventoryListPriceMutation__
 *
 * To run a mutation, you first call `useUpdateInventoryListPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInventoryListPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInventoryListPriceMutation, { data, loading, error }] = useUpdateInventoryListPriceMutation({
 *   variables: {
 *      input: // value for 'input'
 *      inventoryId: // value for 'inventoryId'
 *      listPriceId: // value for 'listPriceId'
 *   },
 * });
 */
export function useUpdateInventoryListPriceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInventoryListPriceMutation,
    UpdateInventoryListPriceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateInventoryListPriceMutation, UpdateInventoryListPriceMutationVariables>(
    UpdateInventoryListPriceDocument,
    options,
  );
}
export type UpdateInventoryListPriceMutationHookResult = ReturnType<
  typeof useUpdateInventoryListPriceMutation
>;
export type UpdateInventoryListPriceMutationResult = Apollo.MutationResult<UpdateInventoryListPriceMutation>;
export type UpdateInventoryListPriceMutationOptions = Apollo.BaseMutationOptions<
  UpdateInventoryListPriceMutation,
  UpdateInventoryListPriceMutationVariables
>;
export const RemoveInventoryDocument = gql`
  mutation RemoveInventory($id: String!) {
    removeInventory(id: $id)
  }
`;
export type RemoveInventoryMutationFn = Apollo.MutationFunction<
  RemoveInventoryMutation,
  RemoveInventoryMutationVariables
>;

/**
 * __useRemoveInventoryMutation__
 *
 * To run a mutation, you first call `useRemoveInventoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveInventoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeInventoryMutation, { data, loading, error }] = useRemoveInventoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveInventoryMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveInventoryMutation, RemoveInventoryMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveInventoryMutation, RemoveInventoryMutationVariables>(
    RemoveInventoryDocument,
    options,
  );
}
export type RemoveInventoryMutationHookResult = ReturnType<typeof useRemoveInventoryMutation>;
export type RemoveInventoryMutationResult = Apollo.MutationResult<RemoveInventoryMutation>;
export type RemoveInventoryMutationOptions = Apollo.BaseMutationOptions<
  RemoveInventoryMutation,
  RemoveInventoryMutationVariables
>;
export const UpdateInventoryDocument = gql`
  mutation UpdateInventory($id: String!, $input: UpdateInventoryInput!) {
    updateInventory(id: $id, input: $input) {
      ...InventoryFragment
    }
  }
  ${InventoryFragmentFragmentDoc}
`;
export type UpdateInventoryMutationFn = Apollo.MutationFunction<
  UpdateInventoryMutation,
  UpdateInventoryMutationVariables
>;

/**
 * __useUpdateInventoryMutation__
 *
 * To run a mutation, you first call `useUpdateInventoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInventoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInventoryMutation, { data, loading, error }] = useUpdateInventoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInventoryMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateInventoryMutation, UpdateInventoryMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateInventoryMutation, UpdateInventoryMutationVariables>(
    UpdateInventoryDocument,
    options,
  );
}
export type UpdateInventoryMutationHookResult = ReturnType<typeof useUpdateInventoryMutation>;
export type UpdateInventoryMutationResult = Apollo.MutationResult<UpdateInventoryMutation>;
export type UpdateInventoryMutationOptions = Apollo.BaseMutationOptions<
  UpdateInventoryMutation,
  UpdateInventoryMutationVariables
>;
export const InvoiceExportsDocument = gql`
  query InvoiceExports {
    invoiceExports {
      edges {
        node {
          id
          zipFile
          createdAt
          exportedBy {
            id
          }
          invoices {
            id
            seasonId
            contractId
            companyName
            invoiceId
            invoiceDate
            invoiceNumber
            docType
            amount
            type
            pdf
            fileExists
          }
        }
      }
    }
  }
`;

/**
 * __useInvoiceExportsQuery__
 *
 * To run a query within a React component, call `useInvoiceExportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceExportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceExportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useInvoiceExportsQuery(
  baseOptions?: Apollo.QueryHookOptions<InvoiceExportsQuery, InvoiceExportsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvoiceExportsQuery, InvoiceExportsQueryVariables>(InvoiceExportsDocument, options);
}
export function useInvoiceExportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InvoiceExportsQuery, InvoiceExportsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvoiceExportsQuery, InvoiceExportsQueryVariables>(
    InvoiceExportsDocument,
    options,
  );
}
export function useInvoiceExportsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<InvoiceExportsQuery, InvoiceExportsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<InvoiceExportsQuery, InvoiceExportsQueryVariables>(
    InvoiceExportsDocument,
    options,
  );
}
export type InvoiceExportsQueryHookResult = ReturnType<typeof useInvoiceExportsQuery>;
export type InvoiceExportsLazyQueryHookResult = ReturnType<typeof useInvoiceExportsLazyQuery>;
export type InvoiceExportsSuspenseQueryHookResult = ReturnType<typeof useInvoiceExportsSuspenseQuery>;
export type InvoiceExportsQueryResult = Apollo.QueryResult<InvoiceExportsQuery, InvoiceExportsQueryVariables>;
export const ConvertToPdfDocument = gql`
  mutation ConvertToPdf(
    $contractId: String!
    $file: Upload!
    $invoiceId: String!
    $docType: InvoiceDocType
    $datevCancellationLines: String
    $datevInvoiceLines: String
  ) {
    convertToPdf(
      contractId: $contractId
      file: $file
      invoiceId: $invoiceId
      docType: $docType
      datevCancellationLines: $datevCancellationLines
      datevInvoiceLines: $datevInvoiceLines
    )
  }
`;
export type ConvertToPdfMutationFn = Apollo.MutationFunction<
  ConvertToPdfMutation,
  ConvertToPdfMutationVariables
>;

/**
 * __useConvertToPdfMutation__
 *
 * To run a mutation, you first call `useConvertToPdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConvertToPdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [convertToPdfMutation, { data, loading, error }] = useConvertToPdfMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      file: // value for 'file'
 *      invoiceId: // value for 'invoiceId'
 *      docType: // value for 'docType'
 *      datevCancellationLines: // value for 'datevCancellationLines'
 *      datevInvoiceLines: // value for 'datevInvoiceLines'
 *   },
 * });
 */
export function useConvertToPdfMutation(
  baseOptions?: Apollo.MutationHookOptions<ConvertToPdfMutation, ConvertToPdfMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConvertToPdfMutation, ConvertToPdfMutationVariables>(
    ConvertToPdfDocument,
    options,
  );
}
export type ConvertToPdfMutationHookResult = ReturnType<typeof useConvertToPdfMutation>;
export type ConvertToPdfMutationResult = Apollo.MutationResult<ConvertToPdfMutation>;
export type ConvertToPdfMutationOptions = Apollo.BaseMutationOptions<
  ConvertToPdfMutation,
  ConvertToPdfMutationVariables
>;
export const CreateInvoiceExportDocument = gql`
  mutation CreateInvoiceExport {
    createInvoiceExport {
      id
      zipFile
      createdAt
      exportedBy {
        id
      }
      invoices {
        id
      }
    }
  }
`;
export type CreateInvoiceExportMutationFn = Apollo.MutationFunction<
  CreateInvoiceExportMutation,
  CreateInvoiceExportMutationVariables
>;

/**
 * __useCreateInvoiceExportMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceExportMutation, { data, loading, error }] = useCreateInvoiceExportMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateInvoiceExportMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateInvoiceExportMutation, CreateInvoiceExportMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateInvoiceExportMutation, CreateInvoiceExportMutationVariables>(
    CreateInvoiceExportDocument,
    options,
  );
}
export type CreateInvoiceExportMutationHookResult = ReturnType<typeof useCreateInvoiceExportMutation>;
export type CreateInvoiceExportMutationResult = Apollo.MutationResult<CreateInvoiceExportMutation>;
export type CreateInvoiceExportMutationOptions = Apollo.BaseMutationOptions<
  CreateInvoiceExportMutation,
  CreateInvoiceExportMutationVariables
>;
export const AdminLeaguesDocument = gql`
  query AdminLeagues($accountId: String!) {
    adminLeagues(accountId: $accountId) {
      edges {
        node {
          ...LeagueFragment
        }
      }
    }
  }
  ${LeagueFragmentFragmentDoc}
`;

/**
 * __useAdminLeaguesQuery__
 *
 * To run a query within a React component, call `useAdminLeaguesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminLeaguesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminLeaguesQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAdminLeaguesQuery(
  baseOptions: Apollo.QueryHookOptions<AdminLeaguesQuery, AdminLeaguesQueryVariables> &
    ({ variables: AdminLeaguesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminLeaguesQuery, AdminLeaguesQueryVariables>(AdminLeaguesDocument, options);
}
export function useAdminLeaguesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminLeaguesQuery, AdminLeaguesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminLeaguesQuery, AdminLeaguesQueryVariables>(AdminLeaguesDocument, options);
}
export function useAdminLeaguesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AdminLeaguesQuery, AdminLeaguesQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AdminLeaguesQuery, AdminLeaguesQueryVariables>(
    AdminLeaguesDocument,
    options,
  );
}
export type AdminLeaguesQueryHookResult = ReturnType<typeof useAdminLeaguesQuery>;
export type AdminLeaguesLazyQueryHookResult = ReturnType<typeof useAdminLeaguesLazyQuery>;
export type AdminLeaguesSuspenseQueryHookResult = ReturnType<typeof useAdminLeaguesSuspenseQuery>;
export type AdminLeaguesQueryResult = Apollo.QueryResult<AdminLeaguesQuery, AdminLeaguesQueryVariables>;
export const AdminCreateLeagueDocument = gql`
  mutation AdminCreateLeague($input: AdminCreateLeagueInput!) {
    adminCreateLeague(input: $input) {
      ...LeagueFragment
    }
  }
  ${LeagueFragmentFragmentDoc}
`;
export type AdminCreateLeagueMutationFn = Apollo.MutationFunction<
  AdminCreateLeagueMutation,
  AdminCreateLeagueMutationVariables
>;

/**
 * __useAdminCreateLeagueMutation__
 *
 * To run a mutation, you first call `useAdminCreateLeagueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateLeagueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateLeagueMutation, { data, loading, error }] = useAdminCreateLeagueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminCreateLeagueMutation(
  baseOptions?: Apollo.MutationHookOptions<AdminCreateLeagueMutation, AdminCreateLeagueMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminCreateLeagueMutation, AdminCreateLeagueMutationVariables>(
    AdminCreateLeagueDocument,
    options,
  );
}
export type AdminCreateLeagueMutationHookResult = ReturnType<typeof useAdminCreateLeagueMutation>;
export type AdminCreateLeagueMutationResult = Apollo.MutationResult<AdminCreateLeagueMutation>;
export type AdminCreateLeagueMutationOptions = Apollo.BaseMutationOptions<
  AdminCreateLeagueMutation,
  AdminCreateLeagueMutationVariables
>;
export const AdminRemoveLeagueDocument = gql`
  mutation AdminRemoveLeague($accountId: String!, $id: String!) {
    adminRemoveLeague(accountId: $accountId, id: $id)
  }
`;
export type AdminRemoveLeagueMutationFn = Apollo.MutationFunction<
  AdminRemoveLeagueMutation,
  AdminRemoveLeagueMutationVariables
>;

/**
 * __useAdminRemoveLeagueMutation__
 *
 * To run a mutation, you first call `useAdminRemoveLeagueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminRemoveLeagueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminRemoveLeagueMutation, { data, loading, error }] = useAdminRemoveLeagueMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminRemoveLeagueMutation(
  baseOptions?: Apollo.MutationHookOptions<AdminRemoveLeagueMutation, AdminRemoveLeagueMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminRemoveLeagueMutation, AdminRemoveLeagueMutationVariables>(
    AdminRemoveLeagueDocument,
    options,
  );
}
export type AdminRemoveLeagueMutationHookResult = ReturnType<typeof useAdminRemoveLeagueMutation>;
export type AdminRemoveLeagueMutationResult = Apollo.MutationResult<AdminRemoveLeagueMutation>;
export type AdminRemoveLeagueMutationOptions = Apollo.BaseMutationOptions<
  AdminRemoveLeagueMutation,
  AdminRemoveLeagueMutationVariables
>;
export const AdminUpdateLeagueDocument = gql`
  mutation AdminUpdateLeague($accountId: String!, $id: String!, $input: UpdateLeagueInput!) {
    adminUpdateLeague(accountId: $accountId, id: $id, input: $input) {
      ...LeagueFragment
    }
  }
  ${LeagueFragmentFragmentDoc}
`;
export type AdminUpdateLeagueMutationFn = Apollo.MutationFunction<
  AdminUpdateLeagueMutation,
  AdminUpdateLeagueMutationVariables
>;

/**
 * __useAdminUpdateLeagueMutation__
 *
 * To run a mutation, you first call `useAdminUpdateLeagueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateLeagueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateLeagueMutation, { data, loading, error }] = useAdminUpdateLeagueMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminUpdateLeagueMutation(
  baseOptions?: Apollo.MutationHookOptions<AdminUpdateLeagueMutation, AdminUpdateLeagueMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminUpdateLeagueMutation, AdminUpdateLeagueMutationVariables>(
    AdminUpdateLeagueDocument,
    options,
  );
}
export type AdminUpdateLeagueMutationHookResult = ReturnType<typeof useAdminUpdateLeagueMutation>;
export type AdminUpdateLeagueMutationResult = Apollo.MutationResult<AdminUpdateLeagueMutation>;
export type AdminUpdateLeagueMutationOptions = Apollo.BaseMutationOptions<
  AdminUpdateLeagueMutation,
  AdminUpdateLeagueMutationVariables
>;
export const LeaguesDocument = gql`
  query Leagues($pageSize: Float, $cursor: String, $search: String) {
    leagues(pageSize: $pageSize, cursor: $cursor, search: $search) {
      edges {
        node {
          ...LeagueFragment
        }
      }
    }
  }
  ${LeagueFragmentFragmentDoc}
`;

/**
 * __useLeaguesQuery__
 *
 * To run a query within a React component, call `useLeaguesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeaguesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeaguesQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      cursor: // value for 'cursor'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useLeaguesQuery(baseOptions?: Apollo.QueryHookOptions<LeaguesQuery, LeaguesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LeaguesQuery, LeaguesQueryVariables>(LeaguesDocument, options);
}
export function useLeaguesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LeaguesQuery, LeaguesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LeaguesQuery, LeaguesQueryVariables>(LeaguesDocument, options);
}
export function useLeaguesSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<LeaguesQuery, LeaguesQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<LeaguesQuery, LeaguesQueryVariables>(LeaguesDocument, options);
}
export type LeaguesQueryHookResult = ReturnType<typeof useLeaguesQuery>;
export type LeaguesLazyQueryHookResult = ReturnType<typeof useLeaguesLazyQuery>;
export type LeaguesSuspenseQueryHookResult = ReturnType<typeof useLeaguesSuspenseQuery>;
export type LeaguesQueryResult = Apollo.QueryResult<LeaguesQuery, LeaguesQueryVariables>;
export const LeagueDocument = gql`
  query League($id: String!) {
    league(id: $id) {
      ...LeagueFragment
    }
  }
  ${LeagueFragmentFragmentDoc}
`;

/**
 * __useLeagueQuery__
 *
 * To run a query within a React component, call `useLeagueQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeagueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeagueQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLeagueQuery(
  baseOptions: Apollo.QueryHookOptions<LeagueQuery, LeagueQueryVariables> &
    ({ variables: LeagueQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LeagueQuery, LeagueQueryVariables>(LeagueDocument, options);
}
export function useLeagueLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LeagueQuery, LeagueQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LeagueQuery, LeagueQueryVariables>(LeagueDocument, options);
}
export function useLeagueSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<LeagueQuery, LeagueQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<LeagueQuery, LeagueQueryVariables>(LeagueDocument, options);
}
export type LeagueQueryHookResult = ReturnType<typeof useLeagueQuery>;
export type LeagueLazyQueryHookResult = ReturnType<typeof useLeagueLazyQuery>;
export type LeagueSuspenseQueryHookResult = ReturnType<typeof useLeagueSuspenseQuery>;
export type LeagueQueryResult = Apollo.QueryResult<LeagueQuery, LeagueQueryVariables>;
export const AcquireTokenDocument = gql`
  mutation AcquireToken($token: String, $stealLogin: Boolean) {
    acquireLoginToken(token: $token, stealLogin: $stealLogin)
  }
`;
export type AcquireTokenMutationFn = Apollo.MutationFunction<
  AcquireTokenMutation,
  AcquireTokenMutationVariables
>;

/**
 * __useAcquireTokenMutation__
 *
 * To run a mutation, you first call `useAcquireTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcquireTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acquireTokenMutation, { data, loading, error }] = useAcquireTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *      stealLogin: // value for 'stealLogin'
 *   },
 * });
 */
export function useAcquireTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<AcquireTokenMutation, AcquireTokenMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AcquireTokenMutation, AcquireTokenMutationVariables>(
    AcquireTokenDocument,
    options,
  );
}
export type AcquireTokenMutationHookResult = ReturnType<typeof useAcquireTokenMutation>;
export type AcquireTokenMutationResult = Apollo.MutationResult<AcquireTokenMutation>;
export type AcquireTokenMutationOptions = Apollo.BaseMutationOptions<
  AcquireTokenMutation,
  AcquireTokenMutationVariables
>;
export const ReleaseTokenDocument = gql`
  mutation ReleaseToken {
    releaseLoginToken
  }
`;
export type ReleaseTokenMutationFn = Apollo.MutationFunction<
  ReleaseTokenMutation,
  ReleaseTokenMutationVariables
>;

/**
 * __useReleaseTokenMutation__
 *
 * To run a mutation, you first call `useReleaseTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReleaseTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [releaseTokenMutation, { data, loading, error }] = useReleaseTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useReleaseTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<ReleaseTokenMutation, ReleaseTokenMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ReleaseTokenMutation, ReleaseTokenMutationVariables>(
    ReleaseTokenDocument,
    options,
  );
}
export type ReleaseTokenMutationHookResult = ReturnType<typeof useReleaseTokenMutation>;
export type ReleaseTokenMutationResult = Apollo.MutationResult<ReleaseTokenMutation>;
export type ReleaseTokenMutationOptions = Apollo.BaseMutationOptions<
  ReleaseTokenMutation,
  ReleaseTokenMutationVariables
>;
export const PartnersDocument = gql`
  query Partners(
    $seasonId: String!
    $leagueIds: [String!]!
    $relevance: PartnerRelevanceFilter
    $search: String
    $status: PartnerStatus
    $partnerCategoryId: String
    $clubLeadIds: [String!]
    $salesLeadIds: [String!]
    $expiringOnly: Boolean
  ) {
    partners(
      seasonId: $seasonId
      leagueIds: $leagueIds
      relevance: $relevance
      search: $search
      status: $status
      partnerCategoryId: $partnerCategoryId
      clubLeadIds: $clubLeadIds
      salesLeadIds: $salesLeadIds
      expiringOnly: $expiringOnly
    ) {
      pageInfo {
        endCursor
        hasNext
        startCursor
        totalCount
      }
      edges {
        node {
          ...PartnerFragment
        }
      }
      totals {
        totalPartners
        totalLeads
        bonuses
        bonusesValue
        bonusesAchieved
        bonusesAchievedValue
        discount
        totalBarter
        totalContracts
        totalPrice
        totalDone
        totalInProgress
        totalOpen
        totalSubTasks
        totalTasks
      }
    }
  }
  ${PartnerFragmentFragmentDoc}
`;

/**
 * __usePartnersQuery__
 *
 * To run a query within a React component, call `usePartnersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnersQuery({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      leagueIds: // value for 'leagueIds'
 *      relevance: // value for 'relevance'
 *      search: // value for 'search'
 *      status: // value for 'status'
 *      partnerCategoryId: // value for 'partnerCategoryId'
 *      clubLeadIds: // value for 'clubLeadIds'
 *      salesLeadIds: // value for 'salesLeadIds'
 *      expiringOnly: // value for 'expiringOnly'
 *   },
 * });
 */
export function usePartnersQuery(
  baseOptions: Apollo.QueryHookOptions<PartnersQuery, PartnersQueryVariables> &
    ({ variables: PartnersQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PartnersQuery, PartnersQueryVariables>(PartnersDocument, options);
}
export function usePartnersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PartnersQuery, PartnersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PartnersQuery, PartnersQueryVariables>(PartnersDocument, options);
}
export function usePartnersSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PartnersQuery, PartnersQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PartnersQuery, PartnersQueryVariables>(PartnersDocument, options);
}
export type PartnersQueryHookResult = ReturnType<typeof usePartnersQuery>;
export type PartnersLazyQueryHookResult = ReturnType<typeof usePartnersLazyQuery>;
export type PartnersSuspenseQueryHookResult = ReturnType<typeof usePartnersSuspenseQuery>;
export type PartnersQueryResult = Apollo.QueryResult<PartnersQuery, PartnersQueryVariables>;
export const CreatePartnerContactDocument = gql`
  mutation CreatePartnerContact($input: CreatePartnerContactInput!, $partnerId: String!) {
    createPartnerContact(input: $input, partnerId: $partnerId) {
      ...PartnerContactFragment
    }
  }
  ${PartnerContactFragmentFragmentDoc}
`;
export type CreatePartnerContactMutationFn = Apollo.MutationFunction<
  CreatePartnerContactMutation,
  CreatePartnerContactMutationVariables
>;

/**
 * __useCreatePartnerContactMutation__
 *
 * To run a mutation, you first call `useCreatePartnerContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePartnerContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPartnerContactMutation, { data, loading, error }] = useCreatePartnerContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useCreatePartnerContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePartnerContactMutation,
    CreatePartnerContactMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePartnerContactMutation, CreatePartnerContactMutationVariables>(
    CreatePartnerContactDocument,
    options,
  );
}
export type CreatePartnerContactMutationHookResult = ReturnType<typeof useCreatePartnerContactMutation>;
export type CreatePartnerContactMutationResult = Apollo.MutationResult<CreatePartnerContactMutation>;
export type CreatePartnerContactMutationOptions = Apollo.BaseMutationOptions<
  CreatePartnerContactMutation,
  CreatePartnerContactMutationVariables
>;
export const RemovePartnerContactDocument = gql`
  mutation RemovePartnerContact($contactId: String!, $partnerId: String!) {
    removePartnerContact(contactId: $contactId, partnerId: $partnerId)
  }
`;
export type RemovePartnerContactMutationFn = Apollo.MutationFunction<
  RemovePartnerContactMutation,
  RemovePartnerContactMutationVariables
>;

/**
 * __useRemovePartnerContactMutation__
 *
 * To run a mutation, you first call `useRemovePartnerContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePartnerContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePartnerContactMutation, { data, loading, error }] = useRemovePartnerContactMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useRemovePartnerContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemovePartnerContactMutation,
    RemovePartnerContactMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemovePartnerContactMutation, RemovePartnerContactMutationVariables>(
    RemovePartnerContactDocument,
    options,
  );
}
export type RemovePartnerContactMutationHookResult = ReturnType<typeof useRemovePartnerContactMutation>;
export type RemovePartnerContactMutationResult = Apollo.MutationResult<RemovePartnerContactMutation>;
export type RemovePartnerContactMutationOptions = Apollo.BaseMutationOptions<
  RemovePartnerContactMutation,
  RemovePartnerContactMutationVariables
>;
export const UpdatePartnerContactDocument = gql`
  mutation UpdatePartnerContact(
    $contactId: String!
    $input: UpdatePartnerContactInput!
    $partnerId: String!
  ) {
    updatePartnerContact(contactId: $contactId, input: $input, partnerId: $partnerId) {
      ...PartnerContactFragment
    }
  }
  ${PartnerContactFragmentFragmentDoc}
`;
export type UpdatePartnerContactMutationFn = Apollo.MutationFunction<
  UpdatePartnerContactMutation,
  UpdatePartnerContactMutationVariables
>;

/**
 * __useUpdatePartnerContactMutation__
 *
 * To run a mutation, you first call `useUpdatePartnerContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartnerContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartnerContactMutation, { data, loading, error }] = useUpdatePartnerContactMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      input: // value for 'input'
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useUpdatePartnerContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePartnerContactMutation,
    UpdatePartnerContactMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePartnerContactMutation, UpdatePartnerContactMutationVariables>(
    UpdatePartnerContactDocument,
    options,
  );
}
export type UpdatePartnerContactMutationHookResult = ReturnType<typeof useUpdatePartnerContactMutation>;
export type UpdatePartnerContactMutationResult = Apollo.MutationResult<UpdatePartnerContactMutation>;
export type UpdatePartnerContactMutationOptions = Apollo.BaseMutationOptions<
  UpdatePartnerContactMutation,
  UpdatePartnerContactMutationVariables
>;
export const CreatePartnerDocument = gql`
  mutation CreatePartner($input: CreatePartnerInput!) {
    createPartner(input: $input) {
      ...PartnerFragment
    }
  }
  ${PartnerFragmentFragmentDoc}
`;
export type CreatePartnerMutationFn = Apollo.MutationFunction<
  CreatePartnerMutation,
  CreatePartnerMutationVariables
>;

/**
 * __useCreatePartnerMutation__
 *
 * To run a mutation, you first call `useCreatePartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPartnerMutation, { data, loading, error }] = useCreatePartnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePartnerMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePartnerMutation, CreatePartnerMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePartnerMutation, CreatePartnerMutationVariables>(
    CreatePartnerDocument,
    options,
  );
}
export type CreatePartnerMutationHookResult = ReturnType<typeof useCreatePartnerMutation>;
export type CreatePartnerMutationResult = Apollo.MutationResult<CreatePartnerMutation>;
export type CreatePartnerMutationOptions = Apollo.BaseMutationOptions<
  CreatePartnerMutation,
  CreatePartnerMutationVariables
>;
export const PartnerExportDocument = gql`
  query PartnerExport($partnerId: String!) {
    partnerExport(id: $partnerId) {
      ...PartnerFragment
    }
  }
  ${PartnerFragmentFragmentDoc}
`;

/**
 * __usePartnerExportQuery__
 *
 * To run a query within a React component, call `usePartnerExportQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerExportQuery({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function usePartnerExportQuery(
  baseOptions: Apollo.QueryHookOptions<PartnerExportQuery, PartnerExportQueryVariables> &
    ({ variables: PartnerExportQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PartnerExportQuery, PartnerExportQueryVariables>(PartnerExportDocument, options);
}
export function usePartnerExportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PartnerExportQuery, PartnerExportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PartnerExportQuery, PartnerExportQueryVariables>(PartnerExportDocument, options);
}
export function usePartnerExportSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<PartnerExportQuery, PartnerExportQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PartnerExportQuery, PartnerExportQueryVariables>(
    PartnerExportDocument,
    options,
  );
}
export type PartnerExportQueryHookResult = ReturnType<typeof usePartnerExportQuery>;
export type PartnerExportLazyQueryHookResult = ReturnType<typeof usePartnerExportLazyQuery>;
export type PartnerExportSuspenseQueryHookResult = ReturnType<typeof usePartnerExportSuspenseQuery>;
export type PartnerExportQueryResult = Apollo.QueryResult<PartnerExportQuery, PartnerExportQueryVariables>;
export const PartnerDocument = gql`
  query Partner($partnerId: String!) {
    partner(id: $partnerId) {
      ...PartnerFragment
    }
  }
  ${PartnerFragmentFragmentDoc}
`;

/**
 * __usePartnerQuery__
 *
 * To run a query within a React component, call `usePartnerQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerQuery({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function usePartnerQuery(
  baseOptions: Apollo.QueryHookOptions<PartnerQuery, PartnerQueryVariables> &
    ({ variables: PartnerQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PartnerQuery, PartnerQueryVariables>(PartnerDocument, options);
}
export function usePartnerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PartnerQuery, PartnerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PartnerQuery, PartnerQueryVariables>(PartnerDocument, options);
}
export function usePartnerSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PartnerQuery, PartnerQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PartnerQuery, PartnerQueryVariables>(PartnerDocument, options);
}
export type PartnerQueryHookResult = ReturnType<typeof usePartnerQuery>;
export type PartnerLazyQueryHookResult = ReturnType<typeof usePartnerLazyQuery>;
export type PartnerSuspenseQueryHookResult = ReturnType<typeof usePartnerSuspenseQuery>;
export type PartnerQueryResult = Apollo.QueryResult<PartnerQuery, PartnerQueryVariables>;
export const CreatePartnerNoteDocument = gql`
  mutation CreatePartnerNote($input: CreatePartnerNoteInput!, $partnerId: String!) {
    createPartnerNote(input: $input, partnerId: $partnerId) {
      ...PartnerNoteFragment
    }
  }
  ${PartnerNoteFragmentFragmentDoc}
`;
export type CreatePartnerNoteMutationFn = Apollo.MutationFunction<
  CreatePartnerNoteMutation,
  CreatePartnerNoteMutationVariables
>;

/**
 * __useCreatePartnerNoteMutation__
 *
 * To run a mutation, you first call `useCreatePartnerNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePartnerNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPartnerNoteMutation, { data, loading, error }] = useCreatePartnerNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useCreatePartnerNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePartnerNoteMutation, CreatePartnerNoteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePartnerNoteMutation, CreatePartnerNoteMutationVariables>(
    CreatePartnerNoteDocument,
    options,
  );
}
export type CreatePartnerNoteMutationHookResult = ReturnType<typeof useCreatePartnerNoteMutation>;
export type CreatePartnerNoteMutationResult = Apollo.MutationResult<CreatePartnerNoteMutation>;
export type CreatePartnerNoteMutationOptions = Apollo.BaseMutationOptions<
  CreatePartnerNoteMutation,
  CreatePartnerNoteMutationVariables
>;
export const RemovePartnerNoteDocument = gql`
  mutation RemovePartnerNote($noteId: String!, $partnerId: String!) {
    removePartnerNote(noteId: $noteId, partnerId: $partnerId)
  }
`;
export type RemovePartnerNoteMutationFn = Apollo.MutationFunction<
  RemovePartnerNoteMutation,
  RemovePartnerNoteMutationVariables
>;

/**
 * __useRemovePartnerNoteMutation__
 *
 * To run a mutation, you first call `useRemovePartnerNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePartnerNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePartnerNoteMutation, { data, loading, error }] = useRemovePartnerNoteMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useRemovePartnerNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<RemovePartnerNoteMutation, RemovePartnerNoteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemovePartnerNoteMutation, RemovePartnerNoteMutationVariables>(
    RemovePartnerNoteDocument,
    options,
  );
}
export type RemovePartnerNoteMutationHookResult = ReturnType<typeof useRemovePartnerNoteMutation>;
export type RemovePartnerNoteMutationResult = Apollo.MutationResult<RemovePartnerNoteMutation>;
export type RemovePartnerNoteMutationOptions = Apollo.BaseMutationOptions<
  RemovePartnerNoteMutation,
  RemovePartnerNoteMutationVariables
>;
export const UpdatePartnerNoteDocument = gql`
  mutation UpdatePartnerNote($input: UpdatePartnerNoteInput!, $noteId: String!, $partnerId: String!) {
    updatePartnerNote(input: $input, noteId: $noteId, partnerId: $partnerId) {
      ...PartnerNoteFragment
    }
  }
  ${PartnerNoteFragmentFragmentDoc}
`;
export type UpdatePartnerNoteMutationFn = Apollo.MutationFunction<
  UpdatePartnerNoteMutation,
  UpdatePartnerNoteMutationVariables
>;

/**
 * __useUpdatePartnerNoteMutation__
 *
 * To run a mutation, you first call `useUpdatePartnerNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartnerNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartnerNoteMutation, { data, loading, error }] = useUpdatePartnerNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *      noteId: // value for 'noteId'
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useUpdatePartnerNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePartnerNoteMutation, UpdatePartnerNoteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePartnerNoteMutation, UpdatePartnerNoteMutationVariables>(
    UpdatePartnerNoteDocument,
    options,
  );
}
export type UpdatePartnerNoteMutationHookResult = ReturnType<typeof useUpdatePartnerNoteMutation>;
export type UpdatePartnerNoteMutationResult = Apollo.MutationResult<UpdatePartnerNoteMutation>;
export type UpdatePartnerNoteMutationOptions = Apollo.BaseMutationOptions<
  UpdatePartnerNoteMutation,
  UpdatePartnerNoteMutationVariables
>;
export const RemovePartnerDocument = gql`
  mutation RemovePartner($partnerId: String!) {
    removePartner(id: $partnerId)
  }
`;
export type RemovePartnerMutationFn = Apollo.MutationFunction<
  RemovePartnerMutation,
  RemovePartnerMutationVariables
>;

/**
 * __useRemovePartnerMutation__
 *
 * To run a mutation, you first call `useRemovePartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePartnerMutation, { data, loading, error }] = useRemovePartnerMutation({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useRemovePartnerMutation(
  baseOptions?: Apollo.MutationHookOptions<RemovePartnerMutation, RemovePartnerMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemovePartnerMutation, RemovePartnerMutationVariables>(
    RemovePartnerDocument,
    options,
  );
}
export type RemovePartnerMutationHookResult = ReturnType<typeof useRemovePartnerMutation>;
export type RemovePartnerMutationResult = Apollo.MutationResult<RemovePartnerMutation>;
export type RemovePartnerMutationOptions = Apollo.BaseMutationOptions<
  RemovePartnerMutation,
  RemovePartnerMutationVariables
>;
export const TestPartnerNameDocument = gql`
  query TestPartnerName($companyName: String!) {
    testPartnerName(companyName: $companyName) {
      id
      companyName
    }
  }
`;

/**
 * __useTestPartnerNameQuery__
 *
 * To run a query within a React component, call `useTestPartnerNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestPartnerNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestPartnerNameQuery({
 *   variables: {
 *      companyName: // value for 'companyName'
 *   },
 * });
 */
export function useTestPartnerNameQuery(
  baseOptions: Apollo.QueryHookOptions<TestPartnerNameQuery, TestPartnerNameQueryVariables> &
    ({ variables: TestPartnerNameQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TestPartnerNameQuery, TestPartnerNameQueryVariables>(
    TestPartnerNameDocument,
    options,
  );
}
export function useTestPartnerNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TestPartnerNameQuery, TestPartnerNameQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TestPartnerNameQuery, TestPartnerNameQueryVariables>(
    TestPartnerNameDocument,
    options,
  );
}
export function useTestPartnerNameSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<TestPartnerNameQuery, TestPartnerNameQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TestPartnerNameQuery, TestPartnerNameQueryVariables>(
    TestPartnerNameDocument,
    options,
  );
}
export type TestPartnerNameQueryHookResult = ReturnType<typeof useTestPartnerNameQuery>;
export type TestPartnerNameLazyQueryHookResult = ReturnType<typeof useTestPartnerNameLazyQuery>;
export type TestPartnerNameSuspenseQueryHookResult = ReturnType<typeof useTestPartnerNameSuspenseQuery>;
export type TestPartnerNameQueryResult = Apollo.QueryResult<
  TestPartnerNameQuery,
  TestPartnerNameQueryVariables
>;
export const UpdatePartnerDocument = gql`
  mutation UpdatePartner($partnerId: String!, $input: UpdatePartnerInput!) {
    updatePartner(id: $partnerId, input: $input) {
      ...PartnerFragment
    }
  }
  ${PartnerFragmentFragmentDoc}
`;
export type UpdatePartnerMutationFn = Apollo.MutationFunction<
  UpdatePartnerMutation,
  UpdatePartnerMutationVariables
>;

/**
 * __useUpdatePartnerMutation__
 *
 * To run a mutation, you first call `useUpdatePartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartnerMutation, { data, loading, error }] = useUpdatePartnerMutation({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePartnerMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePartnerMutation, UpdatePartnerMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePartnerMutation, UpdatePartnerMutationVariables>(
    UpdatePartnerDocument,
    options,
  );
}
export type UpdatePartnerMutationHookResult = ReturnType<typeof useUpdatePartnerMutation>;
export type UpdatePartnerMutationResult = Apollo.MutationResult<UpdatePartnerMutation>;
export type UpdatePartnerMutationOptions = Apollo.BaseMutationOptions<
  UpdatePartnerMutation,
  UpdatePartnerMutationVariables
>;
export const SeasonsWithMatchesDocument = gql`
  query SeasonsWithMatches($pageSize: Float, $cursor: String, $search: String) {
    seasons(pageSize: $pageSize, cursor: $cursor, search: $search) {
      edges {
        node {
          ...SeasonFragment
          matches {
            ...MatchFragment
          }
        }
      }
    }
  }
  ${SeasonFragmentFragmentDoc}
  ${MatchFragmentFragmentDoc}
`;

/**
 * __useSeasonsWithMatchesQuery__
 *
 * To run a query within a React component, call `useSeasonsWithMatchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeasonsWithMatchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeasonsWithMatchesQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      cursor: // value for 'cursor'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSeasonsWithMatchesQuery(
  baseOptions?: Apollo.QueryHookOptions<SeasonsWithMatchesQuery, SeasonsWithMatchesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SeasonsWithMatchesQuery, SeasonsWithMatchesQueryVariables>(
    SeasonsWithMatchesDocument,
    options,
  );
}
export function useSeasonsWithMatchesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SeasonsWithMatchesQuery, SeasonsWithMatchesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SeasonsWithMatchesQuery, SeasonsWithMatchesQueryVariables>(
    SeasonsWithMatchesDocument,
    options,
  );
}
export function useSeasonsWithMatchesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<SeasonsWithMatchesQuery, SeasonsWithMatchesQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SeasonsWithMatchesQuery, SeasonsWithMatchesQueryVariables>(
    SeasonsWithMatchesDocument,
    options,
  );
}
export type SeasonsWithMatchesQueryHookResult = ReturnType<typeof useSeasonsWithMatchesQuery>;
export type SeasonsWithMatchesLazyQueryHookResult = ReturnType<typeof useSeasonsWithMatchesLazyQuery>;
export type SeasonsWithMatchesSuspenseQueryHookResult = ReturnType<typeof useSeasonsWithMatchesSuspenseQuery>;
export type SeasonsWithMatchesQueryResult = Apollo.QueryResult<
  SeasonsWithMatchesQuery,
  SeasonsWithMatchesQueryVariables
>;
export const SeasonsDocument = gql`
  query Seasons($pageSize: Float, $cursor: String, $search: String) {
    seasons(pageSize: $pageSize, cursor: $cursor, search: $search) {
      edges {
        node {
          ...SeasonFragment
        }
      }
    }
  }
  ${SeasonFragmentFragmentDoc}
`;

/**
 * __useSeasonsQuery__
 *
 * To run a query within a React component, call `useSeasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeasonsQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      cursor: // value for 'cursor'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSeasonsQuery(baseOptions?: Apollo.QueryHookOptions<SeasonsQuery, SeasonsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SeasonsQuery, SeasonsQueryVariables>(SeasonsDocument, options);
}
export function useSeasonsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SeasonsQuery, SeasonsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SeasonsQuery, SeasonsQueryVariables>(SeasonsDocument, options);
}
export function useSeasonsSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SeasonsQuery, SeasonsQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SeasonsQuery, SeasonsQueryVariables>(SeasonsDocument, options);
}
export type SeasonsQueryHookResult = ReturnType<typeof useSeasonsQuery>;
export type SeasonsLazyQueryHookResult = ReturnType<typeof useSeasonsLazyQuery>;
export type SeasonsSuspenseQueryHookResult = ReturnType<typeof useSeasonsSuspenseQuery>;
export type SeasonsQueryResult = Apollo.QueryResult<SeasonsQuery, SeasonsQueryVariables>;
export const CreateNextSeasonDocument = gql`
  mutation CreateNextSeason {
    createNextSeason {
      ...SeasonFragment
    }
  }
  ${SeasonFragmentFragmentDoc}
`;
export type CreateNextSeasonMutationFn = Apollo.MutationFunction<
  CreateNextSeasonMutation,
  CreateNextSeasonMutationVariables
>;

/**
 * __useCreateNextSeasonMutation__
 *
 * To run a mutation, you first call `useCreateNextSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNextSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNextSeasonMutation, { data, loading, error }] = useCreateNextSeasonMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateNextSeasonMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateNextSeasonMutation, CreateNextSeasonMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateNextSeasonMutation, CreateNextSeasonMutationVariables>(
    CreateNextSeasonDocument,
    options,
  );
}
export type CreateNextSeasonMutationHookResult = ReturnType<typeof useCreateNextSeasonMutation>;
export type CreateNextSeasonMutationResult = Apollo.MutationResult<CreateNextSeasonMutation>;
export type CreateNextSeasonMutationOptions = Apollo.BaseMutationOptions<
  CreateNextSeasonMutation,
  CreateNextSeasonMutationVariables
>;
export const CreateSeasonDocument = gql`
  mutation CreateSeason($input: CreateSeasonInput!) {
    createSeason(input: $input) {
      ...SeasonFragment
    }
  }
  ${SeasonFragmentFragmentDoc}
`;
export type CreateSeasonMutationFn = Apollo.MutationFunction<
  CreateSeasonMutation,
  CreateSeasonMutationVariables
>;

/**
 * __useCreateSeasonMutation__
 *
 * To run a mutation, you first call `useCreateSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSeasonMutation, { data, loading, error }] = useCreateSeasonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSeasonMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateSeasonMutation, CreateSeasonMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateSeasonMutation, CreateSeasonMutationVariables>(
    CreateSeasonDocument,
    options,
  );
}
export type CreateSeasonMutationHookResult = ReturnType<typeof useCreateSeasonMutation>;
export type CreateSeasonMutationResult = Apollo.MutationResult<CreateSeasonMutation>;
export type CreateSeasonMutationOptions = Apollo.BaseMutationOptions<
  CreateSeasonMutation,
  CreateSeasonMutationVariables
>;
export const SeasonDocument = gql`
  query Season($id: String!) {
    season(id: $id) {
      ...SeasonFragment
      matches {
        ...MatchFragment
      }
    }
  }
  ${SeasonFragmentFragmentDoc}
  ${MatchFragmentFragmentDoc}
`;

/**
 * __useSeasonQuery__
 *
 * To run a query within a React component, call `useSeasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeasonQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSeasonQuery(
  baseOptions: Apollo.QueryHookOptions<SeasonQuery, SeasonQueryVariables> &
    ({ variables: SeasonQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SeasonQuery, SeasonQueryVariables>(SeasonDocument, options);
}
export function useSeasonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SeasonQuery, SeasonQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SeasonQuery, SeasonQueryVariables>(SeasonDocument, options);
}
export function useSeasonSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SeasonQuery, SeasonQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SeasonQuery, SeasonQueryVariables>(SeasonDocument, options);
}
export type SeasonQueryHookResult = ReturnType<typeof useSeasonQuery>;
export type SeasonLazyQueryHookResult = ReturnType<typeof useSeasonLazyQuery>;
export type SeasonSuspenseQueryHookResult = ReturnType<typeof useSeasonSuspenseQuery>;
export type SeasonQueryResult = Apollo.QueryResult<SeasonQuery, SeasonQueryVariables>;
export const CreateSeasonMatchDocument = gql`
  mutation CreateSeasonMatch($input: CreateSeasonMatchInput!, $seasonId: String!) {
    createSeasonMatch(input: $input, seasonId: $seasonId) {
      ...MatchFragment
    }
  }
  ${MatchFragmentFragmentDoc}
`;
export type CreateSeasonMatchMutationFn = Apollo.MutationFunction<
  CreateSeasonMatchMutation,
  CreateSeasonMatchMutationVariables
>;

/**
 * __useCreateSeasonMatchMutation__
 *
 * To run a mutation, you first call `useCreateSeasonMatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSeasonMatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSeasonMatchMutation, { data, loading, error }] = useCreateSeasonMatchMutation({
 *   variables: {
 *      input: // value for 'input'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useCreateSeasonMatchMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateSeasonMatchMutation, CreateSeasonMatchMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateSeasonMatchMutation, CreateSeasonMatchMutationVariables>(
    CreateSeasonMatchDocument,
    options,
  );
}
export type CreateSeasonMatchMutationHookResult = ReturnType<typeof useCreateSeasonMatchMutation>;
export type CreateSeasonMatchMutationResult = Apollo.MutationResult<CreateSeasonMatchMutation>;
export type CreateSeasonMatchMutationOptions = Apollo.BaseMutationOptions<
  CreateSeasonMatchMutation,
  CreateSeasonMatchMutationVariables
>;
export const RemoveMatchDocument = gql`
  mutation RemoveMatch($matchId: String!, $seasonId: String!) {
    removeSeasonMatch(matchId: $matchId, seasonId: $seasonId)
  }
`;
export type RemoveMatchMutationFn = Apollo.MutationFunction<
  RemoveMatchMutation,
  RemoveMatchMutationVariables
>;

/**
 * __useRemoveMatchMutation__
 *
 * To run a mutation, you first call `useRemoveMatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMatchMutation, { data, loading, error }] = useRemoveMatchMutation({
 *   variables: {
 *      matchId: // value for 'matchId'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useRemoveMatchMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveMatchMutation, RemoveMatchMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveMatchMutation, RemoveMatchMutationVariables>(RemoveMatchDocument, options);
}
export type RemoveMatchMutationHookResult = ReturnType<typeof useRemoveMatchMutation>;
export type RemoveMatchMutationResult = Apollo.MutationResult<RemoveMatchMutation>;
export type RemoveMatchMutationOptions = Apollo.BaseMutationOptions<
  RemoveMatchMutation,
  RemoveMatchMutationVariables
>;
export const UpdateMatchDocument = gql`
  mutation UpdateMatch($input: UpdateSeasonMatchInput!, $matchId: String!, $seasonId: String!) {
    updateSeasonMatch(input: $input, matchId: $matchId, seasonId: $seasonId) {
      ...MatchFragment
    }
  }
  ${MatchFragmentFragmentDoc}
`;
export type UpdateMatchMutationFn = Apollo.MutationFunction<
  UpdateMatchMutation,
  UpdateMatchMutationVariables
>;

/**
 * __useUpdateMatchMutation__
 *
 * To run a mutation, you first call `useUpdateMatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMatchMutation, { data, loading, error }] = useUpdateMatchMutation({
 *   variables: {
 *      input: // value for 'input'
 *      matchId: // value for 'matchId'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useUpdateMatchMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMatchMutation, UpdateMatchMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMatchMutation, UpdateMatchMutationVariables>(UpdateMatchDocument, options);
}
export type UpdateMatchMutationHookResult = ReturnType<typeof useUpdateMatchMutation>;
export type UpdateMatchMutationResult = Apollo.MutationResult<UpdateMatchMutation>;
export type UpdateMatchMutationOptions = Apollo.BaseMutationOptions<
  UpdateMatchMutation,
  UpdateMatchMutationVariables
>;
export const UpdateSeasonDocument = gql`
  mutation UpdateSeason($seasonId: String!, $input: UpdateSeasonInput!) {
    updateSeason(id: $seasonId, input: $input) {
      ...SeasonFragment
    }
  }
  ${SeasonFragmentFragmentDoc}
`;
export type UpdateSeasonMutationFn = Apollo.MutationFunction<
  UpdateSeasonMutation,
  UpdateSeasonMutationVariables
>;

/**
 * __useUpdateSeasonMutation__
 *
 * To run a mutation, you first call `useUpdateSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSeasonMutation, { data, loading, error }] = useUpdateSeasonMutation({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSeasonMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSeasonMutation, UpdateSeasonMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSeasonMutation, UpdateSeasonMutationVariables>(
    UpdateSeasonDocument,
    options,
  );
}
export type UpdateSeasonMutationHookResult = ReturnType<typeof useUpdateSeasonMutation>;
export type UpdateSeasonMutationResult = Apollo.MutationResult<UpdateSeasonMutation>;
export type UpdateSeasonMutationOptions = Apollo.BaseMutationOptions<
  UpdateSeasonMutation,
  UpdateSeasonMutationVariables
>;
export const ConfigureSeasonWorkflowDocument = gql`
  mutation ConfigureSeasonWorkflow(
    $competitionLeagues: [CompetitionLeagueInput!]!
    $copySettings: Boolean!
    $seasonId: String!
  ) {
    configureSeasonWorkflow(
      competitionLeagues: $competitionLeagues
      copySettings: $copySettings
      seasonId: $seasonId
    )
  }
`;
export type ConfigureSeasonWorkflowMutationFn = Apollo.MutationFunction<
  ConfigureSeasonWorkflowMutation,
  ConfigureSeasonWorkflowMutationVariables
>;

/**
 * __useConfigureSeasonWorkflowMutation__
 *
 * To run a mutation, you first call `useConfigureSeasonWorkflowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfigureSeasonWorkflowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [configureSeasonWorkflowMutation, { data, loading, error }] = useConfigureSeasonWorkflowMutation({
 *   variables: {
 *      competitionLeagues: // value for 'competitionLeagues'
 *      copySettings: // value for 'copySettings'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useConfigureSeasonWorkflowMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfigureSeasonWorkflowMutation,
    ConfigureSeasonWorkflowMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConfigureSeasonWorkflowMutation, ConfigureSeasonWorkflowMutationVariables>(
    ConfigureSeasonWorkflowDocument,
    options,
  );
}
export type ConfigureSeasonWorkflowMutationHookResult = ReturnType<typeof useConfigureSeasonWorkflowMutation>;
export type ConfigureSeasonWorkflowMutationResult = Apollo.MutationResult<ConfigureSeasonWorkflowMutation>;
export type ConfigureSeasonWorkflowMutationOptions = Apollo.BaseMutationOptions<
  ConfigureSeasonWorkflowMutation,
  ConfigureSeasonWorkflowMutationVariables
>;
export const CreateSubTaskDocument = gql`
  mutation CreateSubTask($taskId: String!, $input: CreateSubTaskInput!) {
    createSubTask(taskId: $taskId, input: $input) {
      ...SubTaskFragment
    }
  }
  ${SubTaskFragmentFragmentDoc}
`;
export type CreateSubTaskMutationFn = Apollo.MutationFunction<
  CreateSubTaskMutation,
  CreateSubTaskMutationVariables
>;

/**
 * __useCreateSubTaskMutation__
 *
 * To run a mutation, you first call `useCreateSubTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubTaskMutation, { data, loading, error }] = useCreateSubTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateSubTaskMutation, CreateSubTaskMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateSubTaskMutation, CreateSubTaskMutationVariables>(
    CreateSubTaskDocument,
    options,
  );
}
export type CreateSubTaskMutationHookResult = ReturnType<typeof useCreateSubTaskMutation>;
export type CreateSubTaskMutationResult = Apollo.MutationResult<CreateSubTaskMutation>;
export type CreateSubTaskMutationOptions = Apollo.BaseMutationOptions<
  CreateSubTaskMutation,
  CreateSubTaskMutationVariables
>;
export const CreateTaskDocument = gql`
  mutation CreateTask($input: CreateTaskInput!) {
    createTask(input: $input) {
      ...TaskFragment
    }
  }
  ${TaskFragmentFragmentDoc}
`;
export type CreateTaskMutationFn = Apollo.MutationFunction<CreateTaskMutation, CreateTaskMutationVariables>;

/**
 * __useCreateTaskMutation__
 *
 * To run a mutation, you first call `useCreateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskMutation, { data, loading, error }] = useCreateTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTaskMutation, CreateTaskMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTaskMutation, CreateTaskMutationVariables>(CreateTaskDocument, options);
}
export type CreateTaskMutationHookResult = ReturnType<typeof useCreateTaskMutation>;
export type CreateTaskMutationResult = Apollo.MutationResult<CreateTaskMutation>;
export type CreateTaskMutationOptions = Apollo.BaseMutationOptions<
  CreateTaskMutation,
  CreateTaskMutationVariables
>;
export const CreateTasksForContractDocument = gql`
  mutation CreateTasksForContract($contractId: String!, $seasonId: String!) {
    createTasksForContract(contractId: $contractId, seasonId: $seasonId)
  }
`;
export type CreateTasksForContractMutationFn = Apollo.MutationFunction<
  CreateTasksForContractMutation,
  CreateTasksForContractMutationVariables
>;

/**
 * __useCreateTasksForContractMutation__
 *
 * To run a mutation, you first call `useCreateTasksForContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTasksForContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTasksForContractMutation, { data, loading, error }] = useCreateTasksForContractMutation({
 *   variables: {
 *      contractId: // value for 'contractId'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useCreateTasksForContractMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTasksForContractMutation,
    CreateTasksForContractMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTasksForContractMutation, CreateTasksForContractMutationVariables>(
    CreateTasksForContractDocument,
    options,
  );
}
export type CreateTasksForContractMutationHookResult = ReturnType<typeof useCreateTasksForContractMutation>;
export type CreateTasksForContractMutationResult = Apollo.MutationResult<CreateTasksForContractMutation>;
export type CreateTasksForContractMutationOptions = Apollo.BaseMutationOptions<
  CreateTasksForContractMutation,
  CreateTasksForContractMutationVariables
>;
export const MatchTasksDocument = gql`
  query MatchTasks($matchId: String!, $seasonId: String!) {
    matchTasks(seasonId: $seasonId, matchId: $matchId) {
      match {
        ...MatchTaskFragment
      }
    }
  }
  ${MatchTaskFragmentFragmentDoc}
`;

/**
 * __useMatchTasksQuery__
 *
 * To run a query within a React component, call `useMatchTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useMatchTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMatchTasksQuery({
 *   variables: {
 *      matchId: // value for 'matchId'
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useMatchTasksQuery(
  baseOptions: Apollo.QueryHookOptions<MatchTasksQuery, MatchTasksQueryVariables> &
    ({ variables: MatchTasksQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MatchTasksQuery, MatchTasksQueryVariables>(MatchTasksDocument, options);
}
export function useMatchTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MatchTasksQuery, MatchTasksQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MatchTasksQuery, MatchTasksQueryVariables>(MatchTasksDocument, options);
}
export function useMatchTasksSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MatchTasksQuery, MatchTasksQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MatchTasksQuery, MatchTasksQueryVariables>(MatchTasksDocument, options);
}
export type MatchTasksQueryHookResult = ReturnType<typeof useMatchTasksQuery>;
export type MatchTasksLazyQueryHookResult = ReturnType<typeof useMatchTasksLazyQuery>;
export type MatchTasksSuspenseQueryHookResult = ReturnType<typeof useMatchTasksSuspenseQuery>;
export type MatchTasksQueryResult = Apollo.QueryResult<MatchTasksQuery, MatchTasksQueryVariables>;
export const RemoveSubTaskDocument = gql`
  mutation RemoveSubTask($subTaskId: String!, $taskId: String!) {
    removeSubTask(subTaskId: $subTaskId, taskId: $taskId)
  }
`;
export type RemoveSubTaskMutationFn = Apollo.MutationFunction<
  RemoveSubTaskMutation,
  RemoveSubTaskMutationVariables
>;

/**
 * __useRemoveSubTaskMutation__
 *
 * To run a mutation, you first call `useRemoveSubTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSubTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSubTaskMutation, { data, loading, error }] = useRemoveSubTaskMutation({
 *   variables: {
 *      subTaskId: // value for 'subTaskId'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useRemoveSubTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveSubTaskMutation, RemoveSubTaskMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveSubTaskMutation, RemoveSubTaskMutationVariables>(
    RemoveSubTaskDocument,
    options,
  );
}
export type RemoveSubTaskMutationHookResult = ReturnType<typeof useRemoveSubTaskMutation>;
export type RemoveSubTaskMutationResult = Apollo.MutationResult<RemoveSubTaskMutation>;
export type RemoveSubTaskMutationOptions = Apollo.BaseMutationOptions<
  RemoveSubTaskMutation,
  RemoveSubTaskMutationVariables
>;
export const RemoveTaskDocument = gql`
  mutation RemoveTask($id: String!) {
    removeTask(id: $id)
  }
`;
export type RemoveTaskMutationFn = Apollo.MutationFunction<RemoveTaskMutation, RemoveTaskMutationVariables>;

/**
 * __useRemoveTaskMutation__
 *
 * To run a mutation, you first call `useRemoveTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTaskMutation, { data, loading, error }] = useRemoveTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveTaskMutation, RemoveTaskMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveTaskMutation, RemoveTaskMutationVariables>(RemoveTaskDocument, options);
}
export type RemoveTaskMutationHookResult = ReturnType<typeof useRemoveTaskMutation>;
export type RemoveTaskMutationResult = Apollo.MutationResult<RemoveTaskMutation>;
export type RemoveTaskMutationOptions = Apollo.BaseMutationOptions<
  RemoveTaskMutation,
  RemoveTaskMutationVariables
>;
export const UpdateSubTaskDocument = gql`
  mutation UpdateSubTask($subTaskId: String!, $taskId: String!, $input: UpdateSubTaskInput!) {
    updateSubTask(subTaskId: $subTaskId, taskId: $taskId, input: $input) {
      ...SubTaskFragment
    }
  }
  ${SubTaskFragmentFragmentDoc}
`;
export type UpdateSubTaskMutationFn = Apollo.MutationFunction<
  UpdateSubTaskMutation,
  UpdateSubTaskMutationVariables
>;

/**
 * __useUpdateSubTaskMutation__
 *
 * To run a mutation, you first call `useUpdateSubTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubTaskMutation, { data, loading, error }] = useUpdateSubTaskMutation({
 *   variables: {
 *      subTaskId: // value for 'subTaskId'
 *      taskId: // value for 'taskId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSubTaskMutation, UpdateSubTaskMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSubTaskMutation, UpdateSubTaskMutationVariables>(
    UpdateSubTaskDocument,
    options,
  );
}
export type UpdateSubTaskMutationHookResult = ReturnType<typeof useUpdateSubTaskMutation>;
export type UpdateSubTaskMutationResult = Apollo.MutationResult<UpdateSubTaskMutation>;
export type UpdateSubTaskMutationOptions = Apollo.BaseMutationOptions<
  UpdateSubTaskMutation,
  UpdateSubTaskMutationVariables
>;
export const UpdateTaskDocument = gql`
  mutation UpdateTask($id: String!, $input: UpdateTaskInput!) {
    updateTask(id: $id, input: $input) {
      ...TaskFragment
    }
  }
  ${TaskFragmentFragmentDoc}
`;
export type UpdateTaskMutationFn = Apollo.MutationFunction<UpdateTaskMutation, UpdateTaskMutationVariables>;

/**
 * __useUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMutation, { data, loading, error }] = useUpdateTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTaskMutation, UpdateTaskMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(UpdateTaskDocument, options);
}
export type UpdateTaskMutationHookResult = ReturnType<typeof useUpdateTaskMutation>;
export type UpdateTaskMutationResult = Apollo.MutationResult<UpdateTaskMutation>;
export type UpdateTaskMutationOptions = Apollo.BaseMutationOptions<
  UpdateTaskMutation,
  UpdateTaskMutationVariables
>;
export const UsersDocument = gql`
  query Users($search: String) {
    users(search: $search) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
}
export function useUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
}
export function useUsersSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UsersQuery, UsersQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
}
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersSuspenseQueryHookResult = ReturnType<typeof useUsersSuspenseQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const ChangeUserEmailDocument = gql`
  mutation ChangeUserEmail($id: String!, $input: ChangeUserEmailInput!) {
    changeUserEmail(id: $id, input: $input) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type ChangeUserEmailMutationFn = Apollo.MutationFunction<
  ChangeUserEmailMutation,
  ChangeUserEmailMutationVariables
>;

/**
 * __useChangeUserEmailMutation__
 *
 * To run a mutation, you first call `useChangeUserEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserEmailMutation, { data, loading, error }] = useChangeUserEmailMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeUserEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<ChangeUserEmailMutation, ChangeUserEmailMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangeUserEmailMutation, ChangeUserEmailMutationVariables>(
    ChangeUserEmailDocument,
    options,
  );
}
export type ChangeUserEmailMutationHookResult = ReturnType<typeof useChangeUserEmailMutation>;
export type ChangeUserEmailMutationResult = Apollo.MutationResult<ChangeUserEmailMutation>;
export type ChangeUserEmailMutationOptions = Apollo.BaseMutationOptions<
  ChangeUserEmailMutation,
  ChangeUserEmailMutationVariables
>;
export const CreateUserDocument = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
}
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>;
export const UserDocument = gql`
  query User($id: String!) {
    user(id: $id) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(
  baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables> &
    ({ variables: UserQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export function useUserSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserQuery, UserQueryVariables>,
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserSuspenseQueryHookResult = ReturnType<typeof useUserSuspenseQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const ResetMyPasswordDocument = gql`
  mutation ResetMyPassword($email: String!) {
    resetMyPassword(email: $email)
  }
`;
export type ResetMyPasswordMutationFn = Apollo.MutationFunction<
  ResetMyPasswordMutation,
  ResetMyPasswordMutationVariables
>;

/**
 * __useResetMyPasswordMutation__
 *
 * To run a mutation, you first call `useResetMyPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetMyPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetMyPasswordMutation, { data, loading, error }] = useResetMyPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResetMyPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<ResetMyPasswordMutation, ResetMyPasswordMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResetMyPasswordMutation, ResetMyPasswordMutationVariables>(
    ResetMyPasswordDocument,
    options,
  );
}
export type ResetMyPasswordMutationHookResult = ReturnType<typeof useResetMyPasswordMutation>;
export type ResetMyPasswordMutationResult = Apollo.MutationResult<ResetMyPasswordMutation>;
export type ResetMyPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetMyPasswordMutation,
  ResetMyPasswordMutationVariables
>;
export const PasswordResetDocument = gql`
  mutation PasswordReset($id: String!) {
    resetPassword(id: $id)
  }
`;
export type PasswordResetMutationFn = Apollo.MutationFunction<
  PasswordResetMutation,
  PasswordResetMutationVariables
>;

/**
 * __usePasswordResetMutation__
 *
 * To run a mutation, you first call `usePasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordResetMutation, { data, loading, error }] = usePasswordResetMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePasswordResetMutation(
  baseOptions?: Apollo.MutationHookOptions<PasswordResetMutation, PasswordResetMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PasswordResetMutation, PasswordResetMutationVariables>(
    PasswordResetDocument,
    options,
  );
}
export type PasswordResetMutationHookResult = ReturnType<typeof usePasswordResetMutation>;
export type PasswordResetMutationResult = Apollo.MutationResult<PasswordResetMutation>;
export type PasswordResetMutationOptions = Apollo.BaseMutationOptions<
  PasswordResetMutation,
  PasswordResetMutationVariables
>;
export const RemoveUserDocument = gql`
  mutation RemoveUser($id: String!) {
    removeUser(id: $id)
  }
`;
export type RemoveUserMutationFn = Apollo.MutationFunction<RemoveUserMutation, RemoveUserMutationVariables>;

/**
 * __useRemoveUserMutation__
 *
 * To run a mutation, you first call `useRemoveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserMutation, { data, loading, error }] = useRemoveUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveUserMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveUserMutation, RemoveUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveUserMutation, RemoveUserMutationVariables>(RemoveUserDocument, options);
}
export type RemoveUserMutationHookResult = ReturnType<typeof useRemoveUserMutation>;
export type RemoveUserMutationResult = Apollo.MutationResult<RemoveUserMutation>;
export type RemoveUserMutationOptions = Apollo.BaseMutationOptions<
  RemoveUserMutation,
  RemoveUserMutationVariables
>;
export const UpdateAccountUserDocument = gql`
  mutation UpdateAccountUser($id: String!, $input: UpdateAccountUserInput!) {
    updateAccountUser(id: $id, input: $input) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type UpdateAccountUserMutationFn = Apollo.MutationFunction<
  UpdateAccountUserMutation,
  UpdateAccountUserMutationVariables
>;

/**
 * __useUpdateAccountUserMutation__
 *
 * To run a mutation, you first call `useUpdateAccountUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountUserMutation, { data, loading, error }] = useUpdateAccountUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountUserMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAccountUserMutation, UpdateAccountUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAccountUserMutation, UpdateAccountUserMutationVariables>(
    UpdateAccountUserDocument,
    options,
  );
}
export type UpdateAccountUserMutationHookResult = ReturnType<typeof useUpdateAccountUserMutation>;
export type UpdateAccountUserMutationResult = Apollo.MutationResult<UpdateAccountUserMutation>;
export type UpdateAccountUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateAccountUserMutation,
  UpdateAccountUserMutationVariables
>;
export const UpdateMeDocument = gql`
  mutation UpdateMe($input: UpdateMeInput!) {
    updateMe(input: $input) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type UpdateMeMutationFn = Apollo.MutationFunction<UpdateMeMutation, UpdateMeMutationVariables>;

/**
 * __useUpdateMeMutation__
 *
 * To run a mutation, you first call `useUpdateMeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeMutation, { data, loading, error }] = useUpdateMeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMeMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMeMutation, UpdateMeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMeMutation, UpdateMeMutationVariables>(UpdateMeDocument, options);
}
export type UpdateMeMutationHookResult = ReturnType<typeof useUpdateMeMutation>;
export type UpdateMeMutationResult = Apollo.MutationResult<UpdateMeMutation>;
export type UpdateMeMutationOptions = Apollo.BaseMutationOptions<UpdateMeMutation, UpdateMeMutationVariables>;
export const UpdateUserDocument = gql`
  mutation UpdateUser($id: String!, $input: UpdateUserInput!) {
    updateUser(id: $id, input: $input) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
